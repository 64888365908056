import { createSlice } from "@reduxjs/toolkit";
import type { WorkflowStatus } from "api/workflows/models/WorkflowStatus";
import omit from "lodash/omit";
import { downloadProgressSteps } from "state/progressSteps/operations";

export type WorkflowStatusStep = {
  [key in WorkflowStatus]: {
    count: number;
    latestCreationDate: string;
    latestCompletionDate: string | null;
  };
};

export type IntentStatuses = Record<string, WorkflowStatusStep>;

interface ProgressStepsState {
  progressIntentStatusByWorkflowId: Record<string, IntentStatuses>;
  isLoadingSteps: Record<string, boolean>;
}

const initialState: ProgressStepsState = {
  progressIntentStatusByWorkflowId: {},
  isLoadingSteps: {},
};

export const { actions, reducer } = createSlice({
  name: "progressSteps",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadProgressSteps.pending, (state, { meta }) => {
      state.isLoadingSteps = Object.assign(
        {},
        state.isLoadingSteps,
        meta.arg.workflowIds.reduce((acc, id) => ({ ...acc, [id]: true }), {})
      );
    });
    builder.addCase(downloadProgressSteps.rejected, (state, { meta }) => {
      const { workflowIds } = meta.arg;
      state.isLoadingSteps = omit(state.isLoadingSteps, workflowIds);
    });
    builder.addCase(downloadProgressSteps.fulfilled, (state, { payload, meta }) => {
      const { workflowIds } = meta.arg;
      state.isLoadingSteps = omit(state.isLoadingSteps, workflowIds);

      payload.forEach((workflowSteps) => {
        if (workflowSteps.intentStatus) {
          state.progressIntentStatusByWorkflowId[workflowSteps.id] = workflowSteps.intentStatus;
        }
      });
    });
  },
});

export default reducer;
