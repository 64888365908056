import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadBillingPlans } from "state/billingPlans/operations";
import type { RootState } from "state/rootReducer";
import type { Plan } from "types/billingPlans/Plan";
import type { PlanUser } from "types/billingPlans/PlanUser";
import type { Entitlement } from "types/entitlements";
import { useEntitlements } from "./useEntitlements";

export function useBillingPlans(): Plan[] {
  const { plans, planUsers } = useSelector((state: RootState) => state.billingPlans);
  const dispatch = useDispatch();
  const entitlements = useEntitlements();
  const { manage_billing_plans_read: hasBillingPlansRead } = entitlements;

  const billingPlans = useMemo(() => {
    return Object.values(plans).map((plan) => {
      const { planUsersIds, ...restOfPlan } = plan;

      return {
        ...restOfPlan,
        planUsers: planUsersIds.map((id) => planUsers[id]),
      };
    });
  }, [plans, planUsers]);

  useEffect(() => {
    if (!hasBillingPlansRead) {
      return;
    }

    dispatch(downloadBillingPlans());
  }, [dispatch, hasBillingPlansRead]);

  return billingPlans;
}

export function useBillingPlan(code: string | null): Plan | undefined {
  const { plans, planUsers } = useSelector((state: RootState) => state.billingPlans);

  const plan = useMemo(() => {
    if (!code) {
      return;
    }

    const maybePlan = plans[code];

    if (!maybePlan) {
      return;
    }

    const { planUsersIds, ...restOfPlan } = maybePlan;

    return {
      ...restOfPlan,
      planUsers: planUsersIds.map((id) => planUsers[id]),
    };
  }, [plans, code, planUsers]);

  return plan;
}

export function useBillingPlanUsersMap(): Record<string, PlanUser> {
  const planUsers = useSelector((root: RootState) => root.billingPlans.planUsers);

  return useMemo(() => Object.values(planUsers).reduce((acc, user) => ({ ...acc, [user.userId]: user }), {}), [planUsers]);
}

export function useIsBillingPlanLoading(): boolean {
  return useSelector((root: RootState) => root.billingPlans.loading);
}

export function useBillingPlanEntitlements(planCode: string | null): { entitlements: Entitlement[]; isLoading: boolean } {
  return useSelector((root: RootState) => ({
    entitlements: !planCode ? [] : root.billingPlans.planEntitlements[planCode] ?? [],
    isLoading: root.billingPlans.loadingEntitlements,
  }));
}
