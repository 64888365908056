import { Record, String, Number, Array } from "runtypes";
import type { Static } from "runtypes";

export const StockExchangeRecord = Record({
  name: String,
  exchange: String,
  ticker: String,
});

export type StockExchangeRecord = Static<typeof StockExchangeRecord>;

export const NewStockExchangeRecord = Record({
  company_name: String,
  company_stock_exchange: String,
  company_ticker: String,
});

export type NewStockExchangeRecord = Static<typeof NewStockExchangeRecord>;

export const StockDataRecords = Record({
  data: Array(
    Record({
      date: String,
      value: Number,
    })
  ),
});

export type StockDataRecords = Static<typeof StockDataRecords>;
