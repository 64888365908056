import { Box, Text, useColorModeValue, Button, Stack } from "@chakra-ui/react";
import { formatDistanceToNow } from "date-fns";
import React from "react";
import { track } from "api/analytics";
import { NOTIFICATION_CLICKED, NOTIFICATION_DISMISSED } from "api/analytics/events";
import { useButtonProps } from "hooks";

interface Props {
  onDismiss: () => void;
  newVersionDate: number;
}

export const VersionTile = (props: Props) => {
  const source = "toast";
  const notificationType = "new_webapp_version";
  const bgColorToast = useColorModeValue("gray.50", "gray.700");
  const commonButtonProps = useButtonProps("sm", "primary");
  const ctaButtonProps = useButtonProps("sm", "cta");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Box
      className="notification-tile"
      backgroundColor={bgColorToast}
      borderRadius={"lg"}
      overflow="hidden"
      boxShadow={"md"}
      borderColor={borderColor}
      borderWidth={"1px"}
      borderTop={"original"}
      cursor={"unset"}>
      <Stack spacing="1rem" p="1rem">
        <Stack>
          <Text fontSize={"sm"}>
            {`A new version of Charli was released 
              ${formatDistanceToNow(props.newVersionDate, {
                addSuffix: true,
              })}.`}
          </Text>
          <Text fontSize={"sm"}>Click Refresh to get the latest updates.</Text>
        </Stack>
        <Stack direction="row" justifyContent={"space-between"} width="100%">
          <Button
            aria-label="Close"
            {...commonButtonProps}
            onClick={(event) => {
              track(NOTIFICATION_DISMISSED, { notification_type: notificationType, source });
              props.onDismiss();
              event.stopPropagation();
            }}>
            Dismiss
          </Button>
          <Button
            aria-label="Accept"
            visibility={["hidden", "hidden", "visible"]}
            {...ctaButtonProps}
            onClick={() => {
              track(NOTIFICATION_CLICKED, { notification_type: notificationType, source });
              window.location.reload();
            }}>
            Refresh
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
