import { UTM_PARAMETERS } from ".";

export const setUtmParametersAsPeopleProperties = () => {
  // Type checks are extra defensive here due to inconsistent behaviour of adblockers (eg. uBlock prevents mixpanel from being set, while Wipr sets mixpanel to an empty array)
  if (window.mixpanel && typeof window.mixpanel.get_property === "function" && typeof window.mixpanel.people?.set_once === "function") {
    UTM_PARAMETERS.forEach((property) => {
      const value = window.mixpanel.get_property(property);
      if (value) {
        window.mixpanel.people.set_once(property, value);
      }
    });
  }
};
