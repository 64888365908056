import React, { useCallback } from "react";
import { useFieldArray, Controller, useFormContext } from "react-hook-form";
import {
  Stack,
  Input,
  IconButton,
  FormControl,
  FormLabel,
  Select,
  Box,
  Button,
  Icon,
  useColorModeValue,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import { AiOutlineDelete } from "react-icons/ai";
import type { FormValues } from "./ConfiguredWorkflowUpsertModal";

const OPERATORS = ["equal", "not_equal", "in", "not_in", "contains", "does_not_contain"];

interface IProps {
  checkpointIndex: number;
}

export const RequirementsFieldArray = (props: IProps) => {
  const { checkpointIndex } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext<FormValues>();
  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");
  const commonButtonProps = useButtonProps("sm", "secondary");
  const { fields, append, remove } = useFieldArray({
    control,
    name: `config.checkpoints.${checkpointIndex}.requirements`,
  });

  const getRequirementError = useCallback(
    (index: number, name: "entity" | "value") => {
      const maybeRequirementsErrors = errors.config?.checkpoints && errors.config.checkpoints[checkpointIndex]?.requirements;

      if (!maybeRequirementsErrors) {
        return undefined;
      } else return maybeRequirementsErrors[index]?.[name];
    },
    [errors, checkpointIndex]
  );

  return (
    <Box mt={"1rem"} backgroundColor={"transparent"}>
      <Button
        mt="1rem"
        {...commonButtonProps}
        onClick={() =>
          append({
            operator: "equal",
            entity: "",
            value: "",
          })
        }>
        Add Requirement
      </Button>
      {fields.map((field, index) => (
        <Stack direction="row" spacing="0.5rem" justifyContent="space-between" width="100%" key={field.id} mt="1rem" height="100%">
          <FormControl isInvalid={!!getRequirementError(index, "entity")}>
            <FormLabel fontSize="sm">Entity</FormLabel>
            <Controller
              render={({ field }) => <Input {...field} size="sm" type="text" />}
              name={`config.checkpoints.${checkpointIndex}.requirements.${index}.entity`}
              control={control}
            />
            {getRequirementError(index, "entity") ? (
              <FormErrorMessage>{getRequirementError(index, "entity")?.message}</FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl>
            <FormLabel fontSize="sm">Operator</FormLabel>
            <Controller
              render={({ field }) => (
                <Select {...field} size="sm">
                  {OPERATORS.map((operator) => (
                    <option key={operator} value={operator}>
                      {operator}
                    </option>
                  ))}
                </Select>
              )}
              name={`config.checkpoints.${checkpointIndex}.requirements.${index}.operator`}
              control={control}
            />
          </FormControl>
          <FormControl isInvalid={!!getRequirementError(index, "value")}>
            <FormLabel fontSize="sm">Value</FormLabel>
            <Controller
              render={({ field }) => <Input {...field} size="sm" value={String(field.value)} type="text" />}
              name={`config.checkpoints.${checkpointIndex}.requirements.${index}.value`}
              control={control}
            />
            {getRequirementError(index, "value") ? (
              <FormErrorMessage>{getRequirementError(index, "value")?.message}</FormErrorMessage>
            ) : null}
          </FormControl>
          <Box pt="1.5rem">
            <IconButton
              onClick={() => remove(index)}
              aria-label="Delete"
              backgroundColor="unset"
              icon={<Icon as={AiOutlineDelete} color={buttonColor} boxSize="1rem" _hover={{ color: buttonHoverColor }} />}
              size="sm"
              _hover={{ backgroundColor: "unset" }}
            />
          </Box>
        </Stack>
      ))}
    </Box>
  );
};
