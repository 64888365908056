import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorModeValue,
  Center,
  Link,
  Checkbox,
  FormHelperText,
  Text,
  Select,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useButtonProps, useUserProfile, useUserSubscriptionDetails, useUserSubscriptionDetailsDaysTillNextBilling } from "hooks";
import { track } from "api/analytics";
import { USER_UPGRADE_PLAN_CONFIRM } from "api/analytics/events";
import { useFeatureUsage } from "hooks/useFeatureUsage";
import { Intent } from "types/intent";
import { useLocation } from "react-router-dom";
import { COUNTRY_CODES } from "api/subscription/data/countryCodes";
import { COUNTRY_SUB_DIVISIONS } from "api/subscription/data/countrySubdivisions";

export const FormErrorLabel = ({ error, textAlign = "end" }: { error: string; textAlign?: "end" | "start" }) => {
  return (
    <Text width="100%" position={"absolute"} textAlign={textAlign} pt="2px" color="red.500" fontSize={"10px"}>
      {error}
    </Text>
  );
};

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (fields: any) => void;
}

export const SubscribeForm = (props: Props) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{
    firstName: string;
    lastName: string;
    organization: string;
    address2: string;
    address: string;
    city: string;
    country: string;
    state?: string;
    postcode: string;
    acceptTerms: boolean;
    promotionCode?: string;
  }>();
  const primaryTextColor = useColorModeValue("gray.500", "gray.400");
  const buttonStyle = useButtonProps("lg", "subscribe");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { firstName, lastName, fullName, email } = useUserProfile();
  const subscriptionInfo = useUserSubscriptionDetails();
  const daysTillPlanExpiry = useUserSubscriptionDetailsDaysTillNextBilling();
  const answerUsage = useFeatureUsage(Intent.generateAnswer);
  const projectUsage = useFeatureUsage(Intent.createDueDiligenceProject);
  const { pathname } = useLocation();
  const countryWatch = watch("country", undefined);

  const onConfirm = useCallback(
    (fields: any) => {
      setIsSubmitting(true);
      track(USER_UPGRADE_PLAN_CONFIRM, {
        pageViewed: pathname,
        userName: fullName,
        userEmail: email,
        answersUsed: answerUsage?.used,
        dueDiligenceUsed: projectUsage?.used,
        currentPlan: subscriptionInfo.plan,
        planStatus: subscriptionInfo.status,
        nextBillingDate: subscriptionInfo.nextBillingDate,
        subscriptionId: subscriptionInfo.subscriptionId,
        daysTillPlanExpiry: daysTillPlanExpiry.daysTillNextBilling,
        promotionCode: fields.promotionCode,
      });
      props.onSubmit(fields);
    },
    [
      answerUsage?.used,
      daysTillPlanExpiry.daysTillNextBilling,
      email,
      fullName,
      pathname,
      projectUsage?.used,
      props,
      subscriptionInfo.nextBillingDate,
      subscriptionInfo.plan,
      subscriptionInfo.status,
      subscriptionInfo.subscriptionId,
    ]
  );

  const countryCodeOptions = useMemo(
    () =>
      COUNTRY_CODES.map((country) =>
        Array.isArray(country) ? { label: country[0], value: country[1] } : { label: country, value: country }
      ),
    []
  );

  const countrySubdivisionOptions = useMemo(() => {
    const subdivisions = COUNTRY_SUB_DIVISIONS[countryWatch];
    if (subdivisions) {
      return subdivisions.map(([label, value]) => ({ label, value }));
    }

    return undefined;
  }, [countryWatch]);

  useEffect(() => {
    setValue("state", undefined);
  }, [countrySubdivisionOptions, setValue]);

  useEffect(() => {
    if (!COUNTRY_SUB_DIVISIONS[countryWatch]) {
      setValue("state", undefined);
    }
  }, [countryWatch, setValue]);

  return (
    <Stack justifyContent={"space-between"} spacing="2rem" height="100%" width="100%">
      <form onSubmit={handleSubmit(onConfirm)} noValidate>
        <Stack spacing="1.5rem">
          <Stack direction="row" spacing="1rem">
            <FormControl isInvalid={!!errors.firstName}>
              <FormLabel color={primaryTextColor} fontWeight="semibold" fontSize="sm" htmlFor="register-fullName">
                First Name
              </FormLabel>
              <Input
                defaultValue={firstName}
                size="sm"
                {...register("firstName", { required: true })}
                id="register-firstName"
                type="text"
                placeholder="John"
                borderColor={errors.firstName ? "red.500" : "gray.200"}
                fontSize="sm"
                boxShadow="none"
              />
              {errors.firstName && <FormErrorLabel error="required" />}
            </FormControl>
            <FormControl isInvalid={!!errors.lastName}>
              <FormLabel color={primaryTextColor} fontWeight="semibold" fontSize="sm" htmlFor="register-lastName">
                Last Name
              </FormLabel>
              <Input
                defaultValue={lastName}
                size="sm"
                {...register("lastName", { required: true })}
                id="register-lastName"
                type="text"
                placeholder="Smith"
                borderColor={errors.lastName ? "red.500" : "gray.200"}
                fontSize="sm"
                boxShadow="none"
              />
              {errors.lastName && <FormErrorLabel error="required" />}
            </FormControl>
          </Stack>
          <FormControl isInvalid={!!errors.organization}>
            <FormLabel color={primaryTextColor} fontWeight="semibold" fontSize="sm" htmlFor="register-organization">
              Organization / Company
            </FormLabel>
            <Input
              size="sm"
              id="register-organization"
              type="string"
              placeholder="Acme Inc."
              {...register("organization", { required: true })}
              borderColor={errors.organization ? "red.500" : "gray.200"}
              fontSize="sm"
              boxShadow="none"
            />
            {errors.organization && <FormErrorLabel error="required" />}
          </FormControl>
          <Stack direction="row" spacing="1rem" height="3.6rem">
            <FormControl isInvalid={!!errors.address}>
              <FormLabel color={primaryTextColor} fontWeight="semibold" fontSize="sm" htmlFor="register-address">
                Street Address
              </FormLabel>
              <Input
                size="sm"
                id="register-address"
                type="string"
                placeholder="100 Main Street"
                {...register("address", { required: true })}
                borderColor={errors.address ? "red.500" : "gray.200"}
                fontSize="sm"
                boxShadow="none"
              />
              {errors.address && <FormErrorLabel error="required" />}
            </FormControl>
            <FormControl width="10rem">
              <FormLabel color={primaryTextColor} fontWeight="semibold" fontSize="sm" htmlFor="register-address2">
                Appt Number
              </FormLabel>
              <Input
                size="sm"
                {...register("address2", {
                  required: false,
                })}
                id="register-address2"
                type="address2"
                placeholder="1225"
                borderColor={errors.address2 ? "red.500" : "gray.200"}
                fontSize="sm"
                boxShadow="none"
              />
              <FormHelperText fontSize={"10px"} lineHeight="10px" textAlign="end" mt="0">
                optional
              </FormHelperText>
            </FormControl>
          </Stack>
          <FormControl isInvalid={!!errors.city}>
            <FormLabel color={primaryTextColor} fontWeight="semibold" fontSize="sm" htmlFor="register-city">
              City
            </FormLabel>
            <Input
              size="sm"
              {...register("city", {
                required: true,
              })}
              id="register-city"
              type="city"
              placeholder="City"
              borderColor={errors.city ? "red.500" : "gray.200"}
              fontSize="sm"
              boxShadow="none"
            />
            {errors.city && <FormErrorLabel error="required" />}
          </FormControl>
          <Stack direction="row" spacing="1rem">
            <FormControl isInvalid={!!errors.state}>
              <FormLabel color={primaryTextColor} fontWeight="semibold" fontSize="sm" htmlFor="register-state">
                State/Province
              </FormLabel>
              {countrySubdivisionOptions ? (
                <Select defaultValue={undefined} size="sm" width={"100%"} value={undefined} {...register("state", { required: true })}>
                  {countrySubdivisionOptions.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
              ) : (
                <Input
                  size="sm"
                  id="register-state"
                  type="string"
                  placeholder="State"
                  {...register("state", { required: countrySubdivisionOptions !== undefined })}
                  borderColor={errors.state ? "red.500" : "gray.200"}
                  fontSize="sm"
                  boxShadow="none"
                />
              )}
              {errors.state && <FormErrorLabel error="required" />}
            </FormControl>
            <FormControl isInvalid={!!errors.postcode}>
              <FormLabel color={primaryTextColor} fontWeight="semibold" fontSize="sm" htmlFor="register-postcode">
                Zip/Postal Code
              </FormLabel>
              <Input
                size="sm"
                id="register-postcode"
                type="string"
                placeholder="99888"
                {...register("postcode", { required: true })}
                borderColor={errors.postcode ? "red.500" : "gray.200"}
                fontSize="sm"
                boxShadow="none"
              />
              {errors.postcode && <FormErrorLabel error="required" />}
            </FormControl>
            <FormControl isInvalid={!!errors.country}>
              <FormLabel color={primaryTextColor} fontWeight="semibold" fontSize="sm" htmlFor="register-country">
                Country
              </FormLabel>
              <Select size="sm" width={"100%"} value={undefined} {...register("country", { required: true })}>
                {countryCodeOptions.map(({ value, label }, idx) => (
                  <option key={idx} disabled={value === label} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
              {errors.country && <FormErrorLabel error="required" />}
            </FormControl>
          </Stack>
          <FormControl>
            <FormLabel color={primaryTextColor} fontWeight="semibold" fontSize="sm" htmlFor="register-country">
              Promotion Code
            </FormLabel>
            <Input
              size="sm"
              id="register-promotionCode"
              type="string"
              placeholder="Enter your promotion code here"
              {...register("promotionCode", { required: false })}
              borderColor={errors.promotionCode ? "red.500" : "gray.200"}
              fontSize="sm"
              boxShadow="none"
            />
          </FormControl>
          <FormControl isInvalid={!!errors.acceptTerms}>
            <Stack direction={"row"} spacing="1rem">
              <Checkbox
                type="checkbox"
                {...register("acceptTerms", { required: true })}
                id="register-acceptTerms"
                borderColor={errors.acceptTerms ? "red.500" : "gray.400"}
                fontSize="sm"
                boxShadow="none"
              />
              <FormLabel mb="0" color={primaryTextColor} lineHeight="2rem" fontSize="sm" htmlFor="register-acceptTerms">
                Accept{" "}
                <Link href="https://charli.ai/terms-of-service/" isExternal textDecoration={"underline"}>
                  terms and conditions
                </Link>
              </FormLabel>
            </Stack>
            {errors.acceptTerms && <FormErrorLabel error="required" textAlign="start" />}
          </FormControl>
        </Stack>
        <Center pt="2rem">
          <Button {...buttonStyle} width="16rem" borderRadius="full" id="subscribe-submit" type="submit" isLoading={isSubmitting}>
            Confirm Upgrade
          </Button>
        </Center>
      </form>
    </Stack>
  );
};
