import { useMemo } from "react";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import type { MemberOrganization } from "types/organization/MemberOrganization";

export const useMemberOrganizations: () => MemberOrganization[] = () => {
  const organizations = useSelector((state: RootState) => state.memberOrganizations.memberOrganizations);

  return Object.values(organizations);
};

export const useMemberOrganizationAdmin: () => MemberOrganization | undefined = () => {
  const organizations = useSelector((state: RootState) => state.memberOrganizations.memberOrganizations);

  return useMemo(() => {
    const organizationsArray = Object.values(organizations);

    return organizationsArray.find((organization) => organization.memberRole === "admin");
  }, [organizations]);
};
