import { Box, Center, Stack, Text, useColorModeValue, Image } from "@chakra-ui/react";
import type { ReactNode } from "react";
import React, { useEffect, useContext, useMemo } from "react";
import { useCollectionKey, useProjectParams, useTileProps } from "hooks";
import { ConversationContext } from "screens/thread/ConversationContext";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "../components/PanelStep";
import { PanelView } from "../components/PanelView";
import { updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";
import notesPanelImage from "screens/common/static/misc/keywords_notes.svg";
import { LandingTitle } from "screens/landing/components";
import { CollectionDetailTags } from "screens/collection/components/CollectionDetailTags";
import { Notes } from "screens/content/common/notes/Notes";

export const ProjectKeywordsNotesPanel = () => {
  const { projectId } = useProjectParams();
  const { setRequestEntities } = useContext(ConversationContext);
  const { isKeywordsNotesPanelOpen, onKeywordsNotesPanelClose } = useAddToCharliContext();
  const collectionConversationId = useCollectionKey(projectId, "conversationId");
  const collectionNotes = useCollectionKey(projectId, "metadata")?.notes || [];
  const bgColor = useColorModeValue("white", "#191f23");
  const commonTileProps = useTileProps();

  const panelHeader: ReactNode = useMemo(
    () => (
      <Stack spacing="0">
        <Box bgColor={"#FDB516"} height="1rem" width="100%" />
        <Stack direction="row" justifyContent={"space-between"} backgroundColor={"gray.100"} px={"1.5rem"} py="1rem">
          <Stack width="100%">
            <Text fontSize={"md"} fontWeight="semibold">
              {`AI Keywords and Notes`}
            </Text>
            <Text fontSize={"sm"} fontWeight="normal">
              AI keyword analysis is an important step in understanding due diligence efforts and can be used to filter as well as retrieve
              new information. Likewise, notes are helpful to the AI for analysis.
            </Text>
          </Stack>
          <Center>
            <Image src={notesPanelImage} height={["2rem", "2.5rem", "4rem"]} ml="1.5rem" />
          </Center>
        </Stack>
      </Stack>
    ),
    []
  );

  useEffect(() => {
    if (!isKeywordsNotesPanelOpen || !projectId) {
      setRequestEntities([]);
    } else {
      updateBatchRequestEntities([{ entity: "collection_id", value: projectId, source: "report-panel" }], setRequestEntities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isKeywordsNotesPanelOpen]);

  return (
    <PanelView isOpen={isKeywordsNotesPanelOpen} onClose={onKeywordsNotesPanelClose} panelTitle="Reports" panelHeader={panelHeader}>
      <Wizard>
        <PanelStep>
          <Stack spacing="3rem" backgroundColor={bgColor} mt="1rem">
            <Stack spacing="2rem">
              {projectId && <CollectionDetailTags collectionId={projectId} maxTagsToShow={30} />}
              <Stack {...commonTileProps} overflow="unset" cursor={"default"} pb="1rem" spacing="1rem">
                <LandingTitle style={{ width: "20rem" }} underline text="Project Notes" color="primary.default" />
                <Box>
                  <Notes
                    isEditing={collectionNotes && collectionNotes?.length === 0}
                    notes={collectionNotes}
                    conversationId={collectionConversationId}
                    isEditEnabled
                  />
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
