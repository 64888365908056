import type { Preference } from "api/systemPreferences/models/Preference";
import { Badge, Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";

export function ValueDisplay({ preference, short = false }: { preference: Preference; short?: boolean }) {
  const value = JSON.stringify(preference.value);

  return short ? (
    <Stack direction="row" align="flex-end" justify="space-between">
      <Flex flexWrap={"wrap"} alignContent="flex-start">
        {Object.values(preference.value).map((preference, index) => (
          <Badge mr=".5rem" mb=".5rem" key={index} colorScheme="gray">
            {preference}
          </Badge>
        ))}
      </Flex>
    </Stack>
  ) : (
    <Text>{value}</Text>
  );
}
