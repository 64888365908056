import type { FunctionComponent } from "react";
import { useContext } from "react";
import React from "react";
import { ContentCanvasGrid } from "./ContentCanvasGrid";
import type { ContentDetails } from "types/content/ContentDetails";
import { Stack, Tag, Text, useColorModeValue } from "@chakra-ui/react";
import { ContentCanvasClearFilterButton } from "../header/ContentCanvasClearFilterButton";
import { ContentFilterContext } from "./ContentFilterContext";
import { useItemSelector } from "hooks";
import { NoContentMessage } from "./NoContentMessage";

interface Props {
  availableContent: ContentDetails[];
}

export const ContentCanvasLayout: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ availableContent }) => {
  const textColor = useColorModeValue("gray.700", "gray.100");
  const { searchText, selectedTags, totalResources } = useContext(ContentFilterContext);

  const { selectedItems } = useItemSelector();
  const getSelectedTags = () => {
    return (
      <>
        {selectedTags.map((tag) => {
          return (
            <Tag mt="5px" mr="5px" key={tag} colorScheme="gray">
              {tag}
            </Tag>
          );
        })}
      </>
    );
  };

  const getFilterMessage = () => {
    if (searchText !== "" || selectedTags.length > 0) {
      const filterMessage = `No resources have `;
      const filterTextMessage = `the text `;
      const filterTagsMessage = `${searchText.length > 0 ? " or " : ""}the selected tag${selectedTags.length > 1 ? "s " : " "}`;
      const clearFiltersMessage = `Clear the filters to see the ${totalResources} resource${
        totalResources > 0 ? "s" : ""
      } in this project.`;

      return (
        <Stack fontSize={"sm"}>
          <>
            <Text textAlign={"center"} lineHeight={"2.2rem"} as="span" color={textColor}>
              {filterMessage}
              {searchText.length > 0 && (
                <>
                  <Text as="span" color={textColor}>
                    {filterTextMessage}
                  </Text>
                  <Tag mt="5px" colorScheme="blue">
                    {searchText}
                  </Tag>
                </>
              )}
              {selectedTags.length > 0 && (
                <>
                  <Text as="span" color={textColor}>
                    {filterTagsMessage}
                    {getSelectedTags()}
                  </Text>
                </>
              )}
            </Text>
          </>
          <Text textAlign={"center"} color={textColor}>
            {clearFiltersMessage}
          </Text>
        </Stack>
      );
    } else {
      return (
        <>
          {Object.keys(selectedItems).length > 1 ? (
            <>
              <Text textAlign={"center"} color={textColor} fontSize="sm">
                {`There ${Object.keys(selectedItems).length === 1 ? "is" : "are"} ${Object.keys(selectedItems).length} source${
                  Object.keys(selectedItems).length > 1 ? "s" : ""
                } in this project not shown due to the current filter.`}
              </Text>
              {Object.keys(selectedItems).length > 1 && (
                <Text textAlign={"center"} color={textColor} fontSize="sm">
                  Either update the filter criteria, or clear the current filter with the button above.
                </Text>
              )}
            </>
          ) : (
            <>
              <Text textAlign={"center"} color={textColor} fontSize="sm">
                {`There are no sources in this project.`}
              </Text>
              <Text textAlign={"center"} color={textColor} fontSize="sm">
                {`You can add new sources with the Actions menu above.`}
              </Text>
            </>
          )}
        </>
      );
    }
  };

  if (availableContent?.length > 0) {
    return <ContentCanvasGrid availableContent={availableContent} />;
  } else {
    return (
      <NoContentMessage>
        {getFilterMessage()}
        {(searchText !== "" || selectedTags.length > 0) && <ContentCanvasClearFilterButton />}
      </NoContentMessage>
    );
  }
};
