import React from "react";
import type { AdminPath } from "types/admin";
import type { EntitlementId } from "types/entitlements";
import { EntityConfigurations } from "screens/landing/tabs/admin/entityConfigurations";
import { MemorizedClarificationConfigs } from "screens/landing/tabs/admin/memorizedClarificationConfigs";
import { ReportTemplates } from "screens/landing/tabs/admin/reportTemplates";
import { ProjectConfigAdmin } from "screens/landing/tabs/admin/projectType";
import { InformationExtractionConfig } from "screens/landing/tabs/admin/informationExtractionConfig";
import { OrganizationsPreferences } from "screens/landing/tabs/admin/organizationsPreferences";
import { CharliActivities } from "screens/landing/tabs/admin/charliActivities";
import { TasksLog } from "screens/landing/tabs/admin/tasksLog";
import { Entitlements } from "screens/landing/tabs/admin/entitlements";
import { BillingPlans } from "screens/landing/tabs/admin/billingPlans";
import { Preferences } from "screens/landing/tabs/admin/preferences";
import { WorkflowsAdmin } from "screens/landing/tabs/admin/workflowsAdmin";
import { UsersAdmin } from "screens/landing/tabs/admin/usersAdmin";
import { Organizations } from "screens/landing/tabs/admin/organizationsAdmin";
import { ConfiguredWorkflows } from "screens/landing/tabs/admin/configuredWorkflows";
import { Tickers } from "screens/landing/tabs/admin/tickers";

export const adminTabsEntitlementsMapping: {
  entitlement: EntitlementId;
  path: AdminPath;
  linkText: string;
  view: JSX.Element;
  params?: string;
}[] = [
  {
    entitlement: "manage_project_config_read",
    path: "project-config",
    linkText: "Project config",
    view: <ProjectConfigAdmin />,
  },
  {
    entitlement: "manage_configured_workflows_read",
    path: "configured-workflows",
    linkText: "Workflow config",
    view: <ConfiguredWorkflows />,
  },
  {
    entitlement: "manage_entity_configurations_read",
    path: "entity-configuration",
    linkText: "Entity config",
    view: <EntityConfigurations />,
  },
  {
    entitlement: "manage_memorized_clarification_config_read",
    path: "memorized-clarification",
    linkText: "Memorized clarifications",
    view: <MemorizedClarificationConfigs />,
  },
  {
    entitlement: "manage_entitlements",
    path: "entitlements",
    linkText: "Entitlements",
    view: <Entitlements />,
  },
  {
    entitlement: "manage_billing_plans_read",
    path: "billing-plans",
    linkText: "Billing plans",
    view: <BillingPlans />,
  },
  {
    entitlement: "manage_system_preferences_read",
    path: "system-preferences",
    linkText: "System preferences",
    view: <Preferences />,
  },
  {
    entitlement: "read_workflows_admin",
    path: "workflows",
    linkText: "Workflow log",
    view: <WorkflowsAdmin />,
  },
  {
    entitlement: "manage_task_log_read",
    path: "tasks-log",
    linkText: "Task log",
    view: <TasksLog />,
  },
  {
    entitlement: "view_users",
    path: "admin-users",
    linkText: "Users",
    view: <UsersAdmin />,
  },
  {
    entitlement: "manage_organizations_read",
    path: "organizations",
    linkText: "Organizations",
    view: <Organizations />,
  },
  {
    entitlement: "manage_organizations_read",
    path: "organizations-preferences",
    linkText: "Organizations preferences",
    view: <OrganizationsPreferences />,
  },
  {
    entitlement: "access_all_report_templates",
    path: "report-templates",
    linkText: "Report templates",
    view: <ReportTemplates />,
  },
  {
    entitlement: "manage_information_extraction_config_service_read",
    path: "information-extraction-config",
    linkText: "Information extraction config",
    view: <InformationExtractionConfig />,
  },
  {
    entitlement: "manage_charli_activities_read",
    path: "charli-activities",
    linkText: "Activity handlers",
    view: <CharliActivities />,
  },
  {
    entitlement: "manage_ticker_read",
    path: "tickers",
    linkText: "Tickers",
    view: <Tickers />,
    params: "status=active",
  },
];
