import React from "react";
import { Box, Center, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { useEntitlements } from "hooks";

interface IProps {
  message: string;
  height: string;
  width: string;
}

export const ComingSoonOverlay = ({ message, width, height }: IProps) => {
  const fontColor = useColorModeValue("gray.500", "gray.400");
  const { ui_enable_coming_soon_features: showComingSoonFeatures } = useEntitlements();

  if (showComingSoonFeatures) {
    return null;
  }

  return (
    <>
      <Box position={"absolute"} opacity="0.8" zIndex="1" borderRadius={"md"} width={width} height={height} bgColor="white"></Box>
      <Center position={"absolute"} zIndex="1" borderRadius={"md"} width={width} height={height} bgColor="transparent">
        <Stack alignItems={"center"} spacing="0">
          {message && (
            <Text color={fontColor} fontWeight={"semibold"} fontSize="1.5rem">
              {message}
            </Text>
          )}
          <Text color={fontColor} fontWeight={"semibold"} fontSize="1.5rem">
            Coming Soon
          </Text>
        </Stack>
      </Center>
    </>
  );
};
