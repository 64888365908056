import type { FunctionComponent } from "react";
import React from "react";
import type { IconProps } from "@chakra-ui/react";

import { ProjectGenericIcon, SearchResultsIcon } from "./ProjectIcons";

interface Props {
  projectType: string;
  styles?: React.CSSProperties;
}

export const ProjectIcon: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props & IconProps>>> = ({
  projectType,
  ...rest
}) => {
  switch (projectType) {
    case "search":
      return <SearchResultsIcon {...rest} width="3rem" height="2rem" />;
    default:
      return <ProjectGenericIcon {...rest} width="5rem" height="2rem" />;
  }
};
