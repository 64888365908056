import React, { useCallback } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useConfiguredWorkflow } from "hooks/useConfiguredWorkflows";
import { formatDate } from "screens/common/modal/formatters";
import { HiClipboard } from "react-icons/hi";
import isEmpty from "lodash/isEmpty";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";

interface IProps {
  id: string | null;
  isOpen: boolean;
  onClose: () => void;
}

export const ConfiguredWorkflowModal = (props: IProps) => {
  const { id, isOpen, onClose } = props;
  const configWorkflow = useConfiguredWorkflow(id);
  const toast = useToast();

  const getCheckpoints = useCallback(() => {
    if (!configWorkflow) return [];

    const {
      config: { checkpoints },
    } = configWorkflow;

    return checkpoints ?? [];
  }, [configWorkflow]);

  const getDefaultEntities = useCallback(() => {
    if (!configWorkflow) return [];

    const {
      config: { defaultEntities },
    } = configWorkflow;

    return defaultEntities ?? [];
  }, [configWorkflow]);

  const copyExpandedUserIntent = useCallback(
    (userIntent: any) => {
      navigator.clipboard.writeText(userIntent);

      toast({
        title: "Configured Workflow",
        description: `${userIntent} text copied to clipboard.`,
        status: "info",
        duration: 1500,
        isClosable: true,
        position: "bottom-left",
      });
    },
    [toast]
  );

  const renderCheckpoints = useCallback(() => {
    const checkpoints = getCheckpoints();

    return checkpoints.map((checkpoint, index) => {
      if (checkpoint.type === "create_entities") {
        return (
          <AccordionItem key={`${checkpoint.type}-${index}`}>
            <Box display={"flex"} alignItems="center">
              <Text size={"sm"} mr="1rem">
                {checkpoint.type}
              </Text>
              <Box flex="1" textAlign="left">
                <h2>
                  <AccordionButton justifyContent={"flex-end"}>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
              </Box>
            </Box>

            <AccordionPanel pb={4}>
              <Stack mb={"1rem"}>
                <Text as="b">Entities to inject: </Text>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Entity</Th>
                        <Th>Value</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {checkpoint.entitiesToInject.map(({ value, entity }, index) => (
                        <Tr key={`entity-to-inject-${entity}-${index}`}>
                          <Td>{entity}</Td>
                          <Td>{value !== null ? JSON.stringify(value) : "null"}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        );
      }

      return (
        <AccordionItem
          isDisabled={
            checkpoint.requirements.length === 0 &&
            (checkpoint.onlyIncludeTheseEntities ?? []).length === 0 &&
            !checkpoint.entityToSplit &&
            !checkpoint.entitiesToInject &&
            !checkpoint.entitiesToRename
          }
          key={`${checkpoint.type}-${checkpoint.intent}-${index}`}>
          <Box display={"flex"} alignItems="center">
            <Text size={"sm"} mr="1rem">
              {checkpoint.intent} {checkpoint.needsConfirmation ? "(needs confirmation)" : null}{" "}
              {checkpoint.runInNewChildWorkflow ? "(runs in separate child workflow)" : null}
            </Text>
            <Box mr="1rem">
              <HiClipboard role={"button"} onClick={() => copyExpandedUserIntent(checkpoint.intent)} />
            </Box>
            <Box flex="1" textAlign="left">
              <h2>
                <AccordionButton justifyContent={"flex-end"}>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
            </Box>
          </Box>

          <AccordionPanel pb={4}>
            {checkpoint.onlyIncludeTheseEntities && checkpoint.onlyIncludeTheseEntities.length > 0 && (
              <Stack mb={"1rem"} direction={"row"}>
                <Text as="b">Input Entities for ChildWorkflow: </Text> <Text>{checkpoint.onlyIncludeTheseEntities?.join(", ")}</Text>
              </Stack>
            )}
            {checkpoint.entitiesToRename && checkpoint.entitiesToRename.length > 0 && (
              <Stack mb={"1rem"}>
                <Text as="b">Entities to rename: </Text>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Rename to</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {checkpoint.entitiesToRename.map((entity) => (
                        <Tr key={`entity-to-rename-${entity.entityName}-${entity.renameTo}`}>
                          <Td>{entity.entityName}</Td>
                          <Td>{entity.renameTo}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Stack>
            )}
            {checkpoint.entitiesToInject && checkpoint.entitiesToInject.length > 0 && (
              <Stack mb={"1rem"}>
                <Text as="b">Entities to inject: </Text>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Entity</Th>
                        <Th>Value</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {checkpoint.entitiesToInject.map(({ value, entity }, index) => (
                        <Tr key={`entity-to-inject-${entity}-${index}`}>
                          <Td>{entity}</Td>
                          <Td>{value !== null ? JSON.stringify(value) : "null"}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Stack>
            )}
            {checkpoint.entityToSplit && (
              <Stack mb={"1rem"} direction={"row"}>
                <Text as="b">Entity to split: </Text>{" "}
                <Text>
                  {checkpoint.entityToSplit.entityName}{" "}
                  {checkpoint.entityToSplit.renameTo ? `(Rename to ${checkpoint.entityToSplit.renameTo})` : ""}
                  {checkpoint.entityToSplit.shouldUnwrap ? `(Unwrap)` : ""}
                </Text>
              </Stack>
            )}
            {checkpoint.requirements.length > 0 && (
              <Stack mb={"1rem"}>
                <Text as="b">Requirements: </Text>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Entity</Th>
                        <Th>Operator</Th>
                        <Th>Value</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {checkpoint.requirements.map((_requirement, index) => (
                        <Tr key={`requirement-${_requirement.entity}-${_requirement.operator}-${index}`}>
                          <Td>{_requirement.entity}</Td>
                          <Td>
                            <Badge>{_requirement.operator}</Badge>
                          </Td>
                          <Td>{_requirement.value !== null ? JSON.stringify(_requirement?.value) : "null"}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Stack>
            )}
          </AccordionPanel>
        </AccordionItem>
      );
    });
  }, [getCheckpoints, copyExpandedUserIntent]);

  const renderDefaultEntities = useCallback(() => {
    const defaultEntities = getDefaultEntities();

    if (isEmpty(defaultEntities)) {
      return "No Default Entities";
    }

    return (
      <Table size={"sm"} variant="simple">
        <Thead>
          <Tr>
            <Th>Entity</Th>
            <Th>Value</Th>
          </Tr>
        </Thead>
        <Tbody>
          {defaultEntities.map(({ entity, value }) => (
            <Tr key={entity}>
              <Td>{entity}</Td>
              <Td>{value !== null ? JSON.stringify(value) : "null"}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  }, [getDefaultEntities]);

  if (!configWorkflow) {
    return null;
  }

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle="Configured Workflow detail">
      <Wizard>
        <PanelStep>
          <Stack spacing={8} px="1rem">
            <Stack my="0.5rem">
              <Stack direction="row" alignItems="center">
                <Heading fontSize="md">User intent:</Heading>
                <Text fontSize="md">{configWorkflow.userIntent}</Text>
              </Stack>

              <Text wordBreak="break-word" fontWeight="light" fontSize="xs">{`Created ${formatDate(
                new Date(configWorkflow.creationDate)
              )} by ${configWorkflow.creationByUserName}`}</Text>

              <Text wordBreak="break-word" fontWeight="light" fontSize="xs">{`Modified ${formatDate(
                new Date(configWorkflow.lastUpdatedDate)
              )} by ${configWorkflow.lastUpdatedByUserName}`}</Text>
            </Stack>

            <Box>
              <Heading marginBottom={4} fontSize="md">
                Default Entities
              </Heading>
              {renderDefaultEntities()}
            </Box>

            <Box>
              <Heading marginBottom={4} fontSize="md">
                Checkpoints
              </Heading>
              <Accordion allowToggle>{renderCheckpoints()}</Accordion>
            </Box>
          </Stack>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
