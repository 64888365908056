import { Box, Image, Stack, Center, Text, HStack, useBreakpointValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { LoadingGate } from "screens/common/components";
import type { RootState } from "state/rootReducer";
import factsetLogo from "screens/common/static/logos/factset_logo.svg";
import nasdaqLogo from "screens/common/static/logos/nasdaq_logo.svg";
import tmxLogo from "screens/common/static/logos/tmx_logo.svg";
import keyboardImage from "screens/common/static/misc/keyboard.jpg";
import { useLocation } from "react-router-dom";
import { LoginWrapperSlides } from "./LoginWrapperSlides";

export const LoginWrapper: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = ({ children }) => {
  const { isLoading, isLoggedIn } = useSelector((state: RootState) => state.session, shallowEqual);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const location = useLocation();
  const willShowLoadingSpinner = (isLoading || isLoggedIn) && location.pathname !== "/verify";

  return (
    <Box id="login-container" height="100vh" overflowY="auto">
      <Stack spacing="0" direction={isMobile ? "column" : "row"} position="relative" width="100vw" height="100%">
        {!isMobile && (
          <Stack
            width={isMobile ? "90vw" : "40rem"}
            height="100vh"
            overflowY="auto"
            backgroundImage={`url(${keyboardImage})`}
            backgroundSize="cover"
            backgroundPosition="center">
            <Stack justifyContent={"space-between"} height="100vh" bgColor={"#4799d4"} pt="5rem" pb="2rem" px="2rem" opacity={0.85}>
              <Stack color="white" spacing="5rem" pt="1rem">
                <Text fontSize="2rem" lineHeight={"2.3rem"} fontWeight="bold" width="100%" textAlign={"center"}>
                  The Automated Analyst for Wall Street
                </Text>
                <LoginWrapperSlides />
              </Stack>
              <Center>
                <Stack spacing="3rem">
                  <HStack align={"center"} spacing="1rem">
                    <Image src={factsetLogo} height="1.2rem" />
                    <Image src={nasdaqLogo} height="2rem" />
                    <Image src={tmxLogo} height="2.5rem" />
                  </HStack>
                </Stack>
              </Center>
            </Stack>
          </Stack>
        )}
        <Box width="100%" height="100%">
          <LoadingGate isLoading={willShowLoadingSpinner}>{children}</LoadingGate>
        </Box>
      </Stack>
    </Box>
  );
};
