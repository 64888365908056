import type { ContentDetailsResponse } from "api/content/models/ContentDetailsResponse";
import type { Note as ResponseNote } from "api/content/models/Note";
import type { Tag as ResponseTag } from "api/content/models/Tag";
import cloneDeep from "lodash/cloneDeep";
import type { ContentUrls } from "./ContentUrls";
import type { DetailSection } from "./DetailSection";
import type { DynamicData } from "./DynamicData";
import type { ExtractedHighlights } from "./ExtractedHighlights";
import type { ExtractedTableHighlights } from "./ExtractedTableHighlights";
import type { SummaryDetail } from "./SummaryDetail";
import type { TableContent } from "./TableContent";

export class ContentDetails {
  id: string;
  mediaId: string;
  type: string;
  collectionType: string | undefined;
  name: string | undefined;
  extension: string | undefined;
  manualTags: string[];
  autoTags: string[];
  notes: string[];
  detailSections: DetailSection[];
  summaryDetails: SummaryDetail | null;
  urls: ContentUrls | null;
  size: number | undefined;
  timeCreated: string;
  timeLastModified: string;
  isArchived: boolean;
  highlights: { [key: string]: string }[];
  extractedHighlights: ExtractedHighlights[];
  category: string | undefined;
  tables: TableContent[];
  extractedTableHighlights: ExtractedTableHighlights[];
  dynamicData: DynamicData | undefined;
  integrationUrn?: string;
  sourceIntegrationUrn?: string;

  constructor(item: ContentDetailsResponse) {
    const contentDetails = cloneDeep(item);
    this.id = contentDetails.id;
    this.mediaId = contentDetails.mediaId;
    this.type = contentDetails.type;
    this.collectionType = contentDetails.collectionType;
    this.name = contentDetails.name ?? undefined;
    this.extension = contentDetails.extension ?? undefined;
    const { manualTags, autoTags } = ContentDetails.mapTags(contentDetails.tags);
    this.manualTags = manualTags;
    this.autoTags = autoTags;
    this.notes = ContentDetails.mapNotes(contentDetails.notes);
    this.detailSections = contentDetails.detailSections;
    this.summaryDetails = contentDetails.summaryDetails;
    this.urls = contentDetails.urls;
    this.size = contentDetails.size ?? undefined;
    this.timeCreated = contentDetails.timeCreated;
    this.timeLastModified = contentDetails.timeLastModified;
    this.isArchived = contentDetails.isArchived;
    this.highlights = contentDetails.highlights;
    this.extractedHighlights = contentDetails.extractedHighlights;
    this.category = contentDetails.category ?? undefined;
    this.tables = contentDetails.table;
    this.extractedTableHighlights = contentDetails.extractedTableHighlights ?? [];
    this.dynamicData = contentDetails.dynamicData;
    this.integrationUrn = contentDetails.integrationUrn;
    this.sourceIntegrationUrn = contentDetails.sourceIntegrationUrn;
  }

  private static mapTags(tags: ResponseTag[]) {
    const manualTags: string[] = [];
    const autoTags: string[] = [];

    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];
      if (tag.type === "manual") {
        manualTags.push(tag.value);
      } else if (tag.type === "auto") {
        autoTags.push(tag.value);
      }
    }

    return { manualTags, autoTags };
  }

  private static mapNotes(notes: ResponseNote[]): string[] {
    return notes.map((note) => note.value);
  }
}
