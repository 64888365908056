import React, { useMemo, useEffect, createContext, useContext, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export enum QueryParamFilters {
  status = "status",
  query = "query",
}

const TickersFiltersContext = createContext({
  status: "" as string,
  setStatus: (_: string) => {
    return;
  },
  searchQuery: "" as string,
});

export const useTickersFiltersContext = () => {
  return useContext(TickersFiltersContext);
};

export const TickersFiltersContextProvider = ({ children }) => {
  const location = useLocation();
  const mounted = useRef(false);
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const statusParam = searchParams.get(QueryParamFilters.status);
  const searchQueryParam = searchParams.get(QueryParamFilters.query);

  const [status, setStatus] = useState<string>(statusParam ?? "active");

  useEffect(() => {
    mounted.current = true;

    const paramStatus = searchParams.get(QueryParamFilters.status);

    if (mounted.current) {
      setStatus(paramStatus ?? "");
    }

    return () => {
      mounted.current = false;
    };
  }, [searchParams]);

  return (
    <TickersFiltersContext.Provider
      value={{
        status,
        setStatus,
        searchQuery: searchQueryParam ?? "",
      }}>
      {children}
    </TickersFiltersContext.Provider>
  );
};
