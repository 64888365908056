import { Box, useToast } from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import { getVersion } from "api/version/version";
import { VersionTile } from "screens/landing/tabs/notifications/VersionTile";

const VERSION_TOAST_ID = "web-app-version-toast";

export const useVersionTile = () => {
  const [newVersionDate, setNewVersionDate] = useState<number | null>(null);
  const toast = useToast();
  const versionsMapRef = useRef<Set<string>>(new Set<string>());

  useEffect(() => {
    const versionInterval = setInterval(() => {
      getVersion().then((response) => {
        const serverVersion = response.version;

        const versionsMap = versionsMapRef.current;
        if (serverVersion && serverVersion !== window.env.version && !versionsMap.has(serverVersion)) {
          versionsMap.add(serverVersion);
          setNewVersionDate(new Date().getTime());
        }
      });
    }, 15000);

    return () => {
      clearInterval(versionInterval);
    };
  }, []);

  useEffect(() => {
    if (newVersionDate) {
      toast.close(VERSION_TOAST_ID);
      toast({
        id: VERSION_TOAST_ID,
        position: "top-right",
        duration: null,
        isClosable: true,
        render: ({ onClose }) => {
          return (
            <Box width="18rem" pr="0" pt="0">
              <VersionTile
                newVersionDate={newVersionDate}
                onDismiss={() => {
                  setNewVersionDate(null);
                  onClose();
                }}
              />
            </Box>
          );
        },
      });
    }
  }, [newVersionDate, toast]);
};
