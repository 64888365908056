import React from "react";
import { SidebarButton } from "./SidebarButton";
import { getTypeIcon, getViewConfig, getTypeFromRoute } from "configs/configMap";
import { Box, Stack } from "@chakra-ui/react";
import type { ProjectConfigState } from "state/config/reducer";
import { SidebarCategory } from "./SidebarMenuCategory";

interface Props {
  reorderedMenuConfig: ProjectConfigState[];
  configMap: Record<string, ProjectConfigState>;
}

export const SidebarNavigationMainMenu = ({ reorderedMenuConfig, configMap }: Props) => {
  return (
    <Stack spacing="1rem" key={"test"}>
      {reorderedMenuConfig.map((menuItem) => (
        <Box key={`category-${menuItem.id}`}>
          {menuItem.config.portfolioProjectType ? (
            <SidebarCategory
              text={getViewConfig("title", menuItem.id, configMap)}
              selectedProjectCategory={menuItem.config.collectionType}
              icon={getTypeIcon(getViewConfig("icon", menuItem.id, configMap))}
              className={`ch-sidebar-nav-${getTypeFromRoute(menuItem.config.collectionType)}`}
            />
          ) : (
            <SidebarButton
              key={`sidebar-nav-${menuItem.id}`}
              text={getViewConfig("title", menuItem.id, configMap)}
              icon={getTypeIcon(getViewConfig("icon", menuItem.id, configMap))}
              screen={getViewConfig("route", menuItem.id, configMap)}
              cssClasses={[`ch-sidebar-nav-${getTypeFromRoute(menuItem.config.route)}`]}
              tooltip={menuItem?.config.menuTooltip}
            />
          )}
        </Box>
      ))}
    </Stack>
  );
};
