import React, { useMemo } from "react";
import { Box, Button, FormControl, FormLabel, Stack, useToast } from "@chakra-ui/react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useButtonProps } from "hooks";
import { FormTextField } from "../../shared/FormTextField";
import capitalize from "lodash/capitalize";
import {
  addOrganizationEntitlementConfig,
  addPlanEntitlementConfig,
  addUserEntitlementConfig,
  createEntitlementConfig,
  downloadEntitlementConfigs,
  removeOrganizationEntitlementConfig,
  removeUserEntitlementConfig,
  updateEntitlementConfig,
  updatePlanEntitlementConfig,
} from "state/entitlements/operations";
import { useEntitlementConfig, useIsEntitlementUpdating } from "hooks/useEntitlementConfigs";
import type { Plan } from "../utils/EntitlementFormValidation";
import type { MultiValue } from "chakra-react-select";
import { useBillingPlans } from "hooks/useBillingPlans";
import difference from "lodash/difference";
import { EntitlementUsersFieldArray } from "../components/EntitlementUsersFieldArray";
import { EntitlementConfig } from "api/entitlementConfigs/models/Entitlement";
import { OrganizationsSelect } from "screens/landing/tabs/admin/common";
import { PlansFieldArray } from "../components/PlansFieldArray";

interface IProps {
  code: string | null;
  isOpen: boolean;
  onClose: () => void;
}

type OrganizationOption = { label: string; value: string };
type UserOption = { label: string; value: string };

export interface FormValues {
  id?: string;
  plans: Plan[];
  code: string;
  name: string;
  description: string | null;
  users: UserOption[];
  organizations?: OrganizationOption[];
}

export const EntitlementConfigUpsertModal = (props: IProps) => {
  const { code, isOpen, onClose } = props;
  const dispatch = useAppDispatch();
  const isLoading = useIsEntitlementUpdating();
  const toast = useToast();
  const commonButtonProps = useButtonProps("sm", "primary");
  const entitlement = useEntitlementConfig(code);
  const plans = useBillingPlans();
  const hasPlans = EntitlementConfig.guard(entitlement);

  const isUpdate = !!entitlement;

  const allPlans: Plan[] = useMemo(() => plans.map((plan) => ({ code: plan.code, enabled: false, limit: 0 })), [plans]);
  const formPlans: Plan[] = hasPlans
    ? Object.entries(entitlement.plans).map(([code, plan]) => ({ code, enabled: plan.enabled, limit: 0 }))
    : allPlans;

  const form = useForm<FormValues>({
    defaultValues: isUpdate
      ? {
          name: entitlement.name || `${capitalize(code?.replace(/_/g, " "))} entitlement` || undefined,
          description: entitlement.description ?? null,
          code: entitlement.code || code || undefined,
          plans: formPlans,
          users: (entitlement.users || []).map((user) => ({
            label: `${user.firstName} ${user.lastName} (${user.email})`,
            value: user.id,
          })),
          organizations: (entitlement.organizations || []).map((org) => ({ label: org.name, value: org.id })),
        }
      : {
          name: "",
          description: code || "",
          code: code || "",
          plans: [],
          users: [],
          organizations: [],
        },
    // TODO: fix validation using yup
    // resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
  } = form;

  const watchOrganizations = watch("organizations", []);

  const onSubmit = async (formValues: FormValues) => {
    const { users = [], plans = [], organizations = [], ...entitlement } = formValues;

    const entitlementPayload: EntitlementConfig = (() => {
      const { code, name, description } = entitlement;

      return {
        code,
        name,
        description,
        plans: plans.reduce((acc, { code, ...planFields }) => ({ ...acc, [code]: planFields }), {}),
        users: [],
      };
    })();

    const response = await dispatch(createEntitlementConfig(entitlementPayload));

    if (response.type === createEntitlementConfig.rejected.type) {
      const errorMessage = (response as { error: { message: string } }).error?.message;

      toast({
        title: "Entitlement configuration error",
        description: errorMessage,
        status: "error",
        duration: 10000,
        isClosable: true,
      });

      return;
    }

    const entitlementId = entitlement.code;
    users.map((user) => dispatch(addUserEntitlementConfig({ userId: user.value, entitlementId })));
    organizations.map((org) => dispatch(addOrganizationEntitlementConfig({ organizationId: org.value, entitlementId })));

    toast({
      title: "Entitlement configuration",
      description: "Successfully created",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    dispatch(downloadEntitlementConfigs());
    onClose();
  };

  const onUpdateEntitlement = async () => {
    const { name, description, code } = getValues();

    await dispatch(
      updateEntitlementConfig({
        name: String(name),
        description: String(description),
        code: String(code),
      })
    );

    toast({
      title: "Entitlement configuration",
      description: "Successfully updated",
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    dispatch(downloadEntitlementConfigs());
  };

  const onOrganizationChange = (newValue: MultiValue<OrganizationOption>, onChange: (newValue: MultiValue<OrganizationOption>) => void) => {
    if (!isUpdate || !watchOrganizations) {
      onChange(newValue);
      return;
    }

    const operation = (() => {
      const addedValues = difference(newValue, watchOrganizations);

      if (addedValues.length > 0) {
        return { type: "add", value: addedValues };
      }

      const removedValues = difference(watchOrganizations, newValue);
      if (removedValues.length > 0) {
        return { type: "remove", value: removedValues };
      }
    })();

    if (!operation) {
      return;
    }

    if (operation.type === "add") {
      Promise.all(
        operation.value.map((org) =>
          dispatch(addOrganizationEntitlementConfig({ organizationId: org.value, entitlementId: entitlement.code }))
        )
      ).then(() => {
        onChange(newValue);
        dispatch(downloadEntitlementConfigs());
      });
    } else {
      Promise.all(
        operation.value.map((org) =>
          dispatch(removeOrganizationEntitlementConfig({ organizationId: org.value, entitlementId: entitlement.code }))
        )
      ).then(() => {
        onChange(newValue);
        dispatch(downloadEntitlementConfigs());
      });
    }
  };

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`${isUpdate ? "Update" : "Create"} entitlement configuration`}>
      <Wizard>
        <PanelStep>
          <Box pt="1rem">
            <FormProvider {...form}>
              <form id="upsert-entitlement-form" onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing="1rem">
                  <FormTextField
                    control={control}
                    errors={errors}
                    keyField="code"
                    label="Code"
                    onUpdate={null}
                    disabled={isUpdate}
                    required
                  />
                  <FormTextField
                    control={control}
                    errors={errors}
                    keyField="name"
                    label="Name"
                    atomic={isUpdate}
                    onUpdate={onUpdateEntitlement}
                    disabled={isLoading}
                    required
                  />
                  <FormTextField
                    control={control}
                    errors={errors}
                    keyField="description"
                    label="Description"
                    atomic={isUpdate}
                    onUpdate={onUpdateEntitlement}
                    disabled={isLoading}
                  />

                  <PlansFieldArray
                    disabled={isLoading}
                    onAdd={(plan) => {
                      return isUpdate
                        ? dispatch(
                            addPlanEntitlementConfig({
                              entitlementId: entitlement.code,
                              plan,
                            })
                          )
                        : Promise.resolve();
                    }}
                    onUpdate={(plan) => {
                      return isUpdate
                        ? dispatch(
                            updatePlanEntitlementConfig({
                              entitlementId: entitlement.code,
                              plan,
                            })
                          )
                        : Promise.resolve();
                    }}
                    supportedPlans={plans.map((plan) => plan.code)}
                  />

                  <EntitlementUsersFieldArray
                    onAdd={(id) => {
                      return isUpdate
                        ? dispatch(
                            addUserEntitlementConfig({
                              entitlementId: entitlement.code,
                              userId: id,
                            })
                          )
                        : Promise.resolve();
                    }}
                    onDelete={(id) => {
                      return isUpdate
                        ? dispatch(
                            removeUserEntitlementConfig({
                              entitlementId: entitlement.code,
                              userId: id,
                            })
                          )
                        : Promise.resolve();
                    }}
                  />
                  <FormControl>
                    <FormLabel fontSize="sm">Organizations</FormLabel>
                    <Controller
                      render={({ field }) => {
                        return (
                          <OrganizationsSelect
                            {...field}
                            isLoading={isLoading}
                            onChange={(newVal) => {
                              onOrganizationChange(newVal, field.onChange);
                            }}
                          />
                        );
                      }}
                      control={control}
                      name="organizations"
                    />
                  </FormControl>
                </Stack>
              </form>

              <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
                {!isUpdate && (
                  <Button {...commonButtonProps} type="submit" form="upsert-entitlement-form">
                    Save Entitlement
                  </Button>
                )}
              </Box>
            </FormProvider>
          </Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
