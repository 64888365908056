import React, { useMemo } from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useButtonProps } from "hooks";
import type { IField } from "screens/common/components";
import { DynamicForm } from "screens/common/components";
import type { Ticker } from "api/tickers/models/Ticker";
import { formatDate } from "screens/common/modal/formatters";

export interface UpdateTickerForm {
  symbol: string;
  name: string;
  exchange: string;
  status: "active" | "inactive";
}

interface IProps {
  onSubmit: (values: UpdateTickerForm, id?: string) => void;
  isLoading?: boolean;
  ticker?: Ticker;
}

interface IPanelProps extends IProps {
  onClose: () => void;
  isOpen: boolean;
}

const UpdateTicker = ({ onSubmit, ticker, isLoading }: IProps) => {
  const commonButtonProps = useButtonProps("sm", "primary");

  const fields: IField<UpdateTickerForm>[] = useMemo(
    () => [
      {
        type: "text",
        entity: "symbol",
        label: "Symbol",
        isRequired: true,
        defaultValue: "",
      },
      {
        type: "text",
        entity: "exchange",
        label: "Exchange",
        isRequired: true,
        defaultValue: "",
      },
      {
        type: "text",
        entity: "name",
        label: "Name",
        isRequired: true,
        defaultValue: "",
      },
      {
        type: "select",
        entity: "status",
        label: "Status",
        isRequired: true,
        options: [
          { value: "active", label: "Active" },
          { value: "inactive", label: "Inactive" },
        ],
        defaultValue: "active",
      },
    ],
    []
  );

  const defaultValues: UpdateTickerForm = useMemo(() => {
    return (
      ticker ?? {
        symbol: "",
        exchange: "",
        name: "",
        status: "active",
      }
    );
  }, [ticker]);

  return (
    <Box>
      {ticker && (
        <Box my="0.5rem">
          <Text wordBreak="break-word" fontWeight="light" fontSize="xs">{`Created ${formatDate(new Date(ticker.creationDate))} by ${
            ticker?.creationByUser
          }`}</Text>

          {ticker.lastUpdatedDate && ticker.lastUpdatedByUser && (
            <Text wordBreak="break-word" fontWeight="light" fontSize="xs">{`Modified ${formatDate(new Date(ticker.lastUpdatedDate))} by ${
              ticker.lastUpdatedByUser
            }`}</Text>
          )}
        </Box>
      )}

      <DynamicForm<UpdateTickerForm>
        formId="update-ticker-form"
        defaultValues={defaultValues}
        fields={fields}
        onSubmit={(values) => onSubmit(values, ticker?.id)}
        title="Update ticker"
        isDisabled={isLoading}
      />

      <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
        <Button isLoading={isLoading} {...commonButtonProps} type="submit" form="update-ticker-form">
          Save Ticker
        </Button>
      </Box>
    </Box>
  );
};

const UpdateTickerPanel = (props: IPanelProps) => {
  const { onClose, isOpen, ...rest } = props;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={"Update ticker"}>
      <Wizard>
        <PanelStep>
          <Box pt="1rem">{isOpen && rest.ticker && <UpdateTicker {...rest} />}</Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};

export { UpdateTickerPanel };
