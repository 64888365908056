import type { TrackableProperties } from "types/analytics";

const PATH = "path";

const integrations = (destinations: Set<string>, excludedDestinations: Set<string>) => {
  const formattedDestinations = Object.fromEntries([...destinations].map((destination: string) => [destination, true]));
  const formattedExcludedDestinations = Object.fromEntries([...excludedDestinations].map((destination: string) => [destination, false]));

  return {
    ...formattedDestinations,
    ...formattedExcludedDestinations,
  };
};

export const track = (eventName: string, properties: TrackableProperties, destinations: Set<string>, excludedDestinations: Set<string>) => {
  if (window.analytics) {
    window.analytics.track(eventName, properties, {
      integrations: integrations(destinations, excludedDestinations),
    });
  }
};

export const identify = (userId: string, properties: TrackableProperties, destinations: Set<string>, excludedDestinations: Set<string>) => {
  if (window.analytics) {
    window.analytics.identify(userId, properties, {
      integrations: integrations(destinations, excludedDestinations),
    });
  }
};

export const reset = () => {
  if (window.analytics) {
    window.analytics.reset();
  }

  setTimeout(() => {
    if (window.mixpanel) {
      window.mixpanel.reset();
    }
  }, 5);
};

export const page = (pagePath: string, properties: TrackableProperties, destinations: Set<string>, excludedDestinations: Set<string>) => {
  if (properties) {
    properties[PATH] = pagePath;
  } else {
    properties = {};
    properties[PATH] = pagePath;
  }

  if (window.analytics) {
    window.analytics.page(properties, {
      integrations: integrations(destinations, excludedDestinations),
    });
  }
};
