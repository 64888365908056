import { Button, VStack } from "@chakra-ui/react";
import { useLinkActionProps } from "hooks";
import type { FunctionComponent } from "react";
import { motion } from "framer-motion";
import React from "react";

interface Props {
  collectionId?: string;
  size?: string;
}

// Create motion components
const MotionVStack = motion(VStack);
const MotionButton = motion(Button);

export const ProjectQuestionActionButtons: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = () => {
  const commonLinkActionProps = useLinkActionProps("sm");

  return (
    <MotionVStack
      alignItems={"flex-start"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, staggerChildren: 0.5, delayChildren: 0.5 }}
      transition={{ duration: 0.5 }}>
      <MotionButton {...commonLinkActionProps} whileHover={{ scale: 1.1 }}>
        Get more FactSet metrics
      </MotionButton>
      <MotionButton {...commonLinkActionProps} whileHover={{ scale: 1.1 }}>
        Get more research on this topic
      </MotionButton>
      <MotionButton {...commonLinkActionProps} whileHover={{ scale: 1.1 }}>
        Give me a more succinct answer
      </MotionButton>
      <MotionButton {...commonLinkActionProps} whileHover={{ scale: 1.1 }}>
        Give me a more detailed list style answer
      </MotionButton>
    </MotionVStack>
  );
};
