import { Box, Button, Icon, IconButton, SimpleGrid, Stack, Text, Tooltip, useColorModeValue, useToast } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useAppDispatch, useButtonProps } from "hooks";
import { AiOutlineDelete } from "react-icons/ai";
import { useBillingPlans } from "hooks/useBillingPlans";
import { BillingPlanModal } from "./modals/BillingPlanModal";
import { BillinPlanUpsertModal } from "./modals/BillingPlanUpsertModal";
import { removePlan, removePlanUsers } from "state/billingPlans/operations";
import { ConfirmDeleteModal } from "./modals/ConfirmDeleteModal";

const MODALS_KEYS = {
  DETAIL: "detail",
  UPSERT: "upsert",
  DELETE: "delete",
};

export const BillingPlans = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const [isOpen, setIsOpen] = useState<string | undefined>();
  const [selectedCode, setSelectedCode] = useState<string | null>(null);

  const billingPlans = useBillingPlans();

  const onClose = useCallback(() => {
    setIsOpen(undefined);
    setSelectedCode(null);
  }, []);

  const onDelete = useCallback(async () => {
    if (!selectedCode) {
      return;
    }

    const billingPlan = billingPlans.find((billingPlan) => billingPlan.code === selectedCode);

    if (!billingPlan) {
      return;
    }

    if (billingPlan.planUsers && billingPlan.planUsers?.length > 0) {
      await dispatch(
        removePlanUsers({
          planCode: selectedCode,
          ids: billingPlan.planUsers.map((planUser) => planUser.id),
        })
      );
    }

    await dispatch(removePlan(selectedCode));

    toast({
      title: "Billing plan",
      description: `Action completed`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    onClose();
  }, [selectedCode, billingPlans, dispatch, onClose, toast]);

  const onOpenUpsert = useCallback((code?: string) => {
    if (code) setSelectedCode(code);
    setIsOpen(MODALS_KEYS.UPSERT);
  }, []);

  // styles
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const commonButtonProps = useButtonProps("sm", "primary");
  const subTitleColor = useColorModeValue("charli.mediumGray", "gray.400");
  const titleColor = useColorModeValue("charli.lightGray", "gray.500");

  const renderPlans = useCallback(() => {
    return (billingPlans || []).map((plan, index) => {
      return (
        <Box
          fontSize={"sm"}
          key={index}
          position="relative"
          cursor="pointer"
          backgroundColor={bgColor}
          borderRadius="md"
          overflow="hidden"
          borderColor={borderColor}
          borderWidth="1px"
          boxShadow="none"
          onClick={async (event: { stopPropagation: () => void }) => {
            event.stopPropagation();

            setSelectedCode(plan.code);
            setIsOpen(MODALS_KEYS.UPSERT);
          }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" backgroundColor={borderColor} p=".5rem">
            <Text width="100%" isTruncated color={subTitleColor} fontWeight="semibold">
              {plan.name}
            </Text>
            <Tooltip aria-label="" label={`Delete ${plan.name} Plan`} placement="top" hasArrow>
              <IconButton
                backgroundColor={"transparent"}
                height="unset"
                minWidth={"unset"}
                isDisabled
                cursor="pointer"
                icon={<Icon as={AiOutlineDelete} boxSize="1rem" />}
                aria-label="Delete"
                onClick={async (event: { stopPropagation: () => void }) => {
                  event.stopPropagation();

                  setSelectedCode(plan.code);
                  setIsOpen(MODALS_KEYS.DELETE);
                }}
                _hover={{ backgroundColor: "unset" }}
              />
            </Tooltip>
          </Stack>
          <Stack p=".5rem">
            <Stack align="flex-start" justifyContent="space-between" width="100%">
              {plan.planUsers && (
                <Stack>
                  <Text fontSize="xs" color={titleColor}>
                    Users
                  </Text>
                  <Text fontSize="sm" color={subTitleColor} fontWeight="semibold" mt="0!important">
                    {plan.planUsers.length > 0 ? plan.planUsers.map(({ userName }) => userName).join(", ") : "No users"}
                  </Text>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Box>
      );
    });
  }, [billingPlans, bgColor, borderColor, subTitleColor, titleColor]);

  return (
    <>
      <Stack direction="row" justifyContent="end" pb="1rem" mt="0!important">
        <Button isDisabled {...commonButtonProps} onClick={() => onOpenUpsert()}>
          Create New Plan
        </Button>
      </Stack>
      <SimpleGrid columns={[1, 2, 3, 4, 5]} spacingX="1rem" spacingY="1rem">
        {renderPlans()}
      </SimpleGrid>

      {isOpen === MODALS_KEYS.DETAIL && selectedCode && <BillingPlanModal code={selectedCode} isOpen onClose={onClose} />}
      {isOpen === MODALS_KEYS.UPSERT && <BillinPlanUpsertModal code={selectedCode} isOpen onClose={onClose} />}
      {isOpen === MODALS_KEYS.DELETE && <ConfirmDeleteModal isOpen onClose={onClose} onDelete={onDelete} />}
    </>
  );
};
