/* eslint-disable @typescript-eslint/no-empty-function */
import { useDisclosure } from "@chakra-ui/react";
import type { ReactNode } from "react";
import React, { createContext, useState, useContext, useCallback } from "react";
import type { RequestEntities } from "types/charliui";
import type { Collection } from "types/collection";

const defaultThrowError = (): void => {
  throw new Error("Component must be nested inside <ConversationContextProvider />");
};

export const useConversationContext = () => {
  return useContext(ConversationContext);
};

export const ConversationContext = createContext({
  conversationId: "",
  setConversationId: (() => {
    defaultThrowError();
  }) as React.Dispatch<React.SetStateAction<string>>,
  contentId: "",
  setContentId: (text: string): void => {
    defaultThrowError();
  },
  collectionId: "",
  setCollectionId: (text: string): void => {
    defaultThrowError();
  },
  requestEntities: [] as RequestEntities,
  setRequestEntities: (() => {
    defaultThrowError();
  }) as React.Dispatch<React.SetStateAction<RequestEntities>>,
  hideButtonElements: false,
  setHideButtonElements: (value: boolean): void => {
    defaultThrowError();
  },
  showHelpPopups: false,
  setShowHelpPopups: (value: boolean): void => {
    defaultThrowError();
  },
  hideConversationButton: false,
  setHideConversationButton: (value: boolean): void => {
    defaultThrowError();
  },
  showCommands: false,
  setShowCommands: (() => {
    defaultThrowError();
  }) as React.Dispatch<React.SetStateAction<boolean>>,
  onConversationOpen: (conversationId?: string): void => {
    defaultThrowError();
  },
  isConversationOpen: false,
  onConversationClose: (): void => {
    defaultThrowError();
  },
  childPanelCount: 0,
  setChildPanelCount: (value: number): void => {
    defaultThrowError();
  },
  isConversationDialogExpanded: false,
  setIsConversationDialogExpanded: (value: boolean): void => {
    defaultThrowError();
  },
  willResetConversationDialog: false,
  setWillResetConversationDialog: (value: boolean): void => {
    defaultThrowError();
  },
  isUsingDefaultConversationDialog: false,
  setIsUsingDefaultConversationDialog: (value: boolean): void => {
    defaultThrowError();
  },
  isEditing: false,
  setIsEditing: (value: boolean): void => {
    defaultThrowError();
  },
  conversationDialogWidth: 0,
  setConversationDialogWidth: (value: number): void => {
    defaultThrowError();
  },
  isAnotherInputFocused: false,
  setIsAnotherInputFocused: (value: boolean): void => {
    defaultThrowError();
  },
  questionText: "",
  setQuestionText: (value: string): void => {
    defaultThrowError();
  },
  showSuggestions: false,
  setShowSuggestions: (value: boolean): void => {
    defaultThrowError();
  },
  isUpgradeModalOpen: true,
  onUpgradeModalOpen: (): void => {
    defaultThrowError();
  },
  onUpgradeModalClose: (): void => {
    defaultThrowError();
  },
  onSubscriptionModalOpen: (): void => {
    defaultThrowError();
  },
  onSubscriptionModalClose: (): void => {
    defaultThrowError();
  },
  isSubscriptionModalOpen: false,
  isUpgrading: false,
  setIsUpgrading: (value: boolean): void => {
    defaultThrowError();
  },
  isPortfolioModalOpen: false,
  onPortfolioModalOpen: (): void => {
    defaultThrowError();
  },
  onPortfolioModalClose: (): void => {
    defaultThrowError();
  },
  selectedProjectCategory: "" as string | undefined,
  setSelectedProjectCategory: (value: string | undefined): void => {
    defaultThrowError();
  },
  filteredProjects: [] as Collection[] | undefined,
  setFilteredProjects: (() => {
    defaultThrowError();
  }) as React.Dispatch<React.SetStateAction<Collection[] | undefined>>,
  isDeleteProjectModalOpen: false,
  onDeleteProjectModalOpen: (): void => {
    defaultThrowError();
  },
  onDeleteProjectModalClose: (): void => {
    defaultThrowError();
  },
  isDeleteProjectId: "" as string | undefined,
  setIsDeleteProjectId: (value: string | undefined): void => {
    defaultThrowError();
  },
  navigatePath: "" as string | undefined,
  setNavigatePath: (value: string | undefined): void => {
    defaultThrowError();
  },
  isOnboardingModalOpen: false,
  onOnboardingModalOpen: (): void => {
    defaultThrowError();
  },
  onOnboardingModalClose: (): void => {
    defaultThrowError();
  },
});

export const ConversationContextProvider = ({ children }: { children: ReactNode }) => {
  const {
    isOpen: isConversationOpen,
    onOpen: onConversationOpen,
    onClose: onConversationClose,
  } = useDisclosure({
    defaultIsOpen: navigator.userAgent.includes("HeadlessChrome"),
  });
  const [conversationId, setConversationId] = useState("");
  const [contentId, setContentId] = useState("");
  const [requestEntities, setRequestEntities] = useState<RequestEntities>([]);
  const [collectionId, setCollectionId] = useState("");
  const [hideButtonElements, setHideButtonElements] = useState(false);
  const [hideConversationButton, setHideConversationButton] = useState(false);
  const [showHelpPopups, setShowHelpPopups] = useState(false);
  const [showCommands, setShowCommands] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [childPanelCount, setChildPanelCount] = useState(0);
  const [isConversationDialogExpanded, setIsConversationDialogExpanded] = useState(false);
  const [conversationDialogWidth, setConversationDialogWidth] = useState(0);
  const [willResetConversationDialog, setWillResetConversationDialog] = useState(false);
  const [isUsingDefaultConversationDialog, setIsUsingDefaultConversationDialog] = useState(false);
  const [isAnotherInputFocused, setIsAnotherInputFocused] = useState(false);
  const [questionText, setQuestionText] = useState("");
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const { isOpen: isUpgradeModalOpen, onOpen: onUpgradeModalOpen, onClose: onUpgradeModalClose } = useDisclosure();
  const { isOpen: isPortfolioModalOpen, onOpen: onPortfolioModalOpen, onClose: onPortfolioModalClose } = useDisclosure();
  const [selectedProjectCategory, setSelectedProjectCategory] = useState<string | undefined>(undefined);
  const [filteredProjects, setFilteredProjects] = useState<Collection[] | undefined>(undefined);
  const { isOpen: isSubscriptionModalOpen, onOpen: onSubscriptionModalOpen, onClose: onSubscriptionModalClose } = useDisclosure();
  const [isUpgrading, setIsUpgrading] = useState(false);
  const { isOpen: isDeleteProjectModalOpen, onOpen: onDeleteProjectModalOpen, onClose: onDeleteProjectModalClose } = useDisclosure();
  const [isDeleteProjectId, setIsDeleteProjectId] = useState<string | undefined>(undefined);
  const [navigatePath, setNavigatePath] = useState<string | undefined>(undefined);
  const { isOpen: isOnboardingModalOpen, onOpen: onOnboardingModalOpen, onClose: onOnboardingModalClose } = useDisclosure();

  const onConversationOpenCB = useCallback(
    (conversationId?: string) => {
      if (conversationId) {
        setConversationId(conversationId);
      }

      onConversationOpen();
    },
    [onConversationOpen]
  );

  return (
    <ConversationContext.Provider
      value={{
        isOnboardingModalOpen,
        onOnboardingModalOpen,
        onOnboardingModalClose,
        navigatePath,
        setNavigatePath,
        isDeleteProjectModalOpen,
        onDeleteProjectModalOpen,
        onDeleteProjectModalClose,
        isDeleteProjectId,
        setIsDeleteProjectId,
        isSubscriptionModalOpen,
        onSubscriptionModalOpen,
        onSubscriptionModalClose,
        isUpgrading,
        setIsUpgrading,
        filteredProjects,
        setFilteredProjects,
        questionText,
        setQuestionText,
        conversationId,
        setConversationId,
        contentId,
        setContentId,
        requestEntities,
        setRequestEntities,
        collectionId,
        setCollectionId,
        hideButtonElements,
        setHideButtonElements,
        hideConversationButton,
        setHideConversationButton,
        showHelpPopups,
        setShowHelpPopups,
        onConversationOpen: onConversationOpenCB,
        isConversationOpen,
        onConversationClose,
        showCommands,
        setShowCommands,
        childPanelCount,
        setChildPanelCount,
        isConversationDialogExpanded,
        setIsConversationDialogExpanded,
        isEditing,
        setIsEditing,
        conversationDialogWidth,
        setConversationDialogWidth,
        willResetConversationDialog,
        setWillResetConversationDialog,
        isUsingDefaultConversationDialog,
        setIsUsingDefaultConversationDialog,
        isAnotherInputFocused,
        setIsAnotherInputFocused,
        showSuggestions,
        setShowSuggestions,
        isUpgradeModalOpen,
        onUpgradeModalOpen,
        onUpgradeModalClose,
        isPortfolioModalOpen,
        onPortfolioModalOpen,
        onPortfolioModalClose,
        selectedProjectCategory,
        setSelectedProjectCategory,
      }}>
      {children}
    </ConversationContext.Provider>
  );
};
