import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "screens/common/static/logos/charli-logo-full.svg";
import { useForm } from "react-hook-form";
import type { RootState } from "state/rootReducer";
import { verifyEmail } from "state/session/operations";
import { buttonStyles, useAppDispatch, useButtonProps } from "hooks";
import { generateVerificationKey } from "api/user";
import { TabTitle } from "screens/common/components/TabTitle";
import { LoginWrapper } from "./components/LoginWrapper";

type FormValues = {
  verify: string;
};

export const Verify: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const { user } = useSelector((state: RootState) => state.session, shallowEqual);
  const [lastError, setLastError] = useState<string | undefined>(undefined);
  const [resendConfirmationMessage, setResendConfirmationMessage] = useState<string | undefined>(undefined);
  const buttonColorRegister = useColorModeValue("primary.default", "gray.100");
  const trialBannerStyle = buttonStyles["subscribe"];
  const commonButtonProps = useButtonProps("sm", "cta");

  useEffect(() => {
    if (user?.isEmailVerified === true) {
      navigate("/");
    }
  }, [navigate, user]);

  const onSubmit = (fields: FormValues) => {
    setResendConfirmationMessage(undefined);

    dispatch(verifyEmail(fields.verify)).then((result) => {
      if (result.payload === false) {
        setLastError("Provided code was not valid");
      } else {
        setLastError(undefined);
      }
    });
  };

  const onResendSubmit = async () => {
    await generateVerificationKey();
    setResendConfirmationMessage("Code was resent, check your email");
  };

  return (
    <LoginWrapper>
      <Stack justifyContent={"space-between"} width={"100%"} height="100vh" alignSelf="center">
        <TabTitle title="Charli > Verify" />
        <Box>
          <Box width="100%" height="3rem" {...trialBannerStyle}>
            <Flex width="100%" height="100%" alignItems="center" justifyContent="center">
              <Text textAlign="center" fontWeight={"semibold"} fontSize="xl">
                14 Day Free Trial
              </Text>
            </Flex>
          </Box>
          <Center py="4rem">
            <Image src={logo} alt="Charli logo" width="12rem" cursor="pointer" />
          </Center>
          <Center>
            <Stack justifyContent={"space-between"} spacing="2rem" px="2rem" maxWidth={["90%", "25rem", "20rem"]}>
              <Text pb="2rem" fontSize="sm" textAlign="center">
                Enter the verification code that was emailed to you in the box below and click Verify Code.
              </Text>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack align="center" justify="center" spacing="2rem">
                  <FormControl isInvalid={errors.verify !== undefined || lastError !== undefined}>
                    <FormLabel fontWeight="semibold" fontSize="sm" htmlFor="verify">
                      Verification Code
                    </FormLabel>
                    <Input
                      {...register("verify", { required: true })}
                      id="verify-code"
                      type="verify"
                      placeholder="paste code here"
                      borderColor={errors.verify ? "red.500" : "gray.200"}
                      fontSize="md"
                      boxShadow="none"
                    />
                    {errors.verify && <FormErrorMessage>Code is required.</FormErrorMessage>}
                    {lastError && <FormErrorMessage>{lastError}</FormErrorMessage>}
                  </FormControl>
                  <Button {...commonButtonProps} id="login-submit" type="submit" width="100%">
                    Verify Code
                  </Button>
                </Stack>
              </form>
              <Stack direction="row" justifyContent="space-between" width="100%" pt="5rem">
                <Button
                  id="link-button"
                  fontWeight="400"
                  fontFamily="print"
                  fontSize="sm"
                  color={buttonColorRegister}
                  variant="link"
                  onClick={onResendSubmit}>
                  Resend code
                </Button>
                <Button
                  id="link-button"
                  fontWeight="400"
                  fontFamily="print"
                  fontSize="sm"
                  color={buttonColorRegister}
                  variant="link"
                  onClick={() => {
                    navigate("/logout");
                  }}>
                  Logout
                </Button>
              </Stack>
              {resendConfirmationMessage && (
                <Text pt="1rem" pb="1.5rem" fontSize="sm" textAlign={"center"}>
                  {resendConfirmationMessage}
                </Text>
              )}
            </Stack>
          </Center>
        </Box>
      </Stack>
    </LoginWrapper>
  );
};
