import React, { useContext, useEffect, useMemo, useState } from "react";
import { Stack, Text, Button, useBreakpointValue, Menu, MenuButton, MenuList, useDisclosure, Box, Divider, Center } from "@chakra-ui/react";
import type { CollectionWithAdditionalProps } from "types/collection";
import { useButtonProps, useContents, useConversation, useItemSelector, useProjectParams, useTileProps } from "hooks";
import { getDateFromContent, getMetatDataForContent, getParentCellType } from "screens/content/common/utils";
import type { ContentDetails } from "types/content/ContentDetails";
import { OptionsMenuItem } from "screens/collection/components/OptionsMenuItem";
import { ContentPreview } from "screens/content/contentView/previewSection/ContentPreview";
import { ContentSummaryData } from "screens/content/contentView/previewSection/ContentSummaryData";
import { LandingTitle } from "screens/landing/components";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { formatDate } from "screens/common/modal/formatters";
import { ProjectReportPanelDeleteModal } from "./ProjectReportPanelDeleteModal";
import { updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { ConversationContext } from "screens/thread/ConversationContext";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { hasProjectOutput } from "screens/collection/components/utils";
import { ProjectReportPanelDownloadButton } from "./ProjectReportPanelDownloadButton";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";

interface Props {
  collection: CollectionWithAdditionalProps;
}

export const ProjectReportPanelLatestReport = ({ collection }: Props) => {
  const { contentId } = useProjectParams();
  const collectionMetadataIds = useMemo(() => collection?.metadataIds && collection?.metadataIds, [collection?.metadataIds]);
  const contentData = useContents(collectionMetadataIds || []);
  const currentConversationId = contentId ? contentId : collection.conversationId;
  const conversationState = useConversation(currentConversationId || "", ["generate_collection_report", "cancel"]).conversationState;
  const { isOpen: isDeleteReport, onOpen: onOpenDeleteReport, onClose: onCloseDeleteReport } = useDisclosure();
  const { onProjectReportSelectorOpen } = useAddToCharliContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const buttonSize = isMobile ? "xs" : "sm";
  const primaryButtonProps = useButtonProps(buttonSize, "primary");
  const [isSelectedContent, setIsSelectedContent] = useState<ContentDetails | undefined>(undefined);
  const commonTileProps = useTileProps();
  const { setSelectedItems, setFocusedItem } = useItemSelector();
  const { setRequestEntities } = useContext(ConversationContext);

  const contentDataFiltered = useMemo(() => {
    const filtered = contentData?.filter((content) => {
      return hasProjectOutput(content);
    });
    const sorted = filtered?.sort((a: ContentDetails, b: ContentDetails) => {
      return new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime();
    });
    return sorted ? sorted.slice(0) : [];
  }, [contentData]);

  useEffect(() => {
    const fetchContentDetails = async () => {
      if (contentDataFiltered) {
        const metadata = getMetatDataForContent(contentDataFiltered[0]);
        if (metadata && metadata.mediaId.length > 0) {
          setFocusedItem({ id: metadata.mediaId, type: metadata.fileType, ...(metadata.extension && { extension: metadata.extension }) });
        }
        if (metadata && metadata.mediaId.length > 0) setSelectedItems({ [metadata.mediaId]: { type: metadata.fileType } });
        updateBatchRequestEntities([{ entity: metadata.fileType, value: metadata.mediaId, source: "selection" }], setRequestEntities);
      }
    };

    contentDataFiltered.length > 0 && fetchContentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box {...commonTileProps} borderWidth={isMobile ? "0" : "1px"} cursor="default" p={isMobile ? "0" : "1rem"} overflow={"unset"}>
      <LandingTitle text="Latest AI Generated Report" color="primary.default">
        {contentDataFiltered[0] && (
          <Stack direction="row" spacing="0.4rem" justifyContent={"flex-end"} width="100%" className="ch-report-options-menu">
            <Menu isLazy>
              <MenuButton
                {...primaryButtonProps}
                className="ch-project-report-options"
                as={Button}
                aria-label="Options"
                rightIcon={<TriangleDownIcon />}
                onClick={(event) => event.stopPropagation()}>
                Actions
              </MenuButton>
              <MenuList minWidth={"unset"}>
                <OptionsMenuItem
                  menuLabel={"Generate New Report"}
                  className="ch-content-report-panel"
                  onClick={onProjectReportSelectorOpen}
                />
                <OptionsMenuItem
                  menuLabel={"Delete Report"}
                  className="ch-delete-record"
                  onClick={() => {
                    setIsSelectedContent(contentDataFiltered[0]);
                    onOpenDeleteReport();
                  }}
                />
              </MenuList>
            </Menu>
          </Stack>
        )}
      </LandingTitle>
      <Divider marginTop={"0!important"} borderColor={"primary.default"} width="15rem" />
      {!contentDataFiltered[0] && (
        <Center width="100%" height="4rem">
          {conversationState !== "in_progress" ? (
            <Button {...primaryButtonProps} onClick={onProjectReportSelectorOpen} className="ch-content-report-panel">
              Generate New Report
            </Button>
          ) : (
            <Stack direction="row" spacing="1rem">
              <Text fontSize={"sm"}>Generating Report</Text>
              <TypingIndicator size="small" />
            </Stack>
          )}
        </Center>
      )}
      {contentDataFiltered[0] && (
        <Box pt="1rem">
          <Stack direction={isMobile ? "column" : "row"} className="ch-latest-project-report" spacing={"2rem"}>
            <Stack height={"100%"} {...commonTileProps} cursor="default" className="test" width="unset" minWidth="21rem">
              <ContentPreview itemContent={contentDataFiltered[0]} thumbnailHeight={"400"} thumbnailWidth={"300"} />
              <Center>
                <ProjectReportPanelDownloadButton
                  downloadUrl={contentDataFiltered[0].urls?.downloadUrl || ""}
                  integrationUrn={contentDataFiltered[0].integrationUrn || ""}
                  resourceName={contentDataFiltered[0].name || ""}
                  isDisabled={!contentDataFiltered[0].urls?.downloadUrl}
                />
              </Center>
            </Stack>
            <Stack spacing="1rem" cursor="default" width="100%">
              <Text wordBreak="break-word" fontSize="sm" className="file-name">
                {contentDataFiltered[0].name}
              </Text>
              <Stack direction="row" spacing="1rem">
                <Text fontSize="sm">Date Added:</Text>
                <Text wordBreak="break-word" fontWeight="light" fontSize="sm">
                  {formatDate(getDateFromContent(contentDataFiltered[0]), `${isMobile ? "d-MM-yy" : "d MMMM yyyy"}`)}
                </Text>
              </Stack>
              <ContentSummaryData hideTitle itemContent={contentDataFiltered[0]} />
            </Stack>
          </Stack>
        </Box>
      )}
      {isSelectedContent && currentConversationId && (
        <ProjectReportPanelDeleteModal
          onClose={onCloseDeleteReport}
          isOpen={isDeleteReport}
          collectionId={collection.id}
          conversationId={currentConversationId}
          mediaId={isSelectedContent?.mediaId}
          parentCellTypeEntityName={getParentCellType(isSelectedContent) + "_id"}
          reason={"Deleted by user"}
          reportName={isSelectedContent.name}
        />
      )}
    </Box>
  );
};
