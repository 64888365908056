import { useUserProfile } from "hooks";
import type { ReactNode } from "react";
import React, { createContext, useCallback, useState, useContext } from "react";
import type { SettingsTab } from "types/settings";

const defaultThrowError = (): void => {
  throw new Error("Component must be nested inside <SettingsProvider />");
};

export const useSettingContext = () => {
  return useContext(SettingsProviderContext);
};

export const SettingsProviderContext = createContext({
  activeSettingsTab: "profile" as SettingsTab,
  setActiveSettingsTab: defaultThrowError as (activeSettingsTab: SettingsTab) => void,
  isSideNavigationOpen: false,
  setIsSideNavigationOpen: defaultThrowError as (isSideNavigationOpen: boolean) => void,
  isHeaderTextEditable: false,
  setIsHeaderTextEditable: defaultThrowError as (isHeaderTextEditable: boolean) => void,
  headerText: "",
  setHeaderText: defaultThrowError as (isSideNavigationOpen: string, isHeaderEditable?: boolean) => void,
  isEditing: false,
  setIsEditing: defaultThrowError as (isEditing: boolean) => void,
  sidebarWidth: 0,
  setSidebarWidth: defaultThrowError as (sidebarWidth: number) => void,
  isLoading: false,
  setIsLoading: defaultThrowError as (isLoading: boolean) => void,
});

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const [activeSettingsTab, setActiveSettingsTab] = useState<SettingsTab>("profile");
  const [isSideNavigationOpen, setIsSideNavigationOpen] = useState(false);
  const [isHeaderTextEditable, setIsHeaderTextEditable] = useState(false);
  const { firstName } = useUserProfile();
  const [headerText, setHeaderText] = useState(`Welcome back, ${firstName}!`);
  const [isEditing, setIsEditing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const setHeaderTextCallback = useCallback((text: string, isEditable = false) => {
    setHeaderText(text);
    setIsHeaderTextEditable(isEditable);
  }, []);

  return (
    <SettingsProviderContext.Provider
      value={{
        activeSettingsTab,
        setActiveSettingsTab,
        isSideNavigationOpen,
        setIsSideNavigationOpen,
        isHeaderTextEditable,
        setIsHeaderTextEditable,
        headerText,
        setHeaderText: setHeaderTextCallback,
        isEditing,
        setIsEditing,
        sidebarWidth,
        setSidebarWidth,
        isLoading,
        setIsLoading,
      }}>
      {children}
    </SettingsProviderContext.Provider>
  );
};
