import { Box, Stack, useColorModeValue, useBreakpointValue, Text } from "@chakra-ui/react";
import {
  useCollectionHasHighlights,
  useConfigMap,
  useContents,
  useConversation,
  useFilterContentsIdsWithoutProjectOutput,
  useLatestCollectionWorkflowId,
} from "hooks";
import type { FunctionComponent } from "react";
import React, { useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Highlights } from "screens/panels/highlights/Highlights";
import type { CollectionWithAdditionalProps } from "types/collection";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useProjectTimeSeriesData } from "../../../../collection/components/utils";
import { useWorkflowCompletionDateOrNow } from "hooks/useWorkflows";
import { CollectionsFilterContext } from "screens/landing/tabs";
import { getViewConfig } from "configs/configMap";
import { WorkflowProgressBar } from "screens/landing/components/WorkflowProgressBar";
import { formatDistanceToNow } from "date-fns";
import { CustomAreaChartGradient } from "screens/landing/tabs/collections/chartComponents/CustomAreaChartGradient";
import type { DataType } from "hooks/useStats";
import { ProjectHeaderImage } from "./components/ProjectHeaderImage";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { WorkflowProgressClarification } from "screens/landing/components/WorkflowProgressClarification";
import { MarkdownItemView } from "screens/markdown/MarkdownItemView";
import Highlighter from "react-highlight-words";
import { useTickerDataBySymbol } from "hooks/useTickers";

interface Props {
  collection: CollectionWithAdditionalProps;
}

export const HomeDefaultTile: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ collection }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const tileBorderColor = useColorModeValue("gray.300", "gray.700");
  const borderColorHover = useColorModeValue("gray.400", "gray.600");
  const navigate = useNavigate();
  const extractedHighlights = collection?.extractedHighlights;
  const currentWorkflowId = useLatestCollectionWorkflowId(collection.id);
  const currentWorkflowCompletionDate = useWorkflowCompletionDateOrNow(currentWorkflowId);
  const configMap = useConfigMap();
  const { onConversationOpen } = useContext(ConversationContext);
  const { searchText } = useContext(CollectionsFilterContext);
  const companyStockPriceResourceData = useProjectTimeSeriesData(collection);
  const highlightLines = useBreakpointValue([4, 5, 5, 6, 6]);
  const collectionMetadataIds = useMemo(() => collection?.metadataIds ?? [], [collection?.metadataIds]);
  const filteredContentData = useFilterContentsIdsWithoutProjectOutput(collectionMetadataIds);
  const { conversationState, initialCommandText } = useConversation(collection.conversationId || "");

  const companyTicker = initialCommandText?.split(" ").find((word, index, arr) => arr[index - 1] === ">company_ticker");
  const tickerData = useTickerDataBySymbol(companyTicker);
  const reformattedStockData =
    (companyStockPriceResourceData &&
      companyStockPriceResourceData.data.length &&
      companyStockPriceResourceData.data.map((data) => {
        const date = new Date(data.date);
        const formattedDate = date.toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" });
        return { label: formattedDate, name: formattedDate, value: data.value } as DataType;
      })) ||
    [];

  const getPath = () => {
    const projectRoute = getViewConfig("route", collection?.collectionType || "", configMap);
    if (projectRoute) {
      return navigate(`/${projectRoute}/${collection.id}`);
    } else {
      onConversationOpen();
    }
  };

  const businessOverview = useMemo(() => {
    const relevantQuestions = ["what are the material changes", "company nature of business and overview"];
    const question = collection.questions?.find(
      (q) => q.status !== "unpinned" && relevantQuestions.includes(q.question.toLowerCase()) && q.answers && q.answers.length > 0
    );

    return question?.answers[0].answer;
  }, [collection.questions]);

  const hasHighlights = useCollectionHasHighlights(collection.id);

  const projectTitle = getViewConfig("title", collection.collectionType, configMap);

  const itemCount = collectionMetadataIds.length;

  useContents(collectionMetadataIds, { refreshFromNetwork: true });

  return (
    <Box
      position="relative"
      cursor="pointer"
      _hover={{
        borderColor: borderColorHover,
      }}
      backgroundColor={bgColor}
      borderColor={tileBorderColor}
      borderWidth="1px"
      borderRadius="md"
      justifyContent="flex-start"
      width="100%"
      height={"100%"}
      minH="13rem"
      overflow="hidden"
      _active={{ backgroundColor: { bgColor } }}
      p="1rem"
      pr="11px"
      onClick={() => {
        getPath();
      }}
      whiteSpace="normal"
      textAlign="left"
      className={`ch-collections-tab-collection-tile simple-tile-layout ${hasHighlights ? "has-highlights" : ""}`}>
      {currentWorkflowId && (
        <Box position="absolute" right="5px" top="5px" bgColor={bgColor} borderRadius="full" boxSize={"1.4rem"}>
          <WorkflowProgressBar
            workflowId={currentWorkflowId}
            size="compact"
            lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
              addSuffix: true,
              includeSeconds: false,
            })}
            progressIndicatorStyle="circle"
            conversationId={collection.conversationId}
          />
        </Box>
      )}
      <Stack direction="row" height="100%" width="100%" spacing="1rem">
        <Stack width="8rem" spacing="4.5rem">
          <ProjectHeaderImage metadataIds={filteredContentData} projectType={collection.collectionType} />
          {(hasHighlights || (businessOverview && businessOverview.length > 0)) && reformattedStockData.length && (
            <Box zIndex={1}>
              <CustomAreaChartGradient
                showTooltip={false}
                chartId="project-chart"
                data={reformattedStockData}
                width="100%"
                height="4rem"
                chartHeight={75}
              />
            </Box>
          )}
        </Stack>
        <Stack alignContent={"top"} width="100%" spacing=".5rem">
          {collection.collectionType !== "due_diligence" && <TextOverflowTooltip label={collection.name} />}
          {hasHighlights || (businessOverview && businessOverview.length > 0) ? (
            <Box alignContent={"top"}>
              {businessOverview && businessOverview.length > 0 ? (
                <Box maxHeight={filteredContentData.length === 0 ? "8.5rem" : "11rem"} overflow="auto" fontSize={"xs"}>
                  <Box pr="5px">
                    <MarkdownItemView markdown={businessOverview} />
                  </Box>
                </Box>
              ) : (
                <Highlights
                  textOnlyView
                  textTruncated
                  extractedHighlights={extractedHighlights}
                  collectionId={collection.id}
                  conversationId={collection.id}
                  showActionMenu={false}
                  maxHighlights={1}
                  maxLines={highlightLines}
                  searchPhrase={searchText}
                />
              )}
            </Box>
          ) : (
            <>
              {conversationState === "in_progress" && (
                <Text alignSelf="left" fontSize="xs" fontStyle={"italic"}>
                  {tickerData ? (
                    <Highlighter
                      highlightStyle={{
                        backgroundColor: "transparent",
                        fontWeight: "bold",
                      }}
                      searchWords={[`${tickerData.name} (${tickerData.symbol})` || ""]}
                      autoEscape
                      textToHighlight={`Your ${projectTitle.replace("All", "")} project ${
                        tickerData ? `on ${tickerData.name} (${tickerData.symbol})` : ""
                      } is currently being processed. You will be notified when it is complete`}
                    />
                  ) : (
                    `Your ${projectTitle.replace("All", "")} project is currently being processed. You will be notified when it is complete`
                  )}
                </Text>
              )}
              {conversationState === "complete" && (
                <Text alignSelf="left" fontSize="xs">
                  {itemCount
                    ? `Your ${projectTitle.replace("All", "")} project ${
                        tickerData ? `on ${tickerData.name} (${tickerData.symbol})` : ""
                      } is complete. There ${itemCount === 1 ? "is" : "are"} ${itemCount} source content item${
                        itemCount === 1 ? "" : "s"
                      } in the project. Click here to access the project.`
                    : `Your ${projectTitle.replace(
                        "All",
                        ""
                      )} project is complete. No resources were added but you can click here to access the project.`}
                </Text>
              )}
            </>
          )}
        </Stack>
      </Stack>
      {currentWorkflowId && conversationState === "in_progress" && (
        <Box
          zIndex={1}
          position={"absolute"}
          width="100%"
          left="0"
          bottom="0"
          borderColor={tileBorderColor}
          borderTopWidth="1px"
          p="10px"
          bgColor={bgColor}>
          <WorkflowProgressBar
            workflowId={currentWorkflowId}
            lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
              addSuffix: true,
              includeSeconds: false,
            })}
            size="compact"
            showInline={false}
            hideCompletedInDate
            conversationId={collection.conversationId}
          />
        </Box>
      )}
      {currentWorkflowId && conversationState !== "complete" && conversationState !== "in_progress" && (
        <WorkflowProgressClarification workflowId={currentWorkflowId} collectionId={collection.id} size="compact" />
      )}
    </Box>
  );
};
