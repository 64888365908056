import React, { useEffect, useState } from "react";
import { Box, Center, Grid, GridItem, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { useTileProps, useMetricProps } from "hooks";
import useMockData from "hooks/useMockData";
import { useDebouncedCallback } from "use-debounce";
import { SimpleMetric } from "../dashboard/SimpleMetric";
import { CustomPieChart } from "./chartComponents/CustomPieChart.";
import { ComingSoonOverlay } from "screens/common/components/ComingSoonOverlay";

export const ProjectMetrics = () => {
  const commonTileProps = useTileProps();
  const commonMetricProps = useMetricProps();
  const mockData = useMockData(2).mockLabelData;
  const [fontSize, setFontSize] = useState("2.5rem");
  const [columns, setColumns] = useState(5);
  const bgColor = useColorModeValue("gray.200", "gray.800");
  const debouncedSetWidth = useDebouncedCallback(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 850 && windowWidth > 0) {
      setFontSize("1.5rem");
      setColumns(1);
    } else if (windowWidth < 1100) {
      setFontSize("1.5rem");
      setColumns(2);
    } else {
      setFontSize("2.5rem");
      setColumns(5);
    }
  }, 30);
  useEffect(() => {
    window.addEventListener("resize", debouncedSetWidth);
    debouncedSetWidth();
    return () => {
      window.removeEventListener("resize", debouncedSetWidth);
    };
  }, [debouncedSetWidth]);

  return (
    <Box position={"relative"} height="100%" p="1rem" bgColor={bgColor} mb="1rem">
      <ComingSoonOverlay message="Project Metrics" height="calc(100% - 2rem)" width="calc(100% - 2rem)" />
      <Grid
        templateColumns={`repeat(${columns}, 1fr)`}
        templateRows={`repeat(${columns === 2 ? 5 : columns === 1 ? 4 : 2}, 1fr)`}
        gap={3}
        height={columns === 1 ? "21rem" : columns === 2 ? "40rem" : "17rem"}>
        {columns !== 1 && (
          <>
            <GridItem colSpan={1} rowSpan={2}>
              <Box {...commonTileProps}>
                <SimpleMetric title="Verified ROI" subtitle="FOR PAST 4 WEEKS">
                  <CustomPieChart
                    chartHeight={200}
                    chartId="ch-project-landing-metric-verified-roi"
                    data={mockData}
                    pieColors={["#72ad91", "#6482C0"]}
                  />
                </SimpleMetric>
              </Box>
            </GridItem>
            <GridItem colSpan={1} rowSpan={2}>
              <Box {...commonTileProps}>
                <SimpleMetric title="In Progress States" subtitle="FOR PAST 4 WEEKS">
                  <CustomPieChart
                    chartHeight={200}
                    chartId="ch-project-landing-metric-in-progress-states"
                    data={mockData}
                    pieColors={["#72ad91", "#6482C0"]}
                  />
                </SimpleMetric>
              </Box>
            </GridItem>
          </>
        )}
        <GridItem colSpan={columns === 2 ? 2 : 1} rowSpan={columns === 5 ? 2 : 1}>
          <Box {...commonTileProps}>
            <Center height="100%">
              <SimpleMetric title="Completed Projects" subtitle="TODAY">
                <Text className="ch-project-landing-metric-completed-projects" {...commonMetricProps} fontSize={fontSize}>
                  8
                </Text>
              </SimpleMetric>
            </Center>
          </Box>
        </GridItem>
        <GridItem colSpan={columns === 1 ? 1 : 2} rowSpan={1}>
          <Stack direction="row" width="100%" height="100%" spacing={3}>
            <Box {...commonTileProps}>
              <SimpleMetric title="Records" subtitle="TODAY">
                <Text className="ch-project-landing-metric-records" {...commonMetricProps} fontSize={fontSize}>
                  827
                </Text>
              </SimpleMetric>
            </Box>
            <Box {...commonTileProps}>
              <SimpleMetric title="Resources" subtitle="TODAY">
                <Text className="ch-project-landing-metric-resources" {...commonMetricProps} fontSize={fontSize}>
                  104
                </Text>
              </SimpleMetric>
            </Box>
          </Stack>
        </GridItem>
        <GridItem colSpan={columns === 1 ? 1 : 2} rowSpan={1}>
          <Stack direction="row" width="100%" height="100%" spacing={3}>
            <Box {...commonTileProps}>
              <SimpleMetric title="AI Actions" subtitle="TODAY">
                <Text className="ch-project-landing-metric-ai-actions" {...commonMetricProps} fontSize={fontSize}>
                  349
                </Text>
              </SimpleMetric>
            </Box>
            <Box {...commonTileProps}>
              <SimpleMetric title="Manual Edits" subtitle="TODAY">
                <Text className="ch-project-landing-metric-manual-edits" {...commonMetricProps} fontSize={fontSize}>
                  4
                </Text>
              </SimpleMetric>
            </Box>
          </Stack>
        </GridItem>
      </Grid>
    </Box>
  );
};
