import React from "react";
import { SidebarButton } from "./SidebarButton";
import { SIDEBAR_ORGANIZATION_BUTTON_CLICKED } from "api/analytics/events";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { useMemberOrganizationAdmin } from "hooks/useMemberOrganizations";

export const SidebarOrganizationButton = () => {
  const organizationAdmin = useMemberOrganizationAdmin();

  if (!organizationAdmin) {
    return <React.Fragment />;
  }

  return (
    <SidebarButton
      text="Organization"
      icon={AiOutlineUsergroupAdd}
      screen={`organization`}
      routeParams={[organizationAdmin.organizationId]}
      analyticsEvent={SIDEBAR_ORGANIZATION_BUTTON_CLICKED}
      cssClasses={["ch-sidebar-nav-admin"]}
    />
  );
};
