import { prependProxyBaseUrl, request } from "api/request";
import { GetRelevantIntents } from "api/workflowsAdmin/models/GetRelevantIntents";
import type { ChildWorkflow, Workflow, ChildWorkflowStep } from "types/workflows/workflow";
import { GetWorkflowsProgressSteps } from "api/workflows/models/GetWorkflowsProgressSteps";
import { GetWorkflowsStats } from "api/workflows/models/GetWorkflowsStats";
import type { WorkflowFetchError } from "api/workflows/models/WorkflowFetchError";
import { GetWorkflowSummary } from "./models/GetWorkflowSummary";

const WORKFLOWS_ENDPOINT = prependProxyBaseUrl("/api/workflows");

const CHILD_WORKFLOWS_ENDPOINT = (workflowId: string) => `${WORKFLOWS_ENDPOINT}/${workflowId}/child-workflows`;

const WORKFLOWS_BY_USER_ID_ENDPOINT = (userId: string) => `${WORKFLOWS_ENDPOINT}/by-user-id/${userId}`;

const RELEVANT_INTENTS_ENDPOINT = () => `${WORKFLOWS_ENDPOINT}/relevant-intents`;

const CHILD_WORKFLOW_STEPS_ENDPOINT = (workflowId: string, childWorkflowId: string) =>
  `${WORKFLOWS_ENDPOINT}/${workflowId}/child-workflows/${childWorkflowId}/steps`;

export function getWorkflows(workflowsIds: string[]): Promise<(Workflow | WorkflowFetchError)[]> {
  return request().url(`${WORKFLOWS_ENDPOINT}`).query({ id: workflowsIds }).get().json();
}

export async function getChildWorkflows(workflowId: string): Promise<ChildWorkflow[]> {
  return await request().url(CHILD_WORKFLOWS_ENDPOINT(workflowId)).get().json();
}

export async function getUserWorkflows(
  userId: string,
  options?: {
    token?: string;
    limit?: number;
    filters?: {
      inStatus?: string[];
      outStatus?: string[];
      inIntents?: string[];
    };
  }
): Promise<{ data: string[]; totalCount: number; nextToken: string | null }> {
  return await request()
    .url(WORKFLOWS_BY_USER_ID_ENDPOINT(userId))
    .query({
      ...(options && {
        ...(options.token && { token: options.token }),
        ...(options.limit && { limit: options.limit }),
        ...(options.filters && options.filters.inStatus && { filterInStatus: options.filters.inStatus }),
        ...(options.filters && options.filters.inIntents && { filterInIntents: options.filters.inIntents }),
        ...(options.filters && options.filters.outStatus && { filterOutStatus: options.filters.outStatus }),
      }),
    })
    .get()
    .json();
}

export async function getRelevantIntents(): Promise<GetRelevantIntents> {
  const response = await request().url(RELEVANT_INTENTS_ENDPOINT()).get().json();
  const validate = GetRelevantIntents.validate(response);

  if (validate.success) {
    return validate.value;
  } else {
    throw new Error(validate.message);
  }
}

export async function getWorkflowsStats(workflowsIds: string[]): Promise<GetWorkflowsStats> {
  const response = await request().url(`${WORKFLOWS_ENDPOINT}/stats`).query({ id: workflowsIds }).get().json();

  const validate = GetWorkflowsStats.validate(response);

  if (!validate.success) {
    throw new Error(validate.message);
  } else {
    return validate.value;
  }
}

export async function getWorkflowsProgressSteps(workflowsIds: string[], intentFilters?: string[]): Promise<GetWorkflowsProgressSteps> {
  const response = await request()
    .url(`${WORKFLOWS_ENDPOINT}/progress-steps`)
    .query({ id: workflowsIds, ...(intentFilters && { intentFilters }) })
    .get()
    .json();

  const validate = GetWorkflowsProgressSteps.validate(response);
  if (!validate.success) {
    throw new Error(validate.message);
  } else {
    return validate.value;
  }
}

export async function getWorkflowSummary(workflowId: string): Promise<GetWorkflowSummary> {
  const response = await request().url(`${WORKFLOWS_ENDPOINT}/${workflowId}/summary`).get().json();

  const validate = GetWorkflowSummary.validate(response);

  if (!validate.success) {
    throw new Error(validate.message);
  } else {
    return validate.value;
  }
}

export async function getChildWorkflowsV2(
  workflowId: string,
  options?: {
    limit: number;
    token?: string;
    filters?: {
      status?: string;
      intent?: string;
    };
  }
): Promise<{ data: ChildWorkflow[]; totalCount: number; nextToken: string | null }> {
  return await request()
    .url(`${CHILD_WORKFLOWS_ENDPOINT(workflowId)}/v2`)
    .query({
      ...(options && {
        ...(options.limit && { limit: options.limit }),
        ...(options.token && { token: options.token }),
        ...(options.filters && options.filters.status && { filterByStatus: options.filters.status }),
        ...(options.filters && options.filters.intent && { filterByIntent: options.filters.intent }),
      }),
    })
    .get()
    .json();
}

export async function getChildWorkflowSteps(workflowId: string, childWorkflowId: string): Promise<ChildWorkflowStep[]> {
  return await request().url(CHILD_WORKFLOW_STEPS_ENDPOINT(workflowId, childWorkflowId)).get().json();
}
