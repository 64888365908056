import { Text, Stack, useColorModeValue, MenuItem } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useState } from "react";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { OptionsMenuItem } from "../../components/OptionsMenuItem";

interface Props {
  collectionId?: string;
  size?: string;
}

export const ProjectRerunButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = () => {
  const [isRequesting, setIsRequesting] = useState(false);
  const fontColor = useColorModeValue("gray.500", "gray.400");

  const submitMessage = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    setTimeout(() => {
      setIsRequesting(false);
    }, 5000);
    setIsRequesting(true);
  };

  return (
    <>
      {isRequesting ? (
        <MenuItem isDisabled>
          <Stack direction="row" spacing="1rem">
            <Text color={fontColor} fontSize={"sm"}>
              Rerunning Project
            </Text>
            <TypingIndicator size="small" />
          </Stack>
        </MenuItem>
      ) : (
        <OptionsMenuItem menuLabel={"Rerun Project"} className="ch-analyse-project" onClick={(event) => submitMessage(event)} isDisabled />
      )}
    </>
  );
};
