import { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { downloadConfiguredWorkflows } from "state/configuredWorkflow/operations";
import type { RootState } from "state/rootReducer";
import type { ConfiguredWorkflow } from "types/configuredWorkflows";
import { useEntitlements } from "./useEntitlements";

export const getActiveConfiguredWorkflowWithIntent = (userIntent: string, configuredWorkflows: ConfiguredWorkflow[]) => {
  return configuredWorkflows.find((cw) => cw.state === "active" && cw.userIntent === userIntent);
};

export function useConfiguredWorkflowsList() {
  const { configuredWorkflows, order } = useSelector((state: RootState) => state.configuredWorkflow);

  return useMemo(
    () =>
      order.map((id) => {
        return configuredWorkflows[id];
      }),
    [configuredWorkflows, order]
  );
}

export function useConfiguredWorkflows() {
  const configuredWorkflows = useConfiguredWorkflowsList();
  const dispatch = useDispatch();
  const entitlements = useEntitlements();
  const { manage_configured_workflows_read: hasAdminConfiguredWorkflowRead } = entitlements;

  useEffect(() => {
    if (!hasAdminConfiguredWorkflowRead) {
      return;
    }

    dispatch(downloadConfiguredWorkflows());
  }, [dispatch, hasAdminConfiguredWorkflowRead]);

  return configuredWorkflows;
}

export function useConfiguredWorkflow(id: string | null | undefined): ConfiguredWorkflow | undefined {
  const configuredWorkflow = useSelector(
    (state: RootState) => (id ? state.configuredWorkflow.configuredWorkflows[id] : undefined),
    shallowEqual
  );

  return configuredWorkflow;
}

export function useLoadingConfiguredWorkflows(): boolean {
  return useSelector((state: RootState) => state.configuredWorkflow.isLoading);
}

export function useActiveConfiguredWorkflowWithIntent(userIntent: string | undefined): ConfiguredWorkflow | undefined {
  const configuredWorkflows = useConfiguredWorkflowsList();

  return useMemo(
    () => (!userIntent ? undefined : getActiveConfiguredWorkflowWithIntent(userIntent, configuredWorkflows)),
    [configuredWorkflows, userIntent]
  );
}
