import { Stack, Grid, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { SETTINGS_CHROME_EXTENSION_BUTTON_CLICKED, SETTINGS_GMAIL_EXTENSION_BUTTON_CLICKED } from "api/analytics/events";
import { track } from "api/analytics";
import { IntegrationTile } from ".";

export const Extensions = () => {
  const gridColumns = useBreakpointValue([1, 1, 2, 3, 3]);

  return (
    <Stack spacing="1rem" height="100%" width="100%">
      <Grid templateColumns={`repeat(${gridColumns}, 1fr)`} gap="1.5rem">
        <IntegrationTile
          integration={{
            integrationId: "GmailAddon",
            name: "Charli for Gmail",
            description: "Forward attachments, emails, and other important information to organize it with Charli.",
            domain: "gmail.com",
            isEnabled: false,
            isAvailable: true,
            isPreferred: false,
            isInBeta: false,
            isUserEntitled: true,
            isReauthRequired: false,
            categoryKey: "integrations_gmail_addon_provider",
            isImplicitlyEnabled: false,
          }}
          buttonLabel="Install from Marketplace"
          onClick={() => {
            track(SETTINGS_GMAIL_EXTENSION_BUTTON_CLICKED);
            window.open("https://gsuite.google.com/u/0/marketplace/app/charli/150099786033", "_blank");
          }}
        />
        <IntegrationTile
          integration={{
            integrationId: "ChromeExtension",
            name: "Charli Chrome Extension",
            description: "Make saving web links easier by installing Charli Web Clipper for Chrome Extension.",
            domain: "chromestore.google.com",
            isEnabled: false,
            isAvailable: true,
            isPreferred: false,
            isInBeta: false,
            isUserEntitled: true,
            isReauthRequired: false,
            categoryKey: "integrations_charli_chrome_extension_provider",
            isImplicitlyEnabled: false,
          }}
          buttonLabel="Install from Web Store"
          onClick={() => {
            track(SETTINGS_CHROME_EXTENSION_BUTTON_CLICKED);
            window.open("https://chrome.google.com/webstore/detail/charli-web-clipper/pcciicjoadbodibdhkngcghmkeblmcmo", "_blank");
          }}
        />
      </Grid>
    </Stack>
  );
};
