import React, { createContext, useContext, useRef, useState } from "react";
import type { StockTickerData } from "api/stockTickerData";

export const CollectionsStocksContext = createContext({
  memoizedStocks: {} as Record<string, StockTickerData>,
  setMemoizedStocks: (() => {
    throw new Error("Component must be nested in <CollectionsStocksContext.Provider />");
  }) as React.Dispatch<React.SetStateAction<Record<string, StockTickerData>>>,
  isFetchingStocks: {} as React.MutableRefObject<Record<string, boolean>>,
});

export const useCollectionsStocksContext = () => {
  return useContext(CollectionsStocksContext);
};

export const CollectionsStocksProvider = ({ children }) => {
  // Memoize by company ticker and company stock exchange
  const [memoizedStocks, setMemoizedStocks] = useState<Record<`${string}-${string}`, StockTickerData>>({});
  const isFetchingStocks = useRef<Record<string, boolean>>({});

  return (
    <CollectionsStocksContext.Provider
      value={{
        memoizedStocks,
        setMemoizedStocks,
        isFetchingStocks,
      }}>
      {children}
    </CollectionsStocksContext.Provider>
  );
};
