/* eslint-disable no-script-url */
import { Stack, Tooltip, useColorModeValue, Box, Icon } from "@chakra-ui/react";
import { useConfigMap, useCustomTheme, useEntitlementKey, useGetViewConfig, useSidebarNavigation, useUserPreference } from "hooks";
import React, { useContext, useEffect, useRef } from "react";
import type { SidebarType } from "state/navigation/reducer";
import { SidebarNavigationMain } from "./SidebarNavigationMain";
import { AddNewMenu } from "./AddNewMenu";
import { HelpMenu } from "./HelpMenu";
import { SidebarAdminButton } from "./SidebarAdminButton";
import { SidebarOrganizationButton } from "./SidebarOrganizationButton";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { useDispatch } from "react-redux";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { SidebarNavigationLogos } from "./SidebarNavigationLogo";
import { BsPin, BsPinAngle } from "react-icons/bs";
import SideBarHelpVideos from "./SideBarHelpVideos";

export const SIDEBAR_PADDING = "1rem";

export const SidebarNavigation = () => {
  const hoverColor = useColorModeValue("secondaryButton.hover", "secondaryButton.hoverDarkMode");
  const buttonColor = useColorModeValue("secondaryButton.color", "secondaryButton.colorDarkMode");
  const bgColor = useColorModeValue("charli.lightBlue", "gray.800");
  const { isEmbeddedApp } = useCustomTheme();
  const { setSidebarType, currentSidebarType: reduxNavigationType } = useSidebarNavigation();
  const saveNavigationType = useUserPreference("ui_save_navigation_type");
  const userNavigationType = useUserPreference("ui_navigation_type");
  const currentSidebarType = saveNavigationType ? userNavigationType : reduxNavigationType;
  const isOrganizationAdminPageEnabled = useEntitlementKey("ui_enable_organization_admin_page");
  const isNewButtonEnabled = useEntitlementKey("ui_show_menu_new_button");
  const dispatch = useDispatch();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const mainMenuRef = useRef<HTMLDivElement>(null);
  const adminMenuRef = useRef<HTMLDivElement>(null);
  const { setSidebarWidth, setIsSideNavigationOpen } = useContext(SettingsProviderContext);
  const onChevronClick = (currentSidebarType: SidebarType) => {
    setSidebarType(currentSidebarType);
    setIsSideNavigationOpen(false);
    dispatch(updateTypedUserPreference({ preferenceKey: "ui_navigation_type", value: currentSidebarType }));
  };
  const configMap = useConfigMap();
  const homeOnboardingSteps = useGetViewConfig("onBoardingSteps", "home", configMap);

  useEffect(() => {
    const updateSidebarWidth = () => {
      sidebarRef.current ? setSidebarWidth(sidebarRef.current.offsetWidth) : setSidebarWidth(0);
    };
    updateSidebarWidth();
    window.addEventListener("resize", updateSidebarWidth);

    return () => {
      window.removeEventListener("resize", updateSidebarWidth);
    };
  }, [reduxNavigationType, setSidebarWidth]);

  return (
    <Stack ref={sidebarRef} mt="0!important" backgroundColor={bgColor} width="13rem" justifyContent="space-between" height="100vh">
      {!isEmbeddedApp && <SidebarNavigationLogos />}
      <Stack pt="1rem" height="100%" align="flex-start" justify="space-between">
        <Box px="1rem" ref={mainMenuRef} maxHeight={"70vh"} width="100%" overflowY={"auto"} overflowX="hidden" mt="0rem!important">
          <SidebarNavigationMain />
        </Box>
        <Stack ref={adminMenuRef} spacing="1rem" width="100%">
          {homeOnboardingSteps && <SideBarHelpVideos links={homeOnboardingSteps} />}
          {!isEmbeddedApp && (
            <Stack spacing="1rem" px="1rem">
              <SidebarAdminButton />
              {isOrganizationAdminPageEnabled && <SidebarOrganizationButton />}
              {isNewButtonEnabled && <HelpMenu />}
            </Stack>
          )}
          <Stack width="100%" px="1rem">
            {isNewButtonEnabled && <AddNewMenu buttonLabel="New" className="add-new-menu-sidebar" />}
            <Stack
              direction={"row"}
              spacing="1rem"
              justify={isNewButtonEnabled ? "flex-end" : "space-between"}
              pb={isNewButtonEnabled ? "unset" : "1rem"}>
              {!isNewButtonEnabled && (
                <Box>
                  <HelpMenu />
                </Box>
              )}
              <Tooltip label={currentSidebarType === "hidden" ? "Pin Sidebar" : "Unpin Sidebar"} placement="right">
                <Box
                  className="ch-sidebar-toggle"
                  width="1rem"
                  height="2rem"
                  cursor="pointer"
                  onClick={() => onChevronClick(currentSidebarType === "desktop" ? "hidden" : "desktop")}>
                  <Icon
                    as={currentSidebarType === "hidden" ? BsPinAngle : BsPin}
                    color={buttonColor}
                    zIndex="3"
                    backgroundColor={"transparent"}
                    _hover={{ color: hoverColor }}
                    cursor="pointer"
                    onClick={() => onChevronClick(currentSidebarType === "desktop" ? "hidden" : "desktop")}
                  />
                </Box>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
