import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
  useColorModeValue,
  Center,
  HStack,
  Image,
  useBreakpointValue,
  Flex,
  FormHelperText,
} from "@chakra-ui/react";
import { validate as validateEmail } from "email-validator";
import React from "react";
import { useForm } from "react-hook-form";
import { CopyrightNotice } from "screens/common/components";
import { TabTitle } from "screens/common/components/TabTitle";
import verifiedShield from "screens/common/static/misc/verified_shield.svg";
import soc2logo from "screens/common/static/logos/soc2_logo.png";
import { buttonStyles, useButtonProps } from "hooks";
import logo from "screens/common/static/logos/charli-logo-full.svg";
import { GoogleSignInButton } from "./GoogleSignInButton";
import { FormErrorLabel } from "screens/panels/settings/tabs/subscription/SubscribeForm";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (fields: any) => void;
}

export const RegisterForm = (props: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    firstName: string;
    lastName: string;
    username: string;
    organization: string;
    factset: boolean;
    role: string;
    password: string;
    marketingOptIn: boolean;
  }>();
  const primaryTextColor = useColorModeValue("gray.500", "gray.400");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const trialBannerStyle = buttonStyles["subscribe"];
  const commonButtonProps = useButtonProps("sm", "cta");

  return (
    <Stack justifyContent={"space-between"} width={"100%"} height="100vh" alignSelf="center">
      <TabTitle title="Charli > Register" />
      <Box>
        <Box width="100%" height="3rem" {...trialBannerStyle}>
          <Flex width="100%" height="100%" alignItems="center" justifyContent="center">
            <Text textAlign="center" fontWeight={"semibold"} fontSize="xl">
              14 Day Free Trial
            </Text>
          </Flex>
        </Box>
        <Center py="4rem">
          <Image src={logo} alt="Charli logo" width="12rem" cursor="pointer" />
        </Center>
        <Center>
          <Stack justifyContent={"space-between"} spacing="2rem" px="2rem" maxWidth={["90%", "40rem", "45rem"]}>
            <form onSubmit={handleSubmit(props.onSubmit)} noValidate>
              <Stack spacing="1.5rem">
                <Stack direction="row" spacing="1rem">
                  <FormControl isInvalid={!!errors.firstName}>
                    <FormLabel color={primaryTextColor} fontWeight="semibold" fontSize="sm" htmlFor="register-firstName">
                      First Name
                    </FormLabel>
                    <Input
                      size="sm"
                      {...register("firstName", { required: true })}
                      id="register-firstName"
                      type="text"
                      placeholder="John"
                      borderColor={errors.firstName ? "red.500" : "gray.200"}
                      fontSize="sm"
                      boxShadow="none"
                    />
                    {errors.firstName && <FormErrorLabel error="required" />}
                  </FormControl>
                  <FormControl isInvalid={!!errors.lastName}>
                    <FormLabel color={primaryTextColor} fontWeight="semibold" fontSize="sm" htmlFor="register-lastName">
                      Last Name
                    </FormLabel>
                    <Input
                      size="sm"
                      {...register("lastName", { required: true })}
                      id="register-lastName"
                      type="text"
                      placeholder="Smith"
                      borderColor={errors.lastName ? "red.500" : "gray.200"}
                      fontSize="sm"
                      boxShadow="none"
                    />
                    {errors.lastName && <FormErrorLabel error="required" />}
                  </FormControl>
                </Stack>
                <FormControl isInvalid={!!errors.username}>
                  <FormLabel color={primaryTextColor} fontWeight="semibold" fontSize="sm" htmlFor="register-username">
                    Email
                  </FormLabel>
                  <Input
                    size="sm"
                    {...register("username", {
                      required: true,
                      validate: (value) => validateEmail(value) || "Email provided is not valid.",
                    })}
                    id="register-username"
                    type="email"
                    placeholder="example@domain.com"
                    borderColor={errors.username ? "red.500" : "gray.200"}
                    fontSize="sm"
                    boxShadow="none"
                  />
                  {errors.username?.type === "required" && <FormErrorLabel error="required" />}
                  {errors.username && errors.username?.type !== "required" && errors.username.message && (
                    <FormErrorLabel error={errors.username.message} />
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.password}>
                  <FormLabel color={primaryTextColor} fontWeight="semibold" fontSize="sm" htmlFor="register-password">
                    Password
                  </FormLabel>
                  <Input
                    size="sm"
                    id="register-password"
                    type="password"
                    placeholder="************"
                    {...register("password", { required: true, minLength: 12, maxLength: 64 })}
                    borderColor={errors.password ? "red.500" : "gray.200"}
                    fontSize="sm"
                    boxShadow="none"
                    autoComplete={"new-password"}
                  />
                  <FormHelperText textAlign={"end"} color="gray.500" fontSize={"xs"} mt="2px">
                    password must be at least 12 characters long
                  </FormHelperText>
                  {errors.password?.type === "required" && <FormErrorLabel error="required" />}
                  {errors.password?.type === "minLength" && <FormErrorLabel error="Password must be at least 12 characters long" />}
                  {errors.password?.type === "maxLength" && <FormErrorLabel error="Password must be less than 65 characters long" />}
                </FormControl>
                <Center>
                  <Button {...commonButtonProps} id="login-submit" mt="2rem!important" type="submit" width="12rem">
                    Sign up to access Charli
                  </Button>
                </Center>
              </Stack>
            </form>
            <Stack pt="3rem" spacing={"2rem"}>
              <Box borderTop="1px" borderRadius="0" borderColor="secondary.lightGray" color={primaryTextColor}>
                <Center>
                  <Text position="relative" w="3rem" textAlign="center" mt="-.8rem" fontSize="sm">
                    or
                  </Text>
                </Center>
              </Box>
              <GoogleSignInButton variant={"signup"} />
            </Stack>
          </Stack>
        </Center>
      </Box>

      <Stack spacing="3rem">
        <Text textAlign="center" fontSize="xs" fontWeight="light" color="gray.400">
          Already have an account?{" "}
          <Link href="/" fontWeight="bold" color="gray.400">
            Log in
          </Link>
        </Text>
        <Stack pb="1.7rem">
          <Text textAlign="center" fontSize="11px" fontWeight="light" textColor="gray.500">
            By signing up you agree to Charli's{" "}
            <Link href="https://charli.ai/terms-of-service/" isExternal textDecoration={"underline"}>
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link href="https://charli.ai/privacy-policy/" isExternal textDecoration={"underline"}>
              Privacy Policy
            </Link>
          </Text>
          <Box textAlign="center">
            <CopyrightNotice />
          </Box>
        </Stack>
      </Stack>
      {!isMobile && (
        <HStack position="absolute" bottom="2rem" right="2rem" align={"center"} spacing="1rem">
          <Image src={soc2logo} height="3.2rem" />
          <Image src={verifiedShield} height="2.9rem" />
        </HStack>
      )}
    </Stack>
  );
};
