import React, { useCallback, useMemo } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import type { DropResult } from "@hello-pangea/dnd";
import { useFieldArray, Controller, useWatch, useFormContext } from "react-hook-form";
import {
  Stack,
  Text,
  FormControl,
  FormLabel,
  Box,
  Button,
  useColorModeValue,
  Checkbox,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Badge,
  Flex,
} from "@chakra-ui/react";
import { RequirementsFieldArray } from "./RequirementsFieldArray";
import { useButtonProps } from "hooks";
import type { FormValues } from "./ConfiguredWorkflowUpsertModal";
import { IncludedEntitiesFieldArray } from "./IncludedEntitiesFieldArray";
import { EntitiesToRenameFieldArray } from "./EntitiesToRenameFieldArray";
import { EntitiesToInjectFieldArray } from "./EntitiesToInjectFieldArray";
import { CheckpointUserIntentField } from "./CheckpointUserIntentField";
import { CheckpointIntentContextProvider } from "../context/CheckpointIntentContext";
import { EntityToSplit } from "./EntityToSplit";
import { CheckpointType } from "./CheckpointType";

export const CheckpointsFieldArray = () => {
  const { control } = useFormContext<FormValues>();
  const bgColor = useColorModeValue("white!important", "gray.700!important");
  const commonButtonProps = useButtonProps("sm", "secondary");
  const { fields, prepend, remove, move } = useFieldArray({
    control,
    name: "config.checkpoints",
    keyName: "checkpointId",
  });

  const checkpoints = useWatch({
    control,
    name: "config.checkpoints",
  });

  const controlledCheckpoints = useMemo(
    () =>
      fields.map((field, index) => {
        return {
          ...field,
          ...checkpoints[index],
        };
      }),
    [checkpoints, fields]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      if (source.index === destination.index && source.droppableId === destination.droppableId) {
        return;
      }

      move(source.index, destination.index);
    },
    [move]
  );

  const grid = 8;
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    backgroundColor: isDragging ? "#F3F2F8" : "white",
    boxShadow: isDragging ? "0 0 10px rgba(0,0,0,0.5)" : "none",

    ...draggableStyle,
  });

  const renderCheckpoint = useCallback(
    (index: number) => {
      const checkpoint = controlledCheckpoints[index];

      if (checkpoint?.type === "execute_intent") {
        return (
          <>
            <Stack mb="1rem" direction="row" spacing={2} alignItems="flex-end">
              <CheckpointUserIntentField index={index} />
            </Stack>
            <Stack direction="row">
              <Controller
                render={({ field }) => (
                  <Checkbox size={"sm"} isChecked={field.value} name={field.name} onChange={(e) => field.onChange(e.target.checked)}>
                    Confirm before running
                  </Checkbox>
                )}
                name={`config.checkpoints.${index}.needsConfirmation`}
                control={control}
              />
            </Stack>
            <Stack direction="row">
              <Controller
                render={({ field }) => (
                  <Checkbox size={"sm"} isChecked={field.value} name={field.name} onChange={(e) => field.onChange(e.target.checked)}>
                    Run in separate child workflow
                  </Checkbox>
                )}
                name={`config.checkpoints.${index}.runInNewChildWorkflow`}
                control={control}
              />
            </Stack>
            {checkpoint.runInNewChildWorkflow && (
              <>
                <Box mt={"1rem"} mb="1rem" borderWidth="1px" borderRadius="lg">
                  <Tabs isFitted>
                    <TabList>
                      <Tab>
                        <Flex>
                          <Text>Entities to Restrict to Child Workflow</Text>
                          {checkpoint.onlyIncludeTheseEntities?.length ? (
                            <Badge ml={"0.5rem"}>{checkpoint.onlyIncludeTheseEntities.length}</Badge>
                          ) : undefined}
                        </Flex>
                      </Tab>
                      <Tab>
                        <Flex>
                          <Text>Entities to Rename</Text>
                          {checkpoint.entitiesToRename?.length ? (
                            <Badge ml={"0.5rem"}>{checkpoint.entitiesToRename.length}</Badge>
                          ) : undefined}
                        </Flex>
                      </Tab>
                      <Tab>
                        <Flex>
                          <Text>Entities to Inject</Text>
                          {checkpoint?.entitiesToInject?.length ? (
                            <Badge ml={"0.5rem"}>{checkpoint?.entitiesToInject?.length}</Badge>
                          ) : undefined}
                        </Flex>
                      </Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel>
                        <IncludedEntitiesFieldArray checkpointIndex={index} />
                      </TabPanel>
                      <TabPanel>
                        <EntitiesToRenameFieldArray checkpointIndex={index} />
                      </TabPanel>
                      <TabPanel>
                        <EntitiesToInjectFieldArray checkpointIndex={index} />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
                <EntityToSplit checkpointIndex={index} />
              </>
            )}
            <RequirementsFieldArray checkpointIndex={index} />
          </>
        );
      } else {
        return (
          <FormControl>
            <FormLabel fontSize="sm">Entities to Inject</FormLabel>
            <EntitiesToInjectFieldArray checkpointIndex={index} />
          </FormControl>
        );
      }
    },
    [controlledCheckpoints, control]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box backgroundColor={bgColor}>
        <Stack direction="row" justifyContent="space-between" mb="1rem">
          <Text fontSize="sm" mr="1rem">
            Checkpoints
          </Text>
          <Button
            {...commonButtonProps}
            onClick={() =>
              prepend({
                intent: "",
                requirements: [],
                runInNewChildWorkflow: false,
                needsConfirmation: false,
                showEntityToSplit: false,
                entityToSplitName: "",
                entityToSplitRenameTo: "",
                entityToSplitShouldUnwrap: false,
                entitiesToInject: [],
                entitiesToRename: [],
                type: "execute_intent",
              })
            }>
            Add Checkpoint
          </Button>
        </Stack>
        <CheckpointIntentContextProvider>
          <Droppable droppableId="checkpoints">
            {(droppableProvided, snapshot) => (
              <Stack {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} spacing={4}>
                {fields.map((field, index) => (
                  <Draggable key={field.checkpointId} draggableId={field.checkpointId || String(index)} index={index}>
                    {(draggableProvided, snapshot) => (
                      <Box
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, draggableProvided.draggableProps.style)}
                        borderWidth="2px"
                        borderRadius="md"
                        padding="1rem"
                        left={"auto !important"}>
                        <CheckpointType control={control} checkpointIndex={index} onRemove={() => remove(index)} />

                        {renderCheckpoint(index)}
                      </Box>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </Stack>
            )}
          </Droppable>
        </CheckpointIntentContextProvider>
      </Box>
    </DragDropContext>
  );
};
