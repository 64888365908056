import { Box, Center, Flex, HStack, Image, Stack, Text, Tooltip } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";
import React from "react";
import SlideImage1 from "screens/common/static/images/SlideImage1.png";
import SlideImage2 from "screens/common/static/images/SlideImage2.png";
import SlideImage3 from "screens/common/static/images/SlideImage3.png";
import SlideImage4 from "screens/common/static/images/SlideImage4.png";

export const LoginWrapperSlides: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = ({ children }) => {
  const slides = [
    {
      img: SlideImage1,
      label: "Equity Reports in Minutes",
      description: "Automation that works while you commute, take a business meeting, or discuss the market with clients.",
    },
    {
      img: SlideImage2,
      label: "Rapid Access to Trusted Data",
      description: "Partnered with FactSet, TMX and Nasdaq to deliver comprehensive analysis of quantitative and qualitative data.",
    },
    {
      img: SlideImage3,
      label: "Secure, Private and Compliant AI Research and Analysis",
      description:
        "Purpose built for the financial services industry to ensure your data and interactions are secure and private, and can be fully audited.",
    },
    {
      img: SlideImage4,
      label: "Fact-based Interactive Q&A",
      description:
        "Not a prompt engineer?! That's ok, ask Charli questions and experience the power and capabilities of an interactive question and answer that is concentrated on your portfolio company.",
    },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesCount = slides.length;
  const carouselStyle = {
    transition: "all .5s",
    ml: `-${currentSlide * 100}%`,
  };
  const SLIDES_INTERVAL_TIME = 7000;
  const ANIMATION_DIRECTION = "right";

  useEffect(() => {
    const prevSlide = () => {
      setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
    };

    const nextSlide = () => {
      setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
    };

    const automatedSlide = setInterval(() => {
      ANIMATION_DIRECTION.toLowerCase() === "left" ? prevSlide() : nextSlide();
    }, SLIDES_INTERVAL_TIME);
    return () => clearInterval(automatedSlide);
  }, [slidesCount]);

  const setSlide = (slide) => {
    setCurrentSlide(slide);
  };

  return (
    // src/screens/login/components/LoginWrapperSlides.tsx

    <Flex w="full" bg="transparent" alignItems="center" justifyContent="center" flexDirection="column">
      <Flex w="full" overflow="hidden">
        <Flex pos="relative" h="25rem" w="full" {...carouselStyle}>
          {slides.map((slide, sid) => (
            <Box key={`slide-${sid}`} boxSize="full" flex="none">
              <Stack height="100%" justifyContent={"space-between"} pos="absolute" textAlign="left" w="full" color="white" spacing="1.5rem">
                <Stack spacing="1rem">
                  <Text fontSize="xl" textAlign={"center"} fontWeight={"semibold"}>
                    {slide.label}
                  </Text>
                  <Text fontSize="lg" textAlign={"justify"}>
                    {slide.description}
                  </Text>
                </Stack>
                <Center width="100%">
                  <Tooltip label="Click to learn more about AI Due Diligence" aria-label="Learn more about AI Due Diligence">
                    <Image
                      src={slide.img}
                      width="20rem"
                      onClick={() => window.open("https://charli.ai/ai-due-dilligence/", "_blank")}
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </Center>
              </Stack>
            </Box>
          ))}
        </Flex>
      </Flex>
      <HStack justify="center" w="full" pt="2rem">
        {Array.from({
          length: slidesCount,
        }).map((_, slide) => (
          <Box
            key={`dots-${slide}`}
            cursor="pointer"
            boxSize={["7px", null, "15px"]}
            m="0 2px"
            bg={currentSlide === slide ? "whiteAlpha.800" : "whiteAlpha.500"}
            rounded="50%"
            display="inline-block"
            transition="background-color 0.6s ease"
            _hover={{
              bg: "whiteAlpha.800",
            }}
            onClick={() => setSlide(slide)}
          />
        ))}
      </HStack>
    </Flex>
  );
};
