import React from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { CommonModal } from "./CommonModal";
import { NewPortfolioModalBody, ModalHeader } from "./NewPortfolioModal";
import { useWizard, Wizard } from "react-use-wizard";
import { OnboardingNewProjectStep } from "./OnboardingNewProjectStep";
import { OnboardingWelcomeStep } from "./OnboardingWelcomeStep";
import { Stack } from "@chakra-ui/react";
import { OnboardingLearnMoreStep } from "./OnboardingLearnMoreStep";

export const ONBOARDING_MODEL_HEIGHT = "35.5rem";

const NewPortfolioStep = () => {
  const { nextStep } = useWizard();

  return (
    <Stack height={ONBOARDING_MODEL_HEIGHT}>
      <ModalHeader modalColor="#4799d4" modalFontColor="white" modalTitle="STEP 1: Add a New Portfolio" />
      <NewPortfolioModalBody onClickStep={nextStep} modalColor="#4799d4" modalFontColor="white" />
    </Stack>
  );
};

export const OnboardingModal = () => {
  const { isOnboardingModalOpen, onOnboardingModalClose } = useConversationContext();

  return (
    <CommonModal
      allowDismiss={false}
      borderColor="#4799d4"
      isOpen={isOnboardingModalOpen}
      onClose={onOnboardingModalClose}
      modalBody={
        <Wizard>
          <OnboardingWelcomeStep />
          <NewPortfolioStep />
          <OnboardingNewProjectStep />
          <OnboardingLearnMoreStep />
        </Wizard>
      }
    />
  );
};
