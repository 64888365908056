import React from "react";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";

import { useButtonProps } from "hooks";
import { useIsEntitlementUpdating } from "hooks/useEntitlementConfigs";

interface IProps {
  isOpen: boolean;
  onDelete: () => void;
  onClose: () => void;
}

export const ConfirmDeleteModal = (props: IProps) => {
  const { isOpen, onClose, onDelete } = props;
  const commonButtonProps = useButtonProps("sm", "primary");
  const isLoading = useIsEntitlementUpdating();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Are you sure you want to delete this plan?</ModalBody>

        <ModalFooter>
          <Button onClick={onDelete} {...commonButtonProps} disabled={isLoading} isLoading={isLoading}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
