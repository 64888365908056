import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import { WorkflowTaskStatus } from "types/workflows/workflow";
import { useWorkflowKey } from "./useWorkflows";

export function useWorkflowProgress(workflowId: string) {
  const [percent, setPercent] = useState<number>(0);
  const [completed, setCompleted] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const progress = useSelector((state: RootState) => state.checkpoint.progressByWorkflow[workflowId]);

  useEffect(() => {
    if (!progress || !progress.completedStepCount || !progress.estimatedTotalStepCount) {
      return;
    }

    const { completedStepCount: completedSteps, estimatedTotalStepCount: totalSteps } = progress;

    setCompleted(completedSteps);
    setTotal(totalSteps);
    setPercent(Math.round((completedSteps / totalSteps) * 100));
  }, [progress]);

  return {
    percent,
    completed,
    total,
  };
}

export function useCurrentTask(workflowId: string): string | undefined {
  return useSelector((state: RootState) => {
    return state.checkpoint.runningTaskByWorkflow[workflowId];
  });
}

export function useWorkflowChildWorkflowsIds(workflowId: string): string[] {
  return useSelector(
    (state: RootState) => (state.checkpoint.childWorkflowsIdsByWorkflow && state.checkpoint.childWorkflowsIdsByWorkflow[workflowId]) || [],
    shallowEqual
  );
}

export function useChildWorkflowsProgress(childWorkflowId: string):
  | {
      id: string;
      intent: string;
      estimatedTotalStepCount: number;
      completedStepCount: number;
      task: string;
    }
  | undefined {
  return useSelector((state: RootState) => {
    const progress = state.checkpoint.progressByChildWorkflow[childWorkflowId];
    const status = state.checkpoint.statusByChildWorkflow[childWorkflowId];
    const tasks = (state.checkpoint.tasksIdsByChildWorkflow[childWorkflowId] || []).flatMap((taskId) =>
      state.checkpoint.tasksById[taskId] ? [state.checkpoint.tasksById[taskId]] : []
    );

    const maybeTaskInProgress = tasks.find((task) => task.status === WorkflowTaskStatus.inProgress);

    if (progress && (status === WorkflowTaskStatus.inProgress || status === WorkflowTaskStatus.awaitingAyncTask)) {
      return {
        ...progress,
        task: maybeTaskInProgress?.name || "Waiting for next task to start.",
      };
    } else {
      return undefined;
    }
  }, shallowEqual);
}

export function useChildWorkflowActiveTask(childWorkflowId: string): string | undefined {
  return useSelector((state: RootState) => {
    const tasks = (state.checkpoint.tasksIdsByChildWorkflow[childWorkflowId] || []).flatMap((taskId) =>
      state.checkpoint.tasksById[taskId] ? [state.checkpoint.tasksById[taskId]] : []
    );

    const maybeTaskInProgress = tasks.find((task) => task.status === WorkflowTaskStatus.inProgress);

    return maybeTaskInProgress?.name;
  }, shallowEqual);
}

export function useWorkflowInProgressOrClarificationRequested(workflowId: string | undefined): boolean {
  const status = useWorkflowKey(workflowId, "status");

  return status === "in_progress" || status === "clarification_needed" || status === "queued";
}

export function useWorkflowActiveTasksCount(workflowId: string): number {
  return useSelector((state: RootState) => {
    const childWorkflows = state.checkpoint.childWorkflowsIdsByWorkflow[workflowId] || [];
    const tasksIds = childWorkflows.flatMap((childWorkflowId) => state.checkpoint.tasksIdsByChildWorkflow[childWorkflowId] || []);
    const tasksInProgress = tasksIds.filter((taskId) => state.checkpoint.tasksById[taskId]?.status === WorkflowTaskStatus.inProgress);

    return tasksInProgress.length;
  }, shallowEqual);
}
