import React, { useCallback } from "react";
import type { Control } from "react-hook-form";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Icon, IconButton, Input, Stack, useColorModeValue } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import type { FormValues } from "./ConfiguredWorkflowUpsertModal";
import { AiOutlineDelete } from "react-icons/ai";

interface IProps {
  control?: Control<FormValues>;
  checkpointIndex: number;
}

export const IncludedEntitiesFieldArray = (props: IProps) => {
  const { checkpointIndex } = props;
  const { control } = useFormContext<FormValues>();
  const commonButtonProps = useButtonProps("sm", "secondary");
  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");
  const { fields, append, remove } = useFieldArray({
    control,
    name: `config.checkpoints.${checkpointIndex}.onlyIncludeTheseEntities`,
  });

  const {
    formState: { errors },
  } = useFormContext<FormValues>();

  const getValueError = useCallback(
    (index: number) => {
      const maybeOnlyIncludesEntities = errors.config?.checkpoints && errors.config.checkpoints[checkpointIndex]?.onlyIncludeTheseEntities;

      if (!maybeOnlyIncludesEntities) {
        return undefined;
      } else {
        return maybeOnlyIncludesEntities[index]?.value;
      }
    },
    [errors, checkpointIndex]
  );

  return (
    <Box backgroundColor={"transparent"}>
      <Button onClick={() => append({ value: "" })} {...commonButtonProps}>
        Add Entity to Restrict to Child Workflow
      </Button>
      {fields.map((field, index) => (
        <Stack direction="row" spacing="0.5rem" justifyContent="space-between" width="100%" key={field.id} mt="1rem" height="100%">
          <FormControl isInvalid={!!getValueError(index)}>
            <FormLabel fontSize="sm">Entity</FormLabel>
            <Controller
              render={({ field }) => <Input {...field} size="sm" value={field.value} type="text" fontSize="sm" />}
              name={`config.checkpoints.${checkpointIndex}.onlyIncludeTheseEntities.${index}.value`}
              control={control}
            />
            {getValueError(index) && <FormErrorMessage>{getValueError(index)?.message}</FormErrorMessage>}
          </FormControl>
          <Box pt="1.5rem">
            <IconButton
              onClick={() => remove(index)}
              aria-label="Delete"
              backgroundColor="unset"
              icon={<Icon as={AiOutlineDelete} color={buttonColor} boxSize="1rem" _hover={{ color: buttonHoverColor }} />}
              size="sm"
              _hover={{ backgroundColor: "unset" }}
            />
          </Box>
        </Stack>
      ))}
    </Box>
  );
};
