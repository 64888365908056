import type { FunctionComponent } from "react";
import React from "react";
import { SidebarButton } from "./SidebarButton";
import type { IconType } from "react-icons";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useCollections, filterCollectionsByType } from "hooks";
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Stack, AccordionIcon } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface Props {
  text?: string;
  selectedProjectCategory?: string;
  icon?: IconType;
  className?: string;
}

export const SidebarCategory: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  text,
  selectedProjectCategory,
  icon,
  className,
}) => {
  const { onPortfolioModalOpen, setSelectedProjectCategory } = useConversationContext();
  const onClickHandler = () => {
    setSelectedProjectCategory(selectedProjectCategory);
    onPortfolioModalOpen();
  };
  const collections = useCollections();
  const filteredCollections = filterCollectionsByType(collections, selectedProjectCategory || "");
  const navigate = useNavigate();

  return (
    <Accordion allowToggle defaultIndex={0} reduceMotion>
      <AccordionItem border="none">
        <AccordionButton _expanded={{}} paddingInline={0} _hover={{}} py="0">
          <SidebarButton
            text={text}
            icon={icon}
            cssClasses={[className || "ch-menu-category"]}
            buttonOnClick={onClickHandler}
            additionalButtons={<AccordionIcon color="gray.500" />}
          />
        </AccordionButton>
        {filteredCollections.length > 0 && (
          <AccordionPanel pl="2rem" pb="0" pt="1rem" paddingInlineEnd={0}>
            <Stack spacing="1rem">
              {filteredCollections.map((collection) => (
                <SidebarButton
                  onClick={() => navigate(`/portfolios/${collection.id}`)}
                  key={collection.id}
                  text={collection.name}
                  screen={`/portfolios/${collection.id}`}
                  cssClasses={["ch-menu-category"]}
                  showProjectActionMenu
                  menuProjectId={collection.id}
                />
              ))}
            </Stack>
          </AccordionPanel>
        )}
      </AccordionItem>
    </Accordion>
  );
};
