import { Button, useColorModeValue, useToast } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useContext } from "react";
import React from "react";
import { useButtonProps, useConversation } from "hooks";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import type { WorkflowTaskStatus } from "types/workflows/workflow";
import { ToastMessageContent } from "screens/common/components";
import { ConversationContext } from "screens/thread/ConversationContext";

interface Props {
  conversationId: string;
  state?: WorkflowTaskStatus;
  dismissed?: boolean;
  hideActionButtons?: boolean;
  workflowId?: string;
  setIsDismissing?: (isDismissing: boolean) => void;
}

export const ActivityActionButtons: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
  const dispatch = useDispatch();
  const secondaryButtonProps = useButtonProps("xs", "secondary");
  const affirmColor = useColorModeValue("green.50", "gray.700");
  const denyColor = useColorModeValue("red.50", "gray.700");
  const { setConversationId, onConversationOpen } = useContext(ConversationContext);
  const toast = useToast();
  const { inlineActions } = useConversation(props.conversationId);

  // inline actions will have an intent, message, or both
  const processInlineAction = (intent?: string) => {
    if (!intent) return;
    props.setIsDismissing && props.setIsDismissing(true);
    setTimeout(() => {
      props.setIsDismissing && props.setIsDismissing(false);
    }, 5000);

    dispatch(
      sendMessage({
        conversationId: props.conversationId,
        intent: intent,
        entities: [{ entity: "workflow_id", value: props.workflowId }],
      })
    );
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={`I'll ${intent.slice(1)} this project now. You can open the conversation I started about it by clicking this message`}
          onClick={() => {
            setConversationId(props.conversationId);
            onConversationOpen();
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const processAction = (messageText?: string, intent?: string) => {
    if (!messageText) return;
    props.setIsDismissing && props.setIsDismissing(true);
    setTimeout(() => {
      props.setIsDismissing && props.setIsDismissing(false);
    }, 5000);

    dispatch(
      sendMessage({
        conversationId: props.conversationId,
        message: messageText,
        intent: messageText,
        disableDebugging: true,
        disableTesting: true,
      })
    );
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={`I'll continue with this project as instructed. You can open the conversation I started about it by clicking this message`}
          onClick={() => {
            setConversationId(props.conversationId);
            onConversationOpen();
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  return (
    <>
      {!props.hideActionButtons &&
        inlineActions &&
        inlineActions.map((action, i) => {
          const isActionAffirmative = action.message && (action.message.toLowerCase() === "yes" || action.message.toLowerCase() === "ok");
          if (action.label && (action.intent || action.message)) {
            return (
              <Button
                {...secondaryButtonProps}
                backgroundColor={isActionAffirmative ? affirmColor : denyColor}
                aria-label={action.label}
                key={action.label}
                size="xs"
                onClick={(event) => {
                  processAction(action.message, action.intent);
                  event.stopPropagation();
                }}>
                {action.label}
              </Button>
            );
          } else {
            return <React.Fragment key={i} />;
          }
        })}
      {(props.state === "error" || props.state === "failed") && props.dismissed !== true && (
        <Button
          {...secondaryButtonProps}
          id={`workflow-${props.workflowId}`}
          className={`workflow-${props.workflowId} ch-activity-tile-dismiss-button`}
          key={`${props.workflowId}-dismiss`}
          position="relative"
          onClick={(event) => {
            processInlineAction("/dismiss");
            event.stopPropagation();
          }}>
          Dismiss
        </Button>
      )}
      {props.state === "clarification_needed" && (
        <Button
          {...secondaryButtonProps}
          backgroundColor={affirmColor}
          id={`${props.workflowId}-reply`}
          className={`${props.workflowId} ch-activity-tile-reply-button`}
          key={`${props.workflowId}-reply`}
          position="relative"
          onClick={(event) => {
            setConversationId(props.conversationId);
            onConversationOpen();
            event.stopPropagation();
          }}>
          Reply
        </Button>
      )}
      {(props.state === "in_progress" || props.state === "clarification_needed") && (
        <Button
          {...secondaryButtonProps}
          id={`${props.workflowId}-cancel`}
          className={`${props.workflowId} ${props.state} ch-activity-tile-cancel-button`}
          key={`${props.workflowId}-cancel`}
          position="relative"
          onClick={(event) => {
            processInlineAction("/cancel");
            event.stopPropagation();
          }}>
          Cancel
        </Button>
      )}
    </>
  );
};
