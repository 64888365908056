import {
  Center,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  Image,
  HStack,
  useBreakpointValue,
  Box,
  useToast,
  ModalCloseButton,
} from "@chakra-ui/react";
import type { PlanName } from "api/subscription/models/PlanName";
import { useButtonProps, useUserProfile } from "hooks";
import React from "react";
// import { useDispatch } from "react-redux";
import { useConversationContext } from "screens/thread/ConversationContext";
import factsetLogo from "screens/common/static/logos/factset_logo.svg";
import nasdaqLogo from "screens/common/static/logos/nasdaq_logo.svg";
import tmxLogo from "screens/common/static/logos/tmx_logo.svg";
import reportImage from "screens/common/static/misc/reports_panel_image.png";
import verifiedShield from "screens/common/static/misc/verified_shield.svg";
import soc2logo from "screens/common/static/logos/soc2_logo.png";
import stockChartImage from "screens/common/static/misc/stock_chart.jpg";
import { SubscribeForm } from "./SubscribeForm";
import { ToastMessageContent } from "screens/common/components";
import { subscribeToPlan, updateCustomerDetails } from "api/subscription";
import { downloadSubscriptionDetails } from "state/subscription/operations";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";

export interface Feature {
  name: string;
  trial: boolean | string;
  business: boolean | string;
}

export const subscriptionFeatures = () => {
  return [
    {
      name: "Supercharge analysis with FactSet trusted data including street accounts, fundamentals, M&A, transcripts and so much more",
      trial: true,
      business: true,
    },
    { name: "Automatically fetch, read, and analyze complex EDGAR and SEDAR global filing information", trial: true, business: true },
    { name: "Augment research with sentiment analysis from verified news and internet content", trial: true, business: true },
    {
      name: "Full Autopilot analysis from ticker to report with a comprehensive editable report delivered right to your desk",
      trial: true,
      business: true,
    },
    { name: "Number of Analysis per Month (per ticker)", trial: "3", business: "20" },
    { name: "Number of Private Q&A questions per Month", trial: "15", business: "200" },
    { name: "Data Archiving and Record Keeping", trial: "30 Days", business: "180 Days" },
  ];
};

export const SubscriptionUpgradeConfirm = ({ upgradePlan = "business" }: { upgradePlan: PlanName }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { onSubscriptionModalClose } = useConversationContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const buttonStyle = useButtonProps("lg", "subscribe");
  const { id: userId } = useUserProfile();

  const onSubscribeFormSubmit = async (fields: {
    firstName: string;
    lastName: string;
    organization: string;
    city: string;
    address: string;
    address2: string;
    country: string;
    state: string;
    postcode: string;
    acceptTerms: boolean;
    promotionCode?: string;
  }) => {
    const { firstName, lastName, address, address2, country, state, postcode, city, promotionCode } = fields;

    await updateCustomerDetails(String(userId), {
      firstName,
      lastName,
      address,
      address2,
      country,
      state,
      city,
      zip: postcode,
    });

    const promoCode = isEmpty(promotionCode) ? undefined : promotionCode;
    await subscribeToPlan(String(userId), "business", promoCode);
    dispatch(downloadSubscriptionDetails());

    onSubscriptionModalClose();

    toast.closeAll();
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={"Thank you for confirming the upgrade, I will send you a follow-up email shortly."}
          onClick={() => {
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  return (
    <Stack direction={isMobile ? "column" : "row"} height={"47.5rem"}>
      <ModalCloseButton />
      {!isMobile && (
        <Stack
          width="30rem"
          overflowY="auto"
          backgroundImage={`url(${stockChartImage})`}
          backgroundSize="cover"
          backgroundPosition="center">
          <Box opacity={0.8} height="100%" bgColor="#4799d4" width="100%" />
          <Stack
            width="19rem"
            position={"absolute"}
            height={"100%"}
            color="white"
            borderLeftRadius={"md"}
            p="2rem"
            justifyContent={"space-between"}>
            <Stack spacing="3rem" pt="1rem">
              <Text fontSize="1.5rem" lineHeight={"1.7rem"} fontWeight="bold" width="100%" textAlign={"center"}>
                The Automated Analyst
              </Text>
              <Center>
                <Stack>
                  <Text fontWeight={"semibold"}>$899 USD per month*</Text>
                  <UnorderedList spacing={"1rem"}>
                    <ListItem>Equivalent to $45 per report</ListItem>
                    <ListItem>Annual Contract Required</ListItem>
                    <ListItem>Invoiced Monthly</ListItem>
                  </UnorderedList>
                </Stack>
              </Center>
              <Center>
                <Stack spacing="1rem" {...buttonStyle} bgColor="#ffda87" color="gray.700" p="1rem" textAlign={"center"} width="12rem">
                  <Text fontWeight={"semibold"}>Limited Time Offer</Text>
                  <Text fontSize={"sm"}>Exclusive offer for Business customers</Text>
                </Stack>
              </Center>
            </Stack>
            <Center>
              <HStack align={"center"} spacing="1rem">
                <Image src={factsetLogo} height="1rem" />
                <Image src={nasdaqLogo} height="1.5rem" />
                <Image src={tmxLogo} height="2rem" />
              </HStack>
            </Center>
          </Stack>
        </Stack>
      )}
      <Stack justifyContent={"space-between"} height="100%" spacing="0" p="2rem" width="100%">
        <HStack align={"center"} spacing="1rem" width="100%" height="5rem">
          <Text color="gray.800" fontWeight={"semibold"} fontSize="1.3rem" lineHeight={"1.5rem"} width="100%">
            Billing Details
          </Text>
          <Image src={reportImage} height="5rem" />
        </HStack>
        <Box height="100%">
          <SubscribeForm onSubmit={onSubscribeFormSubmit} />
        </Box>
        {!isMobile && (
          <HStack position="absolute" bottom="2rem" right="2rem" align={"center"} spacing="1rem">
            <Image src={soc2logo} height="2.7rem" />
            <Image src={verifiedShield} height="2.5rem" />
          </HStack>
        )}
      </Stack>
    </Stack>
  );
};
