import React, { useContext, useMemo } from "react";
import { Icon, Tooltip, IconButton } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import { VscClearAll } from "react-icons/vsc";
import { CollectionsFilterContext } from "screens/landing/tabs";

export const CollectionsFilterClearFilterButton = () => {
  const { searchText, setSearchText } = useContext(CollectionsFilterContext);
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const primaryButtonProps = useButtonProps("sm", "primary");

  const isContentFiltered = useMemo(() => {
    return searchText !== "";
  }, [searchText]);

  return (
    <Tooltip label={isContentFiltered ? "Clear Filter" : ""}>
      <IconButton
        isDisabled={searchText === ""}
        {...(searchText !== "" ? { ...primaryButtonProps } : { ...secondaryButtonProps })}
        className="ch-clear-project-filter-btn"
        onClick={() => {
          setSearchText("");
        }}
        aria-label="Clear Filters"
        icon={<Icon as={VscClearAll} fontSize=".9rem" />}></IconButton>
    </Tooltip>
  );
};
