import { UTM_PARAMETERS } from "api/analytics";
import { useUserPreference } from "hooks";
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { loginWithRefreshToken } from "state/session/operations";
import type { RootState } from "../state/rootReducer";

const LOGIN_REDIRECT = "/login";
const VERIFY_REDIRECT = "/verify";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const { pathname: path, search: queryString } = useLocation();

  const defaultHomePage = useUserPreference("ui_home_page") as string | undefined;

  const fallbackTo = useMemo(() => {
    return defaultHomePage ? (defaultHomePage === "dashboard" ? "home" : defaultHomePage) : "home";
  }, [defaultHomePage]);

  const { isAuthorized, isLoggedIn, isLoading, token, user } = useSelector((state: RootState) => state.session, shallowEqual);

  useEffect(() => {
    if (!isLoading && !isLoggedIn && isAuthorized && token) {
      dispatch(loginWithRefreshToken(token));
    }
  }, [isLoading, isLoggedIn, isAuthorized, token, dispatch]);

  if (isAuthorized && user?.isEmailVerified !== true && path !== VERIFY_REDIRECT && path !== "/logout") {
    return <Navigate to={VERIFY_REDIRECT} />;
  } else if (isAuthorized) {
    if (path === "/") {
      const navigateToUrl = `${fallbackTo}${queryString}`;
      return <Navigate to={navigateToUrl} />;
    } else {
      return children;
    }
  } else {
    const incomingSearchParams = new URLSearchParams(queryString);
    const utmSearchParams = new URLSearchParams();

    UTM_PARAMETERS.forEach((utmParam) => {
      const maybeUtmValue = incomingSearchParams.get(utmParam);
      if (maybeUtmValue) {
        utmSearchParams.append(utmParam, maybeUtmValue);
        incomingSearchParams.delete(utmParam);
      }
    });

    const redirectQueryString = formatQueryString(utmSearchParams);
    const postLoginQueryString = formatQueryString(incomingSearchParams);

    const loginRedirect = (() => {
      const isNewUser = incomingSearchParams.get("is_new_user");

      if (isNewUser === "true") {
        return `/register`;
      } else {
        return LOGIN_REDIRECT;
      }
    })();

    return <Navigate to={loginRedirect + (redirectQueryString ?? "")} state={{ path, queryString: postLoginQueryString }} />;
  }
};

function formatQueryString(searchParams: URLSearchParams): string | undefined {
  const queryString = searchParams.toString();

  if (queryString !== "") {
    return "?" + queryString;
  } else {
    return undefined;
  }
}
