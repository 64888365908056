import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";
import { Profile } from "./tabs/profile";
import { About } from "./tabs/about";
import { Config } from "./tabs/config";
import { ConfigLayoutOptions } from "./tabs/config/ConfigLayoutOptions";
import { SubscriptionInformation } from "./tabs/subscription";
import { useFeatureFlags } from "hooks";

export const Settings = () => {
  const { full_maxio_integration: isSubscriptionEnabled } = useFeatureFlags();

  return (
    <Tabs maxHeight={"calc(100vh - 10rem)"} overflow="hidden">
      <TabList>
        <Tab className="ch-settings-profile-button">Profile</Tab>
        {isSubscriptionEnabled && <Tab className="ch-settings-subscription-button">Subscription</Tab>}
        <Tab className="ch-settings-config-button">General</Tab>
        <Tab className="ch-config-layout-options">Project</Tab>
        <Tab className="ch-settings-about-button">About Charli</Tab>
      </TabList>
      <TabPanels pt="1rem">
        <TabPanel overflow={"auto"} maxHeight={"calc(100vh - 13rem)"}>
          <Profile />
        </TabPanel>
        {isSubscriptionEnabled && (
          <TabPanel overflow={"auto"} maxHeight={"calc(100vh - 13rem)"}>
            <SubscriptionInformation scrollHeight="30rem" />
          </TabPanel>
        )}
        <TabPanel overflow={"auto"} maxHeight={"calc(100vh - 13rem)"}>
          <Config />
        </TabPanel>
        <TabPanel overflow={"auto"} maxHeight={"calc(100vh - 13rem)"}>
          <ConfigLayoutOptions />
        </TabPanel>
        <TabPanel overflow={"auto"} maxHeight={"calc(100vh - 13rem)"}>
          <About />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
