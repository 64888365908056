import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchUserMarketingPreference as fetchUserPreferenceMarketingApi,
  fetchUserPreference as fetchUserPreferenceApi,
  updateUserPreference as updateUserPreferenceApi,
  fetchUserPreferencesByKeys as fetchUserPreferencesByKeysAPI,
  deleteUserPreference as deleteUserPreferenceAPI,
} from "api/preferences";
import type { RootState } from "state/rootReducer";
import type { UserPreferenceKey } from "types/userPreferences";

export const fetchUserPreferencesByKeys = createAsyncThunk("user-preference/download", async (keys: string[], thunkAPI) => {
  const state = thunkAPI.getState() as RootState;

  if (state.session.user?.id) {
    return await fetchUserPreferencesByKeysAPI(state.session.user.id, keys);
  } else {
    console.warn(`Tried to fetch user preference but no user id was available`);

    return false;
  }
});

export const fetchUserPreference = createAsyncThunk(
  "user-preference/fetch",
  async ({ preferenceKey }: { preferenceKey: string }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    if (state.session.user?.id) {
      return await fetchUserPreferenceApi(state.session.user.id, preferenceKey);
    } else {
      console.warn(`Tried to fetch user preference but no user id was available`);

      return false;
    }
  }
);

export const updateUserPreference = createAsyncThunk(
  "user-preference/update",
  async ({ preferenceKey, value }: { preferenceKey: string; value: boolean | string }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    if (state.session.user?.id) {
      await updateUserPreferenceApi(state.session.user.id, preferenceKey, value);

      return value;
    } else {
      console.warn(`Tried to update user preference but no user id was available`);

      return false;
    }
  }
);

export const updateTypedUserPreference = createAsyncThunk(
  "user-preference-typed/update",
  async ({ preferenceKey, value }: { preferenceKey: UserPreferenceKey; value: boolean | string | number }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    if (state.session.user?.id) {
      await updateUserPreferenceApi(state.session.user.id, preferenceKey, value);

      return value;
    } else {
      console.warn(`Tried to update user preference but no user id was available`);

      return false;
    }
  }
);

export const updateIntegrationPreference = createAsyncThunk(
  "user-preference/update",
  async ({ preferenceKey, value }: { preferenceKey: string; value: string }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    if (state.session.user?.id) {
      await updateUserPreferenceApi(state.session.user.id, preferenceKey, value);

      return value;
    } else {
      console.warn(`Tried to update user preference but no user id was available`);

      return false;
    }
  }
);

export const deleteUserPreference = createAsyncThunk(
  "user-preference/delete",
  async ({ preferenceKey }: { preferenceKey: string }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    if (state.session.user?.id) {
      await deleteUserPreferenceAPI(state.session.user.id, preferenceKey);
    } else {
      console.warn(`Tried to delete user preference but no user id was available`);

      return false;
    }
  }
);

export const deleteIntegrationPreference = createAsyncThunk(
  "user-preference/delete",
  async ({ preferenceKey }: { preferenceKey: string }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    if (state.session.user?.id) {
      await deleteUserPreferenceAPI(state.session.user.id, preferenceKey);
    } else {
      console.warn(`Tried to delete user preference but no user id was available`);

      return false;
    }
  }
);

export const fetchUserMarketingPreference = createAsyncThunk("user-preference/marketing", async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  if (state.session.user?.id) {
    return await fetchUserPreferenceMarketingApi();
  } else {
    console.warn(`Tried to update user marketing preference but no user id was available`);

    return false;
  }
});
