import { Stack, Text, Center, Box } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useConfigMap, useGetViewConfig } from "hooks";
import { CollectionAddNewButton } from "../library/CollectionAddNewButton";
import { CharliLogoCircle } from "screens/thread/CharliLogoCircle";
import { AddNewMenu } from "screens/landing/components/AddNewMenu";
import { ProjectLandingAutocompleteInput } from "./projectLandingTileLayouts/components/ProjectLandingAutocompleteInput";
import { ProjectDetailAutocompleteInput } from "./projectLandingTileLayouts/components/ProjectDetailAutocompleteInput";
import { useLocation } from "react-router-dom";

interface Props {
  collectionType?: string;
  collectionId?: string;
  portfolioId?: string;
}

export const ProjectNewInput: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionType,
  collectionId,
  portfolioId,
}) => {
  const configMap = useConfigMap();
  const commandPlaceholder = useGetViewConfig("commandPlaceholder", collectionType, configMap);
  const showQAinputProjectLanding = useGetViewConfig("projectLandingQAInput", collectionType, configMap);
  const showQAinputProjectLandingShowInput = showQAinputProjectLanding?.showInput || false;
  const showQAinputProjectLandingSource = showQAinputProjectLanding?.source || false;
  const showQAinputProjectDetail = useGetViewConfig("projectDetailQAInput", collectionType, configMap)?.showInput || false;
  const { pathname } = useLocation();

  const renderProjectLandingAutocompleteInput = useCallback(
    () => (
      <Box width="100%" maxWidth="35rem">
        <ProjectLandingAutocompleteInput collectionType={collectionType} initialText={commandPlaceholder} portfolioId={portfolioId} />
      </Box>
    ),
    [collectionType, commandPlaceholder, portfolioId]
  );

  const renderDetailAutocompleteInput = useCallback(
    () => (
      <Box width="100%" maxWidth="35rem">
        <ProjectDetailAutocompleteInput
          collectionType={collectionType}
          collectionId={collectionId}
          initialText="Ask me a question about this project"
        />
      </Box>
    ),
    [collectionId, collectionType]
  );

  const renderNewProjectButton = useCallback(
    () => (
      <Stack width="100%" direction="row" spacing="1rem" justifyContent={"space-between"} maxWidth="35rem" pb="1.3rem">
        <Stack direction={"row"} spacing="1rem" pl="1px">
          <CharliLogoCircle className="project-conversation-button" style={{ zIndex: 10 }} />
          <Center>
            <Text fontSize={"md"}>Click on the button to start a project...</Text>
          </Center>
        </Stack>
        <Center>
          {collectionType === "all" ? (
            <Box>
              <AddNewMenu buttonLabel="New Project" className="add-new-menu-home" showProjectsOnly buttonType="secondary" />
            </Box>
          ) : (
            <CollectionAddNewButton collectionType={collectionType || ""} />
          )}
        </Center>
      </Stack>
    ),
    [collectionType]
  );

  const renderInput = useCallback(() => {
    if (pathname.includes("/home")) {
      return renderProjectLandingAutocompleteInput();
    } else if (
      showQAinputProjectLandingShowInput &&
      showQAinputProjectLandingSource &&
      collectionType === "due_diligence" &&
      (!collectionId || pathname.includes("/panel"))
    ) {
      return renderProjectLandingAutocompleteInput();
    } else if (showQAinputProjectDetail && collectionType === "due_diligence" && collectionId) {
      return renderDetailAutocompleteInput();
    } else if (!showQAinputProjectDetail && collectionId) {
      return null;
    } else {
      return renderNewProjectButton();
    }
  }, [
    collectionId,
    collectionType,
    pathname,
    renderDetailAutocompleteInput,
    renderNewProjectButton,
    renderProjectLandingAutocompleteInput,
    showQAinputProjectDetail,
    showQAinputProjectLandingShowInput,
    showQAinputProjectLandingSource,
  ]);

  return <>{renderInput()}</>;
};
