import React from "react";
import { Box, Button, FormControl, FormErrorMessage, FormLabel, useToast } from "@chakra-ui/react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useButtonProps } from "hooks";
import { FormTextField } from "../../shared/FormTextField";

import { downloadSystemPreferences, updateSystemPreference } from "state/systemPreference/operations";
import type { Value } from "api/systemPreferences/models/Value";
import { SelectPreferenceKey } from "../components/SelectPreferenceKey";
import type { Preference } from "api/systemPreferences/models/Preference";

interface IProps {
  preference: Preference | null | undefined;
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
}

export interface FormValues {
  id?: string;
  key: { value: string; label: string } | null;
  value: Value;
}

export const PreferenceUpsertModal = (props: IProps) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const commonButtonProps = useButtonProps("sm", "primary");

  const { preference, isOpen, onClose, isLoading } = props;
  const isUpdate = !!preference;

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    // TODO: form validation with yup
    // validationResolver: PreferencesFormValidation,
    defaultValues: preference
      ? {
          key: preference?.key ? { value: preference.key, label: preference.key } : null,
          value: JSON.stringify(preference?.value),
        }
      : {
          key: null,
          value: '""',
        },
  });

  const onSubmit = async (formValues) => {
    const { key, value } = formValues;
    const response = await dispatch(updateSystemPreference({ key: key.value, value: JSON.parse(value) }));

    if (!response.payload) {
      const errorMessage = (response as { error: { message: string } }).error?.message;

      toast({
        title: "System preference error",
        description: errorMessage,
        status: "error",
        duration: 10000,
        isClosable: true,
      });

      return;
    }

    toast({
      title: "Preference configuration",
      description: "Successful operation",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    dispatch(downloadSystemPreferences());
    onClose();
  };

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`${isUpdate ? "Update" : "Create"} preference`}>
      <Wizard>
        <PanelStep>
          <Box pl=".5rem">
            <form id="upsert-preference-form" onSubmit={handleSubmit(onSubmit)}>
              <FormControl pb="1rem" isDisabled={isUpdate || isLoading} isInvalid={errors["key"] !== undefined}>
                <FormLabel htmlFor="user-intent">Key</FormLabel>
                <Box>
                  <Controller
                    render={({ field }) => (
                      <SelectPreferenceKey
                        isDisabled={isUpdate || isLoading}
                        selectedValue={field.value}
                        onChange={(newVal) => field.onChange(newVal)}
                      />
                    )}
                    name="key"
                    control={control}
                  />
                </Box>
                {errors["key"]?.type === "required" && <FormErrorMessage>This field is required.</FormErrorMessage>}
              </FormControl>
              <FormTextField control={control} errors={errors} keyField="value" label="Value" onUpdate={null} disabled={isLoading} />
            </form>

            <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
              <Button {...commonButtonProps} type="submit" form="upsert-preference-form" isLoading={isLoading}>
                Save preference
              </Button>
            </Box>
          </Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
