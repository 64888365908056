import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { highlightPlugin, Trigger } from "@react-pdf-viewer/highlight";
import { zoomPlugin } from "@react-pdf-viewer/zoom";

import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useContentDetails, useLoadingContents } from "hooks";
import { IntegrationURN } from "types/integration";
import { getAccessToken } from "api/auth";
import { Record, Array as RuntypeArray, Number as RuntypeNumber, String as RuntypeString } from "runtypes";

import type { RenderHighlightsProps } from "@react-pdf-viewer/highlight";

// Import styles
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// Import default layout styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { v4 as uuid } from "uuid";
import { FaHighlighter } from "react-icons/fa";
import { useContextCoordinates } from "hooks/useContextCoordinates";

const Highlights = RuntypeArray(
  Record({
    id: RuntypeString,
    pageIndex: RuntypeNumber,
    top: RuntypeNumber,
    left: RuntypeNumber,
    width: RuntypeNumber,
    height: RuntypeNumber,
  })
);

export const ProjectContentPDF = () => {
  const { answerId, contentId } = useParams();
  const contentDetails = useContentDetails(contentId, { refreshFromNetwork: true });
  const isLoading = useLoadingContents();
  const contextCoordinates = useContextCoordinates(answerId, contentId);

  const notesContainerRef = useRef<HTMLDivElement | null>(null);

  const highlightsList = useMemo(() => {
    if (!contextCoordinates || contextCoordinates.length === 0) {
      return [];
    }

    const maybeHighlights = contextCoordinates.map(({ pageIndex, top, left, width, height }) => {
      return {
        id: uuid(),
        pageIndex,
        top,
        left,
        width,
        height,
      };
    });

    const validate = Highlights.validate(maybeHighlights);

    if (!validate.success) {
      console.error(validate.message);
      return [];
    }

    return validate.value;
  }, [contextCoordinates]);

  const renderHighlights = useCallback(
    (props: RenderHighlightsProps) => (
      <div>
        {highlightsList
          .filter((area) => area.pageIndex === props.pageIndex)
          .map((area, idx) => (
            <div
              key={idx}
              className="highlight-area"
              style={Object.assign(
                {},
                {
                  background: "yellow",
                  opacity: 0.4,
                },
                props.getCssProperties(area, props.rotation)
              )}
            />
          ))}
      </div>
    ),
    [highlightsList]
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  const { jumpToHighlightArea } = highlightPluginInstance;

  const sidebarHighlights = useMemo(() => {
    return (
      <div
        ref={notesContainerRef}
        style={{
          overflow: "auto",
          width: "100%",
        }}>
        {highlightsList.length === 0 && <div style={{ textAlign: "center" }}>There is no highlight</div>}
        {highlightsList.map((note) => {
          return (
            <div
              key={note.id}
              style={{
                borderBottom: "1px solid rgba(0, 0, 0, .3)",
                cursor: "pointer",
                padding: "8px",
              }}
              onClick={() =>
                jumpToHighlightArea({
                  pageIndex: note.pageIndex,
                  top: note.top,
                  left: note.left,
                  height: note.height,
                  width: note.width,
                })
              }>
              <blockquote
                style={{
                  borderLeft: "2px solid rgba(0, 0, 0, 0.2)",
                  fontSize: ".75rem",
                  lineHeight: 1.5,
                  margin: "0 0 8px 0",
                  paddingLeft: "8px",
                  textAlign: "justify",
                }}>
                {"Page " + (note.pageIndex + 1)}
              </blockquote>
            </div>
          );
        })}
      </div>
    );
  }, [jumpToHighlightArea, highlightsList]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => {
      return [
        ...defaultTabs,
        {
          content: sidebarHighlights,
          icon: <FaHighlighter />,
          title: "Highlights",
        },
      ];
    },
  });

  const zoomPluginInstance = zoomPlugin();
  const { zoomTo } = zoomPluginInstance;

  const fileUrl = useMemo(() => {
    if (!contentDetails || contentDetails.type !== "document" || contentDetails.extension !== "pdf") {
      return {
        url: "",
        token: undefined,
      };
    }

    if (contentDetails.integrationUrn === IntegrationURN.charliCloudStorage) {
      return {
        url: contentDetails.urls?.downloadUrl || "",
        token: getAccessToken(),
      };
    } else {
      return {
        url: contentDetails.urls?.url || "",
        token: undefined,
      };
    }
  }, [contentDetails]);

  useEffect(() => {
    if (highlightsList.length > 0) {
      defaultLayoutPluginInstance.activateTab(3);
      const firstHighlight = highlightsList[0];

      jumpToHighlightArea({
        pageIndex: firstHighlight.pageIndex,
        top: firstHighlight.top,
        left: firstHighlight.left,
        height: firstHighlight.height,
        width: firstHighlight.width,
      });
    }
  }, [highlightsList, defaultLayoutPluginInstance, jumpToHighlightArea]);

  useEffect(() => {
    zoomTo(1);
  }, [zoomTo]);

  return (
    <Box height={"100vh"} overflow={"auto"}>
      {isLoading && !contentDetails ? null : fileUrl.url ? (
        <div
          style={{
            height: "100%",
          }}>
          <Viewer
            {...(highlightsList.length > 0 && { initialPage: highlightsList[0].pageIndex })}
            plugins={[defaultLayoutPluginInstance, highlightPluginInstance, zoomPluginInstance]}
            fileUrl={fileUrl.url}
            {...(fileUrl.token && {
              httpHeaders: {
                Authorization: `Bearer ${fileUrl.token}`,
              },
            })}
          />
        </div>
      ) : (
        <Box>PDF not found</Box>
      )}
    </Box>
  );
};
