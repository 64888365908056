import React, { useCallback, useEffect, useState } from "react";
import { Text, Badge, Table, Thead, Tbody, Tr, Th, Td, Center, Stack } from "@chakra-ui/react";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { formatDate } from "screens/common/modal/formatters";
import { useFilterInput } from "hooks/useFilterInput";
import { PanelView } from "screens/panels/components/PanelView";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import type { EventLog } from "api/eventLogs/models/EventLog";

interface IProps {
  id: string;
  fetchLogs: (id: string) => Promise<EventLog[]>;
}

interface IPanelProps extends IProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

const EventLogs = (props: IProps) => {
  const { id, fetchLogs } = props;

  const [isLoadingLogs, setIsLoadingLogs] = useState<boolean>(false);
  const [logs, setLogs] = useState<EventLog[]>([]);
  const { filteredList, renderFilterInputComponent } = useFilterInput<EventLog>(["modifiedByUserIdName", "eventType"], logs);

  useEffect(() => {
    if (!id) {
      return;
    }
    setIsLoadingLogs(true);

    fetchLogs(id)
      .then((response) => setLogs(response))
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoadingLogs(false));
  }, [id, fetchLogs]);

  const formatLog = useCallback((log: EventLog) => {
    const formatCreateEventObject = (tableRecordName: string, eventObject: Record<string, unknown>) => {
      return (
        <Text>
          {" "}
          <Badge>{tableRecordName} </Badge>
          {Object.entries(eventObject).map(([key, value]) => {
            return (
              <Text wordBreak={"break-all"} key={key}>
                {" "}
                <Badge>{key}</Badge> is <Badge>{JSON.stringify(value)}</Badge>
              </Text>
            );
          })}
        </Text>
      );
    };

    const formatUpdateObject = (tableRecordName: string, objectChanges: Record<string, { old: unknown; new: unknown }>) => {
      return (
        <span>
          <Badge>{tableRecordName}</Badge> fields:{" "}
          {Object.entries(objectChanges).map(([key, value]) => {
            return (
              <span key={key}>
                {" "}
                <Badge>{key}</Badge> from <Badge>{JSON.stringify(value.old)}</Badge> to <Badge>{JSON.stringify(value.new)}</Badge>
              </span>
            );
          })}{" "}
        </span>
      );
    };

    return (
      <>
        <Td>
          <Text width="12rem" fontSize={"sm"}>
            {formatDate(new Date(log.modifiedAt))}
          </Text>
        </Td>
        <Td>
          <Badge>{log.eventType}</Badge>
        </Td>
        <Td>
          {log.tableName}
          {log.eventType === "create" && log.eventObject && formatCreateEventObject(log.recordId, log.eventObject)}
          {log.eventType === "update" && log.objectChanges && formatUpdateObject(log.recordId, log.objectChanges)}
          {log.eventType === "delete" && <Badge>{log.recordId}</Badge>}
        </Td>
        <Td>
          <Text width="14rem" fontSize={"sm"}>
            {log.modifiedByUserIdName}
          </Text>
        </Td>
      </>
    );
  }, []);

  if (isLoadingLogs) {
    return (
      <Center height="80vh">
        <TypingIndicator />
      </Center>
    );
  }

  if (logs.length === 0) {
    return <Text>No logs found.</Text>;
  }

  return (
    <Stack>
      {renderFilterInputComponent("Filter logs user or action (create, update or delete)")}
      <Table wordBreak={"break-all"} size="sm">
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Action</Th>
            <Th>Details</Th>
            <Th>Modified By</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredList.map((log, index) => (
            <Tr key={index}>{formatLog(log)}</Tr>
          ))}
        </Tbody>
      </Table>
    </Stack>
  );
};

export const EventLogsPanel = (props: IPanelProps) => {
  const { id, title, isOpen, fetchLogs, onClose } = props;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`${title} logs: ${id}`}>
      <Wizard>
        <PanelStep>{isOpen && <EventLogs fetchLogs={fetchLogs} id={id} />}</PanelStep>
      </Wizard>
    </PanelView>
  );
};
