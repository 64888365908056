import { Stack, Text, Image, Box, Skeleton, Icon } from "@chakra-ui/react";
import type { ExternalLink } from "api/newsData/models/ExternalLink";
import { useSkeletonProps } from "hooks";
import { useNewsLinks } from "hooks/useNewsInformation";
import type { FunctionComponent } from "react";
import React, { useCallback, useMemo } from "react";
import { BsNewspaper } from "react-icons/bs";
import { LinkLogo } from "screens/common/components";

export const NewsTilesSection: FunctionComponent = () => {
  const newsArticles = useNewsLinks({ limit: 9 });
  const skeletonStyle = useSkeletonProps();

  const fallbackImage = useMemo(() => {
    return (
      <Box width="6rem" height="4rem" borderRadius="md" bgColor="gray.200" display="flex" justifyContent="center" alignItems="center">
        <Icon as={BsNewspaper} boxSize="2.5rem" color="gray.400" />
      </Box>
    );
  }, []);

  const NewsTile: FunctionComponent<{ newsArticle: ExternalLink }> = React.memo(({ newsArticle }) => {
    const linkDomain = new URL(newsArticle.link).hostname;
    const handleTileClick = useCallback(() => {
      window.open(newsArticle.link, "_blank");
    }, [newsArticle.link]);

    return (
      <Stack className="ch-news-article" cursor={"pointer"} onClick={handleTileClick} direction="row" spacing="1rem" maxWidth="19rem">
        <Image fit="fill" width="6rem" height="4rem" borderRadius="md" src={newsArticle.thumbnail} fallback={fallbackImage} />
        <Stack width="11.5rem" height={"100%"} justifyContent="space-between">
          <Text noOfLines={2} fontSize={"sm"}>
            {newsArticle.alias}
          </Text>
          <Stack direction="row" spacing="5px" alignItems="left">
            <Box>
              <LinkLogo url={linkDomain} maxHeight={"1rem"} />
            </Box>
            <Text fontSize={"xs"}>{linkDomain}</Text>
          </Stack>
        </Stack>
      </Stack>
    );
  });

  const newsSkeletons = useMemo(() => {
    return Array.from({ length: 9 }, (_, index) => (
      <Stack key={index} direction="row" spacing="1rem" maxWidth="19rem">
        <Skeleton {...skeletonStyle} width="6rem" height="4rem" borderRadius="md" />
        <Stack width="11.5rem" height={"100%"} justifyContent="space-between" pt="5px">
          <Skeleton {...skeletonStyle} height=".8rem" />
          <Skeleton {...skeletonStyle} height=".8rem" />
          <Skeleton {...skeletonStyle} height=".5rem" width="5rem" mt=".5rem" />
        </Stack>
      </Stack>
    ));
  }, [skeletonStyle]);

  const newsTiles = useMemo(
    () => newsArticles.map((newsArticle, index) => <NewsTile key={index} newsArticle={newsArticle} />),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newsArticles]
  );

  return (
    <Stack spacing="1rem" pt="1.7rem">
      {newsArticles && newsArticles.length === 0 ? newsSkeletons : newsTiles}
    </Stack>
  );
};
