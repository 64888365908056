import React, { useContext, useEffect, useMemo } from "react";
import { Wizard } from "react-use-wizard";
import { Notes } from "screens/content/common/notes/Notes";
import { useContentDetails } from "hooks";
import { Box } from "@chakra-ui/react";
import { ConversationContext } from "screens/thread/ConversationContext";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";

export const NotesPanel = () => {
  const { contentId } = useContext(ConversationContext);
  const itemContent = useContentDetails(contentId);

  const getNotes = useMemo(() => {
    if (contentId.length > 0) {
      return itemContent?.notes;
    }
  }, [contentId, itemContent]);

  const { isNotesPanelOpen, onNotesPanelClose, setNotesInputState, setNoteText } = useAddToCharliContext();

  useEffect(() => {
    setNotesInputState(getNotes?.length ? "viewing" : "editing");
    setNoteText(getNotes ? getNotes[0] : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotesPanelOpen]);

  useEffect(() => {
    setNoteText("");
  }, [onNotesPanelClose, setNoteText]);

  return (
    <PanelView isOpen={isNotesPanelOpen} onClose={onNotesPanelClose} panelTitle={`Notes`} className="ch-panel-notes">
      <Wizard>
        <PanelStep>
          <Box>
            <Notes notes={getNotes} isEditEnabled />
          </Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
