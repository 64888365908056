import { Select } from "chakra-react-select";
import React, { useEffect } from "react";
import { useAppDispatch } from "hooks";
import { downloadSystemKeyPreferences } from "state/systemPreference/operations";
import { useSystemKeyPreferences, useSystemPreferences } from "hooks/useSystemPreferences";
import { Box } from "@chakra-ui/react";
import type { SingleValue } from "chakra-react-select";

interface Props {
  selectedValue: { value: string; label: string } | null;
  onChange: (value: SingleValue<{ value: string | undefined; label: string | undefined }>) => void;
  isDisabled?: boolean;
}

export const SelectPreferenceKey = React.forwardRef<HTMLInputElement, Props>(({ isDisabled, selectedValue, onChange }, ref) => {
  const dispatch = useAppDispatch();
  const keys = useSystemKeyPreferences();
  const selectedKeys = useSystemPreferences().map((preference) => preference.key);

  const filteredKeys = (keys ?? [])
    .filter((key) => (isDisabled ? true : !selectedKeys.includes(key)))
    .map((key) => ({ label: key, value: key }));
  useEffect(() => {
    dispatch(downloadSystemKeyPreferences());
  }, [dispatch]);

  return (
    <Box ref={ref}>
      <Select
        id="key"
        className="ch-multi-select"
        useBasicStyles
        value={{ value: selectedValue?.value, label: selectedValue?.label }}
        options={filteredKeys}
        onChange={(newValue) => onChange({ value: newValue?.value, label: newValue?.label })}
      />
    </Box>
  );
});
