import React, { useCallback } from "react";
import { Badge, Box, Heading, SimpleGrid, Stack, Text } from "@chakra-ui/react";

import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useBillingPlan, useBillingPlanEntitlements } from "hooks/useBillingPlans";
import { EntitlementsList, RenderIfHasEntitlements } from "screens/common/components";
import type { Plan } from "types/billingPlans/Plan";

interface IProps {
  code: string;
  isOpen: boolean;
  onClose: () => void;
}

interface IBillingPlanResumeProps {
  billingPlan: Plan;
}

export const BillingPlanResume = (props: IBillingPlanResumeProps) => {
  const { billingPlan } = props;
  const { entitlements, isLoading } = useBillingPlanEntitlements(billingPlan.code);

  const renderUsers = useCallback(() => {
    if (!billingPlan.planUsers) {
      return <React.Fragment />;
    }

    if (billingPlan.planUsers.length === 0) {
      return <Text>No users configured</Text>;
    } else {
      return billingPlan.planUsers.map((planUser) => (
        <Box borderWidth="2px" borderRadius="lg" padding="1rem" key={planUser.id}>
          <Stack>
            <Text>Name: {planUser.userName}</Text>
          </Stack>
          <Stack>
            <Text>Email: {planUser.userEmail || "n/a"}</Text>
          </Stack>
          <Stack>
            <Text fontSize="sm" as="i">
              Created by: Maxio
            </Text>
          </Stack>
        </Box>
      ));
    }
  }, [billingPlan]);

  return (
    <Stack spacing={8} px="1rem">
      <Stack my="0.5rem">
        <Stack direction="row" alignItems="center">
          <Heading fontSize="md">Code:</Heading>
          <Badge>{billingPlan.code}</Badge>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Heading fontSize="md">Name:</Heading>
          <Text fontSize="md">{billingPlan.name}</Text>
        </Stack>
      </Stack>

      <Box>
        <Heading marginBottom={4} fontSize="md">
          Users
        </Heading>
        <SimpleGrid columns={2} spacingX="1.5rem" spacingY="1.5rem">
          {renderUsers()}
        </SimpleGrid>
      </Box>

      <Box>
        <Heading marginBottom={4} fontSize="md">
          Entitlements
        </Heading>
        <RenderIfHasEntitlements entitlement="manage_entitlements">
          <EntitlementsList type="plan" entityId={billingPlan.code} entitlements={entitlements} isLoading={isLoading} />
        </RenderIfHasEntitlements>
      </Box>
    </Stack>
  );
};

export const BillingPlanModal = (props: IProps) => {
  const { code, isOpen, onClose } = props;
  const billingPlan = useBillingPlan(code);

  if (!billingPlan) return <></>;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`${billingPlan.name} plan details`}>
      <Wizard>
        <PanelStep>
          <BillingPlanResume billingPlan={billingPlan} />
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
