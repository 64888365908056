import { Box, Center } from "@chakra-ui/react";
import { useContentWithTagId } from "hooks";
import type { FunctionComponent } from "react";
import React from "react";
import { ContentDetailImage } from "screens/collection/components/ContentDetailImage";
import { ProjectIcon } from "screens/common/components/ProjectIcon";
import { ProjectThumbnailsGrid } from "./ProjectThumbnailGrid";

interface Props {
  projectType: string;
  width?: string;
  metadataIds?: string[];
}

export const ProjectHeaderImage: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  projectType,
  width,
  metadataIds,
}) => {
  const contentIdOfCompanyLogo = useContentWithTagId(metadataIds ?? [], "company_logo");
  const contentIdOfProjectLandingThumbnail = useContentWithTagId(metadataIds ?? [], "project_landing_thumbnail");
  const calculatedWidth = width ? width : "8rem";

  if (contentIdOfProjectLandingThumbnail) {
    return (
      <Box width={calculatedWidth}>
        <ContentDetailImage contentId={contentIdOfProjectLandingThumbnail} maxHeight="2rem" />
      </Box>
    );
  }

  if (contentIdOfCompanyLogo) {
    return (
      <Center width={calculatedWidth}>
        <Box className="ch-project-company-logo" width="100%">
          <ContentDetailImage contentId={contentIdOfCompanyLogo} maxHeight="2rem" />
        </Box>
      </Center>
    );
  }

  if (metadataIds && metadataIds?.length > 0) {
    return (
      <Box width={calculatedWidth}>
        <ProjectThumbnailsGrid metadataIds={metadataIds} />
      </Box>
    );
  } else {
    return (
      <Box className="ch-project-default-icon" width={calculatedWidth}>
        <ProjectIcon projectType={projectType} />
      </Box>
    );
  }
};
