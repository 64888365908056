// dueDiligence.ts
import { v4 as uuid } from "uuid";
import { sendMessage } from "state/websocket/operations";
import type { Dispatch } from "redux";

interface Entity {
  entity: string;
  value: string;
}

type RequestEntities = Entity[];

export function onSubmitDueDiligence(
  companyTickerOrName: string,
  companyStockExchange: string,
  isTicker: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  onConversationOpen?: (conversationId: string) => void,
  additionalEntities: RequestEntities = [],
  collectionType = "due_diligence",
  intent = "create_due_diligence_project",
  portfolioId?: string
) {
  const entities: RequestEntities = [];
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  entities.push({ entity: "collection_type", value: collectionType });
  if (isTicker) {
    entities.push({ entity: "company_ticker", value: companyTickerOrName.trim() });
  } else {
    entities.push({ entity: "company_name", value: companyTickerOrName.trim() });
  }
  if (companyStockExchange && companyStockExchange.length > 0) {
    entities.push({ entity: "company_stock_exchange", value: companyStockExchange });
  }
  if (portfolioId) {
    entities.push({ entity: "portfolio_id", value: portfolioId });
  }
  if (timezone) {
    entities.push({ entity: "client_time_zone", value: timezone });
  }
  entities.push({ entity: "answer_focus", value: "analytical" });
  const newConversationId = uuid();

  dispatch(
    sendMessage({
      conversationId: newConversationId,
      intent: `/${intent}`,
      entities: [...entities, ...additionalEntities],
    })
  );

  onConversationOpen && onConversationOpen(newConversationId);
}
