import type { SuggestedQuestions } from "api/suggestions";
import { useCollectionCompanyStockFromContents, useCollectionKey, useCommandSuggestions } from "hooks";
import { useMemo } from "react";

export const useAvailableCommands = (questionText: string, exitCondition?: boolean) => {
  const { availableIntents } = useCommandSuggestions();
  const availableCommandsMemo = useMemo(() => {
    if (exitCondition) return [];
    if (availableIntents) {
      return availableIntents.map((command) => ({
        question: `/${command}`,
        matchFilter: [`/${command}`],
        focus: `${command.split("_")[0]}`,
      }));
    }
    return [];
  }, [exitCondition, availableIntents]);

  const filterCommandsMemo = useMemo(() => {
    if (!availableCommandsMemo) return [];
    return availableCommandsMemo.filter((item) => item.question.toLowerCase().includes(questionText.toLowerCase()));
  }, [availableCommandsMemo, questionText]);

  return { availableCommandsMemo, filterCommandsMemo };
};

export const useCurrentCollectionQuestions = (sourcesList?: SuggestedQuestions, collectionId?: string) => {
  const collectionQuestions = useCollectionKey(collectionId, "questions");
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const companyStockData = useCollectionCompanyStockFromContents(collectionMetadataIds ?? []);

  const currentCollectionQuestions = useMemo(() => {
    return collectionQuestions?.flatMap((question) => question?.question) || [];
  }, [collectionQuestions]);

  const filterQuestionsByFocusMemo = useMemo(() => {
    if (!sourcesList) return [];
    const suggestedQuestionReplaceCompanyName = sourcesList.map((suggestion) => {
      return {
        ...suggestion,
        question: suggestion.question.replace("{company_name}", companyStockData?.name ?? ""),
      };
    });

    return suggestedQuestionReplaceCompanyName.filter((suggestion) => !currentCollectionQuestions.includes(suggestion.question));
  }, [sourcesList, companyStockData?.name, currentCollectionQuestions]);

  return { currentCollectionQuestions, filterQuestionsByFocusMemo };
};
