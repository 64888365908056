import { Button, Center, ModalCloseButton, Stack, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useButtonProps, useCollectionKey, useMainCollectionWorkflowId, useUserProfile } from "hooks";
import capitalize from "lodash/capitalize";
import React, { createContext } from "react";
import { useDispatch } from "react-redux";
import { ToastMessageContent } from "screens/common/components";
import { CommonModal } from "screens/landing/components/popoverComponent/CommonModal";
import { useConversationContext } from "screens/thread/ConversationContext";
import { sendMessage } from "state/websocket/operations";
import { v4 as uuid } from "uuid";

const defaultThrowError = (): void => {
  throw new Error("Component must be nested inside <PausedWorkflowModalProvider />");
};

const PausedWorkflowModalContext = createContext({
  onOpen: (collectionId: string) => {
    defaultThrowError();
  },
});

export const usePausedWorkflowModal = () => {
  return React.useContext(PausedWorkflowModalContext);
};

export const PausedWorkflowModalProvider = ({ children }: { children: React.ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [collectionId, setCollectionId] = React.useState<string | undefined>();

  const handleOpen = React.useCallback(
    (collectionId: string) => {
      setCollectionId(collectionId);
      onOpen();
    },
    [onOpen]
  );

  const handleClose = React.useCallback(() => {
    setCollectionId(undefined);
    onClose();
  }, [onClose]);

  return (
    <PausedWorkflowModalContext.Provider value={{ onOpen: handleOpen }}>
      {children}
      <PausedWorkflowModalWrapper collectionId={collectionId} isOpen={isOpen} onClose={handleClose} />
    </PausedWorkflowModalContext.Provider>
  );
};

interface PausedWorkflowModal extends PausedWorkflowModalWrapper {
  collectionId: string;
}

interface PausedWorkflowModalWrapper {
  isOpen: boolean;
  onClose: () => void;
  collectionId?: string;
}

const PausedWorkflowModalWrapper = ({ isOpen, onClose, collectionId }: PausedWorkflowModalWrapper) => {
  if (!collectionId) {
    return null;
  } else {
    return <PausedWorkflowModal collectionId={collectionId} isOpen={isOpen} onClose={onClose} />;
  }
};

const PausedWorkflowModal = ({ isOpen, onClose, collectionId }: PausedWorkflowModal) => {
  const conversationId = useCollectionKey(collectionId, "conversationId");
  const maybeWorkflowId = useMainCollectionWorkflowId(collectionId);
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const { onConversationOpen, setConversationId } = useConversationContext();
  const dispatch = useDispatch();
  const toast = useToast();
  const user = useUserProfile();

  const handleRetry = () => {
    if (!conversationId) {
      return;
    }

    dispatch(
      sendMessage({
        conversationId,
        intent: "/retry",
        entities: [],
        disableDebugging: true,
        disableTesting: true,
      })
    );

    setConversationId(conversationId);

    toast({
      render: ({ onClose: onCloseToast }) => (
        <ToastMessageContent
          message={`Rerunning step now. You can open the conversation by clicking this message`}
          onClick={() => {
            onCloseToast();
            onConversationOpen();
          }}
          onClose={onCloseToast}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });

    onClose();
  };

  const handleSupportRequest = () => {
    if (!user.email || !maybeWorkflowId) {
      return;
    }

    const currentConversationId = uuid();

    dispatch(
      sendMessage({
        conversationId: currentConversationId,
        intent: "/publish_prod_alert",
        entities: [
          {
            entity: "message",
            value: `The following user has reported an issue in ${capitalize(
              window.env.environment ?? "local"
            )} that is likely linked to a third party issue: \nUser: ${user.email} \nWorkflow URL: ${window.location.protocol}//${
              window.location.host
            }/admin/workflows/${maybeWorkflowId}`,
          },
        ],
      })
    );

    setConversationId(currentConversationId);

    toast({
      render: ({ onClose: onCloseToast }) => (
        <ToastMessageContent
          message={`Support request sent. You can open the conversation by clicking this message.`}
          onClick={() => {
            onCloseToast();
            onConversationOpen();
          }}
          onClose={onCloseToast}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });

    onClose();
  };

  return (
    <CommonModal
      isOpen={isOpen}
      onClose={onClose}
      modalHeader={
        <Stack
          bgColor={"gray.100"}
          direction={"row"}
          fontSize="md"
          textAlign="center"
          width="100%"
          height={"3rem"}
          justifyContent={"space-between"}
          px="1.5rem"
          py="1.rem">
          <Center>
            <Text fontWeight={"semibold"} fontSize="lg">
              Paused Workflow
            </Text>
          </Center>
          <Center>
            <ModalCloseButton cursor="pointer" />
          </Center>
        </Stack>
      }
      modalBody={
        <Center backgroundColor={"white"}>
          <Text color={"gray.500"} fontStyle="italic" textAlign={"justify"} fontSize="sm" px="2rem" py="2rem">
            I have run into an issue with a third-party service, this can be a result of {"\n"} maintenance on the third-party system or a
            communication issue with the {"\n"} third-party system. You can retry the step once the issue has been cleared {"\n"} and I will
            continue with the AI analysis.
          </Text>
        </Center>
      }
      modalFooter={
        <Stack direction="row" justifyContent={"flex-end"} width="100%" py="2rem" pr="1rem" pl="2rem">
          <Button {...secondaryButtonProps} mr={3} onClick={() => handleSupportRequest()}>
            Support Request
          </Button>
          <Button _hover={{}} color={"white"} bgColor="primary.default" size={"sm"} variant="solid" mr={3} onClick={() => handleRetry()}>
            Retry Step
          </Button>
        </Stack>
      }
    />
  );
};
