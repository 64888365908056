import { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { downloadRecentContents } from "state/content/operations";
import { requestToDownloadContents } from "state/content/utils";
import type { RootState } from "state/rootReducer";
import { NewStockExchangeRecord, StockExchangeRecord } from "types/stock";
import type { ContentDetails } from "types/content/ContentDetails";
import { hasProjectOutput } from "screens/collection/components/utils";

export function useContentDetails(metadataId?: string, options = { refreshFromNetwork: false }) {
  const content = useSelector((state: RootState) => (metadataId ? state.content.contentData[metadataId] : undefined), shallowEqual);
  const hasContent = useSelector((state: RootState) => !!metadataId && !!state.content.contentData[metadataId], shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hasContent && metadataId && options.refreshFromNetwork) {
      requestToDownloadContents({ metadataIds: [metadataId] }, dispatch);
    }
  }, [dispatch, metadataId, hasContent, options.refreshFromNetwork]);

  return content;
}

export function useAllContents() {
  return useSelector((state: RootState) => state.content.contentData, shallowEqual);
}

export function useContents(
  metadataIds: string[] | undefined,
  options: { refreshFromNetwork: boolean; includeTags?: boolean } = { refreshFromNetwork: false, includeTags: false }
) {
  const deDuplicatedMetadataIds = useMemo(() => [...new Set(metadataIds)], [metadataIds]);
  const tags = useMemo(() => ["company_logo", "project_landing_thumbnail", "stock_price", "project_output"], []);
  const content = useSelector(
    (state: RootState) =>
      deDuplicatedMetadataIds
        ? deDuplicatedMetadataIds.flatMap((id) => {
            if (state.content.contentData[id]) {
              return [state.content.contentData[id]];
            } else {
              return [];
            }
          })
        : [],
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (options.refreshFromNetwork && deDuplicatedMetadataIds.length > content.length) {
      requestToDownloadContents({ metadataIds: deDuplicatedMetadataIds, ...(options.includeTags && { tags }) }, dispatch);
    }
  }, [dispatch, deDuplicatedMetadataIds, content.length, options.refreshFromNetwork, tags, options.includeTags]);

  return content.length > 0 ? content : undefined;
}

export function useFilterContentsIdsWithoutProjectOutput(metadataIds: string[]): string[] {
  return useSelector((state: RootState) => {
    return metadataIds.flatMap((metadataId) => (!hasProjectOutput(state.content.contentData[metadataId]) ? [metadataId] : []));
  }, shallowEqual);
}

export function useCollectionCompanyStockFromContents(metadataIds: string[]): StockExchangeRecord | null {
  return useSelector((state: RootState) => {
    const contents = metadataIds.flatMap((metadataId) =>
      state.content.contentData[metadataId] ? [state.content.contentData[metadataId]] : []
    );

    const companyDetailsContent = contents.find((content) => content.dynamicData?.category === "company_details");

    if (!companyDetailsContent) {
      return null;
    }

    const validStockExchange = StockExchangeRecord.validate(companyDetailsContent.dynamicData?.dynamic_data);
    const validNewStockExchange = NewStockExchangeRecord.validate(companyDetailsContent.dynamicData?.dynamic_data);

    if (validStockExchange.success) {
      return {
        name: validStockExchange.value.name,
        exchange: validStockExchange.value.exchange,
        ticker: validStockExchange.value.ticker,
      };
    } else if (validNewStockExchange.success) {
      return {
        name: validNewStockExchange.value.company_name,
        exchange: validNewStockExchange.value.company_stock_exchange,
        ticker: validNewStockExchange.value.company_ticker,
      };
    } else {
      return null;
    }
  }, shallowEqual);
}

export function useContent(metadataId: string): ContentDetails | undefined {
  return useSelector((state: RootState) => state.content.contentData[metadataId]);
}

export function useContentWithTagId(metadataIds: string[], tag: string): string | undefined {
  return useSelector((state: RootState) => {
    return metadataIds.find((metadataId) => state.content.contentData[metadataId]?.manualTags?.includes(tag));
  }, shallowEqual);
}

export function useRecentContents(limit: number) {
  const contents = useSelector((state: RootState) => {
    return state.content.order.slice(0, limit).map((metadataId) => state.content.contentData[metadataId]);
  }, shallowEqual);

  return contents;
}

export function useTotalContentsCount() {
  return useSelector((state: RootState) => state.content.recentContent.totalCount);
}

export function useDownloadRecentContents(limit: number) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(downloadRecentContents({ limit: limit }));
  }, [dispatch, limit]);
}

export const useLoadingContents = (): boolean => {
  return useSelector((state: RootState) => state.content.isLoading);
};

export const useDownloadContentsWithTagsFilter = (metadataIds: string[] | undefined) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (metadataIds) {
      requestToDownloadContents({ metadataIds, tags: ["company_logo", "project_landing_thumbnail"] }, dispatch);
    }
  }, [metadataIds, dispatch]);
};
