import { shallowEqual, useSelector } from "react-redux";
import { useMemo } from "react";
import type { RootState } from "state/rootReducer";
import groupBy from "lodash/groupBy";
import filter from "lodash/filter";
import type { ProgressSteps } from "types/ProgressSteps";
import type { WorkflowStatusStep } from "state/progressSteps/reducer";
import { useCollectionWorkflowsIds } from "./useCollections";
import cloneDeep from "lodash/cloneDeep";

export function useLoadingProgressSteps(workflowIds: string[]): boolean {
  const { isLoadingSteps } = useSelector((state: RootState) => state.progressSteps);

  return useMemo(() => (workflowIds.length > 0 ? workflowIds.every((id) => isLoadingSteps[id]) : false), [isLoadingSteps, workflowIds]);
}

export const useProgressSteps = (collectionId: string, filterByIntents?: string[]): ProgressSteps[] => {
  const workflowIds = useCollectionWorkflowsIds(collectionId);

  const intentStatusesByWorkflows = useSelector(
    (state: RootState) =>
      workflowIds.flatMap((id) =>
        state.progressSteps.progressIntentStatusByWorkflowId[id] ? [state.progressSteps.progressIntentStatusByWorkflowId[id]] : []
      ),
    shallowEqual
  );

  return useMemo(() => {
    const progressSteps = cloneDeep(
      intentStatusesByWorkflows.flatMap((intentStatusesByWorkflow) => {
        const steps = Object.entries(intentStatusesByWorkflow).map(([intent, status]) => ({
          intent,
          status,
        }));

        if (filterByIntents) {
          return filter(steps, ({ intent }) => filterByIntents.includes(intent));
        } else {
          return steps;
        }
      })
    );

    const groupedByIntent = groupBy(progressSteps, "intent");

    return Object.entries(groupedByIntent).map(([intent, statuses]) => {
      return {
        intent,
        status: statuses.reduce((acc, { status }) => {
          Object.entries(status).forEach(([key, value]) => {
            if (!acc[key]) {
              acc[key] = value;
            } else {
              // This is comparing records between different workflows
              // It should pickup the date values corresponding to the record with the highest creation date
              const isHigherCreationDate = new Date(value.latestCreationDate).getTime() > new Date(acc[key].latestCreationDate).getTime();

              acc[key].count += value.count;
              acc[key].latestCreationDate = isHigherCreationDate ? value.latestCreationDate : acc[key].latestCreationDate;
              acc[key].latestCompletionDate = isHigherCreationDate ? value.latestCompletionDate : acc[key].latestCompletionDate;
            }
          });

          return acc;
        }, {} as WorkflowStatusStep),
      };
    });
  }, [intentStatusesByWorkflows, filterByIntents]);
};
