import { Box, Text, Stack, Center, useToast, useBreakpointValue, Badge, Button, Tooltip } from "@chakra-ui/react";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { ConversationContext } from "screens/thread/ConversationContext";
import { getStatusColor } from "screens/common/components/WorkflowSummary";
import { sendMessage } from "state/websocket/operations";
import { useDispatch } from "react-redux";
import { ToastMessageContent } from "screens/common/components";
import {
  useAllProjectConfigsIntents,
  useButtonProps,
  useCollectionKey,
  useConfigMap,
  useFeatureFlags,
  useGetViewConfig,
  useLatestCollectionWorkflowId,
  useMainCollectionWorkflowId,
  useTileProps,
} from "hooks";
import { useNavigate } from "react-router-dom";
import { useWorkflowCompletionDateOrNow, useWorkflowKey, useWorkflowStatus } from "hooks/useWorkflows";
import { formatDistanceToNow } from "date-fns";
import { WorkflowProgressBar } from "./WorkflowProgressBar";
import { useProgressSteps } from "hooks/useProgressSteps";
import type { ProgressSteps } from "types/ProgressSteps";
import type { WorkflowStatus } from "api/workflows/models/WorkflowStatus";
import type { WorkflowStatusStep } from "state/progressSteps/reducer";
import max from "lodash/max";
import flatMap from "lodash/flatMap";
import capitalize from "lodash/capitalize";
import { formatDate } from "screens/common/modal/formatters";
import { Popover } from "react-tiny-popover";
import { WorkflowStatusIcon } from "screens/common/components/WorkflowStatusIcon";
import { ChildWorkflowProgressBar } from "./ChildWorkflowProgressBar";
import { usePausedWorkflowModal } from "screens/collection/views/ProjectActions/PausedWorkflowModal";

export interface ContainerProps {
  collectionId: string;
  size?: "xs" | "sm" | "md";
  isProjectView?: boolean;
  maxWidth?: string;
  hideProgressBar?: boolean;
}

interface Props extends ContainerProps {
  progressSteppers: ProgressSteps[];
  conversationId?: string;
}

const getStatusWithHighestDate = (statusStep: WorkflowStatusStep): WorkflowStatus => {
  const allDates = flatMap(Object.values(statusStep), (status) => [
    new Date(status.latestCreationDate).getTime(),
    ...(status.latestCompletionDate ? [new Date(status.latestCompletionDate).getTime()] : []),
  ]);

  if (allDates.length === 0) return "not_started";

  const maxDate = max(allDates);

  const keyWithMaxDate = Object.keys(statusStep).find((key) => {
    const status = statusStep[key];
    return new Date(status.latestCreationDate).getTime() === maxDate || new Date(status.latestCompletionDate ?? 0).getTime() === maxDate;
  });

  return keyWithMaxDate as WorkflowStatus; // find() might return undefined, but we ensure it's always a valid key
};

const StatusStack = ({ status, count, latestCreationDate, latestCompletionDate, intent }) => {
  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Text fontSize={"xs"} fontWeight="semibold">{`${intent}`}</Text>
        {count && <Badge>{count}</Badge>}
      </Stack>
      <Box pl="1rem" borderLeft="1px solid #718096">
        <Stack direction="row" align="center">
          <Text fontSize="xs">Status:</Text>
          <Text fontSize="xs" as="em">
            {capitalize(status).split("_").join(" ") || "Not Started"}
          </Text>
        </Stack>
        <Stack direction="row" align="center">
          <Text fontSize="xs">Started:</Text>
          <Text fontSize="xs" as="em">{`${latestCreationDate ? formatDate(new Date(latestCreationDate), "dd MM yyyy hh:mm") : "--"}`}</Text>
        </Stack>
        <Stack direction="row" align="center">
          <Text fontSize="xs">Finished:</Text>
          <Text fontSize="xs" as="em">{`${
            latestCompletionDate ? formatDate(new Date(latestCompletionDate), "dd MM yyyy hh:mm") : "--"
          }`}</Text>
        </Stack>
      </Box>
    </Stack>
  );
};

const ProgressStepper = ({
  stepper,
  isOpen,
  onClose,
  onOpen,
  workflowId,
  isProjectView,
  onClickAction,
}: {
  stepper: ProgressSteps;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  workflowId: string | undefined;
  isProjectView: boolean;
  onClickAction: (intent: string, status: WorkflowStatus) => void;
}) => {
  const commonTileProps = useTileProps();

  const renderWorkflowStatusSteps = useCallback(
    (statusStep: WorkflowStatusStep, intent: string) => {
      return Object.entries(statusStep).map(([status, { count, latestCreationDate, latestCompletionDate }]) => (
        <StatusStack
          status={status}
          count={count}
          latestCreationDate={latestCreationDate}
          latestCompletionDate={latestCompletionDate}
          intent={intent}
          key={`${workflowId}-${intent}-${status}`}
        />
      ));
    },
    [workflowId]
  );

  const getRelevantStatus = useCallback((step: WorkflowStatusStep | null): WorkflowStatus | "not_started" => {
    if (step === null) {
      return "not_started";
    } else {
      return getStatusWithHighestDate(step);
    }
  }, []);

  return (
    <Popover
      isOpen={isOpen}
      positions={["top", "left"]}
      padding={10}
      reposition={false}
      onClickOutside={() => onClose}
      content={() => (
        <Stack {...commonTileProps}>
          {stepper.status !== null ? (
            renderWorkflowStatusSteps(stepper.status, stepper.intent)
          ) : (
            <StatusStack
              status={"Not Started"}
              count={null}
              latestCreationDate={null}
              latestCompletionDate={null}
              intent={stepper.intent}
            />
          )}
        </Stack>
      )}>
      <Box
        p="3px"
        borderRadius={"full"}
        backgroundColor={`${getStatusColor(getRelevantStatus(stepper.status))}.200`}
        border="none"
        height={isProjectView ? "2rem" : "unset"}
        className="ch-workflow-step"
        cursor="pointer"
        textAlign="left"
        onMouseEnter={() => onOpen()}
        onMouseLeave={() => onClose()}
        onClick={(event) => {
          event.stopPropagation();
          onClickAction(stepper.intent, getRelevantStatus(stepper.status));
        }}>
        <Stack direction="row" justifyContent="left" alignItems="left">
          <WorkflowStatusIcon status={getRelevantStatus(stepper.status)} />
        </Stack>
      </Box>
    </Popover>
  );
};

const WorkflowProgressStepper = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { collectionId, size = "xs", progressSteppers, conversationId, isProjectView, hideProgressBar = true, maxWidth } = props;
  const { onConversationOpen, setConversationId } = useContext(ConversationContext);
  const toast = useToast();
  const collectionType = useCollectionKey(collectionId, "collectionType");
  const collectionConversationId = useCollectionKey(collectionId, "conversationId");
  const collectionName = useCollectionKey(collectionId, "name");
  const configMap = useConfigMap();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const currentWorkflowId = useLatestCollectionWorkflowId(collectionId);
  const currentWorkflowState = useWorkflowStatus(currentWorkflowId);
  const currentWorkflowCompletionDate = useWorkflowCompletionDateOrNow(currentWorkflowId);
  const projectRoute = useGetViewConfig("route", collectionType, configMap);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const commonButtonProps = useButtonProps("xs", "secondary");
  const { parallel_child_workflows: hasParallelChildWorkflowsProgressBar } = useFeatureFlags();
  const { onOpen: onOpenPausedWorkflowModal } = usePausedWorkflowModal();

  const onClickAction = useCallback(
    (intent: string, status: WorkflowStatus) => {
      if (!conversationId) return;

      const processAction = (intentAction?: string, entities?: { entity: string; value: unknown }[]) => {
        if (!conversationId) return;
        dispatch(
          sendMessage({
            conversationId,
            intent: intentAction,
            entities: entities,
            disableDebugging: true,
            disableTesting: true,
          })
        );
        setConversationId(conversationId);
        toast({
          render: ({ onClose }) => (
            <ToastMessageContent
              message={`Rerunning ${intentAction?.replace(/_+/g, " ")} step now. You can open the conversation by clicking this message`}
              onClick={() => {
                onConversationOpen();
                onClose();
              }}
              onClose={onClose}
            />
          ),
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      };

      const getPath = () => {
        if (projectRoute) {
          return navigate(`/${projectRoute}/${collectionId}`);
        } else {
          onConversationOpen();
        }
      };

      switch (status) {
        case "clarification_needed":
          setConversationId(conversationId);
          onConversationOpen();
          break;
        case "failed":
        case "error":
          processAction("/retry", [{ entity: "collection_id", value: collectionId }]);
          break;
        case "cancelled":
        case "denied_intent_confirmation":
          processAction(`/${intent}`, [{ entity: "collection_id", value: collectionId }]);
          break;
        default:
          getPath();
      }
    },
    [collectionId, conversationId, dispatch, navigate, onConversationOpen, projectRoute, setConversationId, toast]
  );

  const onCancelProject = useCallback(() => {
    if (!conversationId) return;

    if (!conversationId) return;
    dispatch(
      sendMessage({
        conversationId,
        intent: "/cancel",
      })
    );
    setConversationId(conversationId);
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={`Cancelling ${collectionName} project now. You can open the conversation by clicking this message`}
          onClick={() => {
            onConversationOpen();
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  }, [collectionName, conversationId, dispatch, onConversationOpen, setConversationId, toast]);

  return (
    <>
      <Stack
        className="ch-workflow-stepper"
        direction="row"
        flexWrap="wrap"
        spacing={isMobile ? "unset" : ".3rem"}
        alignContent="flex-end"
        borderRadius={"full"}>
        {progressSteppers.length > 0 ? (
          <Stack justifyContent={"end"} width="100%">
            <Stack alignItems={"center"} direction="row" justifyContent={"end"}>
              {currentWorkflowId && hasParallelChildWorkflowsProgressBar && <ChildWorkflowProgressBar workflowId={currentWorkflowId} />}
              {progressSteppers.map((stepper, index) => (
                <ProgressStepper
                  isOpen={hoveredIndex === index}
                  onClose={() => setHoveredIndex(-1)}
                  key={`step-${stepper.intent}-${currentWorkflowId}`}
                  stepper={stepper}
                  workflowId={currentWorkflowId}
                  onOpen={() => setHoveredIndex(index)}
                  isProjectView={!!isProjectView}
                  onClickAction={(intent, status) => {
                    if (status === "failed_checkstop") {
                      onOpenPausedWorkflowModal(collectionId);
                    } else {
                      onClickAction(intent, status);
                    }
                  }}
                />
              ))}
            </Stack>
            {currentWorkflowId && !hideProgressBar && currentWorkflowState === "in_progress" && (
              <Stack direction={"row"} spacing="1rem" justifyContent={"end"} width="100%">
                <WorkflowProgressBar
                  maxWidth={maxWidth}
                  workflowId={currentWorkflowId}
                  size="compact"
                  lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
                    addSuffix: true,
                    includeSeconds: false,
                  })}
                  hideCompletedInDate
                  conversationId={collectionConversationId}
                />
                <Tooltip label="Cancel project" placement="top" aria-label="View all steps">
                  <Button
                    {...commonButtonProps}
                    height="unset"
                    minWidth={"unset"}
                    onClick={(evt) => {
                      onCancelProject();
                      evt.stopPropagation();
                    }}>
                    Cancel
                  </Button>
                </Tooltip>
              </Stack>
            )}
          </Stack>
        ) : (
          <Center>
            <Box
              p="5px"
              pr="10px"
              mb="3px"
              className="ch-workflow-step"
              cursor="default"
              textAlign="left"
              height={isProjectView ? "2rem" : "unset"}>
              <Stack direction="row" justifyContent="left" alignItems="left">
                <WorkflowStatusIcon status={"failed"} />
                <Text isTruncated pl="4px" fontSize={size} maxWidth={["100%", "8rem", "unset"]}>
                  {`${"Workflow stepper not available."}`}
                </Text>
              </Stack>
            </Box>
          </Center>
        )}
      </Stack>
    </>
  );
};

export const WorkflowProgressStepperContainer = (props: ContainerProps) => {
  const { collectionId } = props;
  const configMap = useConfigMap();
  const collectionType = useCollectionKey(collectionId, "collectionType");

  const workflowIntentFilters = useGetViewConfig("workflowIntentFilters", collectionType, configMap);

  const workflowIntentWithCommonFilters = useMemo(() => {
    const commonFilters = ["generate_collection_report"];

    return [...(workflowIntentFilters ? workflowIntentFilters : []), ...commonFilters];
  }, [workflowIntentFilters]);

  const includedIntentsForStepper = useAllProjectConfigsIntents();
  const mainWorkflowId = useMainCollectionWorkflowId(collectionId, includedIntentsForStepper);
  const conversationId = useWorkflowKey(mainWorkflowId, "conversationId");

  const steppers = useProgressSteps(collectionId, workflowIntentWithCommonFilters);

  const combinedSteppers = useMemo(() => {
    const steppersMap = steppers.reduce<{ [intent: string]: ProgressSteps }>((accum, step) => {
      accum[step.intent] = step;
      return accum;
    }, {});

    return (workflowIntentFilters || []).map((intent) => {
      return steppersMap[intent] || { intent, status: null };
    });
  }, [steppers, workflowIntentFilters]);

  return <WorkflowProgressStepper {...props} progressSteppers={combinedSteppers} conversationId={conversationId} />;
};
