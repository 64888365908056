import { Box } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import DOMPurify from "dompurify";
import marked from "marked";

interface Props {
  markupText?: string;
  className?: string;
  maxHeight?: string;
}

export const MarkupTextViewer: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  markupText,
  className,
  maxHeight = "55rem",
}) => {
  const parsedMarkdown = marked(markupText || "");
  const purifiedHtml = DOMPurify.sanitize(parsedMarkdown);

  return (
    <Box
      maxHeight={maxHeight}
      className={className}
      overflowY="auto"
      dangerouslySetInnerHTML={{ __html: purifiedHtml.replace(/<br>/g, "<p>") }}
      css={{
        fontSize: "0.85rem",
        blockquote: {
          background: "#f9f9f9",
          marginTop: "1.25rem !important",
          marginBottom: "1.25rem !important",
        },
        ol: {
          margin: "0 0 0 1.5rem",
          padding: "0",
        },
        ul: {
          margin: "0 0 0 1rem",
          padding: "0",
        },
        p: {
          textAlign: "left",
          display: "block",
        },
        "blockquote p": {
          display: "inline",
          color: "#555555",
          fontStyle: "italic",
        },
      }}
    />
  );
};
