import { Box, Flex, useBreakpointValue, useColorMode, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import {
  useCollectionKey,
  useCommandSuggestions,
  useContents,
  useConversation,
  useConversationLoading,
  useHasConversation,
  useItemSelector,
  useProjectParams,
  useUserPreference,
} from "hooks";
import React, { useMemo, useRef, useEffect } from "react";
import { getFirstMessage, getLastMessage, getMessageWithProjectDetails } from "screens/common/app";
import { InputBar, InputBarDropzone } from "screens/conversation/components";
import type { InputBarRef } from "screens/conversation/components";
import { MessagesView, ThreadTitleBar } from "screens/thread/components";
import { getItemTypeForContent } from "screens/content/common/utils";
import type { SupportedItemKeys } from "state/selection/reducer";
import { useConversationContext } from "./ConversationContext";
import { useDispatch } from "react-redux";
import { downloadConversationById, downloadTags } from "state/conversation/operations";
import ReactJson from "react-json-view";
import { v4 as uuid } from "uuid";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { TypingIndicator } from "./components/cells/components";

const toStringOrDefault = (maybeJson: unknown | undefined, defaultMessage: string) => {
  const json = maybeJson ? maybeJson : { error: defaultMessage };
  return JSON.stringify(json);
};

export const ConversationDialog = () => {
  const { projectId } = useProjectParams();
  const collectionConversationId = useCollectionKey(projectId || "", "conversationId");
  const { conversationId, collectionId, setShowCommands, setConversationId } = useConversationContext();
  const { isNewMenuAction } = useAddToCharliContext();
  const { messages, metadataIds } = useConversation(
    collectionConversationId && collectionConversationId.length > 0 ? collectionConversationId : conversationId
  );
  const inputBarRef = useRef<InputBarRef>(null);
  const initiatingMessage = getFirstMessage(messages, "user");
  const projectDetails = getMessageWithProjectDetails(messages);
  const lastMessage = getLastMessage(messages);
  const inlineSuggestions = useMemo(() => {
    if (lastMessage && lastMessage.state === "action_required" && Array.isArray(lastMessage.data)) {
      const suggestions = lastMessage.data.find((datum) => datum.type === "suggestion");
      if (suggestions?.type === "suggestion" && suggestions.body.suggestion.length > 0) {
        return suggestions.body.suggestion;
      }
    }
  }, [lastMessage]);
  const bgColor = useColorModeValue("white", "gray.800");
  const { setSelectedItems, resetSelectedItems, selectedItems } = useItemSelector();
  const canvasItems = useContents(metadataIds ?? []);
  const { availableIntents } = useCommandSuggestions();
  const injectDebugEntityPreference = useUserPreference("ui_inject_debug_entity");
  const getStringMaxLength = useBreakpointValue([50, 70, 70, 100, 180]);
  const { colorMode } = useColorMode();
  const { onToggle: onJsonViewToggle, isOpen: isJsonViewOpen } = useDisclosure();
  const hideCommandLineButton = useUserPreference("ui_hide_command_line_button") || false;
  const hasConversation = useHasConversation(conversationId);
  const isLoadingConversation = useConversationLoading(conversationId);
  const bgInputColor = useColorModeValue("charli.lightBlue", "gray.800");

  useEffect(() => {
    injectDebugEntityPreference && console.log({ "Dialog Conversation Id": conversationId, "Dialog Collection Id": collectionId });
  }, [collectionId, conversationId, injectDebugEntityPreference]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(downloadTags());
  }, [dispatch]);

  useEffect(() => {
    !hideCommandLineButton && setShowCommands(messages.length === 0);
  }, [hideCommandLineButton, messages, setShowCommands]);

  useEffect(() => {
    if (Object.keys(selectedItems).length > 0) return;
    if (canvasItems?.length) {
      const items =
        canvasItems &&
        canvasItems.reduce((cells, cell) => {
          const itemType = getItemTypeForContent(cell.type);
          cells[cell.mediaId] = { type: itemType };
          return cells;
        }, {} as { [id: string]: { type: SupportedItemKeys } });
      if (items) setSelectedItems(items);
    } else {
      resetSelectedItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasItems?.length]);

  useEffect(() => {
    if (isNewMenuAction) {
      return;
    }

    if (conversationId.length > 0 && !hasConversation) {
      dispatch(downloadConversationById({ conversationId: conversationId }));
    }
  }, [hasConversation, isNewMenuAction, conversationId, dispatch]);

  useEffect(() => {
    setConversationId((prevConversationId: string) => {
      const newConversationId = uuid();
      const nextConversationId = isNewMenuAction ? newConversationId : prevConversationId || newConversationId;

      return nextConversationId;
    });
  }, [isNewMenuAction, setConversationId]);

  const parsedJson = useMemo(() => {
    return JSON.parse(toStringOrDefault(messages, "input payload was undefined"));
  }, [messages]);

  return (
    <InputBarDropzone
      attachmentId="conversation"
      display="flex"
      flexGrow={1}
      flexDirection="column"
      background={bgColor}
      minWidth={0}
      height="100%">
      <ThreadTitleBar
        createdDate={initiatingMessage?.createdDate}
        collectionId={projectDetails?.body.collectionId}
        title={initiatingMessage?.intent}
        toggleJsonView={onJsonViewToggle}
      />
      {injectDebugEntityPreference && isJsonViewOpen && (
        <Box p="1rem" overflow="auto" minHeight={"10rem"} maxHeight="25vh">
          <ReactJson
            quotesOnKeys={false}
            displayDataTypes={false}
            collapseStringsAfterLength={getStringMaxLength}
            src={parsedJson}
            theme={colorMode === "dark" ? "ashes" : "rjv-default"}
          />
        </Box>
      )}
      {isLoadingConversation === true ? (
        <Box height={"100%"} minHeight={"10rem"}>
          <TypingIndicator height="100%" />
        </Box>
      ) : (
        <MessagesView conversationId={conversationId} messages={messages} />
      )}
      <Box width="100%" flexGrow={0} flexShrink={0} bgColor={bgInputColor}>
        <Flex px="1rem" py="1rem" justify="flex-end" flexDirection="column">
          <InputBar
            placeholderText={messages.length > 0 ? "Reply to Charli..." : "Select or enter a command..."}
            ref={inputBarRef}
            conversationId={conversationId}
            collectionId={collectionId}
            followUpIntentSource={{ type: "list", intents: availableIntents }}
            inlineSuggestions={inlineSuggestions}
          />
        </Flex>
      </Box>
    </InputBarDropzone>
  );
};
