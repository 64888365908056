import React from "react";
import type { SuggestedQuestions } from "api/suggestions";
import { createContext, useContext } from "react";

type GetSourceList = (params?: unknown) => Promise<SuggestedQuestions>;

interface AutocompleteSourceContextProps {
  getSourceList: GetSourceList;
}

const AutocompleteSourceContext = createContext<AutocompleteSourceContextProps>({
  getSourceList: () => Promise.resolve([]),
});

export const useAutocompleteSourceContext = () => {
  return useContext(AutocompleteSourceContext);
};

export const AutocompleteSourceContextProvider: React.FC<React.PropsWithChildren<{ getSourceList: GetSourceList }>> = ({
  children,
  getSourceList,
}) => {
  return <AutocompleteSourceContext.Provider value={{ getSourceList }}>{children}</AutocompleteSourceContext.Provider>;
};
