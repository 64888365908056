import { createSlice } from "@reduxjs/toolkit";
import type { PlanUser } from "types/billingPlans/PlanUser";
import type { Entitlement } from "types/entitlements";
import {
  addPlanUsers,
  createBillingPlan,
  downloadBillingPlans,
  downloadPlanEntitlements,
  removePlan,
  removePlanUsers,
  updateBillingPlan,
} from "./operations";

interface BillingPlansMap {
  code: string;
  name: string;
  planUsersIds: string[];
}

interface BillingPlansState {
  plans: Record<string, BillingPlansMap>;
  planUsers: Record<string, PlanUser>;
  loading: boolean;
  planEntitlements: Record<string, Entitlement[]>;
  loadingEntitlements: boolean;
}

const initialState: BillingPlansState = {
  plans: {},
  planUsers: {},
  loading: false,
  planEntitlements: {},
  loadingEntitlements: false,
};

export const { actions, reducer } = createSlice({
  name: "billingPlans",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadBillingPlans.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(downloadBillingPlans.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(downloadBillingPlans.fulfilled, (state, action) => {
      state.loading = false;
      const { payload: billingPlans } = action;

      const newBillingPlansState: Record<string, BillingPlansMap> = {};
      const newPlanUsersState: Record<string, PlanUser> = {};
      billingPlans.forEach((billingPlan) => {
        const { code, name, planUsers } = billingPlan;

        const userIds = planUsers ? planUsers.map((planUser) => planUser.id) : [];

        newBillingPlansState[code] = {
          name,
          code,
          planUsersIds: userIds,
        };

        planUsers &&
          planUsers.forEach((planUser) => {
            newPlanUsersState[planUser.id] = planUser;
          });
      });

      state.plans = newBillingPlansState;
      state.planUsers = newPlanUsersState;
    });

    builder.addCase(removePlan.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removePlan.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(removePlan.fulfilled, (state, action) => {
      state.loading = false;
      const { planCode } = action.payload;

      delete state.plans[planCode];
    });

    builder.addCase(createBillingPlan.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBillingPlan.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createBillingPlan.fulfilled, (state, action) => {
      state.loading = false;
      const { name, code, planUsers } = action.payload;

      const userIds = (planUsers || []).map((planUser) => {
        state.planUsers[planUser.id] = planUser;
        return planUser.id;
      });

      state.plans[code] = {
        code,
        name,
        planUsersIds: userIds,
      };
    });

    builder.addCase(updateBillingPlan.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBillingPlan.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateBillingPlan.fulfilled, (state, action) => {
      state.loading = false;
      const { name, code } = action.payload;

      state.plans[code].name = name;
    });

    builder.addCase(addPlanUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addPlanUsers.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addPlanUsers.fulfilled, (state, action) => {
      state.loading = false;
      const { planUsers, planCode } = action.payload;

      planUsers.forEach((planUser) => {
        state.planUsers[planUser.id] = planUser;
        state.plans[planCode].planUsersIds.push(planUser.id);
      });
    });

    builder.addCase(removePlanUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removePlanUsers.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(removePlanUsers.fulfilled, (state, action) => {
      state.loading = false;
      const { planCode, ids } = action.payload;

      const idsToRemoveSet = new Set<string>(ids);
      ids.forEach((id) => {
        delete state.planUsers[id];
      });

      state.plans[planCode].planUsersIds = state.plans[planCode].planUsersIds.flatMap((id) => {
        if (idsToRemoveSet.has(id)) {
          return [];
        } else {
          return [id];
        }
      });
    });

    builder.addCase(downloadPlanEntitlements.pending, (state) => {
      state.loadingEntitlements = true;
    });
    builder.addCase(downloadPlanEntitlements.rejected, (state) => {
      state.loadingEntitlements = false;
    });
    builder.addCase(downloadPlanEntitlements.fulfilled, (state, action) => {
      state.loadingEntitlements = false;
      state.planEntitlements[action.meta.arg.planCode] = action.payload;
    });
  },
});

export default reducer;
