import React, { useRef, useState } from "react";
import { Button, Stack, useColorMode, Box, useOutsideClick } from "@chakra-ui/react";
import CodeMirror from "@uiw/react-codemirror";
import { EditorState } from "@codemirror/state";
import { EditorView, lineNumbers, tooltips } from "@codemirror/view";
import { oneDark } from "@codemirror/theme-one-dark";
import { json, jsonParseLinter } from "@codemirror/lang-json";
import { linter, lintGutter } from "@codemirror/lint";
import { bracketMatching } from "@codemirror/language";
import { autocompletion, closeBrackets } from "@codemirror/autocomplete";
import { history } from "@codemirror/commands";
import type { JSONSchema7 } from "json-schema";
import { JSONSchemaViewer } from "./JsonSchemaViewer";
import { useButtonProps, useTileProps } from "hooks";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { Popover } from "react-tiny-popover";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";

export interface JSONEditorProps {
  value: string;
  onChange?: (value: string) => void;
  jsonSchema?: JSONSchema7;
  previewPanel?: boolean;
  editable?: boolean;
  title?: string;
  helperText?: string;
  height?: string;
}

const commonExtensions = [
  bracketMatching(),
  closeBrackets(),
  history(),
  autocompletion(),
  lineNumbers(),
  lintGutter(),
  EditorView.lineWrapping,
  EditorState.tabSize.of(2),
  autocompletion(),
];

export const JSONEditor = ({
  value,
  jsonSchema: schema,
  previewPanel,
  height = "calc(20vh)",
  editable = true,
  onChange,
  title,
  helperText,
}: JSONEditorProps) => {
  const { colorMode } = useColorMode();
  const commonButtonProps = useButtonProps("xs", "secondary");
  const commonTileProps = useTileProps();
  const mainRef = useRef<HTMLDivElement | null>(null);
  const popRef = useRef<HTMLDivElement | null>(null);
  const [showPopover, setShowPopover] = useState(false);
  const { onAddNewProjectPanelOpen } = useAddToCharliContext();

  useOutsideClick({
    ref: popRef!,
    handler: () => {
      setShowPopover(false);
    },
  });

  const tooltipTheme = EditorView.theme({
    ".cm-tooltip-lint": { backgroundColor: "#fffbd7", maxWidth: "20rem", marginTop: "10px", lineHeight: "1.5rem" },
  });

  return (
    <Box ref={mainRef}>
      <Stack direction="row" width="100%" justifyContent={"space-between"}>
        {title && <SectionHeader title={title} helperText={helperText} />}
        <Stack direction="row" spacing="1rem">
          {previewPanel && (
            <Button isDisabled={value === "null"} {...commonButtonProps} onClick={onAddNewProjectPanelOpen}>
              Open Preview Panel
            </Button>
          )}
          {schema ? (
            <Popover
              ref={popRef}
              parentElement={mainRef?.current || undefined}
              isOpen={showPopover}
              positions={["left"]}
              padding={10}
              reposition={false}
              onClickOutside={() => setShowPopover(false)}
              content={() => (
                <Box {...commonTileProps} maxWidth="30rem" cursor={"default"}>
                  <JSONSchemaViewer value={schema} />
                </Box>
              )}>
              <Button {...commonButtonProps} onClick={() => setShowPopover(!showPopover)}>{`${title} Schema`}</Button>
            </Popover>
          ) : (
            <React.Fragment />
          )}
        </Stack>
      </Stack>
      <CodeMirror
        style={{ fontSize: "0.85rem" }}
        theme={colorMode === "dark" ? oneDark : "light"}
        extensions={[...commonExtensions, json(), tooltips({ position: "absolute" }), linter(jsonParseLinter()), tooltipTheme]}
        value={value}
        height={height}
        width="100%"
        onChange={(value) => {
          onChange && onChange(value);
        }}
        editable={editable}
      />
    </Box>
  );
};
