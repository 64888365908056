import { Box, Button, Code, Icon, IconButton, Stack, Text, Tooltip, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { LinkLogo } from "screens/common/components/LinkLogo";
import type { Integration } from "api/integrations";
import { StarIcon } from "@chakra-ui/icons";
import { deleteIntegrationPreference, updateIntegrationPreference } from "state/userPreference/operations";
import { useDispatch } from "react-redux";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { useButtonProps, useUserPreference } from "hooks";
import { IoCopyOutline } from "react-icons/io5";

export const IntegrationTile = ({
  integration,
  onClick,
  buttonLabel,
}: {
  integration: Integration;
  onClick?: () => void;
  buttonLabel?: string;
}) => {
  const primaryTextColor = useColorModeValue("gray.700", "gray.300");
  const secondaryTextColor = useColorModeValue("gray.500", "gray.300");
  const buttonColor = useColorModeValue("gray.200", "gray.700");
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const betaBgColor = useColorModeValue("blue.50", "gray.700");
  const comingSoonBgColor = useColorModeValue("orange.50", "gray.700");
  const integrationBgColor = useColorModeValue("green.50", "gray.700");
  const codeColor = useColorModeValue("blackAlpha", "white");
  const codeBgColor = useColorModeValue("gray.100", "#191f23");
  const titleText = integration.name;
  const descriptionText = integration.description;
  const logoDomain = integration.domain;
  const dispatch = useDispatch();
  const [isPreferredClicked, setIsPreferredClicked] = React.useState(false);
  const isWide = useBreakpointValue({ base: false, md: true });
  const [showDetail, setShowDetail] = React.useState(false);
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const [isCopied, setIsCopied] = useState(false);
  const injectDebugEntityPreference = useUserPreference("ui_inject_debug_entity");

  const buttonLabelText = () => {
    if (buttonLabel) {
      return buttonLabel;
    } else if (integration.isEnabled && !integration.isImplicitlyEnabled) {
      // todo
      // remove this check, going forward, integration service will treat integrations
      // that need reauth as disabled, so if it's marked as enabled, we know it doesn't require reauth
      if (integration.isReauthRequired) {
        return isWide ? "Reconnect Integration" : "Reconnect";
      } else {
        return isWide ? "Remove Integration" : "Remove";
      }
    } else if (integration.isImplicitlyEnabled && !integration.isReauthRequired) {
      return isWide ? "Automatically Enabled" : "Enabled";
    } else if (!integration.isUserEntitled && integration.isAvailable) {
      return isWide ? `Upgrade to ${integration.minimumPlan} plan` : "Upgrade";
    } else if (integration.isReauthRequired) {
      return isWide ? "Reconnect Integration" : "Reconnect";
    } else if (integration.isAvailable) {
      return isWide ? "Connect Integration" : "Connect";
    } else {
      return "Coming Soon";
    }
  };

  const notesCopied = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const shouldDisableButton = integration.isImplicitlyEnabled || !integration.isAvailable;

  const handleOnChange = (integration: Integration) => {
    if (!integration || !integration.isEnabled) return;

    setIsPreferredClicked(true);
    if (integration.isPreferred) {
      dispatch(
        deleteIntegrationPreference({
          preferenceKey: integration.categoryKey,
        })
      );
    } else if (integration.urn) {
      dispatch(
        updateIntegrationPreference({
          preferenceKey: integration.categoryKey,
          value: integration.urn,
        })
      );
    }
  };

  useEffect(() => {
    setIsPreferredClicked(false);
  }, [integration]);

  const renderDetails = useCallback(() => {
    const detailFields = [
      {
        label: "Category",
        value: integration.category,
      },
      {
        label: "Category Key",
        value: integration.categoryKey,
      },
      {
        label: "URN",
        value: integration.urn,
      },
      {
        label: "ID",
        value: integration.integrationId,
      },
    ];

    return detailFields.map((detail, index) => {
      return (
        <Box key={index} color={secondaryTextColor} fontSize="xs" pl="1rem">
          <Stack direction="row" justifyContent={"space-between"}>
            <Text>
              {`${detail.label}: `}
              <Code overflowWrap={"anywhere"} background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                {detail.value}
              </Code>
            </Text>
            {detail.value && (
              <Tooltip label={isCopied ? "Copied" : `Copy ${detail.label}`}>
                <IconButton
                  {...secondaryButtonProps}
                  size="xs"
                  onClick={(event) => {
                    detail.value && navigator.clipboard.writeText(detail.value);
                    notesCopied();
                    event.stopPropagation();
                  }}
                  aria-label={`copy ${detail.label}`}
                  icon={<Icon as={IoCopyOutline} fontSize=".9rem" />}></IconButton>
              </Tooltip>
            )}
          </Stack>
        </Box>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integration, secondaryTextColor, codeBgColor, codeColor, secondaryButtonProps, notesCopied]);

  return (
    <Box position="relative">
      <Box position="absolute" width="100%" mt="-0.5rem">
        <Stack direction="row" justifyContent="flex-end" spacing="0.2rem" mr="0.3rem">
          {integration.isInBeta && (
            <Text
              px="0.5rem"
              zIndex="1"
              borderRadius="5px"
              borderColor={borderColor}
              borderWidth="1px"
              backgroundColor={betaBgColor}
              fontSize="xs"
              color="gray.500"
              fontWeight="400"
              mr="0.3rem">
              BETA
            </Text>
          )}
          {!integration.isAvailable && (
            <Text
              px="0.5rem"
              zIndex="1"
              borderRadius="5px"
              borderColor={borderColor}
              borderWidth="1px"
              backgroundColor={comingSoonBgColor}
              fontSize="xs"
              color="gray.500"
              fontWeight="400"
              mr="0.3rem">
              COMING SOON
            </Text>
          )}
          {integration.minimumPlan && (
            <Tooltip width="9rem" label={`Requires a ${integration.minimumPlan} plan subscription.`}>
              <Text
                px="0.5rem"
                zIndex="1"
                borderRadius="5px"
                borderColor={borderColor}
                borderWidth="1px"
                backgroundColor={integrationBgColor}
                fontSize="xs"
                color="gray.500"
                fontWeight="400"
                textTransform="uppercase"
                mr="0.3rem">
                {integration.minimumPlan}
              </Text>
            </Tooltip>
          )}
        </Stack>
      </Box>
      <Box
        cursor={injectDebugEntityPreference ? "pointer" : "default"}
        borderRadius="5px"
        borderWidth="1px"
        borderColor={buttonColor}
        backgroundColor={bgColor}
        onClick={() => injectDebugEntityPreference && setShowDetail(!showDetail)}
        boxShadow="none"
        minHeight="6rem">
        <Stack height="100%" p="1rem" justify="space-between">
          <Stack>
            <Stack direction="row">
              {logoDomain && <LinkLogo url={logoDomain} height="1rem" maxWidth="1rem" p="0" borderRadius={"full"} />}
              <Text isTruncated fontWeight="700" fontSize="md" color={primaryTextColor}>
                {titleText}
              </Text>
            </Stack>
            {!showDetail ? (
              <Text color={secondaryTextColor} fontSize="sm" height="4rem" overflow="auto">
                {descriptionText}
              </Text>
            ) : (
              <Stack>{renderDetails()}</Stack>
            )}
          </Stack>
          <Stack direction="row" justifyContent="space-between" spacing="0.2rem" mr="0.3rem" width="100%">
            <Button
              size="sm"
              className={`${integration.urn && integration.urn.replace(/[.:]/g, "_")}_integration ${
                integration.isEnabled ? "enabled" : "removed"
              }`}
              isDisabled={shouldDisableButton}
              onClick={(event) => {
                onClick && onClick();
                event.stopPropagation();
              }}
              borderRadius="5px"
              color={
                integration.isReauthRequired
                  ? "white"
                  : !integration.isImplicitlyEnabled && integration.isEnabled
                  ? "red.700"
                  : primaryTextColor
              }
              fontWeight="bold"
              width="100%"
              backgroundColor={integration.isReauthRequired ? "red.400" : buttonColor}>
              {buttonLabelText()}
            </Button>
            {!integration.isImplicitlyEnabled && (
              <Tooltip
                label={
                  integration.isEnabled
                    ? `${integration.isPreferred ? "Remove" : "Set"} as default ${integration.category} integration`
                    : "You need to connect the integration first"
                }>
                <IconButton
                  size="sm"
                  className={`${integration.urn && integration.urn.replace(/[.:]/g, "_")}_preffered_btn ${
                    integration.isPreferred ? "preferred" : ""
                  }`}
                  onClick={(event) => {
                    handleOnChange(integration);
                    event.stopPropagation();
                  }}
                  isDisabled={isPreferredClicked}
                  aria-label="Default"
                  backgroundColor={buttonColor}
                  color={integration.isPreferred ? "blue.400" : "gray.300"}
                  icon={isPreferredClicked ? <TypingIndicator size="small" /> : <StarIcon />}
                />
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
