import type { FunctionComponent } from "react";
import React from "react";
import { Box, Text, Center, Stack, useColorModeValue, Circle, useBreakpointValue } from "@chakra-ui/react";
import { useConfigMap, useGetViewConfig } from "hooks";
import { useLocation } from "react-router-dom";

interface Props {
  collectionType?: string;
}

export const OnboardingMessageSteps: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ collectionType }) => {
  const bgColor = useColorModeValue("#f9f9f9", "gray.800");
  const color = useColorModeValue("gray.600", "gray.300");
  const { pathname } = useLocation();
  const isProject = pathname.includes("project");
  const route = pathname.split("/").slice(isProject ? 2 : 1, isProject ? 3 : 2)[0];
  const configMap = useConfigMap();
  const onboardingTitle = useGetViewConfig("onBoardingTitle", collectionType || route, configMap);
  const onboardingSteps = useGetViewConfig("onBoardingSteps", collectionType || route, configMap);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const Step = ({ stepNumber, stepColor, stepText, stepTitle }) => (
    <Stack className={`ch-onboarding-step-${stepNumber}`} direction="row" justifyContent={"left"} spacing="1.5rem">
      <Center>
        <Circle size="3rem" fontSize={"1.3rem"} fontWeight={"semibold"} color="white" bgColor={stepColor}>
          {stepNumber}
        </Circle>
      </Center>
      <Stack textAlign="left" spacing="0">
        <Text fontWeight="bold" fontSize={["sm", "md", "md"]} lineHeight="1.5rem" color="black">
          {stepTitle}
        </Text>
        <Text fontWeight="normal" fontSize={["sm", "md", "md"]} lineHeight="1.5rem">
          {stepText}
        </Text>
      </Stack>
    </Stack>
  );

  const renderStepsMobile = () => {
    return (
      <Stack spacing="1rem">
        <Stack spacing="0" textAlign="center" pb="2rem">
          <Text fontWeight="bold" fontSize="2xl" color={"charli.primaryBlue"}>
            {"Welcome to Charli AI"}
          </Text>
          {onboardingTitle && (
            <Text className="ch-onboarding-title" fontWeight="bold" fontSize="xl" color={"charli.primaryBlue"}>
              {onboardingTitle}
            </Text>
          )}
        </Stack>
        {onboardingSteps?.map((step, index) => (
          <Step key={index} stepNumber={step.stepNumber} stepColor={step.stepColor} stepTitle={step.stepTitle} stepText={step.stepText} />
        ))}
      </Stack>
    );
  };

  const renderStepsDesktop = () => {
    return (
      <Box borderLeft={`10px solid #009AD8`} backgroundColor={bgColor} borderRadius="none" width="100%" color={color}>
        <Center>
          <Stack spacing="2rem" px="5rem" py="2rem">
            <Stack spacing=".5rem" textAlign="left">
              <Text fontWeight="bold" fontSize={["md", "xl", "2xl"]} color={"charli.primaryBlue"}>
                {"Welcome to Charli AI"}
              </Text>
              {onboardingTitle && (
                <Text className="ch-onboarding-title" fontWeight="bold" fontSize={["sm", "lg", "xl"]} color={"charli.primaryBlue"}>
                  {onboardingTitle}
                </Text>
              )}
            </Stack>
            {onboardingSteps && onboardingSteps.length > 0 && (
              <>
                {onboardingSteps.map((step, index) => (
                  <Step
                    key={index}
                    stepNumber={step.stepNumber}
                    stepColor={step.stepColor}
                    stepTitle={step.stepTitle}
                    stepText={step.stepText}
                  />
                ))}
              </>
            )}
          </Stack>
        </Center>
      </Box>
    );
  };

  return <>{isMobile ? renderStepsMobile() : renderStepsDesktop()}</>;
};
