import { BillingPlanResponse } from "types/billing/BillingPlanResponse";
import { Plan as PlanResponse } from "types/billingPlans/Plan";
import { PlansResponse } from "./models/PlansResponse";
import { PlanUsersResponse } from "./models/PlanUsersResponse";
import { prependProxyBaseUrl, request } from "api/request";
import type { BillingPlanType } from "types/billing/BillingPlan";
import type { CreatePlanRequest } from "./models/CreatePlanRequest";
import type { DeleteResponse } from "./models/DeleteResponse";
import type { UpsertPlanUsersRequest } from "./models/UpsertPlanUsersRequest";

const PROXY_PREFIX = prependProxyBaseUrl("/api/billing-plan");
const PLANS_ROUTE = `${PROXY_PREFIX}/plans`;
const USER_BILLING_ENDPOINT_PATH = (userId: string) => `${PROXY_PREFIX}/plans/user/${userId}`;

const PLAN_USERS_PATH = (planCode: string) => `${PROXY_PREFIX}/plans/${planCode}/users`;

export async function getBillingStatus(userId: string): Promise<BillingPlanType | undefined> {
  try {
    return await request()
      .url(`${USER_BILLING_ENDPOINT_PATH(userId)}`)
      .get()
      .json((json) => {
        if (BillingPlanResponse.guard(json)) {
          return json.plan;
        } else {
          return undefined;
        }
      });
  } catch (error) {
    return undefined;
  }
}

export async function getBillingPlans(): Promise<PlansResponse> {
  const maybePlans = await request()
    .url(`${PLANS_ROUTE}?${new URLSearchParams({ fetchPlanUsers: "true" })}`)
    .get()
    .json();
  const areValidPlans = PlansResponse.validate(maybePlans);

  if (!areValidPlans.success) {
    console.error(areValidPlans);
    throw new Error(areValidPlans.message);
  }

  return areValidPlans.value;
}

export async function upsertBillingPlan(payload: CreatePlanRequest): Promise<PlanResponse> {
  const maybeNewPlan = await request().url(PLANS_ROUTE).post(payload).json();
  const isValidPlan = PlanResponse.validate(maybeNewPlan);

  if (!isValidPlan.success) {
    console.error(isValidPlan);
    throw new Error(isValidPlan.message);
  }

  return isValidPlan.value;
}

export async function updateBillingPlan(payload: { code: string; name: string }): Promise<PlanResponse> {
  const response = await request().url(PLANS_ROUTE).put(payload).json();
  const isValidPlan = PlanResponse.validate(response);

  if (!isValidPlan.success) {
    console.error(isValidPlan);
    throw new Error(isValidPlan.message);
  }

  return isValidPlan.value;
}

export async function upsertPlanUsers(payload: UpsertPlanUsersRequest): Promise<{ planCode: string; planUsers: PlanUsersResponse }> {
  const response = await request().url(PLAN_USERS_PATH(payload.planCode)).post(payload.planUsers).json();
  const areValidUsers = PlanUsersResponse.validate(response);

  if (!areValidUsers.success) {
    console.error(areValidUsers);
    throw new Error(areValidUsers.message);
  }

  return {
    planCode: payload.planCode,
    planUsers: areValidUsers.value,
  };
}

export async function deletePlanUsers(planCode: string, ids: string[]): Promise<DeleteResponse> {
  await request().url(PLAN_USERS_PATH(planCode)).query({ ids }).delete();

  return { planCode, ids };
}

export async function deletePlan(planCode: string): Promise<{ planCode: string }> {
  await request().url(`${PLANS_ROUTE}/${planCode}`).delete();

  return {
    planCode,
  };
}
