import { CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { Box, IconButton, Stack, Text, useColorModeValue, Image, Link, Icon, Circle } from "@chakra-ui/react";
import { formatDistanceToNow, parseISO } from "date-fns";
import type { FunctionComponent } from "react";
import { useContext } from "react";
import React from "react";
import { ConversationContext } from "../ConversationContext";
import { useCollectionKey, useConfigMap, useGetViewConfig, useProjectParams, useUserPreference } from "hooks";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import CharliLogoSmall from "screens/common/static/logos/charli-logo-small.png";
import { useNavigate } from "react-router-dom";
import { HiOutlineExternalLink } from "react-icons/hi";
import capitalize from "lodash/capitalize";

interface Props {
  collectionId?: string | undefined;
  title?: string | undefined;
  createdDate?: string | undefined;
  toggleJsonView: () => void;
}

const getTimeLabel = (isoDate: string) => {
  try {
    const date = parseISO(isoDate);
    return formatDistanceToNow(date, {
      addSuffix: true,
      includeSeconds: true,
    });
  } catch {
    return "just now";
  }
};

export const ThreadTitleBar: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
  const { parentRoute, projectFilter } = useProjectParams();
  const {
    setIsConversationDialogExpanded,
    isConversationOpen,
    onConversationClose,
    onConversationOpen,
    setCollectionId,
    setShowCommands,
    setConversationId,
  } = useContext(ConversationContext);
  const injectDebugEntityPreference = useUserPreference("ui_inject_debug_entity");
  const fontColor = useColorModeValue("gray.50", "gray.400");
  const bgColor = useColorModeValue("charli.primaryBlue", "gray.900");
  const charliLogoBgColor = useColorModeValue("chatButton.background", "gray.900");
  const chatButtonShadow = useColorModeValue("#CCCCCC", "#565656");
  const navigate = useNavigate();
  const { setIsNewMenuAction } = useAddToCharliContext();
  const collectionType = useCollectionKey(props.collectionId, "collectionType");
  const buttonTitle = useCollectionKey(props.collectionId, "name") ?? "Resource Collection";
  const configMap = useConfigMap();
  const projectRoute = useGetViewConfig("route", props.collectionId, configMap);

  const onHandleClose = () => {
    if (isConversationOpen) {
      setIsNewMenuAction(undefined);
      onConversationClose();
      setCollectionId("");
      setConversationId("");
      setShowCommands(false);
      setIsConversationDialogExpanded(false);
    } else {
      onConversationOpen();
    }
  };

  const onClick = () => {
    if (projectRoute && projectRoute.length > 0) {
      return navigate(`/${projectRoute}/${props.collectionId}`);
    } else {
      return undefined;
    }
  };

  return (
    <Stack spacing="1rem" direction="row" py=".75rem" px=".75rem" bgColor={bgColor} className="titleBar" cursor="move" align="center">
      {collectionType !== "due_diligence" && projectFilter !== "due_diligence" && parentRoute !== "portfolios" && parentRoute !== "home" && (
        <Circle size="3rem" bgColor={charliLogoBgColor} border="3px solid" borderColor={chatButtonShadow}>
          <Image src={CharliLogoSmall} alt="Charli Logo" height={"1.1rem"} />
        </Circle>
      )}
      <Stack isTruncated spacing="0" width="100%" backgroundColor={bgColor}>
        {props.collectionId && projectRoute ? (
          <Stack direction="row" spacing="1rem" onClick={onClick} cursor={"pointer"}>
            <Link fontSize="md" color={fontColor}>
              {buttonTitle}
            </Link>
            <Icon color={fontColor} as={HiOutlineExternalLink} pt="3px" />
          </Stack>
        ) : (
          <Text fontSize="md" color={fontColor}>
            {props.title ? capitalize(props.title.replace(/_/g, " ")) : "New Conversation"}
          </Text>
        )}
        <Text fontSize="10px" fontStyle="italic" color={fontColor}>
          Started {getTimeLabel(props.createdDate || Date.now().toString())}
        </Text>
      </Stack>
      <Stack direction="row" spacing="1rem">
        {injectDebugEntityPreference && (
          <Box>
            <IconButton
              size="sm"
              color={fontColor}
              width="2rem"
              aria-label="Debug Entity"
              variant="ghost"
              icon={<InfoOutlineIcon boxSize="1.1rem" />}
              _hover={{}}
              _active={{}}
              onClick={props.toggleJsonView}
            />
          </Box>
        )}
        <Box>
          <IconButton
            color={fontColor}
            size="sm"
            width="2rem"
            aria-label="Close"
            variant="ghost"
            icon={<CloseIcon />}
            _hover={{}}
            _active={{}}
            onClick={() => onHandleClose()}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
