import type { FunctionComponent } from "react";
import React from "react";
import { Divider, Stack, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";

interface Props {
  text: string;
  color?: string;
  underline?: boolean;
  className?: string;
  tooltip?: string;
  [key: string]: unknown;
}

export const LandingTitle: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  text,
  color,
  underline = false,
  className,
  tooltip,
  children,
  ...styles
}) => {
  const buttonColor = useColorModeValue("gray.700", "gray.400");

  return (
    <Stack {...styles} className={className} width="100%">
      <Stack direction="row" pb=".3rem" width="100%" justifyContent={"space-between"} align="center">
        <Stack maxW={children ? "calc(100% - 7rem)" : "100%"}>
          <Tooltip label={tooltip} aria-label="A tooltip">
            <Text fontWeight="semibold" isTruncated fontSize="md" minW={"10rem"} color={color || buttonColor} px=".5rem">
              {text}
            </Text>
          </Tooltip>
          {underline && <Divider marginTop={"0!important"} borderColor={color || buttonColor} />}
        </Stack>
        {children}
      </Stack>
    </Stack>
  );
};
