import React, { useCallback, useEffect } from "react";
import { Box, useColorModeValue, Badge, useBreakpointValue, Flex } from "@chakra-ui/react";
import { getStatusColor } from "screens/common/components/WorkflowSummary";
import { getWorkflowsAdmin } from "api/workflowsAdmin";
import capitalize from "lodash/capitalize";
import type { Workflow } from "types/workflows/workflow";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { WorkflowsFiltersContextProvider, useWorkflowsFiltersContext, WorkflowsFiltersPanel } from "screens/common/components";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { useInfiniteLoading } from "hooks";

const COLUMNS_WIDTH = 250;
const DATE_COLUMN_WIDTH = 400;
const STATUS_COLUMN_WIDTH = 180;
const PAGE_SIZE = 30;

const WorkflowsAdminBase = () => {
  const { usersInIds, usersOutIds, statusIn, intentsIn, collectionId } = useWorkflowsFiltersContext();
  const bgColor = useColorModeValue("gray.200", "gray.700");
  const titleColor = useColorModeValue("charli.lightGray", "gray.500");
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const loadItems = useCallback(
    async (token: string | null) => {
      const filters = {
        ...(usersInIds.length > 0 && { inUsersIds: usersInIds }),
        ...(usersOutIds.length > 0 && { outUsersIds: usersOutIds }),
        ...(statusIn.length > 0 && { inStatus: statusIn }),
        ...(intentsIn.length > 0 && { inIntents: intentsIn }),
        ...(collectionId && { collectionId }),
      };

      const res = await getWorkflowsAdmin({
        ...(token && { token }),
        limit: PAGE_SIZE,
        ...(Object.keys(filters).length > 0 && { filters }),
      });

      return { data: res.data, totalCount: res.totalCount, nextToken: res.nextToken };
    },
    [usersInIds, usersOutIds, statusIn, intentsIn, collectionId]
  );

  const {
    loading: isLoading,
    items,
    clear,
    lastMessageObserverRef,
    hasNextPage,
  } = useInfiniteLoading<Workflow>({
    loadItems,
  });

  const handleOnClick = (workflow: Workflow) => {
    navigate(`${workflow.id}${location.search}`);
  };

  useEffect(() => {
    clear();
  }, [usersInIds, usersOutIds, statusIn, intentsIn, clear]);

  return (
    <Box width="100%" fontSize="sm" px={isMobile ? ".5rem" : "1rem"} pt={isMobile ? ".5rem" : "1rem"} mt="0!important">
      <WorkflowsFiltersPanel isLoading={isLoading} />
      {/* Table header */}
      <Box
        fontSize={"small"}
        borderBottomColor={bgColor}
        borderBottom={`2px solid`}
        mb={1}
        color={titleColor}
        display={"flex"}
        justifyContent="space-between">
        <Box flex={1}>Intent</Box>
        <Box width={DATE_COLUMN_WIDTH}>Creation date</Box>
        <Box width={COLUMNS_WIDTH}>Created by</Box>
        <Box width={STATUS_COLUMN_WIDTH + 20}>Status</Box>
      </Box>

      {items.map((workflow) => (
        <Flex
          key={workflow.id}
          fontSize="sm"
          onClick={() => handleOnClick(workflow)}
          p={1}
          _hover={{ bgColor }}
          role="button"
          justifyContent="space-between">
          <Box flex={1} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
            {workflow.intent}
          </Box>
          <Box width={DATE_COLUMN_WIDTH}>{moment(new Date(workflow.creationDate)).format("dddd, MMMM Do YYYY, h:mm:ss a")}</Box>
          <Box width={COLUMNS_WIDTH}>{workflow.userName}</Box>
          <Box width={STATUS_COLUMN_WIDTH}>
            <Badge colorScheme={getStatusColor(workflow.status)}>{capitalize(workflow.status).split("_").join(" ")}</Badge>
          </Box>
        </Flex>
      ))}

      {hasNextPage && (
        <Flex visibility={isLoading ? "visible" : "hidden"} height={"3rem"} ref={lastMessageObserverRef} align="center" justify="center">
          <TypingIndicator />
        </Flex>
      )}

      <Outlet />
    </Box>
  );
};

export const WorkflowsAdmin = () => {
  return (
    <WorkflowsFiltersContextProvider>
      <WorkflowsAdminBase />
    </WorkflowsFiltersContextProvider>
  );
};
