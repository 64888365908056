import { String, Record } from "runtypes";
import type { Static } from "runtypes";

export const PlanUser = Record({
  firstName: String,
  lastName: String,
  userName: String,
  userId: String,
  userEmail: String,
  planCode: String,
  id: String,
});

export type PlanUser = Static<typeof PlanUser>;
