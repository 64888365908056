import { Box, Center, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import React, { useContext, useEffect, useRef } from "react";
import { CollectionsFilterContext } from "../collections/CollectionsFilterContext";
import { ItemLoadingIndicator } from "../collections/ItemLoadingIndicator";
import { NotesPanel } from "screens/content/common/notes/NotesPanel";
import { ContentViewPanel } from "screens/content/contentView/ContentViewPanel";
import { SharePanel } from "screens/panels/share/SharePanel";
import { VerifiedAIPanel } from "screens/content/contentView/VerifiedAIPanel";
import { useDispatch } from "react-redux";
import { deleteUserPreference } from "state/userPreference/operations";
import { useUserPreference } from "hooks";
import { ProjectLandingDefaultTile } from "./projectLandingTileLayouts/ProjectLandingDefaultTile";

interface Props {
  loadTilesCount?: number;
}

export const ProjectLandingTiles: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  loadTilesCount = 10,
}) => {
  const { collectionCount, setCollectionCount, filteredCollections, totalCollectionCount } = useContext(CollectionsFilterContext);
  const sectionRef = useRef<HTMLDivElement>(null);
  const bgColor = useColorModeValue("white", "gray.900");
  const dispatch = useDispatch();
  const userPreferenceSourceWeight = useUserPreference("source_weights_index") as 0 | 1;
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (loadTilesCount > 0) {
      setCollectionCount(loadTilesCount);
    }
  }, [loadTilesCount, setCollectionCount]);

  useEffect(() => {
    if (!userPreferenceSourceWeight) return;

    dispatch(
      deleteUserPreference({
        preferenceKey: "source_weights_index",
      })
    );
  }, [dispatch, userPreferenceSourceWeight]);

  const collectionsToRender = useMemo(() => {
    return filteredCollections.slice(0, collectionCount);
  }, [collectionCount, filteredCollections]);

  const lastMessageObserver = useCallback(
    (node: HTMLDivElement | null) => {
      if (!node) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setCollectionCount((prev) => prev + 5);
        }
      });

      observer.current.observe(node);
    },
    [setCollectionCount]
  );

  return (
    <Box ref={sectionRef} height="100%">
      <SimpleGrid spacing="1.3rem" columns={1}>
        {collectionsToRender.map((collection) => (
          <ProjectLandingDefaultTile key={collection.id} collectionId={collection.id} />
        ))}
      </SimpleGrid>
      <Box height="5px" width="100%" className="last-content-item" ref={lastMessageObserver} />
      {collectionsToRender.length && (
        <Box
          position={"fixed"}
          bottom={"0"}
          backgroundColor={bgColor}
          borderRadius="full"
          border={`1px solid #E2E8F0`}
          zIndex={2}
          mb="1rem"
          ml="3px">
          <Center height="100%">
            <ItemLoadingIndicator
              onClick={() => null}
              currentItemCount={collectionsToRender.length}
              totalItemCount={totalCollectionCount}
            />
          </Center>
        </Box>
      )}
      <ContentViewPanel />
      <SharePanel /> <NotesPanel /> <VerifiedAIPanel />
    </Box>
  );
};
