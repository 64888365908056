import React from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { CommonModal } from "./CommonModal";
import { Wizard } from "react-use-wizard";
import { SubscriptionUpgrade } from "screens/panels/settings/tabs/subscription/SubscriptionUpgrade";
import { SubscriptionUpgradeConfirm } from "screens/panels/settings/tabs/subscription/SubscriptionUpgradeConfirm";

export const SubscriptionModal = () => {
  const { isSubscriptionModalOpen, onSubscriptionModalClose } = useConversationContext();

  return (
    <CommonModal
      borderColor="#4799d4"
      minWidth={["90%", "60rem", "60rem"]}
      isOpen={isSubscriptionModalOpen}
      onClose={onSubscriptionModalClose}
      modalBody={
        <Wizard>
          <SubscriptionUpgrade upgradePlan={"business"} />
          <SubscriptionUpgradeConfirm upgradePlan={"business"} />
        </Wizard>
      }
    />
  );
};
