import { createSlice } from "@reduxjs/toolkit";
import type { ConfiguredWorkflow } from "types/configuredWorkflows";
import {
  approveConfiguredWorkflow,
  createConfiguredWorkflow,
  deleteConfiguredWorkflowAction,
  downloadConfiguredWorkflows,
  unapproveConfiguredWorkflow,
  updateConfiguredWorkflow,
  updateConfiguredWorkflowState,
} from "./operations";

interface ConfiguredWorkflowState {
  isLoading: boolean;
  order: string[];
  configuredWorkflows: { [key: string]: ConfiguredWorkflow };
}

const initialState: ConfiguredWorkflowState = {
  isLoading: false,
  order: [],
  configuredWorkflows: {},
};

export const { actions, reducer } = createSlice({
  name: "configuredWorkflow",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteConfiguredWorkflowAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteConfiguredWorkflowAction.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteConfiguredWorkflowAction.fulfilled, (state, action) => {
      state.isLoading = false;

      const { id } = action.payload;
      delete state.configuredWorkflows[id];
      state.order = state.order.filter((configId) => configId !== id);
    });

    builder.addCase(downloadConfiguredWorkflows.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadConfiguredWorkflows.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(downloadConfiguredWorkflows.fulfilled, (state, action) => {
      state.isLoading = false;

      const hashWorkflows = {};
      const workflowsIds: string[] = [];
      action.payload.forEach((cw) => {
        hashWorkflows[cw.id] = true;
        workflowsIds.push(cw.id);

        if (state.configuredWorkflows[cw.id]) {
          Object.assign(state.configuredWorkflows[cw.id], cw);
        } else {
          state.configuredWorkflows[cw.id] = cw;
        }
      });

      state.order.forEach((id) => {
        if (!hashWorkflows[id]) {
          delete state.configuredWorkflows[id];
        }
      });

      state.order = workflowsIds;
    });

    builder.addCase(createConfiguredWorkflow.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createConfiguredWorkflow.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createConfiguredWorkflow.fulfilled, (state, action) => {
      state.isLoading = false;
      const { payload: newConfiguredWorkflow } = action;

      state.configuredWorkflows[newConfiguredWorkflow.id] = newConfiguredWorkflow;
      state.order = [newConfiguredWorkflow.id, ...state.order];
    });

    builder.addCase(updateConfiguredWorkflow.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateConfiguredWorkflow.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateConfiguredWorkflow.fulfilled, (state, action) => {
      state.isLoading = false;
      const { payload: updatedConfiguredWorkflow } = action;

      state.configuredWorkflows[updatedConfiguredWorkflow.id] = updatedConfiguredWorkflow;
    });

    builder.addCase(approveConfiguredWorkflow.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(approveConfiguredWorkflow.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(approveConfiguredWorkflow.fulfilled, (state, action) => {
      state.isLoading = false;
      const { payload: approvedConfiguredWorkflow } = action;

      if (!approvedConfiguredWorkflow) {
        return;
      }

      state.configuredWorkflows[approvedConfiguredWorkflow.id] = approvedConfiguredWorkflow;
    });

    builder.addCase(unapproveConfiguredWorkflow.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(unapproveConfiguredWorkflow.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(unapproveConfiguredWorkflow.fulfilled, (state, action) => {
      state.isLoading = false;
      const { payload: unapprovedConfiguredWorkflow } = action;

      if (!unapprovedConfiguredWorkflow) {
        return;
      }

      state.configuredWorkflows[unapprovedConfiguredWorkflow.id] = unapprovedConfiguredWorkflow;
    });

    builder.addCase(updateConfiguredWorkflowState.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateConfiguredWorkflowState.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateConfiguredWorkflowState.fulfilled, (state, action) => {
      state.isLoading = false;
      const { payload } = action;

      state.configuredWorkflows[payload.id] = payload;
    });
  },
});

export default reducer;
