import { Box } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useEffect, useRef, useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router";
import type { ContentDetails } from "types/content/ContentDetails";
import { getSortedContentCells } from "screens/content/common/utils";
import { ContentFilterContext } from "screens/content";
import { useLocation } from "react-router-dom";
import { getViewConfig } from "configs/configMap";
import { useConfigMap } from "hooks";
import { ContentCellTable } from "./ContentCellTable";
import { ContentCellTableRow } from "./ContentCellTableRow";
import { hasProjectOutput } from "screens/collection/components/utils";

interface Props {
  availableContent: ContentDetails[];
}

export const ContentCanvasGrid: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ availableContent }) => {
  const navigate = useNavigate();
  const sectionRef = useRef<HTMLDivElement>(null);
  const { itemCount, setItemCount } = useContext(ContentFilterContext);
  const mounted = useRef<boolean>(false);
  const loader = useRef(null);
  const configMap = useConfigMap();
  const { pathname } = useLocation();
  const isSearchRoute = pathname.includes("search/");

  const contentToRender = useMemo(() => {
    const sortedContent = getSortedContentCells(availableContent, "Date", "DESC");
    const filteredContent = sortedContent.filter((content) => !hasProjectOutput(content));
    return isSearchRoute ? sortedContent : filteredContent;
  }, [availableContent, isSearchRoute]);

  const handleOnClick = useCallback(
    (content: ContentDetails) => {
      const { type, collectionType, id } = content;
      const projectRoute = getViewConfig("route", collectionType || "", configMap);
      if (type === "collection") {
        return navigate(`/${projectRoute}/${id}`);
      } else {
        navigate(`${pathname}/${id}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );

  const handleObserver = useCallback(
    (entries: any) => {
      const [target] = entries;
      if (target.isIntersecting) {
        if (contentToRender.length > itemCount) {
          setItemCount(itemCount + 5);
        }
      }
    },
    [contentToRender.length, itemCount, setItemCount]
  );

  useEffect(() => {
    mounted.current = true;
    const loaderCurrent = loader.current;
    const option = {
      threshold: 1,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loaderCurrent) observer.observe(loaderCurrent);

    return () => {
      mounted.current = false;
      if (loaderCurrent) observer.unobserve(loaderCurrent);
    };
  }, [handleObserver]);

  const itemsToRender = useMemo(() => {
    return contentToRender.slice(0, itemCount);
  }, [itemCount, contentToRender]);

  return (
    <Box ref={sectionRef} pb="2rem">
      <ContentCellTable>
        {itemsToRender.map((contentItem) => (
          <ContentCellTableRow
            key={`${contentItem.id}-content-cell`}
            contentData={contentItem}
            onClick={() => handleOnClick(contentItem)}
          />
        ))}
      </ContentCellTable>
      <Box className="last-content-item" ref={loader} />
    </Box>
  );
};
