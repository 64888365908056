import React from "react";
import { OptionsMenuItem } from "screens/collection/components/OptionsMenuItem";
import { IntegrationURN } from "types/integration";
import { request } from "api/request";

interface Props {
  downloadUrl: string;
  integrationUrn: string;
  resourceName: string;
  isDisabled?: boolean;
  menuLabel?: string;
  className?: string;
}

export const ProjectReportPanelDownloadMenuItem = ({
  downloadUrl,
  integrationUrn,
  resourceName,
  isDisabled = false,
  menuLabel = "Download Report",
  className = "ch-download-report",
}: Props) => {
  const onHandleDownload = () => {
    if (!downloadUrl) return;
    if (integrationUrn === IntegrationURN.charliCloudStorage) {
      request()
        .url(downloadUrl)
        .get()
        .blob((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          console.log(url, downloadUrl);

          link.href = url;
          link.setAttribute("download", resourceName || "file");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      window.open(downloadUrl, "_blank");
    }
  };

  return (
    <OptionsMenuItem
      menuLabel={menuLabel}
      className={className}
      isDisabled={isDisabled}
      onClick={(event) => {
        event.stopPropagation();
        onHandleDownload();
      }}
    />
  );
};
