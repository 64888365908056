import React, { useContext, useMemo } from "react";
import { Icon, Tooltip, IconButton } from "@chakra-ui/react";
import { useButtonProps } from "hooks";

import { ContentFilterContext } from "../body/ContentFilterContext";
import { VscClearAll } from "react-icons/vsc";

export const ContentCanvasClearFilterButton = () => {
  const { selectedExtractedContentTypes, searchText, selectedTags, resetFilter, setSearchText } = useContext(ContentFilterContext);
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const primaryButtonProps = useButtonProps("sm", "primary");

  const isContentFiltered = useMemo(() => {
    return searchText !== "" || selectedExtractedContentTypes.length > 0 || selectedTags.length > 0;
  }, [searchText, selectedExtractedContentTypes, selectedTags]);

  return (
    <Tooltip label={isContentFiltered ? "Clear Filter" : ""}>
      <IconButton
        isDisabled={!isContentFiltered}
        {...(isContentFiltered ? { ...primaryButtonProps } : { ...secondaryButtonProps })}
        className="ch-clear-filter-btn"
        onClick={() => {
          resetFilter();
          setSearchText("");
        }}
        aria-label="Clear Filters"
        icon={<Icon as={VscClearAll} fontSize=".9rem" />}></IconButton>
    </Tooltip>
  );
};
