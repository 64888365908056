import React, { useContext, useEffect, useState } from "react";
import { AutocompleteInput } from "screens/collection/views/AutocompleteInput";
import type { AutocompleteInputProps } from "screens/collection/views/AutocompleteInput";
import { useConversationContext } from "screens/thread/ConversationContext";
import { Box, Flex } from "@chakra-ui/react";
import {
  useCollectionKey,
  useConfigMap,
  useConversation,
  useEntitlementKey,
  useGetViewConfig,
  useLatestCollectionWorkflowId,
  useUserPreference,
} from "hooks";
import { SemanticFocusedAnswer } from "screens/collection/views/SemanticFocusedAnswer";
import { useQASourceListFunction } from "hooks/useQASourceList";
import { useWorkflowCompletionDateOrNow, useWorkflowKey } from "hooks/useWorkflows";
import { WorkflowProgressBar } from "screens/landing/components/WorkflowProgressBar";
import { formatDistanceToNow } from "date-fns";
import { AutocompleteSourceContextProvider, useAutocompleteSourceContext } from "../../contexts/AutocompleteSourceContext";
import type { SuggestedQuestions } from "api/suggestions";
import { v4 as uuid } from "uuid";
import { InputBarDropzoneContext } from "screens/conversation/components/InputBar/InputBarDropzoneContext";
import { useAvailableCommands, useCurrentCollectionQuestions } from "screens/collection/views/AutoCompleteInputUtils";
import { useProjectDetailAutocompleteInputUtils } from "./ProjectDetailAutocompleteInputUtils";

interface Props extends Omit<AutocompleteInputProps, "onSelectOption" | "value" | "onChange"> {
  collectionType?: string;
  collectionId?: string;
  className?: string;
}

export const ProjectDetailAutocompleteInputInner = ({ initialText, collectionType, collectionId, className }: Props) => {
  const { onConversationOpen, isConversationOpen } = useConversationContext();
  const { getSourceList } = useAutocompleteSourceContext();
  const [sourcesList, setSourcesList] = useState<SuggestedQuestions>([]);
  const collectionConversationId = useCollectionKey(collectionId, "conversationId") || uuid();
  const currentWorkflowId = useLatestCollectionWorkflowId(collectionId);
  const [showCommandSuggestions, setShowCommandSuggestions] = useState(false);
  const workflowStatus = useWorkflowKey(currentWorkflowId, "status");
  const currentWorkflowCompletionDate = useWorkflowCompletionDateOrNow(currentWorkflowId);
  const hideCommandLineButton = (useUserPreference("ui_hide_command_line_button") as boolean) || false;
  const [questionText, setQuestionText] = useState("");
  const { files } = useContext(InputBarDropzoneContext);
  const [isAskingQuestion, setIsAskingQuestion] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const conversation = useConversation(collectionConversationId);

  const {
    selectedFocus,
    handleUpdateSourceWeightPreference,
    selectedSourceWeightsIndex,
    answerFocusOptions,
    onSubmitQuestion,
    onResetQuestion,
    sendInputBarMessage,
  } = useProjectDetailAutocompleteInputUtils({
    collectionType,
    collectionId,
    setQuestionText,
  });
  const hasCommandLine = useEntitlementKey("ui_enable_command_suggestions");
  const { filterCommandsMemo } = useAvailableCommands(questionText, hideCommandLineButton || !hasCommandLine);
  const { filterQuestionsByFocusMemo } = useCurrentCollectionQuestions(sourcesList, collectionId);

  useEffect(() => {
    setShowCommandSuggestions(questionText.startsWith("/") || files.length > 0 || conversation.conversationState === "action");
  }, [conversation.conversationState, files.length, questionText]);

  useEffect(() => {
    if (workflowStatus === "in_progress") {
      setIsAskingQuestion(true);
    } else {
      setIsAskingQuestion(false);
      setQuestionText("");
    }
  }, [workflowStatus, setQuestionText]);

  useEffect(() => {
    setIsLoading(true);
    getSourceList()
      .then((response) => setSourcesList(response))
      .catch((error) => {
        console.error("Error fetching source list", error);
      })
      .finally(() => setIsLoading(false));
  }, [getSourceList]);

  return (
    <>
      <AutocompleteInput
        className={className}
        minWidth="22rem"
        conversationId={collectionConversationId}
        value={questionText}
        onChange={(value) => setQuestionText(value)}
        onReset={onResetQuestion}
        initialText={initialText}
        suggestedQuestions={
          showCommandSuggestions ? filterCommandsMemo : conversation.conversationState === "in_progress" ? [] : filterQuestionsByFocusMemo
        }
        onSelectOption={({ question, focus }) => {
          setQuestionText(question);
          if (focus) {
            handleUpdateSourceWeightPreference(focus === "analytical" ? 0 : 1);
          }
        }}
        onClickSubmit={() => {
          if (showCommandSuggestions) {
            sendInputBarMessage(questionText);
            setQuestionText("");
            onConversationOpen(collectionConversationId);
          } else {
            onSubmitQuestion(questionText, selectedFocus);
            setQuestionText("");
          }
        }}
        isLoading={isLoading || isAskingQuestion}
        conversationState={conversation.conversationState}
      />
      <Box height="1rem" width="100%" mt="5px">
        {!isConversationOpen && currentWorkflowId && workflowStatus === "in_progress" ? (
          <Box pr="1rem" pl="10rem" justifyContent={"end"} width="100%">
            <WorkflowProgressBar
              workflowId={currentWorkflowId}
              lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
                addSuffix: true,
                includeSeconds: false,
              })}
              size="compact"
              showInline={false}
            />
          </Box>
        ) : answerFocusOptions ? (
          <Flex justifyContent={"flex-end"}>
            <SemanticFocusedAnswer
              answerFocusOptions={answerFocusOptions}
              selectedSourceWeightsIndex={selectedSourceWeightsIndex}
              onSourceWeightPreferenceChange={(index) => handleUpdateSourceWeightPreference(index)}
            />
          </Flex>
        ) : null}
      </Box>
    </>
  );
};

export const ProjectDetailAutocompleteInput = (props: Props) => {
  const { collectionType } = props;
  const configMap = useConfigMap();
  const questionInputDetail = useGetViewConfig("projectDetailQAInput", collectionType, configMap);
  const getSourceList = useQASourceListFunction(questionInputDetail?.source);

  return (
    <AutocompleteSourceContextProvider getSourceList={getSourceList}>
      <ProjectDetailAutocompleteInputInner {...props} />
    </AutocompleteSourceContextProvider>
  );
};
