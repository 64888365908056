import { getTickersQuery } from "api/tickers";
import type { Ticker } from "api/tickers/models/Ticker";
import { useEffect, useState } from "react";

export const useTickerDataBySymbol = (symbol?: string): Ticker | undefined => {
  const [tickerData, setTickerData] = useState<Ticker | undefined>(undefined);

  useEffect(() => {
    if (!symbol) {
      return;
    }

    getTickersQuery({ query: symbol, limit: 1 })
      .then((data) => setTickerData(data[0]))
      .catch((error) => {
        console.error("Error fetching ticker data", error);
      });
  }, [symbol]);

  return tickerData;
};
