import React, { useMemo, useState } from "react";
import {
  useColorModeValue,
  Text,
  useBreakpointValue,
  Td,
  Tr,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Center,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import type { CollectionWithAdditionalProps } from "types/collection";
import { useContents, useProjectParams } from "hooks";
import { getDateFromContent, getExtension, getParentCellType } from "screens/content/common/utils";
import { formatDate } from "screens/common/modal/formatters";
import type { ContentDetails } from "types/content/ContentDetails";
import { useConversationContext } from "screens/thread/ConversationContext";
import { FileTypeBadge } from "screens/common/components";
import { IoEllipsisVertical } from "react-icons/io5";
import { OptionsMenuItem } from "screens/collection/components/OptionsMenuItem";
import { ProjectReportPanelDeleteModal } from "./ProjectReportPanelDeleteModal";
import { ProjectReportPanelDownloadMenuItem } from "./ProjectReportPanelDownloadMenuItem";
import { hasProjectOutput } from "screens/collection/components/utils";
import { IntegrationURN } from "types/integration";
import { request } from "api/request";

interface Props {
  collection: CollectionWithAdditionalProps;
}

export const ProjectReportPanelTableContent = ({ collection }: Props) => {
  const { contentId } = useProjectParams();
  const textColor = useColorModeValue("primary.darkGray", "gray.300");
  const secondaryTextColor = useColorModeValue("primary.darkGray", "gray.400");
  const collectionMetadataIds = useMemo(() => collection?.metadataIds && collection?.metadataIds, [collection?.metadataIds]);
  const contentData = useContents(collectionMetadataIds || []);
  const { conversationId } = useConversationContext();
  const currentConversationId = contentId ? contentId : conversationId;
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { isOpen: isDeleteReport, onOpen: onOpenDeleteReport, onClose: onCloseDeleteReport } = useDisclosure();
  const [isSelectedContent, setIsSelectedContent] = useState<ContentDetails | undefined>(undefined);

  const contentDataFiltered = useMemo(() => {
    const filtered = contentData?.filter((content) => {
      return hasProjectOutput(content);
    });
    const sorted = filtered?.sort((a: ContentDetails, b: ContentDetails) => {
      return new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime();
    });
    return sorted;
  }, [contentData]);

  const onHandleDownload = (contentData: ContentDetails) => {
    if (!contentData.urls?.downloadUrl) return;
    if (contentData.integrationUrn === IntegrationURN.charliCloudStorage) {
      request()
        .url(contentData.urls.downloadUrl)
        .get()
        .blob((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", contentData.name || "file");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      window.open(contentData.urls.downloadUrl, "_blank");
    }
  };

  return (
    <>
      {contentDataFiltered &&
        contentDataFiltered.slice(1).map((contentData, index) => (
          <Tr cursor={"pointer"} key={index} onClick={() => onHandleDownload(contentData)}>
            <Td p=".5rem">
              <Tooltip label="Download Report" aria-label="Download Report">
                <Text wordBreak="break-word" fontSize="sm" className="file-name" textColor={textColor}>
                  {contentData.name && contentData.name}
                </Text>
              </Tooltip>
            </Td>
            {!isMobile && (
              <Td p=".5rem">
                <Center>
                  <FileTypeBadge
                    width="1.2rem"
                    height="1.6rem"
                    hideExtensionLabel
                    fileTypeName={contentData.collectionType}
                    extension={getExtension(contentData)}
                  />
                </Center>
              </Td>
            )}
            {!isMobile && (
              <Td p=".5rem">
                <Text wordBreak="break-word" fontWeight="light" fontSize="sm" textColor={secondaryTextColor}>
                  {formatDate(getDateFromContent(contentData), `${isMobile ? "d-MM-yy" : "d MMMM yyyy"}`)}
                </Text>
              </Td>
            )}
            <Td p=".5rem">
              <Center>
                <Menu isLazy>
                  <MenuButton
                    _hover={{}}
                    _active={{}}
                    as={IconButton}
                    aria-label="Options"
                    icon={<Icon as={IoEllipsisVertical} _active={{}} _hover={{}} />}
                    variant="ghost"
                    onClick={(event) => event.stopPropagation()}
                  />
                  <MenuList minWidth={"unset"}>
                    <ProjectReportPanelDownloadMenuItem
                      downloadUrl={contentData.urls?.downloadUrl || ""}
                      integrationUrn={contentData.integrationUrn || ""}
                      resourceName={contentData.name || ""}
                      isDisabled={!contentData.urls?.downloadUrl}
                    />
                    <OptionsMenuItem
                      menuLabel={"Delete Report"}
                      className="ch-delete-record"
                      onClick={(event) => {
                        event.stopPropagation();
                        setIsSelectedContent(contentData);
                        onOpenDeleteReport();
                      }}
                    />
                  </MenuList>
                </Menu>
              </Center>
            </Td>
          </Tr>
        ))}
      {isSelectedContent && (
        <ProjectReportPanelDeleteModal
          onClose={onCloseDeleteReport}
          isOpen={isDeleteReport}
          collectionId={collection.id}
          conversationId={currentConversationId}
          mediaId={isSelectedContent.mediaId}
          parentCellTypeEntityName={getParentCellType(isSelectedContent) + "_id"}
          reason={"Deleted by user"}
          reportName={isSelectedContent.name}
        />
      )}
    </>
  );
};
