import { prependProxyBaseUrl, request } from "api/request";

const INTEGRATIONS_ENDPOINT = prependProxyBaseUrl("/api/integrations/integrations");

export interface Integration {
  integrationId: string;
  name: string;
  description: string;
  domain: string | undefined;
  isEnabled: boolean;
  isAvailable: boolean;
  isInBeta: boolean;
  isUserEntitled: boolean;
  isPreferred: boolean;
  isReauthRequired: boolean;
  minimumPlan?: string;
  category?: string;
  urn?: string;
  categoryKey: string;
  isImplicitlyEnabled: boolean;
}

export async function getSupportedIntegrations() {
  return (await request().url(INTEGRATIONS_ENDPOINT).get().json()) as Integration[];
}
