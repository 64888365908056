import { Stack, Text, Box } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useEffect, useCallback } from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { ArrowContainer, Popover } from "react-tiny-popover";
import { useTileProps } from "hooks";

interface Props {
  title?: string;
  message?: string;
  position?: "top" | "bottom" | "left" | "right";
}

const HelpPopover: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  title,
  message,
  position,
  children,
}) => {
  const { showHelpPopups, setShowHelpPopups } = useConversationContext();
  const commonTileProps = useTileProps();

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowHelpPopups(false);
      }
    },
    [setShowHelpPopups]
  );

  useEffect(() => {
    if (!showHelpPopups) return;
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown, showHelpPopups]);

  return (
    <Popover
      isOpen={showHelpPopups}
      padding={10}
      positions={position || "top"}
      reposition
      onClickOutside={() => setShowHelpPopups(false)}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={"#c8bd01"}
          arrowSize={10}
          arrowStyle={{ opacity: 0.7 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow">
          <Stack {...commonTileProps} spacing="5px" borderColor="#c8bd01" bgColor={"#fffde3"} maxWidth="20rem">
            <Text fontSize="sm" fontWeight="bold">
              {title}
            </Text>
            <Text fontSize="sm">{message}</Text>
          </Stack>
        </ArrowContainer>
      )}>
      <Box>{children as React.ReactElement}</Box>
    </Popover>
  );
};

export default HelpPopover;
