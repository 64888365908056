import { prependProxyBaseUrl, request } from "./request";
import { track } from "api/analytics";
import { EMAIL_WAS_VERIFIED } from "api/analytics/events";
import type { User } from "../types/auth";
import { GetUsersRequest } from "./users/models/GetUsersRequest";

const USER_ENDPOINT = prependProxyBaseUrl("/api/user");
const RESEND_VERIFICATION_EMAIL_ENDPOINT = prependProxyBaseUrl("/api/resend-verification-email");
const SEND_PASSWORD_RESET_EMAIL_ENDPOINT = prependProxyBaseUrl("/api/send-password-reset-email");
const RESET_PASSWORD_ENDPOINT = prependProxyBaseUrl("/api/reset-password");
const USER_SERVICE_PROXY_ENDPOINT = prependProxyBaseUrl("/api/user-service");
const CREATE_ADMIN_USERS_ENDPOINT = prependProxyBaseUrl("/api/admin/register-user");

export async function updateUser({ firstName, lastName }: { firstName: string; lastName: string }) {
  await request().url(USER_ENDPOINT).json({ firstName, lastName }).put().res();
}

export async function verifyEmail({ userId, email, verificationKey }: { userId: string; email: string; verificationKey: string }) {
  try {
    await request()
      .url(`${USER_SERVICE_PROXY_ENDPOINT}/users/${userId}/emails/${email}/verify-email`)
      .json({ verificationKey })
      .post()
      .res();

    track(EMAIL_WAS_VERIFIED);

    return true;
  } catch (error) {
    return false;
  }
}

export async function generateVerificationKey() {
  try {
    await request().url(`${RESEND_VERIFICATION_EMAIL_ENDPOINT}`).post().res();

    return true;
  } catch (error) {
    return false;
  }
}

export async function sendPasswordResetEmail(email: string) {
  try {
    await request().url(`${SEND_PASSWORD_RESET_EMAIL_ENDPOINT}`).post({ email }).res();

    return true;
  } catch (error) {
    console.error(error);

    return false;
  }
}

export async function resetPassword(code: string, password: string) {
  try {
    await request().url(`${RESET_PASSWORD_ENDPOINT}`).post({ code, password }).res();

    return true;
  } catch (error) {
    console.error(error);

    return false;
  }
}

export async function deleteUser({ dataOnly }: { dataOnly: boolean }) {
  await request()
    .url(USER_ENDPOINT)
    .json({
      preserveUserServiceData: dataOnly,
      preserveTokens: dataOnly,
    })
    .delete()
    .res();
}

export async function listUsers(search?: string, returnOrganizations?: boolean) {
  const queryParam = { ...(returnOrganizations ? { fetchOrganizations: true } : {}), ...(search ? { nameOrEmail: search } : {}) };
  const users = await request().url(`${USER_SERVICE_PROXY_ENDPOINT}/users/all`).query(queryParam).get().json();

  return users as User[];
}

export async function getUsersByIds(ids: string[]) {
  const users = await request()
    .url(`${USER_SERVICE_PROXY_ENDPOINT}/users/all`)
    .query({ ids: ids.join(",") })
    .get()
    .json();

  const validateResponse = GetUsersRequest.validate(users);

  if (!validateResponse.success) {
    throw new Error(validateResponse.message);
  } else {
    return validateResponse.value;
  }
}

export async function fetchUserCredentialType(userId: string): Promise<{ identityProvider: string; userId: string; userName: string }[]> {
  return await request().url(`${USER_ENDPOINT}/${userId}/credentials-type`).get().json();
}

export async function forceVerifyUser(userId: string, email: string) {
  return await request().url(`${USER_SERVICE_PROXY_ENDPOINT}/users/${userId}/emails/${email}/force-verify-email`).post().res();
}

export async function createAdminUsers(users: { firstName: string; lastName: string; email: string }[]): Promise<string[]> {
  const results = await Promise.allSettled(users.map((user) => request().url(CREATE_ADMIN_USERS_ENDPOINT).post(user).json<string>()));
  return results.flatMap((result) => {
    if (result.status === "fulfilled") {
      return [...result.value];
    }

    return [];
  });
}
