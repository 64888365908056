import { Box, Icon, Stack, Tooltip, useBreakpointValue, useColorModeValue, useDisclosure, useToast } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useEffect, useContext, useState, useCallback } from "react";
import { BiExpand } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { LoadingGate, ModalView, ToastMessageContent } from "screens/common/components";
import { sendMessage } from "state/websocket/operations";
import type * as collection from "types/collection";
import type { TableContent } from "types/content/TableContent";
import { ConversationContext } from "screens/thread/ConversationContext";
import { v4 as uuid } from "uuid";
import { MultiColumnTableEditable } from "../../common/TwoColumnTable/MultiColumnTableEditable";
import { BlockSectionHeader } from "../previewSection/BlockSectionHeader";
import type { ContentDetails } from "types/content/ContentDetails";

interface Props {
  itemContent: ContentDetails;
  collection?: collection.CollectionWithAdditionalProps;
  sendMetadata: (text: string, type: string) => void;
}

export const ContentMultiColumnTable: FunctionComponent<
  React.PropsWithChildren<React.PropsWithChildren<Props & collection.CharliUIViewAsCollectionItem>>
> = ({ itemContent, collection, sendMetadata }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const tileBorderColor = useColorModeValue("gray.400", "gray.600");
  const additionalThumbnailCountTextColor = useColorModeValue("gray.500", "gray.400");
  const [multiColumnTablesJson, setMultiColumnTablesJson] = useState<TableContent[]>();
  const [multiColumnTablesJsonTemp, setMultiColumnTablesJsonTemp] = useState<TableContent[] | undefined>();
  const [showTableLoader, setShowTableLoader] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { isOpen: isMultiTableOpen, onOpen: onMultiTableOpen, onClose: onMultiTableClose } = useDisclosure();
  const { onConversationOpen, contentId } = useContext(ConversationContext);

  useEffect(() => {
    if (itemContent?.tables && itemContent.tables.length > 0) {
      setMultiColumnTablesJson(itemContent.tables);
    }
  }, [itemContent]);

  const onCloseMultiTable = () => {
    setIsEditing(false);
    onMultiTableClose();
  };

  const saveTableData = () => {
    if (!multiColumnTablesJsonTemp) return;
    setMultiColumnTablesJson(multiColumnTablesJsonTemp);
    sendMetadata(JSON.stringify(multiColumnTablesJsonTemp), "extracted_table_info");
    setIsEditing(false);
  };

  const updateMultiColumnTablesJson = useCallback((tableContent: TableContent[]) => {
    setMultiColumnTablesJsonTemp(tableContent);
    setIsEditing(true);
  }, []);

  // publish the table data
  const publishTableData = () => {
    if (!itemContent) return;

    const entities: {
      entity: string;
      value: string;
    }[] = [];
    if (itemContent?.mediaId) {
      entities.push({ entity: "file_id", value: itemContent?.mediaId });
    }

    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={"I will publish these tables now. You can open the conversation about it by clicking this message."}
          onClick={() => {
            onConversationOpen();
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
    dispatch(
      sendMessage({
        conversationId: contentId ? contentId : collection?.conversationId || uuid(),
        message: "Publish this cap table",
        intent: "/publish_cap_table",
        entities: entities,
      })
    );
  };

  useEffect(() => {
    if (isMultiTableOpen) {
      setMultiColumnTablesJsonTemp(multiColumnTablesJson);
    } else {
      setMultiColumnTablesJsonTemp(undefined);
    }
  }, [isMultiTableOpen, multiColumnTablesJson]);

  return (
    <>
      {itemContent?.tables && itemContent.tables.length > 0 && (
        <>
          <BlockSectionHeader title="Extracted Tables" direction="row">
            <Tooltip aria-label="Expand and Edit" label="Expand and Edit" placement="top" hasArrow>
              <Box>
                <Icon
                  cursor="pointer"
                  as={BiExpand}
                  color={tileBorderColor}
                  boxSize="1rem"
                  _hover={{ color: additionalThumbnailCountTextColor }}
                  onClick={(event) => {
                    onMultiTableOpen();
                    setTimeout(() => {
                      setShowTableLoader(true);
                    }, 2000);
                    event.stopPropagation();
                  }}
                />
              </Box>
            </Tooltip>
          </BlockSectionHeader>
          <Stack spacing="1.5rem" pt={isMobile ? "1rem" : "unset"}>
            <MultiColumnTableEditable tablesData={multiColumnTablesJson} isTabs />
          </Stack>
          {isMultiTableOpen && multiColumnTablesJsonTemp && (
            <ModalView
              isEditing={isEditing}
              onClose={onCloseMultiTable}
              isOpen={isMultiTableOpen}
              onSave={() => saveTableData()}
              onPublish={itemContent.category === "Cap Table" ? () => publishTableData() : undefined}>
              <LoadingGate isLoading={!showTableLoader} height="calc(75vh)">
                <Box height="100%" width="100%" maxH={"75vh"}>
                  <MultiColumnTableEditable
                    isModal
                    tablesData={multiColumnTablesJsonTemp}
                    isEditEnabled
                    onEditTableData={updateMultiColumnTablesJson}
                    isTabs
                  />
                </Box>
              </LoadingGate>
            </ModalView>
          )}
        </>
      )}
    </>
  );
};
