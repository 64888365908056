import React, { useState, useEffect } from "react";
import { Box, useColorModeValue, usePrevious, useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";

// Only warn user there has been a disconnection once meaningful time has elapsed.
// Will remain silent about disconnection and reconnection when below threshold.
const DISCONNECTION_TIME_THRESHOLD_TO_SHOW_MESSAGE = 5000; // 5 seconds

// Disconnection and reconnection is notified via a message which disappears after the specified time.
const DISCONNECTION_MESSAGE_DURATION = 4000; // 4 seconds
const RECONNECTION_MESSAGE_DURATION = 4000; // 4 seconds

const DISCONNECTED_TOAST_KEY = "disconnected-toast-key";
const CONNECTED_TOAST_KEY = "connected-toast-key";

export const WebsocketNotifier = () => {
  const isWebsocketConnected = useSelector((state: RootState) => state.websocket.isConnected);
  const didWebsocketPreviouslyConnect = usePrevious(isWebsocketConnected);
  const [showToast, setShowToast] = useState(false);
  const toast = useToast();
  const primaryTextColor = useColorModeValue("gray.700", "gray.300");
  const bgColorDisconnect = useColorModeValue("#f9e4dc", "gray.800");
  const bgColor = useColorModeValue("#cbe0d0", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.900");

  useEffect(() => {
    // If the websocket disconnects set a timer so that if it stays disconnected for a meaningful time it can be later reported.
    if (isWebsocketConnected === false && didWebsocketPreviouslyConnect && !showToast) {
      const timeout = setTimeout(() => {
        setShowToast(true);
      }, DISCONNECTION_TIME_THRESHOLD_TO_SHOW_MESSAGE);
      return () => clearTimeout(timeout);
    }
  }, [isWebsocketConnected, didWebsocketPreviouslyConnect, showToast]);

  useEffect(() => {
    // Don't do anything if we haven't been disconnected for a meaningful time duration.
    if (!showToast) return;

    // Notify user they have been disconnected
    if (!isWebsocketConnected) {
      toast.close(DISCONNECTED_TOAST_KEY);
      toast.close(CONNECTED_TOAST_KEY);

      toast({
        id: DISCONNECTED_TOAST_KEY,
        render: ({ onClose }) => (
          <Box
            mb=".5rem"
            boxShadow={"lg"}
            cursor={"pointer"}
            borderColor={borderColor}
            onClick={onClose}
            fontSize="xs"
            py="5px"
            px="10px"
            color={primaryTextColor}
            backgroundColor={bgColorDisconnect}
            borderRadius="full">
            You've been disconnected from Charli. The connection will automatically re-establish soon.
          </Box>
        ),
        duration: DISCONNECTION_MESSAGE_DURATION,
        isClosable: true,
        position: "bottom",
      });
    }

    // Notify user when connection is re-established
    if (isWebsocketConnected && didWebsocketPreviouslyConnect === false) {
      toast.close(DISCONNECTED_TOAST_KEY);
      toast.close(CONNECTED_TOAST_KEY);
      toast({
        render: ({ onClose }) => (
          <Box
            mb=".5rem"
            boxShadow={"lg"}
            cursor={"pointer"}
            borderColor={borderColor}
            onClick={onClose}
            fontSize="xs"
            py="5px"
            px="10px"
            color={primaryTextColor}
            backgroundColor={bgColor}
            borderRadius="full">
            Connection re-established with Charli.
          </Box>
        ),
        duration: RECONNECTION_MESSAGE_DURATION,
        isClosable: true,
        position: "bottom",
        containerStyle: {
          minWidth: "unset",
        },
      });

      // If we get disconnected again we want the disconnection to last for a meaningful time duration before we notify the user
      setShowToast(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWebsocketConnected, didWebsocketPreviouslyConnect, toast, showToast]);

  return <React.Fragment />;
};
