import { useCallback, useMemo } from "react";
import type { AutocompleteInputProps } from "screens/collection/views/AutocompleteInput";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useCollectionKey, useEntitlements, useFeatureFlags } from "hooks";
import { useDispatch } from "react-redux";
import { useAnswerFocusOptions } from "hooks/useAnswerFocusOptions";
import { updateTypedUserPreference } from "state/userPreference/operations";
import type { RequestEntities } from "types/charliui";
import { sendMessage } from "state/websocket/operations";
import { useFeatureUsage } from "hooks/useFeatureUsage";
import { Intent } from "types/intent";
import { useInputBar } from "screens/conversation/components/InputBar/useInputBar";
import { v4 as uuid } from "uuid";

interface Props extends Omit<AutocompleteInputProps, "onSelectOption" | "value" | "onChange"> {
  collectionType?: string;
  collectionId?: string;
  setQuestionText: (questionText: string) => void;
}

export const useProjectDetailAutocompleteInputUtils = ({ collectionType, collectionId, setQuestionText }: Props) => {
  const { onConversationOpen, onUpgradeModalOpen } = useConversationContext();
  const dispatch = useDispatch();
  const { answerFocusOptions, selectedSourceWeightsIndex, setSelectedSourceWeightsIndex } = useAnswerFocusOptions(collectionType);
  const collectionConversationId = useCollectionKey(collectionId, "conversationId") || uuid();
  const projectUsage = useFeatureUsage(Intent.generateAnswer);
  const { bypass_usage_limits: canBypassUsageLimits } = useEntitlements();
  const { full_maxio_integration: isSubscriptionEnabled } = useFeatureFlags();

  const { sendMessage: sendInputBarMessage } = useInputBar({
    conversationId: collectionConversationId,
    collectionId,
    areAttachmentsDisabled: false,
  });

  const selectedFocus = useMemo(() => {
    return answerFocusOptions ? answerFocusOptions[selectedSourceWeightsIndex]?.key : undefined;
  }, [answerFocusOptions, selectedSourceWeightsIndex]);

  const handleUpdateSourceWeightPreference = useCallback(
    (value: 0 | 1) => {
      dispatch(
        updateTypedUserPreference({
          preferenceKey: "source_weights_index",
          value: value,
        })
      );

      setSelectedSourceWeightsIndex(value);
    },
    [dispatch, setSelectedSourceWeightsIndex]
  );

  const onSubmitQuestion = useCallback(
    (selectedQuestion: string, selectedFocus?: string) => {
      if (!selectedQuestion || !collectionId || !selectedFocus || !collectionConversationId) return;

      if (isSubscriptionEnabled && !canBypassUsageLimits && projectUsage && projectUsage.used >= projectUsage.allowed) {
        onUpgradeModalOpen();
        return;
      }
      const entities: RequestEntities = [];
      entities.push({ entity: "collection_id", value: collectionId });
      entities.push({ entity: "question", value: selectedQuestion.trim() });
      entities.push({ entity: "answer_focus", value: selectedFocus });
      entities.push({ entity: "asked_by_user", value: "true" });
      entities.push({ entity: "answer_format", value: "html" });

      dispatch(
        sendMessage({
          conversationId: collectionConversationId,
          intent: `/generate_answer`,
          entities: [...entities],
        })
      );

      onConversationOpen(collectionConversationId);
    },
    [
      collectionId,
      collectionConversationId,
      isSubscriptionEnabled,
      canBypassUsageLimits,
      projectUsage,
      dispatch,
      onConversationOpen,
      onUpgradeModalOpen,
    ]
  );

  const onResetQuestion = useCallback(() => {
    setQuestionText("");
    setSelectedSourceWeightsIndex(0);
  }, [setQuestionText, setSelectedSourceWeightsIndex]);

  return {
    selectedFocus,
    handleUpdateSourceWeightPreference,
    answerFocusOptions,
    selectedSourceWeightsIndex,
    onSubmitQuestion,
    onResetQuestion,
    sendInputBarMessage,
  };
};
