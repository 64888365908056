import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";

export const useWorkflowsStatsMap = (workflowsIds: string[]): Record<string, { executionTime: number }> => {
  const [localWorkflowsIds] = useState(workflowsIds);
  const workflowsStats = useSelector((state: RootState) => state.workflowsStats.statsByWorkflowId);

  return useMemo(
    () =>
      localWorkflowsIds
        .map((workflowId) => ({ id: workflowId, ...workflowsStats[workflowId] }))
        .reduce((acc, workflowState) => ({ ...acc, [workflowState.id]: { executionTime: workflowState.executionTime } }), {}),
    [localWorkflowsIds, workflowsStats]
  );
};
