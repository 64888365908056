import { Badge, Box, Center, Stack, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import type { ReactNode } from "react";
import { useCallback } from "react";
import React, { useEffect, useMemo, useState } from "react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import type { Source, UserConfig, UserIcon } from "types/config";
import { createProjectConfig, updateProjectConfig } from "state/config/operations";
import { useAppDispatch, useConfigMap, useConfigProjectTypes, useEntitlements, useExternalIntegrations } from "hooks";
import type { ProjectConfigState } from "state/config/reducer";
import { useDownloadUsers, useUsersMap } from "hooks/useUsers";
import type { User } from "@charliai/node-core-lib/lib/src/userService/models/User";
import { formatDate } from "screens/common/modal/formatters";
import { DynamicForm } from "screens/common/components";
import type { IField } from "screens/common/components";
import * as yup from "yup";
import { IconsList } from "configs/configMap";
import capitalize from "lodash/capitalize";
import { OnBoardingStepsSchema } from "./schemas/OnBoardingStepsSchema";
import { useEntitlementConfigs } from "hooks/useEntitlementConfigs";
import { CostPerProjectSchema } from "./schemas/CostPerProjectSchema";
import { NewProjectFormSchema } from "./schemas/NewProjectFormSchema";
import { validate } from "json-schema";
import type { JSONSchema7 } from "json-schema";
import { AnswerFocusOptionsSchema } from "./schemas/AnswerFocusOptionsSchema";
import { SourceWeightsType } from "types/projectConfig/SourceWeightsType";
import { useReportTemplatesList } from "hooks/useReportTemplates";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { AddNewProjectPanel } from "screens/panels/research/AddNewProjectPanel";
import { qaSourcesMap } from "hooks/useQASourceList";

const lowerCaseUnderscoreRegex = /^([a-z_]+)?$/;

const isJsonString = (value: string | undefined, schema: JSONSchema7): { success: boolean; error?: string } => {
  try {
    if (!value) {
      return { success: true, error: "" };
    }

    const jsonValue = JSON.parse(value);
    const isValid = validate(jsonValue, schema);

    if (!isValid.valid) {
      return { success: false, error: "Invalid JSON: " + JSON.stringify(isValid.errors) };
    }

    return { success: true };
  } catch (error) {
    return { success: false, error: "Invalid JSON" };
  }
};

const isValidAnswerFocusOptions = (value: string | undefined): { success: boolean; error?: string } => {
  try {
    if (!value || value === "null") {
      return { success: true, error: "" };
    }

    const jsonValue = JSON.parse(value);
    const isValid = SourceWeightsType.validate(jsonValue);

    if (!isValid.success) {
      return { success: false, error: "Invalid Config: " + isValid.message };
    }

    return { success: true };
  } catch (error) {
    return { success: false, error: "Invalid JSON" };
  }
};

const questionsInputSources: { label: string; value: Source | "none" }[] = [
  {
    label: "None",
    value: "none",
  },
  ...Object.values(qaSourcesMap).map((source) => ({ label: source.label, value: source.value })),
];

interface ProjectConfigFormValues {
  route: string;
  intent: string;
  icon: UserIcon;
  title: string;
  onBoardingTitle: string;
  onBoardingMessage: string;
  onBoardingSteps?: string;
  collectionType: string;
  portfolioProjectType: string;
  menuTooltip: string;
  showMenu: boolean;
  showStockTicker: boolean;
  showProjectTags: boolean;
  showProjectLandingQAInput: boolean;
  projectLandingQASource: Source | "none";
  showProjectDetailQAInput: boolean;
  projectDetailQASource: Source | "none";
  entitlement: string;
  reverseEntitlement: string;
  workflowIntentFilters: string;
  addSourceOptions: { label: string; value: string }[];
  keyProjectContent: string;
  requiredIntegrations: { label: string; value: string }[];
  defaultFilterTags: string;
  metricTags: string;
  aiResultResourceSections: string;
  maxActiveProjectsAllowed: number;
  costPerProject: string;
  commandPlaceholder: string;
  deleteReasons: string;
  newProjectForm: string;
  answerFocusOptions: string;
  defaultReport: string;
  reportEmailTemplateId: string;
}

interface UpdateProjectConfigFormProps {
  onClose: () => void;
  projectConfig: ProjectConfigState;
}

export const UpdateProjectConfigForm = (props: UpdateProjectConfigFormProps) => {
  const { onClose, projectConfig } = props;
  const dispatch = useAppDispatch();
  const { manage_project_config_write: hasEditProjectEntitlement, view_users: hasViewUsers } = useEntitlements();
  const textColor = useColorModeValue("primary.darkGray", "gray.300");
  const [userData, setUserData] = useState<User>();
  const entitlementsList = useEntitlementConfigs();
  const { availableIntegrations } = useExternalIntegrations();
  const toast = useToast();
  const configMap = useConfigMap();
  const projectTypes = useConfigProjectTypes(configMap);
  const isEdit = projectConfig.id !== "duplicate" ? true : false;
  const reports = useReportTemplatesList();
  const { isAddNewProjectPanelOpen, onAddNewProjectPanelClose } = useAddToCharliContext();

  const getTitle = useCallback(() => {
    if (projectConfig.id && projectConfig.id !== "duplicate") {
      return `${hasEditProjectEntitlement ? "Edit" : "View"} project config: ${projectConfig.config.title}`;
    } else {
      return `New project config`;
    }
  }, [hasEditProjectEntitlement, projectConfig.config.title, projectConfig.id]);

  useDownloadUsers(([projectConfig?.lastModifiedByUserId] as string[]) || "");
  const usersMap = useUsersMap();

  useEffect(() => {
    const userFromConfig = projectConfig?.lastModifiedByUserId as string;
    if (userFromConfig) {
      setUserData(usersMap[userFromConfig]);
    }
  }, [usersMap, projectConfig?.lastModifiedByUserId]);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      route: yup
        .string()
        .matches(/^(dashboard|library|projects\/|home)/)
        .required("Route is required"),
      intent: yup.string().matches(lowerCaseUnderscoreRegex).required("Intent is required"),
      title: yup.string().required("Title is required"),
      collectionType: yup
        .string()
        .test("is-unique", "This collection type already exists. Type a different one.", (value) => {
          if (!value) {
            return true;
          }

          if (isEdit && projectConfig.config.collectionType === value) {
            return true;
          }

          const isUnique = projectTypes.find(
            (projectType) => projectType.config.collectionType !== undefined && projectType.config.collectionType === value
          );

          return !isUnique;
        })
        .matches(lowerCaseUnderscoreRegex),
      entitlement: yup.string().matches(lowerCaseUnderscoreRegex),
      reverseEntitlement: yup.string().matches(lowerCaseUnderscoreRegex),
      onBoardingSteps: yup.string().test("is-json", "Invalid JSON", (value) => isJsonString(value, OnBoardingStepsSchema).success),
      costPerProject: yup.string().test("is-json", "Invalid JSON", (value) => isJsonString(value, CostPerProjectSchema).success),
      newProjectForm: yup.string().test(
        "is-json",
        ({ value }) => isJsonString(value, NewProjectFormSchema).error,
        (value) => isJsonString(value, NewProjectFormSchema).success
      ),
      answerFocusOptions: yup.string().test(
        "is-json",
        ({ value }) => isValidAnswerFocusOptions(value).error,
        (value) => isValidAnswerFocusOptions(value).success
      ),
    });
  }, [projectTypes, projectConfig, isEdit]);

  const fields: IField<ProjectConfigFormValues>[] = useMemo(() => {
    return [
      {
        type: "stack",
        isInline: true,
        spacing: "1rem",
        fields: [
          {
            type: "text",
            label: "Title",
            entity: "title",
            defaultValue: "",
            helperText: "The title that will be displayed in the sidebar menu and views.",
          },
          {
            type: "select",
            label: "Icon",
            entity: "icon",
            defaultValue: IconsList[0],
            options: IconsList.map((icon) => ({ label: capitalize(icon), value: icon })),
            helperText:
              "The icon that will be displayed in the sidebar menu. Must be one of the icons in the IconsList defined in src/configs/configMap.ts",
          },
        ],
      },
      {
        type: "stack",
        isInline: true,
        spacing: "1rem",
        fields: [
          {
            type: "text",
            label: "Collection Type",
            entity: "collectionType",
            defaultValue: "",
            helperText:
              "The collection type used to filter collections for the views. Collection type can only be lowercase letters and underscores.",
          },
          {
            type: "text",
            label: "Intent",
            entity: "intent",
            defaultValue: "",
            helperText: "The intent that will be sent to charli. Intent must be lowercase letters and underscores.",
          },
        ],
      },
      {
        type: "stack",
        isInline: true,
        spacing: "1rem",
        fields: [
          {
            type: "select",
            label: "Portfolio Project Type",
            entity: "portfolioProjectType",
            defaultValue: "",
            options: [
              { label: "None", value: "" },
              ...projectTypes.map((selectItem) => ({ label: selectItem.config.title, value: selectItem.config.collectionType || "" })),
            ],
            helperText: "The project type that will be used to create new projects in this portfolio category.",
          },
          {
            type: "text",
            label: "Menu Tooltip",
            entity: "menuTooltip",
            defaultValue: "",
            helperText: "Show a tooltip message when hovering over this project in the sidebar.",
          },
        ],
      },
      {
        type: "space",
        height: "1rem",
      },
      {
        type: "tabs",
        fields: [
          {
            type: "tab",
            label: "New Project",
            fields: [
              {
                type: "stack",
                spacing: "1.5rem",
                fields: [
                  {
                    type: "json",
                    label: "New Project Form",
                    entity: "newProjectForm",
                    height: "25rem",
                    defaultValue: "",
                    minHeight: "11rem",
                    jsonSchema: NewProjectFormSchema,
                    previewPanel: true,
                    helperText:
                      "The definition of the panel and form that is shown in a panel when the user creates a new project of this type.",
                  },
                  {
                    type: "select",
                    label: "Default Report",
                    entity: "defaultReport",
                    defaultValue: "",
                    options: [{ label: "None", value: "" }, ...reports.map((report) => ({ label: report.name, value: report.id }))],
                    helperText: "The default report that will be shown in the project details view.",
                  },
                  {
                    type: "textarea",
                    label: "Default Filter Tags",
                    entity: "defaultFilterTags",
                    placeholder: "List of default filter tags separated by line breaks",
                    defaultValue: "",
                    helperText: "The tags that will be selected by default in the content filter when the project opens.",
                  },
                ],
              },
            ],
          },
          {
            type: "tab",
            label: "Project Landing",
            fields: [
              {
                type: "stack",
                spacing: "1.5rem",
                fields: [
                  {
                    type: "stack",
                    isInline: true,
                    fields: [
                      {
                        type: "switch",
                        label: "Show Menu",
                        entity: "showMenu",
                        defaultValue: false,
                        helperText:
                          "The system preference for showing the menu. entitlement must be set for this to work. If this is set to false, the view will not be shown in the sidebar menu.",
                      },
                      {
                        type: "switch",
                        label: "Show Stock Ticker",
                        entity: "showStockTicker",
                        defaultValue: false,
                        helperText:
                          "Show a ticker in the project tile. This will only show if the project has a company_details subtype. If this is set to false, the ticker will not be shown in the project tile.",
                      },
                      {
                        type: "switch",
                        label: "Show Project Tags",
                        entity: "showProjectTags",
                        defaultValue: false,
                        helperText:
                          "Show the tags in the project tile. If this is set to false, the tags will not be shown in the project tile.",
                      },
                    ],
                  },
                  {
                    type: "stack",
                    isInline: true,
                    fields: [
                      {
                        type: "switch",
                        label: "Show Q&A Input",
                        entity: "showProjectLandingQAInput",
                        defaultValue: false,
                        helperText:
                          "Show the Q&A input in the project tile. If this is set to false, the Q&A input will not be shown in the project tile.",
                      },
                    ],
                  },
                  {
                    type: "stack",
                    isInline: true,
                    fields: [
                      {
                        type: "select",
                        label: "Q&A Source",
                        entity: "projectLandingQASource",
                        defaultValue: "",
                        options: questionsInputSources,
                        onHide: (values) => !values.showProjectLandingQAInput,
                      },
                    ],
                  },
                  {
                    type: "textarea",
                    label: "Workflow Intent Filters",
                    placeholder: "List of workflow intent filters separated by line breaks",
                    entity: "workflowIntentFilters",
                    minHeight: "11rem",
                    defaultValue: "",
                    helperText:
                      "The workflow intent that will be used to filter the workflow stepper. The workflow intents can be found in /admin/configured-workflows.",
                  },
                  {
                    type: "textarea",
                    label: "Key Project Content",
                    placeholder: "List of key project content separated by line breaks",
                    entity: "keyProjectContent",
                    defaultValue: "",
                    helperText:
                      "The key project content shown as links on the right of each tile in the list layouts on the projects landing page",
                  },
                  {
                    type: "textarea",
                    label: "Metric Tags",
                    entity: "metricTags",
                    placeholder: "List of metric tags separated by line breaks",
                    defaultValue: "",
                    helperText: "The tags that will be used to display stats for the project.",
                  },
                ],
              },
            ],
          },
          {
            type: "tab",
            label: "Project Detail",
            fields: [
              {
                type: "stack",
                isInline: true,
                fields: [
                  {
                    type: "switch",
                    label: "Show Q&A Input",
                    entity: "showProjectDetailQAInput",
                    defaultValue: false,
                    helperText:
                      "Show the Q&A input in the project tile. If this is set to false, the Q&A input will not be shown in the project tile.",
                  },
                ],
              },
              {
                type: "stack",
                isInline: true,
                fields: [
                  {
                    type: "select",
                    label: "Q&A Source",
                    entity: "projectDetailQASource",
                    defaultValue: "",
                    options: questionsInputSources,
                    onHide: (values) => !values.showProjectDetailQAInput,
                  },
                ],
              },
              {
                type: "stack",
                spacing: "1.5rem",
                fields: [
                  {
                    type: "textarea",
                    label: "AI Result Resource Sections",
                    placeholder: "List of AI result resource sections separated by line breaks",
                    entity: "aiResultResourceSections",
                    defaultValue: "",
                    helperText: "The sections that will be displayed in the AI result in the project details view.",
                  },
                  {
                    type: "multi-select",
                    label: "Add Source Options",
                    entity: "addSourceOptions",
                    defaultValue: [],
                    options: ["files", "links", "search", "content"].map((option) => ({ label: capitalize(option), value: option })),
                    helperText: "The source options that will be enabled in the add source panel.",
                  },
                  {
                    type: "json",
                    label: "Answer Focus Options",
                    entity: "answerFocusOptions",
                    height: "34rem",
                    defaultValue: "",
                    helperText: "The focus options for each source used in the search results.",
                    jsonSchema: AnswerFocusOptionsSchema,
                  },
                  {
                    type: "textarea",
                    label: "Delete Reasons",
                    entity: "deleteReasons",
                    placeholder: "List of delete reasons separated by line breaks",
                    defaultValue: "",
                    helperText:
                      "This value will be sent as the value for the delete reason entity. ie. content_delete_reason or highlight_deletion_reason.",
                  },
                ],
              },
            ],
          },
          {
            type: "tab",
            label: "Report Email",
            fields: [
              {
                type: "stack",
                spacing: "1.5rem",
                fields: [
                  {
                    type: "text",
                    label: "Template ID (Sendgrid)",
                    entity: "reportEmailTemplateId",
                    defaultValue: "",
                    helperText: "The ID of the Sendgrid template to use.",
                  },
                ],
              },
            ],
          },
          {
            type: "tab",
            label: "Onboarding",
            fields: [
              {
                type: "stack",
                spacing: "1.5rem",
                fields: [
                  {
                    type: "multi-select",
                    label: "Required integrations",
                    entity: "requiredIntegrations",
                    defaultValue: [],
                    options: availableIntegrations.flatMap((integration) =>
                      integration.urn ? [{ label: integration.name, value: integration.urn }] : []
                    ),
                    helperText: "Used to determine what integrations are required before a project of this type can be created.",
                  },
                  {
                    type: "text",
                    label: "Onboarding Title",
                    entity: "onBoardingTitle",
                    defaultValue: "",
                    helperText: "Onboarding title when there are no projects of this type.",
                  },
                  {
                    type: "json",
                    label: "Onboarding Steps",
                    entity: "onBoardingSteps",
                    height: "25rem",
                    defaultValue: "",
                    jsonSchema: OnBoardingStepsSchema,
                    helperText: "The steps that will be displayed in the onboarding message.",
                  },
                ],
              },
            ],
          },
          {
            type: "tab",
            label: "Entitlements",
            fields: [
              {
                type: "stack",
                spacing: "1.5rem",
                fields: [
                  {
                    type: "text-with-suggestions",
                    label: "Entitlement",
                    entity: "entitlement",
                    defaultValue: "",
                    suggestions: entitlementsList.map((entitlement) => entitlement.code),
                    helperText:
                      "Checks that the user is entitled to the view. showMenu can still hide entitled views. If this is not set, the view will not be shown in the sidebar menu.",
                  },
                  {
                    type: "text-with-suggestions",
                    label: "Reverse Entitlement",
                    entity: "reverseEntitlement",
                    defaultValue: "",
                    suggestions: entitlementsList.map((entitlement) => entitlement.code),
                    helperText: "Checks that the user is specifically not allowed to see the view.",
                  },
                ],
              },
            ],
          },
          {
            type: "tab",
            label: "Other Options",
            fields: [
              {
                type: "stack",
                spacing: "1.5rem",
                fields: [
                  {
                    type: "number",
                    label: "Max Active Projects Allowed",
                    entity: "maxActiveProjectsAllowed",
                    defaultValue: 0,
                    min: 0,
                    helperText:
                      "Set the number of projects that can be active at one time. This is determined by the project statuses, clarification_needed, in_progress, and queued. If value is 0, there is no limit",
                  },
                  {
                    type: "json",
                    label: "Cost Per Project",
                    entity: "costPerProject",
                    height: "5.5rem",
                    defaultValue: "",
                    jsonSchema: CostPerProjectSchema,
                    helperText: "The cost of the project execution. Used in charts on project views.",
                  },
                  {
                    type: "text",
                    label: "Command Placeholder",
                    entity: "commandPlaceholder",
                    defaultValue: "",
                    helperText:
                      "The placeholder text that will be displayed in the command input field when the user is creating a new project of this type.",
                  },
                ],
              },
            ],
          },
        ],
      },
    ];
  }, [projectTypes, reports, availableIntegrations, entitlementsList]);

  const defaultValues: ProjectConfigFormValues = useMemo(() => {
    return {
      route: projectConfig.config.route || "",
      intent: projectConfig.config.intent,
      icon: projectConfig.config.icon,
      title: projectConfig.config.title,
      portfolioProjectType: projectConfig.config.portfolioProjectType || "",
      menuTooltip: projectConfig.config.menuTooltip || "",
      onBoardingTitle: projectConfig.config.onBoardingTitle,
      onBoardingMessage: projectConfig.config.onBoardingMessage,
      onBoardingSteps: JSON.stringify(projectConfig.config.onBoardingSteps ?? null, null, 2),
      collectionType: projectConfig.config.collectionType || "",
      showMenu: projectConfig.config.showMenu ?? false,
      showStockTicker: projectConfig.config.showStockTicker ?? false,
      showProjectTags: projectConfig.config.showProjectTags ?? false,
      showProjectLandingQAInput: !!projectConfig.config.projectLandingQAInput?.showInput,
      projectLandingQASource: projectConfig.config.projectLandingQAInput?.source || questionsInputSources[0].value,
      showProjectDetailQAInput: !!projectConfig.config.projectDetailQAInput?.showInput,
      projectDetailQASource: projectConfig.config.projectDetailQAInput?.source || questionsInputSources[0].value,
      entitlement: projectConfig.config.entitlement || "",
      reverseEntitlement: projectConfig.config.reverseEntitlement || "",
      workflowIntentFilters: projectConfig.config.workflowIntentFilters ? projectConfig.config.workflowIntentFilters.join("\n") : "",
      addSourceOptions: projectConfig.config.addSourceOptions
        ? projectConfig.config.addSourceOptions.map((sourceOption) => ({ label: capitalize(sourceOption), value: sourceOption }))
        : [],
      keyProjectContent: projectConfig.config.keyProjectContent ? projectConfig.config.keyProjectContent.join("\n") : "",
      requiredIntegrations: projectConfig.config.requiredIntegrations
        ? projectConfig.config.requiredIntegrations.map((integration) => ({
            label: availableIntegrations.find((availableIntegration) => availableIntegration.urn === integration)?.name ?? integration,
            value: integration,
          }))
        : [],
      defaultFilterTags: projectConfig.config.defaultFilterTags ? projectConfig.config.defaultFilterTags.join("\n") : "",
      metricTags: projectConfig.config.metricTags ? projectConfig.config.metricTags.join("\n") : "",
      aiResultResourceSections: projectConfig.config.aiResultResourceSections
        ? projectConfig.config.aiResultResourceSections.join("\n")
        : "",
      maxActiveProjectsAllowed: projectConfig.config.maxActiveProjectsAllowed ?? 0,
      costPerProject: JSON.stringify(
        projectConfig.config.costPerProject ?? {
          aiGeneratedCost: 0,
          humanResourceCost: 0,
        },
        null,
        2
      ),
      commandPlaceholder: projectConfig.config.commandPlaceholder ?? "",
      deleteReasons: projectConfig.config.deleteReasons ? projectConfig.config.deleteReasons.join("\n") : "",
      newProjectForm: JSON.stringify(projectConfig.config.newProjectForm ?? null, null, 2),
      answerFocusOptions: JSON.stringify(projectConfig.config.answerFocusOptions ?? null, null, 2),
      defaultReport: projectConfig.config.defaultReport || "",
      reportEmailTemplateId: projectConfig.config.projectReportEmail?.templateId ?? "",
    };
  }, [projectConfig, availableIntegrations]);

  const handleSubmit = async (data: ProjectConfigFormValues) => {
    if (!hasEditProjectEntitlement) return;

    const userConfigPayload: UserConfig = {
      route: `projects/${data.collectionType}`,
      intent: data.intent,
      icon: data.icon,
      title: data.title,
      portfolioProjectType: data.portfolioProjectType || undefined,
      menuTooltip: data.menuTooltip || undefined,
      onBoardingTitle: data.onBoardingTitle,
      onBoardingMessage: data.onBoardingMessage,
      onBoardingSteps: data.onBoardingSteps ? JSON.parse(data.onBoardingSteps) : undefined,
      collectionType: data.collectionType ?? undefined,
      showMenu: data.showMenu,
      showStockTicker: data.showStockTicker,
      showProjectTags: data.showProjectTags,
      entitlement: data.entitlement || undefined,
      reverseEntitlement: data.reverseEntitlement || undefined,
      workflowIntentFilters: data.workflowIntentFilters ? data.workflowIntentFilters.split("\n") : undefined,
      addSourceOptions: data.addSourceOptions.length > 0 ? data.addSourceOptions.map((sourceOption) => sourceOption.value) : undefined,
      keyProjectContent: data.keyProjectContent ? data.keyProjectContent.split("\n") : undefined,
      requiredIntegrations:
        data.requiredIntegrations.length > 0 ? data.requiredIntegrations.map((integration) => integration.value) : undefined,
      defaultFilterTags: data.defaultFilterTags ? data.defaultFilterTags.split("\n") : undefined,
      metricTags: data.metricTags ? data.metricTags.split("\n") : undefined,
      aiResultResourceSections: data.aiResultResourceSections ? data.aiResultResourceSections.split("\n") : undefined,
      maxActiveProjectsAllowed:
        !data.maxActiveProjectsAllowed || data.maxActiveProjectsAllowed === 0 ? undefined : data.maxActiveProjectsAllowed,
      costPerProject:
        !data.costPerProject || data.costPerProject === "null"
          ? { aiGeneratedCost: 0, humanResourceCost: 0 }
          : JSON.parse(data.costPerProject),
      commandPlaceholder: data.commandPlaceholder ?? undefined,
      deleteReasons: data.deleteReasons ? data.deleteReasons.split("\n") : undefined,
      newProjectForm: !data.newProjectForm || data.newProjectForm === "null" ? undefined : JSON.parse(data.newProjectForm),
      defaultReport: data.defaultReport || undefined,
      ...(data.showProjectLandingQAInput && {
        projectLandingQAInput: {
          source: data.projectLandingQASource !== "none" ? data.projectLandingQASource : undefined,
          showInput: true,
        },
      }),
      ...(data.showProjectDetailQAInput && {
        projectDetailQAInput: {
          source: data.projectDetailQASource !== "none" ? data.projectDetailQASource : undefined,
          showInput: true,
        },
      }),
      projectReportEmail: {
        templateId: data.reportEmailTemplateId === "" ? undefined : data.reportEmailTemplateId,
      },
    };

    if (data.answerFocusOptions && data.answerFocusOptions !== "null") {
      const isValidAnswerFocus = SourceWeightsType.validate(JSON.parse(data.answerFocusOptions));

      if (!isValidAnswerFocus.success) {
        toast({
          title: "Error",
          description: isValidAnswerFocus.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });

        return;
      }

      userConfigPayload.answerFocusOptions = isValidAnswerFocus.value;
    } else {
      userConfigPayload.answerFocusOptions = undefined;
    }

    const projectResponse = await (() => {
      if (projectConfig.id && projectConfig.id !== "duplicate") {
        return dispatch(updateProjectConfig({ id: projectConfig.id, config: userConfigPayload }));
      } else {
        return dispatch(createProjectConfig({ config: { ...userConfigPayload } }));
      }
    })();

    if (projectResponse.type === updateProjectConfig.rejected.type || projectResponse.type === createProjectConfig.rejected.type) {
      toast({
        title: "Error",
        description: "There was an error updating the project config",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      return;
    }

    toast({
      title: "Success",
      description: "Project config updated successfully",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    onClose();
  };

  const panelHeader: ReactNode = useMemo(
    () => (
      <Box px="1rem" pt="1.5rem" pb="1rem">
        <Text>{getTitle()}</Text>
        {hasViewUsers && userData && (
          <Stack direction="row" justifyContent={"space-between"} width="100%" pt="1rem">
            <Stack direction="row" spacing="2rem">
              <Center>
                <Stack direction="row">
                  <Text fontWeight={"semibold"} fontSize={"sm"}>
                    Last edited by:
                  </Text>
                  <Text fontWeight={"normal"} fontSize={"sm"}>{`${userData.firstName} ${userData.lastName}`}</Text>
                </Stack>
              </Center>
              <Stack direction="row">
                <Text fontWeight={"semibold"} fontSize={"sm"}>
                  Last edited on:
                </Text>
                <Text fontWeight={"normal"} fontSize={"sm"}>
                  {projectConfig.lastModifiedDate && formatDate(new Date(projectConfig.lastModifiedDate), "do MMM yyyy HH:mm")}
                </Text>
              </Stack>
            </Stack>
          </Stack>
        )}
        <Stack spacing=".5rem" mt=".5rem" maxWidth={"80%"}>
          {!hasEditProjectEntitlement ? (
            <Stack direction="row">
              <Box>
                <Badge>NOTE</Badge>
              </Box>
              <Text fontWeight={"normal"} fontSize="sm" color={textColor}>
                You are not entitled to edit the project config. Add your account to the manage_project_config_write entitlement.
              </Text>
            </Stack>
          ) : (
            <Stack direction="row">
              <Center>
                <Badge colorScheme={"red"}>WARNING</Badge>
              </Center>
              <Text fontWeight={"normal"} fontSize="sm" color={textColor}>
                You're entitled to edit project config. Please verify your changes before saving them.
              </Text>
            </Stack>
          )}
        </Stack>
      </Box>
    ),
    [getTitle, hasEditProjectEntitlement, hasViewUsers, projectConfig.lastModifiedDate, textColor, userData]
  );

  return (
    <>
      <PanelView isOpen onClose={onClose} panelHeader={panelHeader}>
        <Wizard>
          <PanelStep
            onNextDisabled={!hasEditProjectEntitlement}
            onNextLabel="Save Config"
            onNextButtonForm="upsert-project-config-form"
            onNextButtonType="submit">
            <Box my=".5rem" mb="4rem" overflow={"hidden"}>
              <DynamicForm<ProjectConfigFormValues>
                title={getTitle()}
                fields={fields}
                defaultValues={defaultValues}
                formId="upsert-project-config-form"
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              />
            </Box>
          </PanelStep>
        </Wizard>
      </PanelView>
      <AddNewProjectPanel
        isOpen={isAddNewProjectPanelOpen}
        onClose={onAddNewProjectPanelClose}
        collectionType={projectConfig.config.collectionType}
      />
    </>
  );
};
