import type { MessageType } from "./MessageType";
import StompWebsocketClient from "./StompWebsocketClient";
import type { CharliUI } from "types/charliui";

export type NewMessage = {
  senderId: string;
  viewId?: string;
  createdDate: string;
  data?: Array<CharliUI>;
  entities?: { entity: string; value: unknown }[];
  conversationId?: string;
  parentMessageId?: string;
  id: string;
  intent?: string;
  content?: string;
};

export interface IRealTimeClient {
  connect(
    clientId: string,
    onConnect: () => void,
    onDisconnect: () => void,
    onMessage: (message: string, messageType?: MessageType) => void
  ): Promise<IRealTimeClient>;

  send(message: NewMessage): Promise<void>;

  close(code?: number): void;
}

let realTimeClient: IRealTimeClient | null = null;

export const getWebsocketClient = (): IRealTimeClient => {
  if (realTimeClient != null) {
    return realTimeClient;
  }

  realTimeClient = new StompWebsocketClient();

  return realTimeClient;
};
