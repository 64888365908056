import { ArrowForwardIcon, Search2Icon } from "@chakra-ui/icons";
import { IconButton, Input, InputGroup, InputRightElement, useColorModeValue, Box, Stack } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useContext } from "react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendMessage } from "state/websocket/operations";
import { v4 as uuid } from "uuid";
import { InteractionBarFindInputTagAutocomplete } from "./InteractionBarFindInputTagAutocomplete";
import type { InteractionBarFindInputTagAutocompleteRef } from "./InteractionBarFindInputTagAutocomplete";
import type { RootState } from "state/rootReducer";
import type { RequestEntities } from "types/charliui";
import { ConversationContext } from "screens/thread/ConversationContext";
import { Popover } from "react-tiny-popover";
import { motion } from "framer-motion";
import { Box as ChakraBox } from "@chakra-ui/react";

interface Props {
  initialText?: string;
  intent?: string;
  onClick?: (inputText: string) => void;
}
const MotionBox = motion(ChakraBox);
const MotionStack = motion(Stack);

export const InteractionBarFindInput: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  initialText = "",
  intent = "find",
  onClick,
}) => {
  const [findText, setFindText] = useState(initialText);
  const isConnected = useSelector((state: RootState) => state.websocket.isConnected);
  const inputRef = useRef<HTMLInputElement>(null);
  const autoCompleteRef = useRef<InteractionBarFindInputTagAutocompleteRef>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isInputBarFocused, setIsInputBarFocused] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number | false>(false);
  const inputLeftIconColor = useColorModeValue("gray.600", "gray.300");
  const inputBgColor = useColorModeValue("white", "gray.800");
  const hoverColor = useColorModeValue("primary.default", "gray.700");
  const buttonColor = useColorModeValue("gray.400", "gray.800");
  const submitBgColor = useColorModeValue("blue.500", "gray.300");
  const submitColor = useColorModeValue("primary.default", "gray.800");
  const { setConversationId } = useContext(ConversationContext);
  const mainRef = useRef<HTMLDivElement | null>(null);
  const [showSearchInput, setShowSearchInput] = useState(false);

  const inputVariants = {
    open: { width: "100%", opacity: 1 },
    closed: { width: "0", opacity: 0 },
  };
  const stackVariants = {
    open: { width: "100%" },
    closed: { width: "1rem" },
  };

  const onSubmit = useCallback(() => {
    const newConversationId = uuid();
    setConversationId(newConversationId);
    const entities: RequestEntities = [];

    findText.split(" ").forEach((word) => {
      if (word.length === 0) return;
      if (word.startsWith("#")) {
        entities.push({ entity: "tag", value: word.replace("#", "").trim() });
      } else {
        entities.push({ entity: "keywords", value: word.trim() });
      }
    });

    dispatch(
      sendMessage({
        conversationId: newConversationId,
        intent: `/find`,
        entities: entities,
        message: `/find ${findText.replace("/find", "").trim()}`,
      })
    );
    navigate(`/search/${newConversationId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findText]);

  useEffect(() => {
    setFindText(initialText);
  }, [initialText]);

  const focusAndMoveCursorToEnd = useCallback(() => {
    // Capture ref so it's available in our closure
    const ref = inputRef.current;
    // Focus next tick (after input is edited)
    setTimeout(() => {
      if (ref) {
        ref.focus();
        ref.selectionStart = ref.value.length || 0;
        ref.selectionEnd = ref.value.length || 0;
        ref.scrollLeft = ref.scrollWidth;
      }
    }, 0);
  }, []);

  const onClickHandler = useCallback(() => {
    if (findText.trim().length === 0) return;
    if (!isConnected) return;
    if (onClick) {
      onClick(`${findText.trim()}`);
    } else {
      onSubmit();
    }
    setShowSearchInput(false);
    setFindText("");
    setIsInputBarFocused(false);
  }, [findText, isConnected, onClick, onSubmit]);

  const onSuggestionClick = useCallback(
    (tag: string) => {
      if (findText === "") {
        setFindText(`#${tag} `);
      } else {
        const incompleteTag = (findText.split(" ").reverse()[0] || "").replace("#", "");
        let inputText = findText;

        // Remove partially written tag
        if (tag && tag.toLowerCase().includes(incompleteTag.toLowerCase()))
          inputText = inputText.replace(new RegExp(`#?${incompleteTag}$`, "i"), "");
        // Remove leading hashtag if that's the only character in the user input
        if (inputText.slice(-1) === "#") inputText = inputText.slice(0, -1);

        if (inputText.slice(-1) === " " || inputText === "") {
          setFindText(`${inputText}#${tag} `);
        } else {
          setFindText(`${inputText} #${tag} `);
        }
      }

      focusAndMoveCursorToEnd();
    },
    [findText, focusAndMoveCursorToEnd]
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Tab") {
        autoCompleteRef.current?.applySelectedSuggestion();
        setSelectedSuggestionIndex(false);
      }
      if (event.key === "Enter") {
        setIsInputBarFocused(false);
        if (selectedSuggestionIndex !== false) {
          autoCompleteRef.current?.applySelectedSuggestion();
          setSelectedSuggestionIndex(false);
        } else {
          onClickHandler();
        }
        setIsInputBarFocused(false);
      }
      if (event.key === "ArrowUp") {
        autoCompleteRef.current?.setSuggestionFocus("up");
      }
      if (event.key === "ArrowDown") {
        setIsInputBarFocused(true);
        autoCompleteRef.current?.setSuggestionFocus("down");
      }

      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClickHandler, selectedSuggestionIndex]
  );

  useEffect(() => {
    // Reset selection state when the find input loses focus
    if (!isInputBarFocused) {
      setSelectedSuggestionIndex(false);
      setIsInputBarFocused(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInputBarFocused]);

  return (
    <Box ref={mainRef}>
      <Popover
        parentElement={mainRef?.current || undefined}
        isOpen={isInputBarFocused}
        positions={["bottom"]}
        padding={10}
        transform={{ top: 0, left: 10 }}
        transformMode="relative"
        align="start"
        onClickOutside={() => setIsInputBarFocused(false)}
        content={() => (
          <InteractionBarFindInputTagAutocomplete
            ref={autoCompleteRef}
            findText={findText}
            onSuggestionClick={onSuggestionClick}
            selectedSuggestionIndex={selectedSuggestionIndex}
            setSelectedSuggestionIndex={setSelectedSuggestionIndex}
          />
        )}>
        <MotionStack
          direction="row"
          align="center"
          as={motion.div}
          initial="closed"
          animate={showSearchInput ? "open" : "closed"}
          variants={stackVariants}
          transition={{ duration: 0.3 }}>
          <Search2Icon
            className="ch-quick-actions-open-find"
            zIndex={2}
            cursor="pointer"
            color={buttonColor}
            _hover={{ color: hoverColor }}
            boxSize="1.2rem"
            onClick={() => {
              setShowSearchInput(true);
              inputRef.current?.focus();
            }}
          />
          <MotionBox
            as={motion.div}
            initial="closed"
            animate={showSearchInput ? "open" : "closed"}
            variants={inputVariants}
            transition={{ duration: 0.3 }}>
            <InputGroup size="xs" backgroundColor={inputBgColor} borderRadius="md">
              <Input
                fontSize={"sm"}
                className={`ch-quick-actions-${intent}-input`}
                ref={inputRef}
                onBlur={() => {
                  !findText && setShowSearchInput(false);
                  setTimeout(() => {
                    setIsInputBarFocused(false);
                  }, 100);
                }}
                placeholder="Search Charli..."
                backgroundColor={inputBgColor}
                color={inputLeftIconColor}
                border="none"
                spellCheck="false"
                value={findText}
                onKeyDown={onKeyDown}
                onChange={(ev) => {
                  setIsInputBarFocused(true);
                  setFindText(ev.target.value);
                }}
              />
              <InputRightElement
                zIndex={1}
                children={
                  <IconButton
                    aria-label="submit"
                    onClick={onClickHandler}
                    isDisabled={!isConnected || findText.trim().length === 0}
                    color={submitColor}
                    backgroundColor="transparent"
                    size="sm"
                    _hover={{ color: submitBgColor }}
                    _active={{}}
                    icon={<ArrowForwardIcon width="1rem" height="1rem" />}
                  />
                }
              />
            </InputGroup>
          </MotionBox>
        </MotionStack>
      </Popover>
    </Box>
  );
};
