import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Stack,
  Text,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import logo from "screens/common/static/logos/charli-logo-full.svg";
import { useForm } from "react-hook-form";
import { resetPassword } from "api/user";
import { ToastMessageContent } from "screens/common/components";

type FormValues = {
  password: string;
  passwordConfirm: string;
};

export const ResetPassword: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
  const [searchParams] = useSearchParams();
  const maybeCode = searchParams.get("code");
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>();
  const navigate = useNavigate();
  const backgroundColor = useColorModeValue("white", "gray.800");
  const toast = useToast();

  const onSubmit = async (fields: FormValues) => {
    if (await resetPassword(maybeCode ?? "", fields.password)) {
      toastMessage("Your password was reset successfully. You can now login.");
      navigate("/login");
    } else {
      toastMessage("Resetting your password failed. Codes sent to your email address are only valid once for 30 minutes.");
    }
  };

  const toastMessage = (message: string) => {
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={message}
          onClick={() => {
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 10000,
      isClosable: true,
      position: "top-right",
    });
  };

  return (
    <Box id="login-container" height={"calc(var(--vh, 1vh) * 100)"} overflowY="auto">
      <Stack>
        <Stack align="center" justify="center" pt={["3rem", "5rem", "6rem", "6rem", "6rem"]}>
          <Image
            src={logo}
            alt="Charli logo"
            width="10rem"
            mb=".5rem"
            cursor="pointer"
            onClick={() => {
              navigate("/");
            }}
          />
        </Stack>
        <Stack align="center" justify="center" py="1rem">
          <Box width="20rem">
            <Box w={"100%"} alignSelf="center" pt="2rem">
              <Box border="1px" borderRadius="md" borderColor="secondary.lightGray" p="2rem">
                <Center>
                  <Text
                    position="relative"
                    w="10rem"
                    textAlign="center"
                    mt="-3rem"
                    fontSize="md"
                    pb="1rem"
                    backgroundColor={backgroundColor}>
                    reset your password
                  </Text>
                </Center>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Text pt="1rem" pb="2rem" fontSize="sm">
                    Enter and confirm your new password below. You will be redirected to the login page after you click Update Password
                  </Text>
                  <FormControl isInvalid={errors.password !== undefined} pb="1.5rem">
                    <FormLabel fontSize="md" htmlFor="password">
                      Enter New Password
                    </FormLabel>
                    <Input
                      {...register("password", { required: true, minLength: 12, maxLength: 64 })}
                      id="reset-password"
                      type="password"
                      placeholder="************"
                      autoComplete="current-password"
                      borderColor={errors.password ? "red.500" : "gray.200"}
                      mb="0.5rem"
                      fontSize="md"
                      boxShadow="none"
                    />
                    {errors.password?.type === "required" && <FormErrorMessage>Password is required.</FormErrorMessage>}
                    {errors.password?.type === "minLength" && (
                      <FormErrorMessage>Password must be at least 12 characters long.</FormErrorMessage>
                    )}
                    {errors.password?.type === "maxLength" && (
                      <FormErrorMessage>Password must be at most 64 characters long.</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.passwordConfirm !== undefined} pb="1.5rem">
                    <FormLabel fontSize="md" htmlFor="confirm">
                      Confirm New Password
                    </FormLabel>
                    <Input
                      {...register("passwordConfirm", {
                        validate: (value) => value === watch("password") || "The passwords do not match",
                      })}
                      id="passwordConfirm"
                      type="password"
                      placeholder="************"
                      autoComplete="current-password"
                      borderColor={errors.passwordConfirm ? "red.500" : "gray.200"}
                      mb="0.5rem"
                      fontSize="md"
                      boxShadow="none"
                    />
                    {errors.passwordConfirm && <FormErrorMessage>{errors.passwordConfirm.message}</FormErrorMessage>}
                  </FormControl>
                  <Flex justifyContent="center">
                    <Button
                      id="login-submit"
                      fontWeight="400"
                      fontFamily="print"
                      fontSize="md"
                      colorScheme="blue"
                      alignSelf="center"
                      type="submit"
                      mt="2"
                      width="100%">
                      Update Password
                    </Button>
                  </Flex>
                </form>
                <Flex justifyContent="center">
                  <Button
                    id="link-button"
                    fontWeight="400"
                    fontFamily="print"
                    fontSize="md"
                    colorScheme="blue"
                    variant="link"
                    alignSelf="center"
                    mt="1rem"
                    width="100%"
                    onClick={() => {
                      navigate("/login");
                    }}>
                    Back to login
                  </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};
