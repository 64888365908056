import React, { useState } from "react";
import { MenuItem } from "@chakra-ui/react";
import { TiThumbsDown, TiThumbsUp } from "react-icons/ti";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import type { IconType } from "react-icons";

interface ThumbsUpDownProps {
  onClickAction?: () => void;
  isMenuItem?: boolean;
}

export const ThumbsUpDown: React.FC<React.PropsWithChildren<React.PropsWithChildren<ThumbsUpDownProps>>> = ({
  onClickAction,
  isMenuItem,
}) => {
  const [thumbAction, setThumbAction] = useState<"unknown" | "up" | "down">("unknown");

  const onClickThumb = (action: "up" | "down") => {
    setThumbAction(thumbAction === action ? "unknown" : action);
    onClickAction && onClickAction();
  };

  const renderButton = (action: "up" | "down", iconTypeName: IconType, tooltip: string) => {
    const button = (
      <SmallActionButton
        onClick={() => onClickThumb(action)}
        iconSize="1.4rem"
        bgColor={thumbAction === action ? "charli.primaryBlue" : "gray.500"}
        iconTypeName={iconTypeName}
        tooltip={tooltip}
      />
    );

    return isMenuItem ? <MenuItem>{button}</MenuItem> : button;
  };

  return (
    <>
      {renderButton("up", TiThumbsUp, "Thumbs up coming soon!")}
      {renderButton("down", TiThumbsDown, "Thumbs down coming soon!")}
    </>
  );
};
