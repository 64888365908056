import React, { useCallback, useEffect } from "react";
import { addPlanUsers, createBillingPlan, removePlanUsers, updateBillingPlan } from "state/billingPlans/operations";
import { Box, Button, useToast } from "@chakra-ui/react";
import { FormTextField } from "screens/common/components/FormTextField";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useAppDispatch, useButtonProps } from "hooks";
import { useBillingPlan, useBillingPlanUsersMap, useIsBillingPlanLoading } from "hooks/useBillingPlans";
import { FormProvider, useForm } from "react-hook-form";
import { Wizard } from "react-use-wizard";
import type { BillingFormValues } from "../utils/BillingFormValues";
import { Plan } from "types/billingPlans/Plan";
import { generateCode } from "../utils/generateCode";
import { BillingUsersFieldArray } from "./BillingUsersFieldArray";

interface IProps {
  code: string | null;
  isOpen: boolean;
  onClose: () => void;
}

export const BillinPlanUpsertModal = (props: IProps) => {
  const dispatch = useAppDispatch();
  const planUsersMap = useBillingPlanUsersMap();
  const toast = useToast();
  const commonButtonProps = useButtonProps("sm", "primary");
  const { code, isOpen, onClose } = props;

  const billingPlan = useBillingPlan(code);
  const isLoading = useIsBillingPlanLoading();
  const isUpdate = !!code;

  const methods = useForm<BillingFormValues>({
    defaultValues: props.code
      ? {
          name: billingPlan?.name,
          code: billingPlan?.code,
          planUsers: billingPlan?.planUsers
            ? billingPlan.planUsers.map((user) => {
                return {
                  label: `${user.userName} (${user.userEmail || "n/a"})`,
                  value: user.userId,
                };
              })
            : [],
        }
      : {
          name: "",
          code: "",
          planUsers: [],
        },
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    watch,
    setValue,
  } = methods;

  const watchName = watch("name");

  const onSubmit = async (formValues: BillingFormValues) => {
    const { planUsers, ...billing } = formValues;

    const response = await dispatch(
      createBillingPlan({
        code: billing.code,
        name: billing.name,
        ...(planUsers &&
          planUsers.length > 0 && {
            planUsers: planUsers.map((user) => ({ userId: user.value })),
          }),
      })
    );

    if (!response.payload) {
      const errorMessage = (response as { error: { message: string } }).error?.message;

      toast({
        title: "Billing plan error",
        description: errorMessage,
        status: "error",
        duration: 10000,
        isClosable: true,
      });

      return;
    }

    toast({
      title: "Billing plan",
      description: `Successfully ${isUpdate ? "updated" : "created"}.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    onClose();
  };

  const onUpdateBillingPlanName = async () => {
    const { name, code } = getValues();

    const response = await dispatch(updateBillingPlan({ code: String(code), name: String(name) }));
    const isValidResponse = Plan.validate(response.payload);

    if (!isValidResponse) {
      toast({
        title: "Billing plan",
        description: "There was an error when trying to update the plan. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      return;
    }

    toast({
      title: "Billing plan",
      description: "Successfully updated",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const filterAlreadyLinkedUsers = useCallback(
    (users: any) => {
      return users.map((user: { value: string; label: string }) => ({
        ...user,
        ...(planUsersMap[user.value] && { label: `${user.label} (linked to ${planUsersMap[user.value].planCode} plan)`, isDisabled: true }),
      }));
    },
    [planUsersMap]
  );

  useEffect(() => {
    if (isUpdate) {
      return;
    }

    setValue("code", generateCode(watchName));
  }, [watchName, isUpdate, setValue]);

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`${isUpdate ? "Update" : "Create"} billing plan`}>
      <Wizard>
        <PanelStep>
          <Box pl=".5rem">
            <FormProvider {...methods}>
              <form id="upsert-billing-plan-form" onSubmit={handleSubmit(onSubmit)}>
                <FormTextField isRequired control={control} errors={errors} keyField="code" label="Code" onUpdate={null} disabled />
                <FormTextField
                  control={control}
                  errors={errors}
                  keyField="name"
                  label="Name"
                  atomic={isUpdate}
                  isRequired
                  disabled={isLoading}
                  onUpdate={onUpdateBillingPlanName}
                />

                <BillingUsersFieldArray
                  onAdd={(id) => {
                    return code ? dispatch(addPlanUsers({ planCode: code, planUsers: [{ userId: id }] })) : Promise.resolve({});
                  }}
                  onDelete={(id) => {
                    return code ? dispatch(removePlanUsers({ planCode: code, ids: [id] })) : Promise.resolve({});
                  }}
                  onCustomUsers={filterAlreadyLinkedUsers}
                />
              </form>

              <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
                <Button isDisabled {...commonButtonProps} type="submit" form="upsert-billing-plan-form">
                  Save Billing Plan
                </Button>
              </Box>
            </FormProvider>
          </Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
