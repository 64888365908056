import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deletePlan,
  deletePlanUsers,
  getBillingPlans,
  upsertBillingPlan,
  upsertPlanUsers,
  updateBillingPlan as updateBillingPlanAPI,
} from "api/billingPlan";
import type { CreatePlanRequest } from "api/billingPlan/models/CreatePlanRequest";
import type { DeleteRequest } from "api/billingPlan/models/DeleteRequest";
import type { UpsertPlanUsersRequest } from "api/billingPlan/models/UpsertPlanUsersRequest";
import { getPlanEntitlements } from "api/entitlements";

export const downloadBillingPlans = createAsyncThunk("billingPlans/download-all", async () => {
  return await getBillingPlans();
});

export const createBillingPlan = createAsyncThunk("billingPlans/create", async (payload: CreatePlanRequest) => {
  return await upsertBillingPlan(payload);
});

export const updateBillingPlan = createAsyncThunk("billingPlans/update", async (payload: CreatePlanRequest) => {
  return await updateBillingPlanAPI({
    code: payload.code,
    name: payload.name,
  });
});

export const addPlanUsers = createAsyncThunk("billingPlans/users/add", async (payload: UpsertPlanUsersRequest) => {
  return await upsertPlanUsers(payload);
});

export const removePlan = createAsyncThunk("billingPlans/plans/remove", async (planCode: string) => {
  return await deletePlan(planCode);
});

export const removePlanUsers = createAsyncThunk("billingPlans/users/remove", async (payload: DeleteRequest) => {
  return await deletePlanUsers(payload.planCode, payload.ids);
});

export const downloadPlanEntitlements = createAsyncThunk(
  "billingPlans/entitlements/download",
  async ({ planCode }: { planCode: string }) => {
    return await getPlanEntitlements(planCode);
  }
);
