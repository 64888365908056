import React, { useState } from "react";
import { Box, Text, Stack, useColorModeValue, Tooltip } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useTileProps } from "hooks";
import type { AssessmentResultsRiskAssessmentResult } from "types/collection";
import TruncateMarkup from "react-truncate-markup";
import { Popover } from "react-tiny-popover";

interface Props {
  riskLevel: AssessmentResultsRiskAssessmentResult;
}

export const RiskIndicator: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ riskLevel }) => {
  const subTitlecolor = useColorModeValue("charli.mediumGray", "gray.500");
  const commonTileProps = useTileProps();
  const bgColor =
    riskLevel.risk_level.length === 0
      ? "gray.200"
      : riskLevel.risk_level === "High Risk"
      ? "red.300"
      : riskLevel.risk_level === "Medium Risk"
      ? "yellow.300"
      : "green.300";
  const [showPopover, setShowPopover] = useState(false);

  return (
    <Box>
      <Popover
        isOpen={showPopover}
        positions={["bottom"]}
        padding={10}
        reposition={false}
        onClickOutside={() => setShowPopover(false)}
        content={() => (
          <Box {...commonTileProps} boxShadow="lg" height="100%" maxHeight={"15rem"} width="50vw" overflowY={"scroll"}>
            <TruncateMarkup
              lines={15}
              ellipsis={
                <Text fontSize="xs" color="gray.500">
                  Open project for full report...
                </Text>
              }>
              {riskLevel.risk_assessment_report.length && (
                <Stack>
                  {riskLevel.risk_assessment_report.split("\n").map(
                    (sentence, index) =>
                      sentence.length > 0 && (
                        <Text fontSize={"sm"} className="ch-assessment-result-risk-assessment" key={index} width="100%" pb=".5rem">
                          {sentence}
                        </Text>
                      )
                  )}
                </Stack>
              )}
            </TruncateMarkup>
          </Box>
        )}>
        <Box>
          <Tooltip placement="top" label={`${!showPopover ? "Click for report" : ""}`} aria-label="A tooltip">
            <Stack
              className="ch-risk-indicator"
              onClick={(event) => {
                event.stopPropagation();
                setShowPopover(!showPopover);
              }}
              spacing="0"
              {...commonTileProps}
              _hover={{}}
              direction="row"
              fontSize="xs"
              p="5px"
              justifyContent={"space-between"}
              width="6.5rem">
              <Box width={"1.8rem"} borderRadius={"md"} bgColor={bgColor} />
              <Stack spacing="0">
                <Text fontWeight="bold" width="100%">
                  {riskLevel.risk_level === "Medium Risk" ? "Med Risk" : riskLevel.risk_level || "Risk TBD"}
                </Text>
                <Text className="ch-risk-indicator-confidence" lineHeight={"12px"} color={subTitlecolor} fontWeight="normal">
                  {riskLevel.confidence.toFixed(4)}
                </Text>
              </Stack>
            </Stack>
          </Tooltip>
        </Box>
      </Popover>
    </Box>
  );
};
