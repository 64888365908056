import { Box, Stack, useToast, Text, useColorModeValue, Center, useBreakpointValue } from "@chakra-ui/react";
import {
  useCollection,
  useCollectionKey,
  useContents,
  useHasConversation,
  useInitialSyncCompleted,
  useItemSelector,
  useLatestCollectionWorkflowId,
  useProjectParams,
  useUserPreference,
} from "hooks";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingGate, ToastMessageContent } from "screens/common/components";
import { ContentFilterContextProvider } from "screens/content";
import { ContentSortingGroupingContext } from "screens/content/contentCanvas/body/ContentSortingGroupingContext";
import type { GroupByOption, GroupBySortDirection, SortDirection, SortOption } from "types/SortingAndGroupingPreferences";
import { TabTitle } from "screens/common/components/TabTitle";
import { useConversationContext } from "screens/thread/ConversationContext";
import { toTitleCase } from "screens/common/app";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { getItemTypeForContent } from "screens/content/common/utils";
import type { SupportedItemKeys } from "state/selection/reducer";
import { ProjectDetailViewContent } from "./views/ProjectDetailViewContent";
import { TagsPanel } from "screens/panels/tags/TagsPanel";
import { ContentViewPanel } from "screens/content/contentView/ContentViewPanel";
import { NotesPanel } from "screens/content/common/notes/NotesPanel";
import { SharePanel } from "screens/panels/share/SharePanel";
import { VerifiedAIPanel } from "screens/content/contentView/VerifiedAIPanel";
import { ProjectKeywordsNotesPanel } from "screens/panels/notes/ProjectKeywordsNotesPanel";
import { ProjectAddSource } from "./views/ProjectAddSource";
import { useWorkflowKey } from "hooks/useWorkflows";
import { deleteUserPreference } from "state/userPreference/operations";
import { useDispatch } from "react-redux";
import { downloadConversationById } from "state/conversation/operations";
import { ProjectReportPanelNewReportModal } from "screens/panels/researchReport/ProjectReportPanelNewReportModal";

export const ProjectDetailView = () => {
  // Type assertion is needed here as useParams assumes all parameters are present. viewContentId may be undefined, we want the type to accurately reflect this.
  const { parentRoute, projectFilter, projectId, contentId } = useProjectParams();
  const { setConversationId, onConversationClose } = useConversationContext();

  useCollection(projectId, { refreshFromNetwork: true });
  const collectionName = useCollectionKey(projectId, "name");
  const collectionMetadataIds = useCollectionKey(projectId, "metadataIds");
  const collectionConversationId = useCollectionKey(projectId, "conversationId");
  const maybeCollectionId = useCollectionKey(projectId, "id");

  const { setSelectedItems, resetSelectedItems, setFocusedItem } = useItemSelector();
  const [sortName] = useState<SortOption>("Date");
  const [sortDirection] = useState<SortDirection>("DESC");
  const [groupByName] = useState<GroupByOption>("");
  const [groupByDirection] = useState<GroupBySortDirection>("ASC");
  const textColor = useColorModeValue("gray.700", "gray.100");
  const { setHeaderText } = useContext(SettingsProviderContext);
  const {
    isHighlightsPanelOpen,
    isProjectAddSourceOpen,
    onContentViewOpen,
    onContentViewClose,
    isContentViewOpen,
    isProjectReportSelectorOpen,
    onProjectReportSelectorClose,
  } = useAddToCharliContext();
  const workflowId = useLatestCollectionWorkflowId(projectId);
  const workflowConversationId = useWorkflowKey(workflowId, "conversationId");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const hasInitialSyncCompleted = useInitialSyncCompleted();
  const navigate = useNavigate();
  const toast = useToast();

  const contentData = useContents(collectionMetadataIds || [], { refreshFromNetwork: true });
  const dispatch = useDispatch();
  const userPreferenceSourceWeight = useUserPreference("source_weights_index") as 0 | 1;
  const hasConversation = useHasConversation(collectionConversationId);

  useEffect(() => {
    userPreferenceSourceWeight && dispatch(deleteUserPreference({ preferenceKey: "source_weights_index" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const viewConversationId = collectionConversationId || contentId || workflowConversationId;
    viewConversationId && setConversationId(viewConversationId);
    setHeaderText(collectionName ?? "Project", true);
    if (!contentId && projectId) {
      setFocusedItem({ id: projectId, type: "collection_id" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentRoute, projectFilter, projectId, contentId]);

  useEffect(() => {
    if (contentId) {
      onContentViewOpen();
    } else {
      onContentViewClose();
    }
  }, [contentId, onContentViewClose, onContentViewOpen]);

  useEffect(() => {
    if (contentData?.length) {
      const items =
        contentData &&
        contentData.reduce((cells, cell) => {
          const itemType = getItemTypeForContent(cell.type);
          cells[cell.mediaId] = { type: itemType };
          return cells;
        }, {} as { [id: string]: { type: SupportedItemKeys } });
      if (items) setSelectedItems(items);
    } else {
      resetSelectedItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentData?.length, contentId, isHighlightsPanelOpen, isProjectAddSourceOpen]);

  useEffect(() => {
    if (hasInitialSyncCompleted && maybeCollectionId && maybeCollectionId !== projectId) {
      onConversationClose();
      toast({
        render: ({ onClose }) => (
          <ToastMessageContent
            message={`Unfortunately I can't find that ${parentRoute}, please ensure that you are logged in with the correct account and try again.`}
            onClick={() => {
              onConversationClose();
              onClose();
            }}
            onClose={onClose}
          />
        ),
        duration: 10000,
        isClosable: true,
        position: "top-right",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInitialSyncCompleted, projectId, navigate, toast, parentRoute]);

  useEffect(() => {
    if (collectionConversationId && !hasConversation) {
      dispatch(downloadConversationById({ conversationId: collectionConversationId }));
    }
  }, [collectionConversationId, hasConversation, dispatch]);

  const contentToRender = useMemo(() => {
    if (!maybeCollectionId) {
      return (
        <Center height="100%">
          <Stack fontSize={"sm"} align="center" spacing="1rem" minHeight="11rem" justifyContent="center">
            <Text color={textColor}>{`I couldn't find any resources for this ${
              parentRoute === "collection" ? "collection" : "project"
            }`}</Text>
            <Text color={textColor}>You can add some of your latest content using the "Add Resource" button.</Text>
          </Stack>
        </Center>
      );
    } else {
      return <ProjectDetailViewContent collectionId={maybeCollectionId} />;
    }
  }, [maybeCollectionId, parentRoute, textColor]);

  return (
    <ContentFilterContextProvider contentItems={contentData}>
      <TabTitle title={`Charli > ${toTitleCase(collectionName || parentRoute || "Project")}`} />
      <ContentSortingGroupingContext.Provider
        value={{
          sortName,
          sortDirection,
          groupByName,
          groupByDirection,
        }}>
        <Box width="100%" height="100%" px={isMobile ? ".5rem" : "1rem"} pt={isMobile ? ".5rem" : "1rem"}>
          <LoadingGate isLoading={!hasInitialSyncCompleted} height="100%">
            {contentToRender}
          </LoadingGate>
        </Box>
        <TagsPanel />
        <ContentViewPanel />
        <VerifiedAIPanel />
        <ProjectKeywordsNotesPanel />
        {isContentViewOpen && (
          <>
            <SharePanel />
            <NotesPanel />
          </>
        )}
        <ProjectAddSource />
        {maybeCollectionId && (
          <ProjectReportPanelNewReportModal
            onClose={onProjectReportSelectorClose}
            isOpen={isProjectReportSelectorOpen}
            collectionId={maybeCollectionId}
          />
        )}
      </ContentSortingGroupingContext.Provider>
    </ContentFilterContextProvider>
  );
};
