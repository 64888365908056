import { Stack } from "@chakra-ui/react";
import {
  useCustomTheme,
  useEntitlementKey,
  useFeatureFlags,
  useUserSubscriptionDetails,
  useUserSubscriptionDetailsDaysTillNextBilling,
} from "hooks";
import type { FunctionComponent } from "react";
import React from "react";
import { UpgradePlanButton } from "screens/panels/settings/tabs/subscription/SubscriptionUpgrade";
import { InteractionBarFindInput } from "./InteractionBarFindInput";
import { NotificationsMenu } from "./NotificationsMenu";
import { ProfileSettingsMenu } from "./ProfileSettingsMenu";

interface Props {
  initialText?: string;
}

export const InteractionBar: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ initialText = "" }) => {
  const { isEmbeddedApp } = useCustomTheme();
  const hasSearch = useEntitlementKey("ui_enable_search");
  const subscriptionInfo = useUserSubscriptionDetails();
  const { full_maxio_integration: isSubscriptionEnabled } = useFeatureFlags();
  const daysTillPlanExpiry = useUserSubscriptionDetailsDaysTillNextBilling();

  return (
    <Stack direction="row" width="100%" justifyContent={"flex-end"} p="0.5rem" align="center" spacing="1em">
      {hasSearch && <InteractionBarFindInput initialText={initialText} />}
      <NotificationsMenu />
      {isSubscriptionEnabled &&
        subscriptionInfo.plan === "Trial Plan" &&
        daysTillPlanExpiry.daysTillNextBilling >= daysTillPlanExpiry.expireWarningDays && (
          <UpgradePlanButton
            tooltip={`You have ${daysTillPlanExpiry.daysTillNextBilling} days left on the free trial. Click here to upgrade to the Business plan`}
            buttonSize="xs"
          />
        )}
      {!isEmbeddedApp && <ProfileSettingsMenu />}
    </Stack>
  );
};
