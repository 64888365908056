import { formatISO, startOfMonth } from "date-fns";
import { prependProxyBaseUrl, request } from "../request";
import { UserFeatureUsage } from "./models/UserFeatureUsage";

const getFeatureUsageEndpoint = (userId: string, startDate: string) =>
  `${prependProxyBaseUrl("/api/usage")}/users/${userId}/usages?${new URLSearchParams({ startDate })}`;

export async function getFeatureUsage(userId: string): Promise<UserFeatureUsage> {
  const response = await request()
    .url(getFeatureUsageEndpoint(userId, formatISO(startOfMonth(new Date()))))
    .get()
    .json();

  const validate = UserFeatureUsage.validate(response);

  if (validate.success) {
    return validate.value;
  } else {
    throw new Error(validate.message);
  }
}
