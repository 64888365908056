import React from "react";
import { Avatar, Center, MenuList, useColorModeValue, Menu, MenuButton, MenuItem, Icon, Stack, Text } from "@chakra-ui/react";
import { useUserProfile } from "hooks";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { HiOutlineCog, HiOutlineLockClosed, HiOutlinePuzzle } from "react-icons/hi";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";

export const ProfileSettingsMenu = () => {
  const { fullName, avatarUrl } = useUserProfile();
  const buttonColor = useColorModeValue("primaryButton.color", "gray.400");
  const navigate = useNavigate();
  const { onSettingsOpen } = useAddToCharliContext();
  const buttonTextColor = useColorModeValue("gray.600", "gray.400");
  const textHoverColor = useColorModeValue("primary.hover", "gray.300");

  return (
    <Menu isLazy>
      <MenuButton className="ch-profile-menu">
        <Center cursor="pointer">
          <Avatar name={fullName} src={avatarUrl} boxSize="1.6rem" fontSize={"xs"} />
          <ChevronDownIcon boxSize="1.3rem" color={buttonColor} />
        </Center>
      </MenuButton>
      <MenuList minWidth={"10.5rem"} zIndex={3}>
        <MenuItem onClick={onSettingsOpen} className="ch-profile-settings">
          <Stack cursor="pointer" direction="row" spacing="1rem">
            <Center>
              <Icon as={HiOutlineCog} color={buttonColor} boxSize="1.1rem" />
            </Center>
            <Text fontSize="sm" color={buttonTextColor} _hover={{ color: textHoverColor }}>
              Account Settings
            </Text>
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/integrations");
          }}
          className="ch-profile-integrations">
          <Stack cursor="pointer" direction="row" spacing="1rem">
            <Center>
              <Icon as={HiOutlinePuzzle} color={buttonColor} boxSize="1.1rem" />
            </Center>
            <Text fontSize="sm" color={buttonTextColor} _hover={{ color: textHoverColor }}>
              Integrations
            </Text>
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/logout");
          }}
          className="ch-profile-logout">
          <Stack cursor="pointer" direction="row" spacing="1rem">
            <Center>
              <Icon as={HiOutlineLockClosed} color={buttonColor} boxSize="1.1rem" />
            </Center>
            <Text fontSize="sm" color={buttonTextColor} _hover={{ color: textHoverColor }}>
              Log Out
            </Text>
          </Stack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
