import { Stack, Text, useColorModeValue, Divider, Box, useOutsideClick } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useRef, useState } from "react";
import React, { useContext, useMemo } from "react";
import ECHighlighter from "react-ec-highlighter";
import { ContentFilterContext } from "screens/content";
import type { Question, Answer } from "types/question";
import { AnswerStatus } from "types/question";
import { useCollectionKey, useFeatureFlags } from "hooks";
import { AnswerCardPinned } from "./AnswerCardPinned";
import { BlockSectionHeader } from "screens/content/contentView/previewSection/BlockSectionHeader";
import { BiCopy } from "react-icons/bi";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { Popover } from "react-tiny-popover";
import { ProjectQuestionActionButtons } from "./ProjectActions/ProjectQuestionActionButtons";
import { focusBadgeColor } from "./AutocompleteInput";
import HelpPopover from "screens/landing/components/popoverComponent/HelpPopover";

interface Props {
  questionAnswers: Question[];
  collectionId?: string;
}

export const QuestionAnswerResults: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  questionAnswers,
  collectionId,
}) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const bgColorQA = useColorModeValue("white", "gray.800");
  const { searchText } = useContext(ContentFilterContext);
  const conversationId = useCollectionKey(collectionId, "conversationId") || "";

  const filteredQuestions = useMemo(() => {
    const questionsWithAnswers = questionAnswers || [];

    if (searchText && searchText.length > 0) {
      return questionsWithAnswers.filter(
        (question) =>
          question.question.toLowerCase().includes(searchText.toLowerCase()) ||
          question.answers.filter((answer) => answer.answer.toLowerCase().includes(searchText.toLowerCase())).length > 0
      );
    }

    return questionsWithAnswers;
  }, [questionAnswers, searchText]);

  const getQuestionAnswers = useMemo(() => {
    const questionsMap = filteredQuestions.reduce(
      (acc: Record<string, { pinnedAnswers: Answer[]; unpinnedAnswers: Answer[] }>, question) => ({
        ...acc,
        [question.id]: {
          pinnedAnswers: question.answers.filter((answer) => answer.answerStatus === "pinned"),
          unpinnedAnswers: question.answers.filter((answer) => answer.answerStatus === "unpinned"),
        },
      }),
      {}
    );

    return (questionId: string, answerStatus: AnswerStatus | "all") => {
      const answers = questionsMap[questionId];

      if (!answers) {
        return [];
      }

      return answerStatus === "pinned"
        ? answers.pinnedAnswers
        : answerStatus === "unpinned"
        ? answers.unpinnedAnswers
        : [...answers.pinnedAnswers, ...answers.unpinnedAnswers];
    };
  }, [filteredQuestions]);

  const copyAnswers = (question?: Question[]) => {
    const selectedTopicHighlights = question
      ?.map((question) => {
        let answerSection = question.question ? `Question: ${question.question} \r\n` : "";
        question.answers.map((answer) => (answerSection += answer.answer + "\r\n"));
        return answerSection + "\r\n";
      })
      .join("");
    return selectedTopicHighlights || "";
  };

  const [popoverIndex, setPopoverIndex] = useState(-1);
  const mainRef = useRef<HTMLDivElement | null>(null);
  const popRef = useRef<HTMLDivElement | null>(null);
  const { show_answer_actions_popover: showActionPopover } = useFeatureFlags();

  useOutsideClick({
    ref: popRef!,
    handler: () => {
      setPopoverIndex(-1);
    },
  });

  const renderSentimentBadge = (focus: string) => {
    return (
      <Box
        className={`ch-qa-result-question-focus-${focus ? focus.toLowerCase() : "none"}`}
        borderRadius={"4px"}
        height="1.2rem"
        lineHeight={"1.2rem"}
        px="5px"
        fontSize={"10px"}
        color="gray.600"
        bgColor={focusBadgeColor[focus.toUpperCase()]}>
        {focus.toUpperCase()}
      </Box>
    );
  };

  return (
    <Stack>
      <BlockSectionHeader title="AI Questions & Answers">
        <SmallActionButton
          classNames="ch-copy-record"
          iconTypeName={BiCopy}
          onClick={() => navigator.clipboard.writeText(copyAnswers(filteredQuestions))}
          tooltip={"Copy Answers"}
        />
      </BlockSectionHeader>
      {filteredQuestions.length > 0 &&
        filteredQuestions.map((questionRecord, questionIndex) => (
          <Stack width="100%" key={questionIndex} backgroundColor={bgColorQA} pb=".5rem">
            <Stack direction="row" justifyContent="space-between">
              <Box ref={mainRef} onMouseLeave={() => showActionPopover && setPopoverIndex(-1)}>
                <Popover
                  isOpen={popoverIndex === questionIndex}
                  positions={["top"]}
                  align="start"
                  transformMode="relative"
                  onClickOutside={() => setPopoverIndex(-1)}
                  content={() => <ProjectQuestionActionButtons />}>
                  <Text
                    borderWidth={"1px"}
                    borderColor={popoverIndex === questionIndex ? "primary.default" : "transparent"}
                    borderStyle={"dashed"}
                    cursor={showActionPopover ? "pointer" : "default"}
                    onMouseEnter={() => showActionPopover && setPopoverIndex(questionIndex)}
                    onClick={() => showActionPopover && setPopoverIndex(questionIndex)}
                    width="100%"
                    className="ch-qa-result-question"
                    fontWeight="semibold"
                    color={textColor}
                    fontSize="md">
                    {searchText && searchText.length > 0 && questionRecord.question ? (
                      <ECHighlighter searchPhrase={searchText} text={questionRecord.question} />
                    ) : (
                      questionRecord.question
                    )}
                  </Text>
                </Popover>
              </Box>
              {questionRecord.focus ? (
                <>
                  {questionIndex === 0 ? (
                    <HelpPopover
                      title="Sentiment Badge"
                      message="Click here to view the attributions on the source material Charli used for generating the response.">
                      {renderSentimentBadge(questionRecord.focus)}
                    </HelpPopover>
                  ) : (
                    renderSentimentBadge(questionRecord.focus)
                  )}
                </>
              ) : null}
            </Stack>
            <Stack justifyContent="space-between" spacing="1rem" mt="0!important" width="100%">
              {getQuestionAnswers(questionRecord.id, "all").length === 0 ? (
                <AnswerCardPinned
                  questionRecord={questionRecord}
                  index={questionIndex}
                  key={questionRecord.id}
                  collectionId={collectionId || ""}
                  conversationId={conversationId}
                />
              ) : (
                getQuestionAnswers(questionRecord.id, AnswerStatus.pinned).map((answerRecord) => (
                  <AnswerCardPinned
                    questionRecord={questionRecord}
                    answerRecord={answerRecord}
                    enableHelpPopover={questionIndex === 0}
                    index={questionIndex}
                    key={answerRecord.id}
                    collectionId={collectionId || ""}
                    conversationId={conversationId}
                  />
                ))
              )}
            </Stack>
            <Divider />
          </Stack>
        ))}
    </Stack>
  );
};
