import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useMaxAllowedProjectsReached } from "hooks/useStats";
import { Box, Tag, useToast } from "@chakra-ui/react";
import {
  useCollection,
  useConfigMap,
  useEntitlements,
  useFeatureFlags,
  useGetViewConfig,
  useProjectParams,
  useUserPreference,
} from "hooks";
import { onSubmitDueDiligence } from "screens/collection/components/useSubmitDueDiligence";
import { useDispatch } from "react-redux";
import { useFeatureUsage } from "hooks/useFeatureUsage";
import type { SuggestedQuestion, SuggestedQuestions } from "api/suggestions";
import { ToastMessageContent } from "screens/common/components";
import { useAutocompleteSourceContext } from "../../contexts/AutocompleteSourceContext";
import { useDebouncedCallback } from "use-debounce";
import { v4 as uuid } from "uuid";
import { useInputBar } from "screens/conversation/components/InputBar/useInputBar";
import { InputBarDropzoneContext } from "screens/conversation/components/InputBar/InputBarDropzoneContext";
import { useAvailableCommands } from "screens/collection/views/AutoCompleteInputUtils";

interface Props {
  collectionType?: string;
  portfolioId?: string;
  toastMessage?: string;
  afterSubmit?: () => void;
  maxSuggestions?: number;
  questionText: string;
  conversationId?: string;
}

const formatSelectedQuestion = (question: string) => {
  // This formatter is used with the stock exchange data to extract the stock ticker from the question
  return question.includes("|") ? question.split("|").slice(1)[0].replace(/\(|\)/g, "").trim() : question;
};

export const useProjectLandingAutocompleteUtils = ({
  collectionType,
  portfolioId,
  toastMessage,
  afterSubmit,
  maxSuggestions,
  questionText,
  conversationId,
}: Props) => {
  const { projectFilter, isPortfolios } = useProjectParams();
  const collection = useCollection(isPortfolios ? projectFilter : undefined);
  const configMap = useConfigMap();
  const portfolioCollectionType = useGetViewConfig("portfolioProjectType", collection?.collectionType, configMap);
  const currentCollectionType = collectionType || portfolioCollectionType;
  const hasExceededMaxProjects = useMaxAllowedProjectsReached(currentCollectionType || "");
  const maxAllowedProjects = useGetViewConfig("maxActiveProjectsAllowed", currentCollectionType, configMap);
  const intent = useGetViewConfig("intent", currentCollectionType, configMap);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const toast = useToast();
  const [suggestedQuestions, setSuggestedQuestions] = useState<SuggestedQuestions>([]);
  const [isValidQueryMap, setIsValidQueryMap] = useState<{ [key: string]: boolean }>({});
  const [isValidQueryAgainstMatchFiltersMap, setIsValidQueryAgainstMatchFiltersMap] = useState<{ [key: string]: boolean }>({});
  const projectUsage = useFeatureUsage(intent ?? "");
  const { bypass_usage_limits: canBypassUsageLimits } = useEntitlements();
  const { onConversationOpen, onUpgradeModalOpen, isConversationOpen } = useConversationContext();
  const { full_maxio_integration: isSubscriptionEnabled } = useFeatureFlags();
  const cachedResultsByQuery = useRef<{ [key: string]: SuggestedQuestions }>({});
  const hideCommandLineButton = (useUserPreference("ui_hide_command_line_button") as boolean) || false;
  const { files } = useContext(InputBarDropzoneContext);

  const newConversationId = uuid();
  const { sendMessage: sendInputBarMessage } = useInputBar({
    conversationId: conversationId || newConversationId,
    areAttachmentsDisabled: false,
  });

  const [showCommandSuggestions, setShowCommandSuggestions] = useState(false);
  useEffect(() => {
    setShowCommandSuggestions(questionText.startsWith("/") || files.length > 0);
  }, [files.length, questionText]);
  const { getSourceList } = useAutocompleteSourceContext();

  const { filterCommandsMemo } = useAvailableCommands(questionText, hideCommandLineButton);

  const isOnSubmitDueDiligenceProject = useCallback(
    (selectedItem: SuggestedQuestion) => {
      if (isSubscriptionEnabled && !canBypassUsageLimits && projectUsage && projectUsage.used >= projectUsage.allowed) {
        onUpgradeModalOpen();
        return;
      }

      const portfolioIdValue = portfolioId ? portfolioId : projectFilter && isPortfolios ? projectFilter : undefined;
      afterSubmit && afterSubmit();

      onSubmitDueDiligence(
        formatSelectedQuestion(selectedItem.question),
        selectedItem.focus || "",
        true,
        dispatch,
        toastMessage ? undefined : onConversationOpen,
        [],
        currentCollectionType,
        intent,
        portfolioIdValue
      );

      if (toastMessage) {
        toast.closeAll();
        toast({
          render: ({ onClose }) => (
            <ToastMessageContent
              message={toastMessage}
              onClick={() => {
                onClose();
              }}
              onClose={onClose}
            />
          ),
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    },
    [
      isSubscriptionEnabled,
      canBypassUsageLimits,
      projectUsage,
      portfolioId,
      projectFilter,
      isPortfolios,
      afterSubmit,
      dispatch,
      toastMessage,
      onConversationOpen,
      currentCollectionType,
      intent,
      onUpgradeModalOpen,
      toast,
    ]
  );

  const debouncedFilter = useDebouncedCallback((query: string) => {
    if (cachedResultsByQuery.current[query]) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setSuggestedQuestions(cachedResultsByQuery.current[query]);
      }, 150);

      return;
    }

    setIsLoading(true);
    setSuggestedQuestions([]);

    getSourceList({ limit: maxSuggestions, ...(query && { query }) })
      .then((response) => {
        setIsValidQueryMap((prev) => ({ ...prev, [query]: response.length > 0 }));
        setIsValidQueryAgainstMatchFiltersMap((prev) => ({
          ...prev,
          [query]: response.some((suggestedQuestion) => suggestedQuestion.matchFilter?.includes(query.trim().toLowerCase())),
        }));
        setIsLoading(false);
        setSuggestedQuestions(response);
        cachedResultsByQuery.current[query] = response;
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, 150);

  const renderErrorMessage = () => {
    return (
      <Box className="ch-autocomplete-error" width="100%" pt="5px">
        {hasExceededMaxProjects && (
          <Tag ml="3rem" size={"sm"} colorScheme="yellow">
            {hasExceededMaxProjects
              ? `You can only have ${maxAllowedProjects} active ${collectionType?.replace(/_/g, " ")} projects at a time`
              : ""}
          </Tag>
        )}
        {questionText.length > 0 && !questionText.startsWith("/") && isValidQueryMap[questionText] === false && (
          <Box
            ml={isConversationOpen ? "2.5rem" : "4rem"}
            mr={isConversationOpen ? "2.5rem" : "2rem"}
            fontSize={"sm"}
            bgColor="orange.100"
            borderRadius={"md"}
            p="8px"
            lineHeight={"1.4rem"}>
            Please select a ticker from the list. We currently support NASDAQ, NYSE & TSX exchanges. Other exchanges are coming soon.{" "}
          </Box>
        )}
      </Box>
    );
  };

  const onSubmitDueDiligenceProject = useCallback(
    (selectedItem: SuggestedQuestion) => {
      if (isSubscriptionEnabled && !canBypassUsageLimits && projectUsage && projectUsage.used >= projectUsage.allowed) {
        onUpgradeModalOpen();
        return;
      }

      const portfolioIdValue = portfolioId ? portfolioId : projectFilter && isPortfolios ? projectFilter : undefined;
      afterSubmit && afterSubmit();

      onSubmitDueDiligence(
        formatSelectedQuestion(selectedItem.question),
        selectedItem.focus || "",
        true,
        dispatch,
        toastMessage ? undefined : onConversationOpen,
        [],
        currentCollectionType,
        intent,
        portfolioIdValue
      );

      if (toastMessage) {
        toast.closeAll();
        toast({
          render: ({ onClose }) => (
            <ToastMessageContent
              message={toastMessage}
              onClick={() => {
                onClose();
              }}
              onClose={onClose}
            />
          ),
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    },
    [
      isSubscriptionEnabled,
      canBypassUsageLimits,
      projectUsage,
      portfolioId,
      projectFilter,
      isPortfolios,
      afterSubmit,
      dispatch,
      toastMessage,
      onConversationOpen,
      currentCollectionType,
      intent,
      onUpgradeModalOpen,
      toast,
    ]
  );

  return {
    onSubmitDueDiligenceProject,
    sendInputBarMessage,
    newConversationId,
    isLoading,
    setIsLoading,
    suggestedQuestions,
    projectUsage,
    canBypassUsageLimits,
    onConversationOpen,
    onUpgradeModalOpen,
    isConversationOpen,
    showCommandSuggestions,
    setShowCommandSuggestions,
    filterCommandsMemo,
    isOnSubmitDueDiligenceProject,
    debouncedFilter,
    renderErrorMessage,
    getSourceList,
    maxSuggestions,
    isValidQueryAgainstMatchFiltersMap,
  };
};
