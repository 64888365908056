import {
  useColorModeValue,
  InputGroup,
  InputLeftAddon,
  IconButton,
  InputRightElement,
  Box,
  Stack,
  useBreakpointValue,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState, useMemo, useCallback, useContext } from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { CharliLogoCircle } from "screens/thread/CharliLogoCircle";
import type { SuggestedQuestion, SuggestedQuestions } from "api/suggestions";
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList } from "@choc-ui/chakra-autocomplete";
import type { AutoCompleteRefMethods } from "@choc-ui/chakra-autocomplete";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import HelpPopover from "screens/landing/components/popoverComponent/HelpPopover";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { v4 as uuid } from "uuid";
import { InputBarAttachmentButton } from "screens/conversation/components/InputBar/InputBarAttachmentButton";
import { InputBarDropzoneContext } from "screens/conversation/components/InputBar/InputBarDropzoneContext";
import { useProjectLandingAutocompleteUtils } from "screens/landing/tabs/project/projectLandingTileLayouts/components/ProjectLandingAutocompleteInputUtils";

export interface AutocompleteInputProps {
  initialText?: string;
  suggestedQuestions?: SuggestedQuestions;
  isInputDisabled?: boolean;
  isSubmitDisabled?: boolean;
  onSelectOption: (value: SuggestedQuestion) => void;
  value: string;
  onChange: (value: string) => void;
  onReset?: () => void;
  isLoading?: boolean;
  submitOnEnterOrTab?: boolean;
  onClickSubmit?: () => void;
  conversationId?: string;
  minWidth?: string;
  defaultIsOpen?: boolean;
  disableFilter?: boolean;
  className?: string;
  conversationState?: string;
}

export const focusBadgeColor = {
  SENTIMENT: "orange.200",
  ANALYTICAL: "twitter.50",
  NASDAQ: "purple.100",
  NYSE: "orange.100",
  TSX: "blue.100",
};

export const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  initialText = "",
  suggestedQuestions = [],
  isInputDisabled = false,
  isSubmitDisabled = false,
  onSelectOption,
  value,
  onChange,
  onReset,
  isLoading = false,
  onClickSubmit,
  submitOnEnterOrTab = false,
  conversationId,
  minWidth = "32rem",
  disableFilter = false,
  defaultIsOpen = false,
  className,
  conversationState,
}) => {
  const isConnected = useSelector((state: RootState) => state.websocket.isConnected);
  const inputBgColor = useColorModeValue("white", "gray.800");
  const inputBorderColor = useColorModeValue("gray.200", "gray.800");
  const submitColor = useColorModeValue("primary.default", "gray.800");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { isConversationOpen, onConversationClose, onConversationOpen } = useConversationContext();
  const { setIsNewMenuAction } = useAddToCharliContext();
  const autocompleteRef = useRef<AutoCompleteRefMethods>();
  const inputRef = useRef<HTMLInputElement>(null);
  const isMounted = useRef(false);
  const [hasUsedEnterToSelect, setHasUsedEnterToSelect] = useState(false);
  const { files } = useContext(InputBarDropzoneContext);

  // TODO: this is a workaround to prevent the suggestion list from showing when questionText is empty
  const [showSuggestionList, setShowSuggestionList] = useState(false);

  const suggestedQuestionsWithId: (SuggestedQuestion & { id: string })[] = useMemo(() => {
    return suggestedQuestions.map((question) => ({ ...question, id: uuid() }));
  }, [suggestedQuestions]);

  const onHandleConversation = () => {
    if (isConversationOpen) {
      onConversationClose();
    } else {
      setIsNewMenuAction(conversationId ? undefined : "command");
      onConversationOpen(conversationId);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isConversationOpen]);

  const isSubmitAvailable = useCallback((): boolean => {
    return !!(
      value &&
      value.length > 0 &&
      !suggestedQuestions.some((suggestion) => suggestion.question.toLowerCase().trim() === value.toLowerCase().trim())
    );
  }, [suggestedQuestions, value]);

  useEffect(() => {
    const handleKeyDown = (ev: KeyboardEvent) => {
      if (ev.key === "Enter" || ev.key === "Tab") {
        const isSubmitAvailableValue = isSubmitAvailable();
        if (isSubmitAvailableValue || hasUsedEnterToSelect || submitOnEnterOrTab || value.startsWith("/")) {
          if (onClickSubmit) {
            onClickSubmit();
          } else if (onSelectOption) {
            onSelectOption(suggestedQuestions[0]);
          }
          setHasUsedEnterToSelect(false);
          setShowSuggestionList(false);
        } else {
          setHasUsedEnterToSelect(true);
        }
      }
      if (ev.key === "Escape") {
        setHasUsedEnterToSelect(false);
        setShowSuggestionList(false);
        onReset ? onReset() : onChange("");
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    isSubmitAvailable,
    onClickSubmit,
    onReset,
    onChange,
    hasUsedEnterToSelect,
    submitOnEnterOrTab,
    isConversationOpen,
    value,
    onSelectOption,
    suggestedQuestions,
    onConversationClose,
  ]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const { debouncedFilter } = useProjectLandingAutocompleteUtils({ questionText: "" });

  return (
    <Stack spacing="0" width="100%" height="100%">
      <AutoComplete
        ref={autocompleteRef}
        onSelectOption={({ item: { originalValue } }) => {
          const suggestedQuestion = JSON.parse(originalValue) as SuggestedQuestion;
          onSelectOption(suggestedQuestion);
        }}
        defaultIsOpen={defaultIsOpen}
        disableFilter={disableFilter}
        freeSolo
        emptyState={false}
        submitKeys={["Tab"]}>
        {({ onOpen }) => (
          <>
            <InputGroup>
              <HelpPopover
                title="Open Conversation"
                message="For advanced users, click this button to open the Charli conversation dialog.">
                <InputLeftAddon
                  backgroundColor={inputBgColor}
                  paddingInlineStart={"0"}
                  paddingInlineEnd={value.startsWith("/") ? "0" : "1rem"}
                  borderRadius="30px 0px 0px 30px!important"
                  cursor="pointer"
                  children={
                    <Stack direction={"row"} width="100%" justifyContent={"space-between"}>
                      <CharliLogoCircle className="project-conversation-button" onClick={onHandleConversation} />
                      {(value.startsWith("/") || files.length > 0 || (conversationState && conversationState === "action")) && (
                        <Center width="2rem" borderRadius={"30px 0px 0px 30px!important"}>
                          <InputBarAttachmentButton size={"md"} />
                        </Center>
                      )}
                    </Stack>
                  }
                />
              </HelpPopover>
              <AutoCompleteInput
                ref={inputRef}
                onClick={() => {
                  debouncedFilter("");
                  setShowSuggestionList(true);
                  onOpen();
                }}
                autoComplete={"off"}
                paddingInlineStart={"0"}
                paddingInlineEnd="2.5rem"
                borderRadius="0px 30px 30px 0px!important"
                aria-autocomplete="both"
                className={`${className ? className : "ch-question-input"}`}
                disabled={isInputDisabled}
                backgroundColor={inputBgColor}
                placeholder={initialText}
                onChange={(ev) => {
                  setShowSuggestionList(true);
                  onChange(ev.target.value);
                }}
                loadingIcon={<TypingIndicator size="small" />}
                size="md"
                borderColor={inputBorderColor}
                borderWidth="1px"
                borderLeftWidth="0"
                value={value}
              />
              <InputRightElement
                width="2.5rem"
                height="2.5rem"
                zIndex={value.length > 0 ? 2 : 0}
                children={
                  <IconButton
                    className="ch-autocomplete-submit"
                    aria-label="submit"
                    borderRadius={"full"}
                    color={submitColor}
                    size="sm"
                    backgroundColor={inputBgColor}
                    _hover={{ fontWeight: "bold" }}
                    icon={isLoading ? <TypingIndicator size="small" /> : <ArrowForwardIcon width="1.5rem" height="1.5rem" />}
                    isDisabled={!isConnected || isSubmitDisabled}
                    onClick={() => {
                      onClickSubmit && onClickSubmit();
                      setShowSuggestionList(false);
                    }}
                  />
                }
              />
            </InputGroup>
            {suggestedQuestionsWithId.length > 0 && showSuggestionList && (
              <AutoCompleteList
                boxShadow={"xl"}
                p="0"
                marginLeft={"-1rem!important"}
                marginTop="-2px!important"
                minWidth={["unset", "unset", minWidth]}>
                {suggestedQuestionsWithId.map(({ question, focus, id }) => (
                  <AutoCompleteItem
                    key={`option-${id}`}
                    value={JSON.stringify({ question, focus })}
                    className="ch-autocomplete-suggestion"
                    marginInline={0}
                    p="1rem"
                    borderRadius={"none"}>
                    <Stack direction="row" justifyContent={"space-between"} spacing="1rem" alignItems="center" width="100%">
                      <TextOverflowTooltip
                        className="ch-autocomplete-suggestion-text"
                        label={question.replace("|", "")}
                        searchText={value}
                        highlightBackground={false}
                      />
                      {focus && (
                        <Box
                          className="ch-autocomplete-suggestion-tag"
                          borderRadius={"4px"}
                          py="2px"
                          px="5px"
                          fontSize={"10px"}
                          color="gray.600"
                          background={focusBadgeColor[focus.toUpperCase()] || "gray.100"}>
                          {isMobile ? focus.toUpperCase().slice(0, 1) : focus.toUpperCase()}
                        </Box>
                      )}
                    </Stack>
                  </AutoCompleteItem>
                ))}
              </AutoCompleteList>
            )}
          </>
        )}
      </AutoComplete>
    </Stack>
  );
};
