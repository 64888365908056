import React, { useContext, useEffect, useMemo, useState } from "react";
import { ConversationContext } from "screens/thread/ConversationContext";
import {
  Button,
  Center,
  FormControl,
  FormLabel,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { useButtonProps, useConversation, useEntitlements, useItemSelector } from "hooks";
import { isAcceptedSummaryAVTypeByExtension } from "screens/common/modal/utils";
import { ToastMessageContent } from "screens/common/components";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { OptionsMenuItem } from "screens/collection/components/OptionsMenuItem";
import type { ContentDetails } from "types/content/ContentDetails";

interface Props {
  itemContent: ContentDetails;
  showAsButton?: boolean;
}

export const SummarizeButtonMenuItem = ({ itemContent, showAsButton = false }: Props) => {
  const [summarizeSize, setSummarizeSize] = useState("long");
  const [summarizePages, setSummarizePages] = useState("2");
  const dispatch = useDispatch();
  const { focusedItem } = useItemSelector();
  const { summarization: hasSummarizationEntitlement } = useEntitlements();
  const { onConversationOpen, setConversationId } = useContext(ConversationContext);
  const summaryState = useConversation(itemContent.id || "", ["summarize", "generate_summary", "cancel"]);
  const additionalThumbnailCountTextColor = useColorModeValue("gray.500", "gray.400");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const primaryButtonProps = useButtonProps("sm", "primary");
  const secondaryButtonProps = useButtonProps("sm", "secondary");

  useEffect(() => {
    setSummarizeSize("long");
    setSummarizePages("2");
  }, [isOpen]);

  const summarize = () => {
    if (!itemContent) return;
    dispatch(
      sendMessage({
        conversationId: itemContent.id,
        message: "Summarize this resource",
        intent: `/${summarizeSize === "comprehensive" ? "generate_comprehensive_summary" : "generate_summary"}`,
        entities: getSummaryEntities,
        datum: [
          {
            type: "update_data_callback",
            includeInResponse: true,
            body: { items: [{ type: "collection", id: itemContent.id }] },
          },
        ],
      })
    );
    onClose();
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={`No problem, I'll work on summarizing this now. You can open the conversation I started about it by clicking this message`}
          onClick={() => {
            setConversationId(itemContent.id);
            onConversationOpen();
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const getSummaryEntities = useMemo(() => {
    const entities: {
      entity: string;
      value: string;
    }[] = [];

    if (focusedItem) {
      entities.push({ entity: focusedItem.type, value: focusedItem.id });
    }
    const isAVextension = isAcceptedSummaryAVTypeByExtension(focusedItem?.extension);
    if (isAVextension) {
      entities.push({ entity: "document_category_av", value: "true" });
    }

    if (summarizeSize === "comprehensive") {
      entities.push({ entity: "summary_num_pages ", value: summarizePages });
    } else {
      entities.push({ entity: "min_summary_length", value: "450" });
      entities.push({ entity: "max_summary_length", value: "512" });
      entities.push({ entity: "summarize_full_text", value: "True" });
    }
    return entities;
  }, [focusedItem, summarizePages, summarizeSize]);

  return (
    <>
      {showAsButton ? (
        <Center width="100%" height="4rem">
          {summaryState.conversationState !== "in_progress" ? (
            <Button
              {...primaryButtonProps}
              onClick={() => {
                focusedItem?.extension === "pdf" ? onOpen() : summarize();
              }}>
              Generate Summary
            </Button>
          ) : (
            <Stack direction="row" spacing="1rem">
              <Text color={additionalThumbnailCountTextColor} fontSize={"sm"}>
                Generating Summary
              </Text>
              <TypingIndicator size="small" />
            </Stack>
          )}
        </Center>
      ) : (
        <>
          {summaryState.conversationState === "in_progress" ? (
            <MenuItem isDisabled>
              <Stack direction="row" spacing="1rem">
                <Text color={additionalThumbnailCountTextColor} fontSize={"sm"}>
                  Generating Summary
                </Text>
                <TypingIndicator size="small" />
              </Stack>
            </MenuItem>
          ) : (
            <OptionsMenuItem
              menuLabel={"Generate Summary"}
              className="ch-generate-summary-button-menu"
              onClick={() => {
                focusedItem?.extension === "pdf" ? onOpen() : summarize();
              }}
              isDisabled={!hasSummarizationEntitlement}
            />
          )}
        </>
      )}
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generate Summary</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize={"sm"}>
            {focusedItem?.extension === "pdf" && (
              <Stack direction="row" justifyContent={"space-between"} spacing="1rem">
                <FormControl width="unset" display="flex" alignItems="center">
                  <Switch
                    size="sm"
                    isChecked={summarizeSize === "comprehensive"}
                    onChange={() => setSummarizeSize(summarizeSize === "comprehensive" ? "" : "comprehensive")}
                  />
                  <FormLabel isTruncated fontSize="xs" htmlFor="ch-settings-opt-in-marketing" ml="0.5rem" mb="0">
                    Comprehensive Summary
                  </FormLabel>
                </FormControl>
                <Select
                  width="100%"
                  maxWidth={"6rem"}
                  size="sm"
                  borderRadius="md"
                  value={summarizePages}
                  onChange={(event) => setSummarizePages(event.target.value)}>
                  {Array.from({ length: 100 }, (_, index) => (
                    <option key={index + 1} value={String(index + 1)}>
                      {index + 1} Pages
                    </option>
                  ))}
                </Select>
              </Stack>
            )}
          </ModalBody>
          <ModalFooter>
            <Stack direction="row" spacing="1rem">
              <Button {...secondaryButtonProps} onClick={onClose}>
                Cancel
              </Button>
              <Button className="ch-generate-summary-button-modal" {...primaryButtonProps} mr={3} onClick={summarize}>
                Generate Summary
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
