import React from "react";
import { Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { AreaChart, Area, Tooltip, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { CustomTooltipAreaChart } from "./CustomChartUtils";
import type { DataType } from "hooks/useStats";
import { defaultProps } from "react-select/creatable";

interface Props {
  data: DataType[];
  width?: number | string;
  height?: string;
  chartHeight?: number;
  title?: string;
  chartId?: string;
  showXAxis?: boolean;
  showYAxis?: boolean;
  showTooltip?: boolean;
}

export const CustomAreaChartGradient: React.FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  data,
  width,
  height = "13rem",
  chartHeight = 100,
  title,
  chartId,
  showXAxis = false,
  showYAxis = false,
  showTooltip = true,
}) => {
  const chartColor = useColorModeValue("#80B9E1", "gray.300");

  return (
    <Stack height={height} width={width}>
      <ResponsiveContainer width={"100%"} height={chartHeight}>
        <AreaChart id={chartId} data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          {showXAxis && <XAxis dataKey="label" fontSize={"10px"} interval={2} />}
          {showYAxis && <YAxis axisLine={false} tickLine={false} width={15} fontSize={"10px"} />}
          <defs>
            <linearGradient id="value" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={chartColor} stopOpacity={0.8} />
              <stop offset="95%" stopColor={chartColor} stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area type="monotone" dataKey="value" stroke={chartColor} fillOpacity={1} fill="url(#value)" />
          {showTooltip && (
            <Tooltip
              isAnimationActive={false}
              content={
                <CustomTooltipAreaChart active={defaultProps.active} payload={defaultProps.payload} baseColor={chartColor} prefix="" />
              }
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
      <Text mt="0!important" fontSize={"xs"} width={"100%"} align={"center"}>
        {title}
      </Text>
    </Stack>
  );
};
