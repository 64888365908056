import { Box, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import { useCollectionKey, useConfigMap, useContents, useGetViewConfig, useItemSelector } from "hooks";
import type { FunctionComponent } from "react";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { ContentFilterContext } from "screens/content";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { getItemTypeForContent } from "screens/content/common/utils";
import type { SupportedItemKeys } from "state/selection/reducer";
import { AnswerStatus } from "types/question";
import { QuestionAnswerResults } from "./QuestionAnswerResults";
import { HighlightsSimpleList } from "screens/panels/highlights/HighlightsSimpleList";
import { GapAnalysisResults } from "./GapAnalysisResults";
import { AssessmentResultsQuestion } from "./AssessmentResultsQuestion";
import orderBy from "lodash/orderBy";
import { ContentCanvasFilterInput } from "screens/content/contentCanvas/header/ContentCanvasFilterInput";
import { ContentViewExtractedAISection } from "screens/content/contentView/ContentViewExtractedAISection";
import { NoContentMessage } from "screens/content/contentCanvas/body/NoContentMessage";
import { SectionContentLayout } from "../components/utils";

interface Props {
  collectionId: string;
}

export const ProjectDetailDefaultAIResults: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionId,
}) => {
  const { setSelectedItems, resetSelectedItems } = useItemSelector();
  const { searchText, setResultsDataCount } = useContext(ContentFilterContext);
  const { isHighlightsPanelOpen, isAddToCollectionModalOpen } = useAddToCharliContext();
  const configMap = useConfigMap();
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const collectionType = useCollectionKey(collectionId, "collectionType");
  const collectionExtractedHighlights = useCollectionKey(collectionId, "extractedHighlights");
  const collectionQuestions = useCollectionKey(collectionId, "questions");
  const collectionUnstructuredData = useCollectionKey(collectionId, "unstructuredData");
  const contentData = useContents(collectionMetadataIds || []);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const aiResultResourceSections = useGetViewConfig("aiResultResourceSections", collectionType, configMap);

  useEffect(() => {
    if (contentData?.length) {
      const items =
        contentData &&
        contentData.reduce((cells, cell) => {
          const itemType = getItemTypeForContent(cell.type);
          cells[cell.mediaId] = { type: itemType };
          return cells;
        }, {} as { [id: string]: { type: SupportedItemKeys } });
      if (items) setSelectedItems(items);
    } else {
      resetSelectedItems();
    }
  }, [contentData?.length, isHighlightsPanelOpen, isAddToCollectionModalOpen, contentData, setSelectedItems, resetSelectedItems]);

  const extractedHighlightsData = useMemo(() => {
    if (!collectionExtractedHighlights) {
      return [];
    }
    return collectionExtractedHighlights;
  }, [collectionExtractedHighlights]);

  const questionAnswersData = useMemo(() => {
    return (collectionQuestions ?? []).filter((question) => ![AnswerStatus.unpinned, AnswerStatus.removed].includes(question.status));
  }, [collectionQuestions]);

  const gapAnalysisData = useMemo(() => {
    if (!collectionUnstructuredData?.data.gap_analysis_results) {
      return [];
    }
    return collectionUnstructuredData?.data.gap_analysis_results;
  }, [collectionUnstructuredData]);

  const assessmentResultsData = useMemo(() => {
    if (!collectionUnstructuredData?.data.assessment_results) {
      return [];
    }
    return collectionUnstructuredData?.data.assessment_results;
  }, [collectionUnstructuredData]);

  const aiResultResourceSectionContent = useMemo(() => {
    if (!aiResultResourceSections) {
      return [];
    }

    return orderBy(
      contentData?.filter((content) =>
        content.detailSections.some(
          (detailSection) => aiResultResourceSections.includes(detailSection.sectionName) && detailSection.data.length > 0
        )
      ),
      "name"
    );
  }, [aiResultResourceSections, contentData]);

  const filteredQuestions = useMemo(() => {
    if (searchText && searchText.length > 0) {
      return assessmentResultsData.filter(
        (result) =>
          result.section.question.includes(searchText) ||
          result.section.answer.includes(searchText) ||
          result.followup_questions.some((question) => question.question.includes(searchText))
      );
    }

    return assessmentResultsData;
  }, [assessmentResultsData, searchText]);

  const orderByRiskLevel = useCallback(() => {
    const riskLevelEnum = {
      "Low Risk": 1,
      "Medium Risk": 2,
      "High Risk": 3,
    };

    return orderBy(filteredQuestions, [(result) => riskLevelEnum[result.risk_assessment_result.risk_level.replace(".", "")]], ["desc"]);
  }, [filteredQuestions]);

  const hasGeneratedContentQA = useMemo(() => {
    return questionAnswersData.length > 0;
  }, [questionAnswersData.length]);

  const hasGeneratedContentGap = useMemo(() => {
    return gapAnalysisData.length > 0;
  }, [gapAnalysisData.length]);

  const hasGeneratedContentRisk = useMemo(() => {
    return orderByRiskLevel().length > 0;
  }, [orderByRiskLevel]);

  const hasExtractedContentSourceSections = useMemo(() => {
    return aiResultResourceSectionContent && aiResultResourceSectionContent.length > 0;
  }, [aiResultResourceSectionContent]);

  const hasExtractedContentHighlights = useMemo(() => {
    return extractedHighlightsData.length > 0;
  }, [extractedHighlightsData.length]);

  const hasGeneratedContentSectionCount =
    Number(hasGeneratedContentQA) + Number(hasGeneratedContentGap) + Number(hasGeneratedContentRisk) || 0;
  const hasExtractedContentSectionCount = Number(hasExtractedContentSourceSections) + Number(hasExtractedContentHighlights) || 0;

  useEffect(() => {
    setResultsDataCount(Math.max(0, hasGeneratedContentSectionCount + hasExtractedContentSectionCount));
  }, [hasExtractedContentSectionCount, hasGeneratedContentSectionCount, setResultsDataCount]);

  return (
    <>
      <Stack direction="row" width="100%" justifyContent={"flex-end"} pb="1rem">
        <Box width={isMobile ? "100%" : "30rem"}>
          <ContentCanvasFilterInput placeholderText="Filter AI Results" />
        </Box>
      </Stack>
      {!hasGeneratedContentQA && !hasGeneratedContentGap && !hasExtractedContentSourceSections && !hasExtractedContentHighlights ? (
        <NoContentMessage>
          <Text textAlign={"center"} fontSize="sm">
            {`There are no AI Results for the Project.`}
          </Text>
          <Text textAlign={"center"} fontSize="sm">
            {`You can view the Source Content for details or ask questions to generate AI answers based on the source content.`}
          </Text>
        </NoContentMessage>
      ) : (
        <SectionContentLayout
          useGrid={
            hasGeneratedContentSectionCount !== 0 &&
            hasExtractedContentSectionCount !== 0 &&
            hasGeneratedContentSectionCount + hasExtractedContentSectionCount > 1
          }
          className="ch-ai-result-sections">
          {(hasGeneratedContentQA || hasGeneratedContentGap || hasGeneratedContentRisk) && (
            <SectionContentLayout useGrid={hasGeneratedContentSectionCount > 1} className="layout-sections-extracted">
              {hasGeneratedContentQA && <QuestionAnswerResults collectionId={collectionId} questionAnswers={questionAnswersData} />}
              {hasGeneratedContentGap && <GapAnalysisResults gapAnalysisResults={gapAnalysisData} />}
              {hasGeneratedContentRisk && <AssessmentResultsQuestion assessmentResults={orderByRiskLevel()} searchText={searchText} />}
            </SectionContentLayout>
          )}
          {(hasExtractedContentSourceSections || hasExtractedContentHighlights) && (
            <SectionContentLayout
              useGrid={
                hasGeneratedContentSectionCount === 0 && (extractedHighlightsData.length > 1 || aiResultResourceSectionContent.length > 1)
              }
              className="ch-sections-generated">
              {aiResultResourceSectionContent &&
                aiResultResourceSectionContent.map((contentDetail, index) => (
                  <SectionContentLayout useGrid={false} className={`section-${index}`} key={`section-${index}`}>
                    <ContentViewExtractedAISection
                      showEmptyValues={false}
                      contentDetails={contentDetail}
                      sectionTitle={contentDetail.name}
                      includedSections={aiResultResourceSections}
                      isEditModeEnabled={false}
                      valueColumnAlignRight
                      isTextTruncated
                    />
                  </SectionContentLayout>
                ))}
              {hasExtractedContentHighlights && (
                <HighlightsSimpleList extractedHighlights={extractedHighlightsData} searchPhrase={searchText} />
              )}
            </SectionContentLayout>
          )}
        </SectionContentLayout>
      )}
    </>
  );
};
