import React, { useContext, useEffect, useMemo, useState } from "react";
import { AutocompleteInput } from "screens/collection/views/AutocompleteInput";
import type { AutocompleteInputProps } from "screens/collection/views/AutocompleteInput";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useMaxAllowedProjectsReached } from "hooks/useStats";
import { Box } from "@chakra-ui/react";
import {
  useCollectionKey,
  useConfigMap,
  useConversation,
  useEntitlementKey,
  useGetViewConfig,
  useProjectParams,
  useTotalCollectionsByType,
  useUserPreference,
} from "hooks";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useQASourceListFunction } from "hooks/useQASourceList";
import type { SuggestedQuestion } from "api/suggestions";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { AutocompleteSourceContextProvider } from "../../contexts/AutocompleteSourceContext";
import { InputBarDropzoneContext } from "screens/conversation/components/InputBar/InputBarDropzoneContext";
import { useAvailableCommands } from "screens/collection/views/AutoCompleteInputUtils";
import { useProjectLandingAutocompleteUtils } from "./ProjectLandingAutocompleteInputUtils";

interface Props extends Omit<AutocompleteInputProps, "onSelectOption" | "value" | "onChange"> {
  initialText?: string;
  collectionType?: string;
  portfolioId?: string;
  minWidth?: string;
  toastMessage?: string;
  afterSubmit?: () => void;
  defaultIsOpen?: boolean;
  maxSuggestions?: number;
  className?: string;
  conversationId?: string;
}

export const ProjectLandingAutocompleteInputInner = ({
  initialText,
  collectionType,
  portfolioId,
  minWidth,
  toastMessage,
  afterSubmit,
  defaultIsOpen,
  maxSuggestions,
  className,
  conversationId,
}: Props) => {
  const { projectFilter, parentRoute, isPortfolios } = useProjectParams();
  const maybeCollectionId = useCollectionKey(isPortfolios ? projectFilter : undefined, "id");
  const maybeCollectionType = useCollectionKey(isPortfolios ? projectFilter : undefined, "collectionType");
  const configMap = useConfigMap();
  const maybeConversationId = useCollectionKey(maybeCollectionId, "conversationId");
  const portfolioCollectionType = useGetViewConfig("portfolioProjectType", maybeCollectionType, configMap);
  const currentCollectionType = collectionType || portfolioCollectionType;
  const hasExceededMaxProjects = useMaxAllowedProjectsReached(currentCollectionType || "");
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { onConversationOpen, isConversationOpen } = useConversationContext();
  const hasCompletedOnboarding = useUserPreference("ui_onboarding_completed") as boolean;
  const collectionsByType = useTotalCollectionsByType(collectionType);
  const hideCommandLineButton = (useUserPreference("ui_hide_command_line_button") as boolean) || false;
  const [questionText, setQuestionText] = useState("");
  const { files } = useContext(InputBarDropzoneContext);
  const hasCommandLine = useEntitlementKey("ui_enable_command_suggestions");
  const { filterCommandsMemo } = useAvailableCommands(questionText, hideCommandLineButton || !hasCommandLine);
  const conversation = useConversation(conversationId || "");
  const conversationState = useMemo(() => conversation.conversationState, [conversation.conversationState]);

  const {
    onSubmitDueDiligenceProject,
    sendInputBarMessage,
    debouncedFilter,
    renderErrorMessage,
    newConversationId,
    suggestedQuestions,
    isLoading,
    setIsLoading,
    isValidQueryAgainstMatchFiltersMap,
  } = useProjectLandingAutocompleteUtils({
    collectionType,
    portfolioId,
    toastMessage,
    afterSubmit,
    maxSuggestions,
    questionText,
    conversationId,
  });

  const [showCommandSuggestions, setShowCommandSuggestions] = useState(false);
  useEffect(() => {
    setShowCommandSuggestions(questionText.startsWith("/") || files.length > 0 || conversationState === "action");
  }, [conversationState, files.length, questionText]);

  useEffect(() => {
    if (parentRoute === "home" && !hasCompletedOnboarding && collectionsByType > 0) {
      dispatch(updateTypedUserPreference({ preferenceKey: "ui_onboarding_completed", value: true }));
    }
  }, [parentRoute, hasCompletedOnboarding, dispatch, collectionsByType]);

  useEffect(() => {
    setQuestionText("");
  }, [pathname, setQuestionText]);

  useEffect(() => {
    if (suggestedQuestions.length > 0) {
      setIsLoading(false);
    }
  }, [setIsLoading, suggestedQuestions]);

  useEffect(() => {
    if (!questionText.startsWith("/")) debouncedFilter(questionText);
  }, [debouncedFilter, questionText, showCommandSuggestions]);

  return (
    <Box position="relative">
      {isConversationOpen && (
        <Box bottom="3rem" zIndex={2} position={"absolute"} width="100%">
          {renderErrorMessage()}
        </Box>
      )}
      <AutocompleteInput
        className={className}
        defaultIsOpen={defaultIsOpen}
        conversationId={conversationId || maybeConversationId}
        isLoading={isLoading}
        value={questionText}
        onChange={(value) => setQuestionText(value)}
        initialText={conversationState === "action" ? "Reply to Charli..." : initialText}
        suggestedQuestions={showCommandSuggestions ? filterCommandsMemo : suggestedQuestions}
        onSelectOption={(selectedItem) => {
          if (showCommandSuggestions || conversationState === "action") {
            setQuestionText(selectedItem.question);
          } else {
            onSubmitDueDiligenceProject(selectedItem as SuggestedQuestion);
            setQuestionText("");
          }
        }}
        onClickSubmit={() => {
          if (showCommandSuggestions) {
            sendInputBarMessage(questionText);
            onConversationOpen(conversationId || maybeConversationId || newConversationId);
            setQuestionText("");
          }
        }}
        isInputDisabled={hasExceededMaxProjects}
        isSubmitDisabled={
          isValidQueryAgainstMatchFiltersMap[questionText] === false && !questionText.startsWith("/") && conversationState !== "action"
        }
        minWidth={minWidth}
        disableFilter
        submitOnEnterOrTab
        conversationState={conversationState}
      />
      {!isConversationOpen && (
        <Box pr="1rem" position={"absolute"} width="100%">
          {renderErrorMessage()}
        </Box>
      )}
    </Box>
  );
};

export const ProjectLandingAutocompleteInput = (props: Props) => {
  const { collectionType } = props;
  const { projectFilter, isPortfolios } = useProjectParams();
  const maybeCollectionType = useCollectionKey(isPortfolios ? projectFilter : undefined, "collectionType");
  const configMap = useConfigMap();
  const portfolioCollectionType = useGetViewConfig("portfolioProjectType", maybeCollectionType, configMap);
  const currentCollectionType = collectionType || portfolioCollectionType;
  const questionInputLanding = useGetViewConfig("projectLandingQAInput", currentCollectionType, configMap);

  const getSourceList = useQASourceListFunction(questionInputLanding?.source);

  return (
    <AutocompleteSourceContextProvider getSourceList={getSourceList}>
      <ProjectLandingAutocompleteInputInner {...props} />
    </AutocompleteSourceContextProvider>
  );
};
