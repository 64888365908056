import { PlanUser } from "./PlanUser";

import { Record, String, Array } from "runtypes";
import type { Static } from "runtypes";

export const Plan = Record({
  code: String,
  name: String,
  planUsers: Array(PlanUser).optional(),
});

export type Plan = Static<typeof Plan>;
