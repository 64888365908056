import type { FunctionComponent } from "react";
import React, { useCallback, useMemo } from "react";
import { CollectionsFilterContextProvider } from "../collections";
import { ProjectLanding } from "./ProjectLanding";
import { parseISO } from "date-fns";
import { useCollections, useConfigMap, useProjectParams } from "hooks";
import type { CollectionWithAdditionalProps } from "types/collection";
import { getViewConfig } from "configs/configMap";
import { isValidUUID } from "screens/content/common/utils";

interface Props {
  hideTitle?: boolean;
  collectionType?: string;
  collectionIds?: string[];
  hideOnboardingMessage?: boolean;
  loadTilesCount?: number;
}

export const ProjectLandingFilter: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  hideTitle,
  collectionType = "due_diligence",
  collectionIds,
  hideOnboardingMessage = true,
  loadTilesCount = 5,
}) => {
  const { projectFilter } = useProjectParams();
  const collections = useCollections();
  const configMap = useConfigMap();
  const hasValidPortfolioProjectId = isValidUUID(projectFilter || "") ? projectFilter : "";
  const portfolioProject = collections.find((collection) => collection.id === hasValidPortfolioProjectId);
  const portfolioCollectionType = getViewConfig("portfolioProjectType", portfolioProject?.collectionType || "", configMap);
  const hasPortfolioCollectionType = portfolioCollectionType && portfolioCollectionType?.length > 0;

  const sortFunction = useCallback(
    (a: any, b: any) => parseISO(b.metadata.createdTime).getTime() - parseISO(a.metadata.createdTime).getTime(),
    []
  );
  const filterArchivedCollections = useCallback((item: CollectionWithAdditionalProps) => !item.metadata.archived, []);

  const collectionsByType = useCallback(
    (filteredCollections: CollectionWithAdditionalProps[]) => {
      return filteredCollections.filter((item) => item.collectionType === collectionType);
    },
    [collectionType]
  );

  const collectionsByIds = useCallback(
    (filteredCollections: CollectionWithAdditionalProps[]) => {
      if (!collectionIds) return [];
      return filteredCollections.filter((item) => item.id && collectionIds?.includes(item.id));
    },
    [collectionIds]
  );

  const collectionsToRender = useMemo(() => {
    const sortedCollections = collections.filter(filterArchivedCollections).sort(sortFunction);
    if (hasPortfolioCollectionType) {
      return collectionsByIds(sortedCollections);
    }
    return collectionsByType(sortedCollections);
  }, [collections, collectionsByIds, collectionsByType, filterArchivedCollections, hasPortfolioCollectionType, sortFunction]);

  return (
    <CollectionsFilterContextProvider collections={collectionsToRender}>
      <ProjectLanding
        hideTitle={hideTitle}
        collectionType={collectionType}
        hideOnboardingMessage={hideOnboardingMessage}
        totalCollections={collectionsToRender?.length || 0}
        loadTilesCount={loadTilesCount}
      />
    </CollectionsFilterContextProvider>
  );
};
