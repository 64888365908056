import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Text, Stack, useColorModeValue, Center, Icon } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { ArrowUpIcon, ArrowDownIcon, MinusIcon } from "@chakra-ui/icons";
import { useTileProps } from "hooks";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import type { StockTickerData } from "api/stockTickerData";
import { getStockTickerData } from "api/stockTickerData";
import { useCollectionsStocksContext } from "screens/landing/tabs/collections/CollectionsStocksProvider";

interface Props {
  companyStockTitle: string;
  companyTicker?: string;
  companyStockExchange?: string;
  companyStockPrice?: string;
  companyStockPercentageChange?: string;
  companyStockMovement?: string;
  companyStockValueChange?: string;
  style?: React.CSSProperties;
  miniTicker?: boolean;
  onClick?: () => void;
}

export const StockIndicator: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  companyStockTitle,
  companyTicker,
  companyStockExchange,
  companyStockPrice,
  companyStockPercentageChange,
  companyStockValueChange,
  companyStockMovement,
  style,
  onClick,
  miniTicker = false,
}) => {
  const { memoizedStocks, setMemoizedStocks, isFetchingStocks } = useCollectionsStocksContext();
  const subTitlecolor = useColorModeValue("charli.mediumGray", "gray.500");
  const commonTileProps = useTileProps();
  const [stockData, setStockData] = useState<StockTickerData>();
  const isMounted = useRef(false);

  const fetchData = useCallback(() => {
    if (!companyTicker || !companyStockExchange) {
      return;
    }

    const composeKey = `${companyTicker}-${companyStockExchange}`;

    if (memoizedStocks[composeKey]) {
      setStockData(memoizedStocks[composeKey]);
      return;
    }

    if (isFetchingStocks.current[composeKey]) {
      return;
    }

    isFetchingStocks.current[composeKey] = true;
    getStockTickerData(companyTicker, companyStockExchange)
      .then((fetchStockData) => {
        if (fetchStockData && isMounted.current) {
          setMemoizedStocks((prev) => ({ ...prev, [composeKey]: fetchStockData }));
          setStockData(fetchStockData);
        }
      })
      .catch((error) => {
        console.error(`Failed to fetch data: ${error}`);
      })
      .finally(() => {
        isFetchingStocks.current[composeKey] = false;
      });
  }, [companyTicker, companyStockExchange, setMemoizedStocks, isFetchingStocks, memoizedStocks]);

  useEffect(() => {
    isMounted.current = true;
    if (companyStockPrice) return;

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, [companyStockPrice, fetchData]);

  const currentPrice = companyStockPrice ? Number(companyStockPrice) : Number(stockData?.ticker_quote?.price ?? 0);
  const previousClose = Number(stockData?.ticker_quote?.previous_close ?? 0);
  const difference = companyStockValueChange ? Number(companyStockValueChange).toFixed(2) : (currentPrice - previousClose).toFixed(2);
  const percentChange = stockData?.ticker_quote?.percent_change;
  const percentageChange = companyStockPercentageChange
    ? `${Number(companyStockPercentageChange.replace("%", "")).toFixed(2)}%`
    : percentChange
    ? `${Number(percentChange.replace("%", "")).toFixed(2)}%`
    : "--";

  return (
    <>
      {miniTicker ? (
        <Center style={style} _hover={{}} onClick={onClick && onClick}>
          <Stack direction="row" spacing="6px" fontSize="sm" mr="10px">
            <TextOverflowTooltip color="white" className="ch-project-stock-indicator-company-name" label={companyStockTitle} />
            <Text className="ch-project-stock-indicator-current-price" color={"white"}>
              {currentPrice === 0 ? "--" : currentPrice.toFixed(2)}
            </Text>
          </Stack>
          <Stack direction="row" spacing={"0!important"} fontSize="sm">
            <Center pb="2px">
              <Icon
                boxSize={"1rem"}
                as={percentageChange.includes("--") ? MinusIcon : percentageChange.includes("-") ? ArrowDownIcon : ArrowUpIcon}
                color={percentageChange.includes("--") ? "gray.500" : percentageChange.includes("-") ? "red.300" : "green.300"}
              />
            </Center>
            <Text
              className="ch-project-stock-indicator-percentage"
              textAlign={"right"}
              color={percentageChange.includes("-") ? "red.300" : "green.300"}>
              {percentageChange === "NaN%" ? "--" : percentageChange.replace("-", "")}
            </Text>
          </Stack>
        </Center>
      ) : (
        <Stack
          {...commonTileProps}
          style={style}
          _hover={{}}
          direction="row"
          fontSize="xs"
          width={["10rem", "13rem", "13rem"]}
          p="5px"
          justifyContent={"space-between"}
          onClick={onClick && onClick}
          height="2.5rem">
          <Box
            width={"2rem"}
            borderRadius="md"
            bgColor={percentageChange.includes("--") ? "gray.100" : percentageChange.includes("-") ? "red.100" : "green.100"}>
            <Center height="100%">
              <Icon
                boxSize={"1rem"}
                as={percentageChange.includes("--") ? MinusIcon : percentageChange.includes("-") ? ArrowDownIcon : ArrowUpIcon}
                color={percentageChange.includes("--") ? "gray.500" : percentageChange.includes("-") ? "red.500" : "green.500"}
              />
            </Center>
          </Box>
          <Stack direction="row" spacing=".5rem" width={"100%"} justifyContent={"space-between"}>
            <Stack spacing="5px">
              <TextOverflowTooltip
                className="ch-project-stock-indicator-company-name"
                label={companyStockTitle}
                fontSize="xs"
                style={{ fontWeight: "bold", lineHeight: "12px" }}
              />
              <Text className="ch-project-stock-indicator-current-price" lineHeight={"12px"} color={subTitlecolor} fontWeight="normal">
                {currentPrice === 0 ? "--" : currentPrice}
              </Text>
            </Stack>
            <Stack justifyContent={"end"} spacing="0">
              <Text
                className="ch-project-stock-indicator-percentage"
                textAlign={"right"}
                fontWeight="semibold"
                color={percentageChange.includes("-") ? "red.500" : "green.500"}>
                {percentageChange === "NaN%" ? "--" : percentageChange}
              </Text>
              <Text
                className="ch-project-stock-indicator-difference"
                textAlign={"right"}
                lineHeight={"12px"}
                color={difference.includes("-") ? "red.500" : "green.500"}>
                {difference === "0.00" ? "--" : difference}
              </Text>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};
