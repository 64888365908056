import { CheckIcon } from "@chakra-ui/icons";
import { Button, Flex, Stack, Text, Badge, useColorModeValue, Switch } from "@chakra-ui/react";
import { useCollection, useContents, useProjectParams } from "hooks";
import React, { useEffect, useMemo, useRef, useState, useContext } from "react";
import { ContentFilterContext } from "screens/content/contentCanvas/body/ContentFilterContext";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import uniq from "lodash/uniq";
import { hasProjectOutput } from "screens/collection/components/utils";

const INITIAL_TAG_LIMIT = 12;

interface Props {
  selectedTags: string[];
  setSelectedTags: (tags: string[]) => void;
  presentedTags: {
    name: string;
    occurrences: number;
  }[];
  distinctTags: {
    name: string;
    occurrences: number;
  }[];
}

export const TagFilter = ({ selectedTags, setSelectedTags, presentedTags, distinctTags }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [showAllTags, setShowAllTags] = useState(false);
  const tagColors = useColorModeValue(
    {
      defaultBorder: "#E0E0E1",
      selectedBorder: "#5096F1",
      defaultText: "#616161",
      selectedText: "#0E56B3",
      tagCountText: "white",
      selectionTick: "#0E56B3",
      tagBackground: "#90b0bd",
      clearButtonBackground: "#90b0bd",
    },
    {
      defaultBorder: "gray.700",
      selectedBorder: "gray.500",
      defaultText: "gray.300",
      selectedText: "gray.100",
      tagCountText: "gray.200",
      selectionTick: "blue.400",
      tagBackground: "gray.700",
      clearButtonBackground: "gray.700",
    }
  );
  const bgColor = useColorModeValue("white", "gray.800");
  const { setHasEverySelectedTag, hasEverySelectedTag } = useContext(ContentFilterContext);

  const { projectId } = useProjectParams();
  const collection = useCollection(projectId, { refreshFromNetwork: true });
  const collectionMetadataIds = useMemo(() => collection?.metadataIds && collection?.metadataIds, [collection?.metadataIds]);
  const contentData = useContents(collectionMetadataIds || []);

  const allTags = useMemo(() => {
    return contentData?.reduce((accum, content) => {
      const tags = [...content.autoTags, ...content.manualTags];

      uniq(tags)
        .filter(() => !hasProjectOutput(content))
        .forEach((tag) => {
          if (accum[tag.replace('"', "").trim()]) {
            accum[tag.replace('"', "").trim()] = accum[tag.replace('"', "").trim()] + 1;
          } else {
            accum[tag.replace('"', "").trim()] = 1;
          }
        });

      return accum;
    }, {} as { [key: string]: number });
  }, [contentData]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  return (
    <>
      {distinctTags.length > 0 && (
        <Stack direction="row" align="flex-end" justify="space-between" pb=".5rem">
          <Flex flexWrap={"wrap"} alignContent="flex-start">
            <Stack direction="row" alignContent="center" pr="2rem" pt=".3rem">
              <SectionHeader
                title={`${hasEverySelectedTag ? "Has All Tags" : "Has Some Tags"}`}
                titleStyle={{ fontSize: "xs", fontWeight: "normal" }}
              />
              <Switch
                size="sm"
                className="summary-toggle"
                onChange={() => setHasEverySelectedTag(!hasEverySelectedTag)}
                isChecked={hasEverySelectedTag}
                colorScheme="teal"
              />
            </Stack>
            {distinctTags.slice(0, showAllTags ? distinctTags.length : INITIAL_TAG_LIMIT).map((tag, index) => {
              const isSelected = selectedTags.includes(tag.name);
              return (
                <Button
                  className="ch-tag-filter-button"
                  backgroundColor={bgColor}
                  key={index}
                  leftIcon={isSelected ? <CheckIcon color={tagColors.selectionTick} /> : undefined}
                  borderColor={isSelected ? tagColors.selectedBorder : tagColors.defaultBorder}
                  borderWidth="1.2px"
                  onClick={() => {
                    if (isSelected) {
                      setSelectedTags(selectedTags.filter((existingTag) => existingTag !== tag.name));
                    } else {
                      setSelectedTags([...selectedTags, tag.name]);
                    }
                  }}
                  size="xs"
                  variant="outline"
                  px="0.6rem"
                  py="0.7rem"
                  mr="5px"
                  mb="5px">
                  <Text
                    color={isSelected ? tagColors.selectedText : tagColors.defaultText}
                    fontFamily="heading"
                    fontWeight="400"
                    maxWidth={"16rem"}
                    isTruncated>
                    {tag.name}
                  </Text>
                  <Badge colorScheme="blue" backgroundColor={tagColors.tagBackground} rounded="full" ml="10px">
                    <Text fontSize="10px" color={tagColors.tagCountText}>
                      {allTags ? allTags[tag.name] : tag.occurrences}
                    </Text>
                  </Badge>
                </Button>
              );
            })}
            {!showAllTags && distinctTags.length > INITIAL_TAG_LIMIT ? (
              <Button
                onClick={() => {
                  setShowAllTags(true);
                }}
                size="xs"
                variant="ghost"
                px="0.6rem"
                py="0.7rem"
                mr="1"
                mb="1"
                fontFamily="heading"
                fontWeight="400"
                colorScheme="blue">
                Show {distinctTags.length - INITIAL_TAG_LIMIT} more {distinctTags.length - INITIAL_TAG_LIMIT === 1 ? "tag" : "tags"}
              </Button>
            ) : (
              distinctTags.length > INITIAL_TAG_LIMIT && (
                <Button
                  onClick={() => {
                    setShowAllTags(false);
                  }}
                  key="show.less"
                  size="xs"
                  variant="ghost"
                  px="0.6rem"
                  py="0.7rem"
                  mr="1"
                  mb="1"
                  fontFamily="heading"
                  fontWeight="400"
                  colorScheme="blue">
                  Show less tags
                </Button>
              )
            )}
          </Flex>
        </Stack>
      )}
    </>
  );
};
