import {
  Box,
  Stack,
  useColorModeValue,
  Text,
  useBreakpointValue,
  Icon,
  Tooltip,
  useDisclosure,
  Collapse,
  Button,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  MenuDivider,
  Center,
} from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useContext, useCallback, useEffect } from "react";
import { CollectionsFilterContext, CollectionsFilterMenu, CollectionsTabHeaderFilter, ProjectLandingTiles } from "../collections";
import { useButtonProps, useCollection, useConfigMap, useExternalIntegrations, useGetViewConfig, useProjectParams } from "hooks";
import { getViewConfig } from "configs/configMap";
import { IoBarChartSharp } from "react-icons/io5";
import { LandingTitle } from "screens/landing/components";
import { ProjectMetrics } from "../collections/ProjectMetrics";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { OptionsMenuItem } from "screens/collection/components/OptionsMenuItem";
import { NewProjectMenuItem } from "../library/NewProjectMenuItem";
import { OnboardingMessageSteps } from "screens/common/components/OnboardingMessageSteps";
import { ProjectNewInput } from "./ProjectNewInput";
import { ProjectDeleteButton } from "screens/collection/views/ProjectActions/ProjectDeleteButton";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ConversationContext } from "screens/thread/ConversationContext";
import { PausedWorkflowModalProvider } from "screens/collection/views/ProjectActions/PausedWorkflowModal";

interface Props {
  hideTitle?: boolean;
  collectionType: string;
  hideOnboardingMessage?: boolean;
  totalCollections?: number;
  loadTilesCount?: number;
}

export const ProjectLanding: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  hideTitle,
  collectionType,
  hideOnboardingMessage = true,
  totalCollections,
  loadTilesCount,
}) => {
  const { projectFilter, isPortfolios } = useProjectParams();
  const { filteredCollections } = useContext(CollectionsFilterContext);
  const portfolioProject = useCollection(projectFilter);
  const configMap = useConfigMap();
  const portfolioCollectionType = getViewConfig("portfolioProjectType", portfolioProject?.collectionType || "", configMap);
  const { availableIntegrations } = useExternalIntegrations();
  const buttonColor = useColorModeValue("gray.500", "gray.300");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const projectTitle = useGetViewConfig("title", portfolioCollectionType || projectFilter, configMap);
  const { isOpen: showMetrics, onToggle: toggleMetrics } = useDisclosure();
  const commonButtonProps = useButtonProps("sm", "primary");
  const { isOpen: isFilterOpen, onToggle: onFilterToggle, onClose: onFilterClose } = useDisclosure();
  const hasNewProjectForm = useGetViewConfig("newProjectForm", portfolioCollectionType || projectFilter, configMap);
  const { onDeleteProjectModalOpen } = useContext(ConversationContext);
  const { onPortfolioSettingsOpen } = useAddToCharliContext();
  const portfolioIdValue = projectFilter && isPortfolios ? projectFilter : undefined;

  const hasAllRequiredIntegrations = useCallback(() => {
    const requiredIntegrations = getViewConfig("requiredIntegrations", collectionType, configMap) || [];
    if (!requiredIntegrations) return true;
    return requiredIntegrations.every((requiredIntegration) =>
      availableIntegrations?.some(
        (availableIntegration) => availableIntegration.urn === requiredIntegration && availableIntegration.isEnabled
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableIntegrations, configMap]);

  useEffect(() => {
    onFilterClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioProject]);

  return (
    <Box height="100%">
      {totalCollections === 0 && (!hideOnboardingMessage || !hasAllRequiredIntegrations()) ? (
        <Center>
          <Stack p={isMobile ? "1rem" : "3rem"} spacing="4rem" maxW={["96vw", "60vw", "60vw"]}>
            <Box>
              <OnboardingMessageSteps collectionType={collectionType} />
            </Box>
            <Center width="100%">
              <ProjectNewInput collectionType={collectionType} portfolioId={portfolioIdValue} />
            </Center>
          </Stack>
        </Center>
      ) : (
        <Stack spacing="0" justifyContent="space-between">
          <Collapse animateOpacity in={showMetrics}>
            <ProjectMetrics />
          </Collapse>
          {!isMobile && (
            <>
              <Stack px={isMobile ? ".5rem" : "1rem"} mt="0!important">
                <LandingTitle
                  underline
                  text={portfolioProject ? `${portfolioProject.name}` : `${projectTitle} Projects`}
                  color={"primary.default"}>
                  <Stack direction="row" spacing="0.5rem">
                    <Tooltip label={`${showMetrics ? "Hide" : "Show"} Metrics`} aria-label="Show/Hide Metrics">
                      <Box cursor="pointer" onClick={() => toggleMetrics()}>
                        <IconButton
                          className="ch-project-landing-show-metrics"
                          aria-label="Show/Hide Metrics"
                          {...commonButtonProps}
                          icon={<Icon as={IoBarChartSharp} />}
                          onClick={() => {
                            toggleMetrics();
                          }}
                        />
                      </Box>
                    </Tooltip>
                    <Box>
                      <Menu isLazy>
                        <MenuButton
                          {...commonButtonProps}
                          className="ch-project-landing-actions-menu"
                          as={Button}
                          aria-label="Actions"
                          rightIcon={<TriangleDownIcon />}
                          onClick={(event) => event.stopPropagation()}>
                          Actions
                        </MenuButton>
                        <MenuList minWidth={"unset"} zIndex={10}>
                          {hasNewProjectForm && collectionType !== "due_diligence" && (
                            <>
                              <NewProjectMenuItem collectionType={collectionType} buttonLabel={`New ${projectTitle} Project`} />
                            </>
                          )}
                          {portfolioProject && (
                            <OptionsMenuItem
                              menuLabel="Portfolio Settings"
                              className="ch-open-project-settings"
                              onClick={() => {
                                onPortfolioSettingsOpen();
                              }}
                            />
                          )}
                          <OptionsMenuItem
                            isDisabled
                            menuLabel={"Generated AI Reports"}
                            className="ch-open-project-reports"
                            onClick={() => {
                              toggleMetrics();
                            }}
                          />
                          <OptionsMenuItem
                            menuLabel={`${showMetrics ? "Hide" : "Show"} Metrics`}
                            className="ch-open-project-metrics"
                            onClick={() => {
                              toggleMetrics();
                            }}
                          />
                          {portfolioProject && (
                            <>
                              <MenuDivider />
                              <ProjectDeleteButton
                                label="Delete Portfolio"
                                className="ch-delete-portfolio-landing"
                                onOpenConfirmation={onDeleteProjectModalOpen}
                                projectId={portfolioProject.id}
                                isMenuItem
                                navigatePath="/home"
                              />
                            </>
                          )}
                        </MenuList>
                      </Menu>
                    </Box>
                  </Stack>
                </LandingTitle>
                <Stack justifyContent="flex-end" width="100%" direction="row">
                  <Box width={isMobile ? "100%" : "50%"} maxWidth="30rem">
                    <CollectionsFilterMenu onFilterToggle={onFilterToggle} isOpen={isFilterOpen} hideStateFilter hideTagFilter />
                  </Box>
                </Stack>
              </Stack>
            </>
          )}
          <Stack spacing="1rem" px={isMobile ? ".5rem" : "1rem"}>
            {!hideTitle && <CollectionsTabHeaderFilter />}
            {filteredCollections.length === 0 ? (
              <Box>
                <Text fontSize="md" align="start" color={buttonColor} pb="6rem">
                  {`No ${collectionType.replace(/_/g, " ")} projects found. Please try a different filter or create a new project.`}
                </Text>
              </Box>
            ) : (
              <Box mb={"5rem!important"}>
                <PausedWorkflowModalProvider>
                  <ProjectLandingTiles loadTilesCount={loadTilesCount} />
                </PausedWorkflowModalProvider>
              </Box>
            )}
          </Stack>
        </Stack>
      )}
    </Box>
  );
};
