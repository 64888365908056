import { ArrowForwardIcon, CloseIcon } from "@chakra-ui/icons";
import { IconButton, Textarea, InputGroup, InputRightElement, useColorModeValue, Tooltip, Stack, Text } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useRef } from "react";
import React, { useEffect, useState } from "react";
import { useButtonProps } from "hooks";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";

interface Props {
  id?: string;
  value?: string;
  entity: string;
  onEdit: (entity: string, value: string, id?: string) => void;
  onCancel?: () => void;
  formatWhenStoring?: (val: string) => string;
  format?: (val: string) => string;
  parse?: (val: string) => string;
  isEditEnabled?: boolean;
  size?: "sm" | "md" | "lg" | "2xl";
  fontWeight?: "normal" | "bold";
  maxHeight?: string;
  backgroundColor?: string;
  fontColor?: string;
  valueColumnAlignRight?: boolean;
  isTextTruncated?: boolean;
}

export const EditableInput: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  id,
  value = "",
  entity,
  onEdit,
  onCancel,
  formatWhenStoring,
  format,
  parse,
  isEditEnabled = false,
  size = "sm",
  fontWeight = "normal",
  maxHeight,
  backgroundColor,
  fontColor,
  valueColumnAlignRight,
  isTextTruncated,
}) => {
  const [inputState, setInputState] = useState<"viewing" | "editing" | "saving">("viewing");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const borderColor = useColorModeValue("#cbd5e0", "darkgray");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const commonButtonProps = useButtonProps("sm", "primary");
  const isConnected = useSelector((state: RootState) => state.websocket.isConnected);
  const secondaryButtonStyle = useButtonProps("sm", "secondary");
  const [isEditing, setIsEditing] = useState(false);

  const getParsedValue = (unParsedValue: string) => {
    if (parse) return parse(unParsedValue);
    return unParsedValue;
  };

  const getFormattedValue = (unformattedValue: string) => {
    if (format) return format(unformattedValue);
    return unformattedValue;
  };

  const getFormatWhenStoringValue = (unformattedValue: string) => {
    if (formatWhenStoring) return formatWhenStoring(unformattedValue);
    return unformattedValue;
  };

  const [inputText, setInputText] = useState(getFormatWhenStoringValue(value));

  useEffect(() => {
    if (inputState !== "saving") {
      setInputText(value);
      return;
    }
    const timer = setTimeout(() => {
      setInputState("viewing");
      setIsEditing(false);
      setInputText(value);
    }, 15000);
    return () => clearTimeout(timer);
  }, [inputState, setIsEditing, value]);

  const onSubmit = (text: string) => {
    if (inputText.trim() !== value) {
      onEdit(entity!, text, id);
      setInputState("saving");
      setIsEditing(false);
    }
  };

  const handelOnKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const text = (event.target as HTMLTextAreaElement).value;
    if (event.key === "Enter") {
      onSubmit(text);
    }
    if (event.key === "Escape") {
      event.preventDefault();
      handleOnBlur();
    }
    return true;
  };

  const handleEnableEdit = () => {
    setIsEditing(false);
    isEditEnabled && setInputState("editing");
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      const len = textareaRef.current.value.length;
      textareaRef.current.selectionStart = len;
      textareaRef.current.selectionEnd = len;
    }
  }, [inputState]);

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    setIsEditing(true);
    setInputText(getParsedValue(event.currentTarget.value));
  };

  const handleOnBlur = () => {
    if (isEditing) return;
    setInputText(inputText);
    setInputState("viewing");
    setIsEditing(false);
  };

  return (
    <>
      {inputState !== "editing" ? (
        <>
          {isTextTruncated ? (
            <TextOverflowTooltip
              className={isEditEnabled ? "ch-editable-input" : "ch-editable-input-disabled"}
              label={getFormattedValue(inputText)}
              fontSize={size}
              style={{
                width: "100%",
                height: "100%",
                lineHeight: maxHeight ? maxHeight : "1.4rem",
                textAlign: valueColumnAlignRight ? "right" : "left",
                cursor: isEditEnabled ? "pointer" : "default",
                backgroundColor: backgroundColor ? backgroundColor : !inputText ? "#fffee6" : undefined,
              }}
              onClick={() => {
                handleEnableEdit();
              }}
            />
          ) : (
            <Text
              className={isEditEnabled ? "ch-editable-input" : "ch-editable-input-disabled"}
              fontSize={size}
              width="100%"
              height="100%"
              lineHeight={maxHeight ? maxHeight : "1.4rem"}
              textAlign={valueColumnAlignRight ? "right" : "left"}
              cursor={isEditEnabled ? "pointer" : "default"}
              backgroundColor={backgroundColor ? backgroundColor : !inputText ? "#fffee6" : undefined}
              onClick={() => {
                handleEnableEdit();
              }}>
              {getFormattedValue(inputText)}
            </Text>
          )}
        </>
      ) : (
        <InputGroup width="100%" minWidth="8rem" size={size} flexGrow={1}>
          <Textarea
            textAlign={valueColumnAlignRight ? "end" : "start"}
            className="ch-editing-input"
            width="100%"
            cursor="pointer"
            ref={textareaRef}
            p="0"
            onKeyDown={(event) => handelOnKeyDown(event)}
            height={maxHeight ? maxHeight : "auto"}
            overflow={maxHeight ? "hidden" : "auto"}
            lineHeight={maxHeight ? maxHeight : "1.4rem"}
            maxHeight={"20rem"}
            minHeight={"0"}
            spellCheck={false}
            paddingInlineStart="unset"
            fontSize={size}
            fontWeight={fontWeight}
            value={getFormattedValue(inputText)}
            onChange={(event) => handleOnChange(event)}
            onClick={() => {
              !isEditing && handleEnableEdit();
            }}
            onBlur={(event) => {
              event.preventDefault();
              handleOnBlur();
            }}
            _hover={{ borderBottomColor: isEditEnabled ? borderColor : "transparent" }}
            border="none"
            borderBottom="1px solid transparent"
            borderRadius="none"
            backgroundColor={backgroundColor ? backgroundColor : !inputText ? "#fffee6" : undefined}
            resize="vertical"
            color={fontColor ? fontColor : textColor}
          />
          {isEditing && (
            <InputRightElement
              height="100%"
              justifyContent="flex-end"
              children={
                <Stack direction="row" justifyContent="flex-end" spacing="3px">
                  <Tooltip label="Cancel">
                    <IconButton
                      className="ch-editable-input-cancel"
                      {...secondaryButtonStyle}
                      aria-label="cancel"
                      borderRadius="5px"
                      size="xs"
                      icon={<CloseIcon width=".6rem" height=".6rem" />}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        setInputState("viewing");
                        onCancel && onCancel();
                      }}
                    />
                  </Tooltip>
                  <Tooltip label="Save">
                    <IconButton
                      className="ch-editable-input-submit"
                      {...commonButtonProps}
                      aria-label="submit"
                      isDisabled={!isConnected}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        isConnected && onSubmit(inputText);
                      }}
                      borderRadius="5px"
                      size="xs"
                      icon={<ArrowForwardIcon width="1rem" height="1rem" />}
                    />
                  </Tooltip>
                </Stack>
              }
            />
          )}
        </InputGroup>
      )}
    </>
  );
};
