import { createSlice } from "@reduxjs/toolkit";
import type { SubscriptionDetails } from "api/subscription/models/SubscriptionDetails";
import { downloadSubscriptionDetails } from "./operations";

interface SubscriptionState {
  subscriptionDetails: SubscriptionDetails;
  isLoading: boolean;
}

const initialState: SubscriptionState = {
  subscriptionDetails: {} as SubscriptionDetails,
  isLoading: false,
};

export const { actions, reducer } = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadSubscriptionDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(downloadSubscriptionDetails.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(downloadSubscriptionDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subscriptionDetails = action.payload;
    });
  },
});

export default reducer;
