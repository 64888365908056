import { Box, Button, Stack, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loginWithIdentityProvider, signupWithIdentityProvider } from "state/session/operations";
import { actions } from "state/session/reducer";
import { track } from "api/analytics";
import {
  GOOGLE_SIGN_IN_BUTTON_CLICKED,
  GOOGLE_SIGN_IN_FAILED,
  GOOGLE_SIGN_IN_SUCCEEDED,
  GOOGLE_SIGN_UP_BUTTON_CLICKED,
} from "api/analytics/events";
import { useGoogleApi } from "hooks";
import { captureException } from "@sentry/browser";
import { captureMessage } from "@sentry/react";
import { FcGoogle } from "react-icons/fc";
import { useGoogleLogin } from "@react-oauth/google";

interface Props {
  variant: "signin" | "signup";
  formData?: { marketingOptIn: boolean };
  isDisabled?: boolean;
}

export const GoogleSignInButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  variant,
  formData,
  isDisabled,
}) => {
  const dispatch = useDispatch();
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [isClickedEarly, setIsClickedEarly] = useState(false);
  const gapi = useGoogleApi();
  const [googleAuthApi, setGoogleAuthApi] = useState<gapi.auth2.GoogleAuth | undefined>();
  const buttonBackgroundColor = useColorModeValue("#4285F4", "gray.700");
  const iconBackgroundColor = useColorModeValue("white", "gray.600");
  const buttonColor = useColorModeValue("white", "gray.400");

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      track(variant === "signin" ? GOOGLE_SIGN_IN_BUTTON_CLICKED : GOOGLE_SIGN_UP_BUTTON_CLICKED);
      captureMessage("User signed in with Google", { extra: { scope: tokenResponse.scope } });

      const accessToken = tokenResponse.access_token;

      fetch(`https://www.googleapis.com/userinfo/v2/me?access_token=${accessToken}`)
        .then((data) => data.json())
        .then((json) => {
          track(GOOGLE_SIGN_IN_SUCCEEDED);
          const isSignIn = variant === "signin";
          const action = isSignIn ? loginWithIdentityProvider : signupWithIdentityProvider;

          dispatch(
            action({
              accessToken: accessToken,
              issuer: "google",
              avatarUrl: json.picture,
              ...(!isSignIn ? { marketingOptIn: formData?.marketingOptIn } : {}),
            })
          );
        })
        .catch((err) => {
          console.warn(err);
          captureException(err, { extra: { context: "Error when trying to get user's Google profile image" } });
        });
    },
    onError: (reason) => {
      console.error(`Google login error: ${reason}`);
      if (reason.error) {
        track(GOOGLE_SIGN_IN_FAILED, { reason: "other" });
        actions.loginFailed({
          error: `Login failed: ${reason.error}`,
        });
      } else if (typeof reason === "string") {
        if (reason === "popup_closed_by_user") {
          track(GOOGLE_SIGN_IN_FAILED, { reason: "popup_closed_by_user" });
          console.warn("Login aborted, popup closed by user.");
          return;
        } else {
          track(GOOGLE_SIGN_IN_FAILED, { reason: "other" });
          actions.loginFailed({
            error: `Login failed: ${reason}`,
          });
        }
      }
      console.log(reason);
    },
  });

  useEffect(() => {
    // Do nothing if the Google API isn't ready yet.
    if (!gapi) return;

    if (gapi.auth2) {
      setGoogleAuthApi(gapi.auth2.getAuthInstance());
    } else {
      gapi.load("auth2", () => {
        gapi.auth2
          .init({
            client_id: window.env.googleClientId,
            cookie_policy: "none",
            fetch_basic_profile: false,
            scope: "email profile openid",
          })
          .then(
            (auth2) => {
              setGoogleAuthApi(auth2);
            },
            (reason) => {
              if (reason.error === "idpiframe_initialization_failed") {
                if (reason.details.includes("Cookies are not enabled")) {
                  dispatch(
                    actions.loginFailed({
                      error:
                        "Google Sign In is not supported with cookies disabled (eg. in Incognito mode). Please enable cookies and try again.",
                    })
                  );
                } else if (reason.details.includes("Not a valid origin")) {
                  dispatch(
                    actions.loginFailed({
                      error:
                        "This environment has not been whitelisted to use with Google Sign In. Please report this to Charli support at support@charli.ai.",
                    })
                  );
                } else {
                  dispatch(
                    actions.loginFailed({
                      error: reason.details || reason.error,
                    })
                  );
                }
                return;
              }
            }
          );
      });
    }
  }, [dispatch, gapi]);

  useEffect(() => {
    if (isClickedEarly && googleAuthApi) {
      buttonRef.current?.click();
      setIsClickedEarly(false);
    } else if (isClickedEarly && !googleAuthApi) {
      // Warn user that Google API is still unavailable after 2s
      const timer = setTimeout(() => {
        dispatch(
          actions.loginFailed({
            error: "Unable to communicate with Google. Please disable any ad-blockers or content blocking scripts and try again.",
          })
        );
        setIsClickedEarly(false);
      }, 2000);
      return () => {
        // Prevent the error from showing if the auth API loads within 2s
        clearTimeout(timer);
      };
    }
  }, [isClickedEarly, googleAuthApi, buttonRef, dispatch]);

  return (
    <Box w={"100%"} display={"flex"} justifyContent={"center"}>
      <Button
        isDisabled={isDisabled}
        size="sm"
        paddingInlineStart="0!important"
        border={`solid 1px ${buttonBackgroundColor}`}
        borderRadius="3px"
        _hover={{}}
        backgroundColor={buttonBackgroundColor}
        isLoading={isClickedEarly && !googleAuthApi}
        ref={buttonRef}
        className="google-login"
        onClick={() => {
          login();
        }}>
        <Stack direction="row" alignItems="center" height="100%">
          <Box backgroundColor={iconBackgroundColor} borderRadius="3px" display="grid" height="100%" width="38px" alignContent="center">
            <Icon as={FcGoogle} height="1.4rem" width="100%" backgroundColor={iconBackgroundColor} />
          </Box>
          <Text fontFamily="Roboto" fontSize="sm" fontWeight="500" color={buttonColor} pl="5px">
            {variant === "signin" ? "Sign in" : "Sign up"} with Google
          </Text>
        </Stack>
      </Button>
    </Box>
  );
};
