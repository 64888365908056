import React, { useContext, useEffect } from "react";
import { Box, Stack, useBreakpointValue, Text, useColorModeValue } from "@chakra-ui/react";
import { Integrations } from "./components";
import { Extensions } from "./components/Extensions";
import { useParams } from "react-router-dom";
import { TabTitle } from "screens/common/components/TabTitle";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";

export const IntegrationsTab = React.memo(() => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { category } = useParams() as { category: string };
  const { setHeaderText } = useContext(SettingsProviderContext);
  const textColor = useColorModeValue("primary.darkGray", "gray.300");

  useEffect(() => {
    setHeaderText("Integrations");
  }, [setHeaderText]);

  return (
    <Box px={isMobile ? ".5rem" : "1rem"} pt={isMobile ? ".5rem" : "1rem"}>
      <TabTitle title="Charli > Integrations" />
      <Stack spacing="3rem" pb={isMobile ? "7rem" : "2rem"}>
        <Integrations category={category} />
        {!category && (
          <Box>
            <Box borderBottom="2px solid #E2E8F0" px=".5rem" py=".3rem" width="100%" mb="1rem">
              <Text fontWeight="bold" fontSize="md" color={textColor}>
                Extensions and Add Ons
              </Text>
            </Box>
            <Extensions />
          </Box>
        )}
      </Stack>
    </Box>
  );
});
